<div class="container-fluid h-100">
  @if (loading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <div style="display: flex; justify-content: space-between">
    <app-header-text [label]="'VDR Status'"></app-header-text>
    <div class="d-flex align-items-center gap-3">
      <app-icon-button
        [label]="'Dashboard'"
        [icon]="'pi pi-clipboard'"
        [iconColor]="'#6f6f6f'"
        [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        (click)="onLinksTextClick()"
      ></app-icon-button>
      <app-popover [isFilter]="isFilter" [label]="'Filter'">
        <div style="display: flex; flex-direction: column; padding: 1rem 2rem">
          <div>
            <label
             class="custom-label"
              >Area</label
            >
            <select
              class="form-select custom-input"
              [(ngModel)]="selectedArea"
              (change)="onAreaSelect($event)"
            >
              <option [selected]="!selectedArea" value="">
                -- Select Area --
              </option>
              @for (option of areaDropdown; track $index) {
              <option [value]="option.areaName">{{ option.areaName }}</option>
              }
            </select>
          </div>
          <div style="margin-top: 2rem">
            <span
              class="link underline"
              style="font-size: medium"
              (click)="onResetFilter()"
              >Reset</span
            >
          </div>
        </div>
      </app-popover>
    </div>
  </div>
  <div class="row gap-2 gap-lg-0 h-50">
    <div class="col-12 col-lg-4 col-md-12 mb-3 h-100 chart-card">
      <donut-chart
        [id]="'receiptStatus'"
        [cardTitle]="'Receipt Status'"
        [chartData]="receiptStatusData"
        [loading]="receiptChartLoading"
      ></donut-chart>
    </div>
    <div class="col-12 col-lg-4 col-md-12 mb-3 h-100 chart-card">
      <donut-chart
        [id]="'reviewStatus'"
        [cardTitle]="'Review Status'"
        [chartData]="reviewStatusData"
        [drilldown]="true"
        [handleDrillDown]="handleDrillDown"
        [loading]="reviewChartLoading"
      ></donut-chart>
    </div>
    <div class="col-12 col-lg-4 col-md-12 mb-3 h-100 chart-card" #chartRef>
      <single-column
        [id]="'inprogressCriticalDoc'"
        [cardTitle]="'In Progress - Critical Documents'"
        [data]="inProgressCriticalDocData"
        [stacked]="true"
        [legendDisabled]="true"
        [yAxisName]="'Count of Documents'"
        [chartColor]="color"
        [endElement]="endElement"
        [loading]="barChartLoading"
      ></single-column>
    </div>
    <div
      class="col-12 pb-4"
      style="height: 32rem; padding-right: 0.5rem; padding-left: 0.5rem"
    >
      <app-table
        [title]="'Critical Document Status'"
        [data]="criticalDocStatusTableData.rows"
        [cols]="criticalDocStatusTableData.columns"
        [defaultColumns]="initialColumnData"
        [loading]="tableLoading"
        [paginationOptions]="{
          first: first,
          onChangePage: pageChange,
          paginationRequired: true,
          totalRecords: this.criticalDocStatusTableData.count,
          rows: this.rowsPerPage
        }"
        [onDownloadClick]="fetchCriticalDocStatusTableDataForDowload"
      ></app-table>
    </div>
  </div>
</div>

<ng-template #endElement>
  <a (click)="onShowAllClick()" class="showAll-link link">
    {{ showAll ? "Reset" : "Show All" }}
  </a>
</ng-template>

<ng-template #openLinkTemplate let-rowData>
  <div>
    <app-icon
      color="#29c8c1"
      (click)="onOpenFormLinkClick(rowData)"
      [icon]="'pi pi-external-link'"
    ></app-icon>
  </div>
</ng-template>

@if(isLinksTableVisible){
<app-table
  [title]="'Dashboard'"
  [inTableDialog]="true"
  [isTableDialogVisible]="isLinksTableVisible"
  (onClosePopup)="onClosePopup()"
  [cols]="linksData.columns"
  [defaultColumns]="linksData.columns"
  [data]="linksData.data"
  [hideDownloadIcon]="true"
  [hideCustomizeColumnIcon]="true"
></app-table>
}
