import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { OverallScurveModel } from '../../types/OverallScurveModel';
const {
  OVERALL_SCURVE
} = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class OverallScurveServices {

  constructor() { }
  private http = inject(HttpClient);
  getOverallScurveData(): Observable<{data:OverallScurveModel}> {
    return this.http.get<{data:OverallScurveModel}>(OVERALL_SCURVE);
  }
}
