import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  HostListener,
  inject,
  ChangeDetectorRef,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { isEmpty, omit, orderBy } from 'lodash-es';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule, TablePageEvent } from 'primeng/table';
import { firstValueFrom } from 'rxjs';
import { dashboardRedirectUrl } from '../../core/constants';
import { AreaImageComponent } from '../../shared/components/area-image/area-image.component';
import { CardComponent } from '../../shared/components/card/card.component';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { ProjectHighlightConcernsFormComponent } from '../../shared/components/forms/project-highlight-concerns-form/project-highlight-concerns-form.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { StatusIndicatorComponent } from '../../shared/components/status-indicator/status-indicator.component';
import { SwitchComponent } from '../../shared/components/switch/switch.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { FileServiceService } from '../../shared/services/file-service/file-service.service';
import { HomePageService } from '../../shared/services/home/home-page.service';
import { ProjectHighlightConcernService } from '../../shared/services/project-highlight-concern/project-highlight-concern.service';
import { ProjectHighlight } from '../../shared/types/Project-highlight';
import { StatCard } from '../../shared/types/StatCard';
import {
  convertDateToCommonFormat,
  getFileNameFromS3Url,
  toTitleCase,
} from '../../shared/utils/utility-functions';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { SingleSelectComponent } from '../../shared/components/single-select/single-select.component';
import { PopoverComponent } from '../../shared/components/popover/popover.component';
import { SubmitButtonComponent } from '../../shared/components/submit-button/submit-button.component';
import dayjs from 'dayjs';

type MenuTabs =
  | 'dataQuality'
  | 'aiAssist'
  | 'reports'
  | 'dashboard'
  | 'lastRefreshedDate'
  | '';


@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    CardComponent,
    TableComponent,
    CommonModule,
    IconComponent,
    CardComponent,
    CommonModule,
    IconComponent,
    SwitchComponent,
    StatusIndicatorComponent,
    AreaImageComponent,
    RouterModule,
    CustomDialogComponent,
    ProjectHighlightConcernsFormComponent,
    LoaderComponent,
    NgbTooltip,
    InputIconModule,
    InputTextModule,
    IconFieldModule,
    TableModule,
    PopoverComponent,
    SubmitButtonComponent,
    FormsModule,
  ],
  templateUrl: './home.component.html',
  styleUrl: './home.component.css',
})
export class HomeComponent
  implements OnInit, OnChanges, AfterViewInit
{
isEmpty(arg: string) {
return isEmpty(arg)
}
  @ViewChild('actionTemplateProjectHighLight', { static: false })
  actionTemplateProjectHighLight!: TemplateRef<any>;
  @ViewChild('downloadActionTemplate')
  downloadActionTemplate!: TemplateRef<any>;
  @ViewChild('dataQualityTable') dataQualityTable!: TableComponent;

  

  projectHighlights: {
    columns: ColumnProps<ProjectHighlight>[];
    data: ProjectHighlight[];
    count: number;
  } = { columns: [], data: [], count: 0 };

  isConcern = false;

  isProjectConcernFormOpen = false;

  dataQuality: {
    columns: ColumnProps[];
    data: ProjectHighlight[];
    count: number;
  } = {
    columns: [],
    data: [],
    count: 0,
  };
  dataQualityRowsPerPage: number = 10;
  dataQualityFirst: number = 0;
  dataQualityPage: number = 0;
  dataQualityTableLoading: boolean = false;
  selectedTabMenu: MenuTabs = '';
  isDialogVisible: boolean = false;
  isFilter: boolean = false

  selectedMenuIconSelector: Partial<Record<MenuTabs, string>> = {
    dataQuality: 'dataset',
    aiAssist: 'forum',
    reports: 'bar_chart',
    lastRefreshedDate: 'show_chart',
  };

  lastRefreshedDate: string = '';

  lastRefreshedDatasets: {
    columns: ColumnProps[];
    data: Record<string, any>[];
  } = { columns: [], data: [] };
  loading: boolean = false;
  isConcernTableLoading: boolean = false;
  isManualToggle: boolean = false;
  isLoading: boolean = false;
  monthFrom?: string;
  monthTo?: string
  today?: string

  constructor(
    private projectHighlightService: ProjectHighlightConcernService,
    private homePageService: HomePageService,
    private router: Router,
    private fileServiceService: FileServiceService,
  ) {
    this.monthTo = this.today = dayjs().format('YYYY-MM-DD')
    this.monthFrom = dayjs().startOf('month').format('YYYY-MM-DD')
  }
  ngAfterViewInit(): void {
    this.reportsColumns = [
      { field: 'name' as never, header: 'Report Name' },
      {
        field: 'download' as never,
        header: 'Download',
        bodyTemplate: this.downloadActionTemplate,
        style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
      },
    ];
    // this.scrollToBottom();
    // throw new Error('Method not implemented.');
  }

  defaultColsDataQuality: ColumnProps<any>[] = [
    {
      field: 'equipmentTag',
      header: 'Equipment Tag',
    },
    {
      field: 'area',
      header: 'Area',
    },
    {
      field: 'cwp',
      header: 'CWP',
    },
    {
      field: 'remark',
      header: 'Remarks',
    },
  ];

  reportsData: { name: string; download: string }[] = [];

  defaultColumns: ColumnProps<any>[] = [
    {
      field: 'area',
      header: 'Area',
    },
    {
      field: 'highlights/Concerns',
      header: 'Highlights/Concerns',
    },
    {
      field: 'nextAction',
      header: 'Next Action',
    },
    {
      field: 'reportedBy',
      header: 'Reported By',
    },
    {
      field: 'month',
      header: 'Month',
    },
    {
      field: 'action',
      header: 'Supporting Artifacts',
      bodyTemplate: this.actionTemplateProjectHighLight,
    },
  ];

  dashboardRedirectUri: string = dashboardRedirectUrl;

  selectedArea: string = 'Overall';
  isChecked = false;

  first: number = 0;
  rowsPerPage: number = 10;
  totalRecords!: number;

  showImage: boolean = false;
  selectedRow: ProjectHighlight | null = null;
  imageLoading: boolean = false;
  imageUrl: string = '';
  private cdr = inject(ChangeDetectorRef);

  handleCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    this.isChecked = checkbox.checked;
  }

  menuItems = [
    {
      icon: 'pi pi-database',
      label: 'Data Quality',
      command: () => {
        this.onClickTabMenu('dataQuality');
      },
    },
    {
      icon: 'pi pi-comments',
      label: 'AI Assist',
      command: () => {
        this.router.navigate(['/ai-assist']);
      },
    },
    {
      icon: 'pi pi-chart-bar',
      label: 'Reports',
      command: () => {
        this.onClickTabMenu('reports');
      },
    },
    {
      icon: 'pi pi-chart-line',
      label: 'Dashboard',
      command: () => {
        window.open(this.dashboardRedirectUri, '_blank');
      },
    },
  ];

  reportsColumns: ColumnProps<unknown>[] = [
    { field: 'name' as never, header: 'Report Name' },
    {
      field: 'download' as never,
      header: 'Download',
      bodyTemplate: this.downloadActionTemplate,
    },
  ];

  // cardData: {
  //   title: string;
  //   data: StatCard[];
  //   redirectUrl?: string | Function;
  // }[] = this.cardDataInitialState;

  cardLoading?: boolean = false;

  checked: boolean = false;

  ngOnInit(): void {
    this.loading = true;
    this.getProjectHighlightConcernData();
    this.loadLastRefreshedDate();
    this.getCardData();
    this.getDataQualityTableData();
    this.fetchReports();
  }

  onClickTabMenu = (type: MenuTabs) => {
    if (type !== 'dashboard') {
      this.selectedTabMenu = type;
      this.isDialogVisible = true;
    }  else {
      this.router.navigate(['/dashboard']);
    }
  };

  getDataQualityTableData = (isDownloadClicked?: boolean) => {
    this.homePageService
      .getDataQualityTableData(
        {
          pageNo: Math.floor(
            this.dataQualityFirst / this.dataQualityRowsPerPage
          ),
          noOfRecords: this.dataQualityRowsPerPage,
        },
        isDownloadClicked
      )
      .subscribe({
        next: (response) => {
          if (isDownloadClicked) {
          } else {
            this.dataQuality = response.data;
          }
          this.dataQualityTableLoading = false;
        },
        error: (err) => {
          this.dataQualityTableLoading = false;
          console.error('Failed to fetch Data Quality data:', err);
        },
      });
  };

  getDataQualityTableDataForDownload = async (): Promise<
    Record<string, any>[]
  > => {
    try {
      const res = await firstValueFrom(
        this.homePageService.getDataQualityTableData(
          {
            pageNo: this.dataQualityFirst,
            noOfRecords: this.dataQualityRowsPerPage,
          },
          true
        )
      );
      return res.data.data;
    } catch (error) {
      console.error('Failed to fetch Data Quality data:', error);
      return [];
    }
  };

  fetchReports = () => {
    this.homePageService.getReports().subscribe({
      next: (response) => {
        this.reportsData = response.data.map((keyObj) => {
          return {
            name: getFileNameFromS3Url(keyObj.key),
            download: keyObj.key,
          };
        });
      },
    });
  };

  downloadReports = async () => {
    const response = await firstValueFrom(this.homePageService.getReports());

    const finalData = response.data.map((keyObj) => {
      return {
        name: getFileNameFromS3Url(keyObj.key),
        download: keyObj.key,
      };
    });

    return finalData || [];
  };

  getCardData = async () => {
    this.cardLoading = true;
    const area =
      !this.selectedArea || this.selectedArea?.toLowerCase() === 'overall'
        ? 'Overall'
        : this.selectedArea;
    this.homePageService.getCardDataByArea(area).subscribe({
      next: (response) => {
        const mappedData = this.cardDataInitialState.map((initData) => {
          const apiTitleObj = response.data.find(
            (val) => val.title?.toLowerCase() === initData.title?.toLowerCase()
          );
          return {
            ...initData,
            data: apiTitleObj?.data || [],
          };
        });

        this.cardData = mappedData;
        this.cardLoading = false;
      },
      error: (err) => {
        console.error('error while fetching card data', err);
      },
    });
  };

  loadLastRefreshedDate() {
    this.homePageService.refreshDetails().subscribe({
      next: (response) => {
        if (response.data.data?.length) {
          const ordered = orderBy(response.data.data, ['lastRefreshDate']);
          const max = ordered[ordered.length - 1]['lastRefreshDate'];
          this.lastRefreshedDate = convertDateToCommonFormat(max);
          this.lastRefreshedDatasets = response.data;
        }
      },
      error: (err) => {
        console.error('Error fetching last refreshed date:', err); // Handle any errors
      },
    });
  }

  getProjectHighlightConcernData = async (isDownloadClicked?: boolean) => {
    this.isConcernTableLoading = true;
    this.defaultColumns = [
      {
        field: 'area',
        header: 'Area',
      },
      {
        field: this.isConcern === true ? 'concerns' : 'highlights',
        header: this.isConcern === true ? 'Concerns' : 'Highlights/Concerns',
      },
      {
        field: 'nextAction',
        header: 'Next Action',
      },
      {
        field: 'reportedBy',
        header: 'Reported By',
      },
      {
        field: 'month',
        header: 'Month',
      },
      {
        field: 'action',
        header: 'Supporting Artifacts',
        bodyTemplate: this.actionTemplateProjectHighLight,
      },
    ];
    const area =
      !this.selectedArea || this.selectedArea?.toLowerCase() === 'overall'
        ? ''
        : this.selectedArea;
    const filters = {
      ...(this.isConcern ? { isConcern: this.isConcern } : {}),
      ...(area ? { area: [area, 'Not Available'] } : {}),
      ...(this.monthFrom && this.monthTo
        ? {
            monthDate: [
              this.monthFrom,
              this.monthTo,
            ],
          }
        : {}),
    };
    this.projectHighlightService
      .getData({
        filters,
        pageNo: Math.floor(this.first / this.rowsPerPage),
        rows: this.rowsPerPage,
        isDownloadClicked,
      })
      .subscribe({
        next: (res) => {
          if (isDownloadClicked) {
          } else {
            this.projectHighlights = {
              columns: res.data.columns
                .flatMap((el) => {
                  if (!this.isConcern && el.field === 'highlights/concerns') {
                    return [
                      {
                        ...el,
                        field: 'highlights',
                      },
                    ];
                  }
                  if (!this.isConcern && el.field === 'concerns') {
                    return [];
                  }

                  return [el];
                })
                .concat({
                  field: 'action',
                  header: 'Supporting Artifacts',
                  bodyTemplate: this.actionTemplateProjectHighLight,
                  style: {
                    justifySelf: 'center',
                    textAlign: 'center',
                  },
                }),
              data: res.data.rows.map((el) => {
                if (!this.isConcern && el.isConcern) {
                  return {
                    ...el,
                    highlights: el.concerns,
                    concerns: '',
                  };
                }
                return el;
              }),
              count: res.data.count,
            };
          }
          // this.totalRecords =
          // if (this.paginationOptions) {
          //   this.paginationOptions.totalRecords = this.projectHighlights.count;
          //   console.log('check', this.paginationOptions)
          // }
          this.loading = false;
          this.isConcernTableLoading = false;
        },
        error: (error) => {
          console.error('Error in fetching data', error);
          this.loading = false;
          this.isConcernTableLoading = false;
        },
      });
  };

  onApplyFilter = (e: any) => {
    e?.preventDefault();
    this.resetConcernTable()
    this.isFilter = true
    this.getProjectHighlightConcernData();
  };

  onResetConcernFilter = () => {
    this.isFilter = false
    this.monthFrom = dayjs().startOf('month').format('YYYY-MM-DD')
    this.monthTo = dayjs().format('YYYY-MM-DD')
    this.resetConcernTable();
    this.cdr.detectChanges();
    this.getProjectHighlightConcernData();
  };

  fromDateChange(event: any) {
    this.monthTo = ""
  }


  getProjectHighlightConcernDataForDownload = async (): Promise<
    Record<string, any>[]
  > => {
    const area =
      !this.selectedArea || this.selectedArea?.toLowerCase() === 'overall'
        ? ''
        : this.selectedArea;

    const filters = {
      ...(this.isConcern ? { isConcern: this.isConcern } : {}),
      ...(area ? { area: [area, 'Not Available'] } : {}),
    };

    try {
      const response = await firstValueFrom(
        this.projectHighlightService.getData({
          filters,
          pageNo: Math.floor(this.first / this.rowsPerPage),
          rows: this.rowsPerPage,
          isDownloadClicked: true,
        })
      );
      const finalData = response.data.rows.map((el) => {
        return {
          ...el,
          ...omit(el, 'monthDate'),
          month: el.monthDate,
        };
      });
      return finalData || [];
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    }
  };

  onClickImage = async (rowData: ProjectHighlight) => {
    this.showImage = true;
    this.selectedRow = rowData;
    if (rowData.imageUrlKey) {
      this.imageLoading = true;
      if (!this.imageUrl[`${rowData.id}`]) {
        this.fileServiceService
          .getPresignedUrls('form', rowData.imageUrlKey)
          .subscribe({
            next: (res) => {
              this.imageUrl = res.data;
            },
          });
      }
      this.imageLoading = false;
    }
  };

  closeImageClick = () => {
    this.showImage = false;
  };

  getFileNameFromS3Url = (url: string) => {
    return getFileNameFromS3Url(url);
  };

  onClickDownload = async (row: { name: string; key: string }) => {
    if (row.name) {
      const res = await fetch(row.key, {
        headers: new Headers({ 'Content-Type': 'Blob' }),
      });
      const json = await res.blob();
      const href = window.URL.createObjectURL(json);
      const anchorElement = document.createElement('a');
      anchorElement.href = href;
      anchorElement.download = row.name;
      document.body.appendChild(anchorElement);
      anchorElement.click();
      document.body.removeChild(anchorElement);
      window.URL.revokeObjectURL(href);
    }
  };

  onClosePopup = () => {
    if (!this.isDialogVisible) {
      return;
    }
    this.isDialogVisible = false;
  };

  getPopupTitle = () => {
    const titleClass = this.selectedMenuIconSelector[this.selectedTabMenu];
    const title = toTitleCase(this.selectedTabMenu || '');
    return { titleClass, title };
  };

  pageChange = (event: TablePageEvent) => {
    this.first = event.first;
    this.rowsPerPage = event.rows;
    this.getProjectHighlightConcernData();
  };

  onChangeDataQualityPage = (event: TablePageEvent) => {
    if (event.first !== undefined) {
      this.dataQualityPage = event.first;
      this.dataQualityRowsPerPage = event.rows;
      this.dataQualityFirst = event.first;
      this.getDataQualityTableData();
    }
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isConcern'] && this.isManualToggle) {
      this.isManualToggle = false; // Reset the flag
      this.getProjectHighlightConcernData();
    }
  }

  onToggleSwitch = (event: any) => {
    const eventt = event as InputSwitchChangeEvent;
    this.isManualToggle = true; // Mark as a manual toggle
    this.isConcern = eventt.checked;

    if (this.isConcern !== undefined) {
      this.resetConcernTable();
      this.getProjectHighlightConcernData();
    }
  };

  // Method to handle redirection

  cardDataInitialState = [
    {
      title: 'Overall Progress',
      redirectUrl: '/s-curve',
      data: [
        {
          name: 'Cumulative Plan',
          label: 'Cumulative Plan',
          value: '',
          color: 'black',
        },
        {
          name: 'Cumulative Actua',
          label: 'Cumulative Actua',
          value: '',
          color: 'black',
        },
        {
          name: 'Variance',
          label: 'Variance',
          value: '',
          color: 'black',
        },
      ],
    },
    {
      title: 'Engineering Status',
      redirectUrl: 'engineering-status',
      data: [
        {
          name: 'ifcStatus',
          label: 'IFC Status',
          value: '9',
          color: 'black',
        },
        {
          name: 'releaseStatus',
          label: 'Release Status',
          value: '9',
          color: 'black',
        },
      ],
    },
    {
      title: 'Procurement Status(Plan/Actual)',
      redirectUrl: '/procurement-status',
      data: [
        {
          name: 'mrStatus',
          label: 'MR Status',
          value: '',
          color: 'black',
        },
        {
          name: 'polySiliconRelease',
          label: 'PolySilicon Release',
          value: '',
          color: 'black',
        },
        {
          name: 'poRelease',
          label: 'PO Release',
          value: '',
          color: 'black',
        },
      ],
    },
    {
      title: 'Construction Status',
      redirectUrl: '/construction-status',
      redrectUrl: undefined,
      data: [
        {
          name: 'civilWorks',
          label: 'Civil',
          value: '9',
          color: 'black',
        },
        {
          name: 'structuralFabrication',
          label: 'Manpower',
          value: '9',
          color: 'black',
        },
        {
          name: 'strucuturalErection',
          label: 'Structural',
          value: '9',
          color: 'black',
        },
      ],
    },
    {
      title: 'VDR Status',
      redirectUrl: '/vdr-status',
      redrectUrl: undefined,
      data: [
        {
          name: 'vdrsReceived',
          label: 'Project Docs Overdue',
          value: '9',
          color: 'black',
        },
        {
          name: 'criticalOverdue',
          label: 'VDRs Received',
          value: '9',
          color: 'black',
        },
      ],
    },
    {
      title: 'HSE',
      redirectUrl: '/hse',
      data: [
        {
          name: 'firstAidCases',
          label: 'First Aid Cases',
          value: '9',
          color: 'black',
        },
        {
          name: 'lti',
          label: 'LTI Free Days',
          value: '9',
          color: 'black',
        },
        {
          name: 'ltifr',
          label: 'Safe Man hours since last LTI',
          value: '9',
          color: 'black',
        },
      ],
    },
  ];

  cardData: {
    title: string;
    data: StatCard[];
    redirectUrl?: string | Function;
  }[] = this.cardDataInitialState;

  redirectToSchedule() {
    this.router.navigate(['/project-scheduling'], {
      queryParams: {
        area: this.selectedArea || 'Overall',
      },
    });
  }

  isImageDisabled: boolean = false;
  imageClass: string = '';
  isGanttChart?: boolean;
  actionButtonsRequired?: boolean;

  handleAreaClick = (area: string) => {
    this.selectedArea = area;
    this.getCardData();
    this.getProjectHighlightConcernData();
  };

  handle3DClick() {
    console.log('3D View clicked');
  }

  handle4DClick() {
    console.log('4D View clicked');
    // Additional logic for 4D view
  }

  openFormClick = () => {
    this.isProjectConcernFormOpen = true;
  };

  onCloseProjectForm = async (alertType: string) => {
    if (alertType === 'success') {
      this.getProjectHighlightConcernData().then(() => {
        this.isProjectConcernFormOpen = false;
      });
    } else {
      this.isProjectConcernFormOpen = false;
    }
  };

  getRowClass = (item: ProjectHighlight): { [key: string]: boolean } => {
    return {
      'text-red': item.isConcern,
    };
  };
  resetConcernTable = () => {
    this.rowsPerPage = 10;
    this.first = 0;
  };

}
