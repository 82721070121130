import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { camelCase, isNull, startCase } from 'lodash-es';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { monthDropDownValues } from '../../core/constants';
import { ColumnProps } from '../interfaces/table-interface';
import { saveAsExcelFile } from './global-modules';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
const tzMapper: Record<string, string> = {
  'Asia/Calcutta': 'IST',
};
export const convertDateToCommonFormat = (date?: string) => {
  if (!date) {
    return '';
  }
  const localTz = dayjs.tz.guess();
  const formattedDate = dayjs(date)
    .tz(localTz)
    .format(`MMM DD, YYYY hh:mm A ${tzMapper[localTz] || 'z'}`);
  return formattedDate;
};
export const commonDateTimeFormat = (date?: string | Date | null): any => {
  if (!date) {
    return '';
  }
  const localTz = dayjs.tz.guess();
  const dayjsDate = dayjs(date);
  if (dayjsDate.isValid()) {
    const formattedDate = dayjs(date).tz(localTz).format('YYYY-MM-DD hh:mm A');
    return formattedDate;
  }
  return date;
};
export const convertDateToYYMMDD = (date?: string | Date | null): any => {
  if (!date) {
    return '';
  }
  const localTz = dayjs.tz.guess();
  const dayjsDate = date ? dayjs(date) : dayjs();
  if (dayjsDate.isValid()) {
    const formattedDate = dayjsDate.tz(localTz).format('YYYY-MM-DD');
    return formattedDate;
  }
  return date;
};

export const getCurrentDateTime = () => {
  return convertDateToCommonFormat(
    dayjs().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
  );
};

export const isNumericOrDateField = (value: any): boolean => {
  // Check if the value is a valid date string
  const date = new Date(value);
  const isDate = !isNaN(date.getTime());

  // Check if the value is numeric
  const isNumeric = !isNaN(parseFloat(value)) && isFinite(value);

  return isDate || isNumeric;
};

export const lightenHexColor = (
  hexWithHash: string,
  percent: number
): string => {
  // Remove the hash (#) if it's there
  const hex = hexWithHash.replace(/^#/, '');

  // Convert hex color to RGB
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  // Increase RGB values by the percentage
  r = Math.min(255, Math.floor(r + ((255 - r) * percent) / 100));
  g = Math.min(255, Math.floor(g + ((255 - g) * percent) / 100));
  b = Math.min(255, Math.floor(b + ((255 - b) * percent) / 100));

  // Convert RGB back to hex
  const newHex = `#${r.toString(16).padStart(2, '0')}${g
    .toString(16)
    .padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

  return newHex.toUpperCase();
};

export const toTitleCase = (str: string) => {
  return startCase(camelCase(str));
};

export const getFileNameFromS3Url = (url: string) => {
  if (!url) {
    return '';
  }
  // Use the URL constructor to parse the URL
  const parsedUrl = new URL(url);

  // Extract the pathname and split it to get the segments
  const pathSegments = parsedUrl.pathname.split('/');

  // The last segment is the file name
  const fileName = pathSegments[pathSegments.length - 1];

  return isNull(fileName)
    ? 'No Image to display'
    : decodeURIComponent(fileName);
};

export const generateMonthForYear = () => {
  return Object.keys(monthDropDownValues)
    .sort()
    .map((el) => {
      return {
        id: el,
        value: monthDropDownValues[el as keyof typeof monthDropDownValues],
      };
    });
};

export const getWeekOfMonth = (date: Date): number => {
  // Copy the date to avoid modifying the original date
  const currentDate = new Date(date.getTime());

  // Get the first day of the month
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  // Calculate the day of the week for the first day of the month (0: Sunday, 1: Monday, ..., 6: Saturday)
  const startDayOfWeek = startOfMonth.getDay();

  // Calculate the offset from the start of the month
  const offset = (currentDate.getDate() + startDayOfWeek - 1) / 7;

  // Calculate the week number
  return Math.floor(offset) + 1;
};

export const getMonthName = (date: Date): string => {
  return date.toLocaleString('default', { month: 'long' });
};

export const formatThousandNumberToK = (num?: number | null) => {
  if (num === undefined || num === null) {
    return num;
  }

  // Handle millions
  if (num >= 1000000) {
    return `${Number((num / 1000000).toPrecision(4))}M`; // Converts number to millions (e.g., 3,336,454 becomes "3.336M")
  }

  // Handle thousands
  if (num >= 1000) {
    return `${Number((num / 1000).toPrecision(3))}k`; // Converts number to thousands (e.g., 1500 becomes "1.5k")
  }

  // Return number as-is if less than 1000
  return num;
};

export const downloadExportExcel = (
  onDownloadClick: () => Promise<Record<string, any>[]>,
  cols: ColumnProps<any>[],
  appliedColumns: ColumnProps<any>[],
  title: string
) => {
  import('xlsx').then((xlsx) => {
    if (onDownloadClick) {
      console.log('downloadontable');

      onDownloadClick().then((res) => {
        if (cols.some((el) => !!el.children?.length)) {
          // Handle grouped columns
          const parentHeaders: string[] = [];
          const subHeaders: string[] = [];
          const sheetData = [];

          // Traverse columns and create parent and subheaders
          cols.forEach((col) => {
            if (col.children && col.children.length) {
              // Push the parent header with empty subheader placeholders
              parentHeaders.push(col.header);
              col.children.forEach((subCol) => {
                parentHeaders.push(''); // Empty parent placeholder for each child
                subHeaders.push(subCol.header);
              });
            } else {
              parentHeaders.push(col.header);
              subHeaders.push(''); // No sub-header for single column
            }
          });

          // Add headers to sheetData
          sheetData.push(parentHeaders);
          sheetData.push(subHeaders);

          // Map data rows based on grouped columns
          res.forEach((row) => {
            const rowData: any[] = [];
            cols.forEach((col) => {
              if (col.children && col.children.length) {
                col.children.forEach((subCol) => {
                  rowData.push(row[subCol.field as any]);
                });
              } else {
                rowData.push(row[col.field as any]);
              }
            });
            sheetData.push(rowData);
          });

          // Convert to worksheet
          const worksheet = xlsx.utils.aoa_to_sheet(sheetData);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
          };
          const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });

          // Save the file
          saveAsExcelFile(
            excelBuffer,
            typeof title === 'string' ? title : 'data'
          );
        } else {
          // Flat columns (original logic)
          const tableData = res.map((row) => {
            const obj: Record<string, any> = {};
            appliedColumns.forEach((col) => {
              if (typeof col.field === 'string') {
                if (col.field?.toLowerCase() !== 'action') {
                  obj[col.header] = row[col.field];
                }
              }
            });
            return obj;
          });
          const worksheet = xlsx.utils.json_to_sheet(tableData);
          const workbook = {
            Sheets: { data: worksheet },
            SheetNames: ['data'],
          };
          const excelBuffer = xlsx.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
          });

          saveAsExcelFile(
            excelBuffer,
            typeof title === 'string' ? title : 'data'
          );
        }
      });
    }
  });
};
