import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { NgbOffcanvas, NgbOffcanvasRef } from '@ng-bootstrap/ng-bootstrap';
import { SidebarContentComponent } from './sidebar-content.component';
import { MenuItem } from '../../interface/Menu';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  template: ``,
})
export class SidebarComponent {
  @Input() visible: boolean = false;
  @Output() onHide = new EventEmitter<void>();

  private offcanvasService = inject(NgbOffcanvas);
  private offcanvasRef?: NgbOffcanvasRef;

  ngOnChanges() {
    if (this.visible) {
      this.openOffcanvas();
    } else if (this.offcanvasRef) {
      this.offcanvasRef.dismiss();
    }
  }

  openOffcanvas() {
    this.offcanvasRef = this.offcanvasService.open(SidebarContentComponent, { position: 'start', scroll: true, panelClass: 'sidebar-content' });
    this.offcanvasRef.componentInstance.menus = this.getMenus();
    this.offcanvasRef.result.finally(() => {
      this.onHide.emit();
    });
  }

  getMenus() {
    const menus: MenuItem[] = [
      { label: 'Home', icon: 'pi pi-home', path: '/' },
      { label: 'Engineering Status', icon: 'pi pi-chart-line', path: '/engineering-status' },
      { label: 'Procurement Status', icon: 'pi pi-chart-line', path: '/procurement-status' },
      { label: 'Construction Status', icon: 'pi pi-chart-line', path: '/construction-status' },
      { label: 'Materials Planning', icon: 'pi pi-file', path: '/materials-planning' },
      { label: 'VDR Status', icon: 'pi pi-chart-line', path: '/vdr-status' },
      { label: 'HSE', icon: 'pi pi-chart-line', path: '/hse' },
      { label: 'Delay Tracking', icon: 'pi pi-chart-line', path: '/delay-tracking' },
      { label: 'Delay Submission Form', icon: 'pi pi-file', path: '/delay-form/new' },
      { label: 'S Curve', icon: 'pi pi-chart-line', path: '/s-curve' },
      // {
      //   label: 'Admin Module', icon: 'pi pi-address-book', items: [
      //     { label: 'Configuration Module', icon: 'pi pi-cog', path: '/admin/forms' },
      //     { label: 'User Management', icon: 'pi pi-user-edit' },
      //     { label: 'Master Data Management', icon: 'pi pi-server' }
      //   ]
      // },
      // { label: 'Create Service Ticket', icon: 'pi pi-ticket', path: '/create-service-ticket' }
    ];
  
    return menus;
  }
}
