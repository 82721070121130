import { Component, inject } from '@angular/core';
import { HeaderTextComponent } from "../../shared/components/header-text/header-text.component";
import { LoaderComponent } from "../../shared/components/loader/loader.component";
import { ScurvedataCardComponent } from "../../shared/components/scurvedata-card/scurvedata-card.component";
import { SingleColumnComponent } from "../../shared/components/single-column/single-column.component";
import { Router } from 'express';
import { TablePageEvent } from 'primeng/table';
import { ChartDataType } from '../../shared/interface/ChartTypes';
import { ScurveDataCardModel } from '../../shared/interface/ScurveDataCardModel';
import { OverallScurveServices } from '../../shared/services/overall-scurve/overall-scurve.service';
import { OverallScurveModel } from '../../shared/types/OverallScurveModel';
import { TableData } from '../../shared/types/TableProps';
import { ProcurementScurveServices } from '../../shared/services/procurement-scurve/procurement-scurve.service';
import { MaterialsPlanningService } from '../../shared/services/materials-planning/materials-planning.service';
import { BehaviorSubject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { SingleSelectComponent } from "../../shared/components/single-select/single-select.component";

@Component({
  selector: 'app-discipline-scurve',
  standalone: true,
  imports: [HeaderTextComponent, LoaderComponent, SingleColumnComponent, FormsModule, SingleSelectComponent],
  templateUrl: './discipline-scurve.component.html',
  styleUrl: './discipline-scurve.component.css'
})
export class DisciplineScurveComponent {
  private ProcurementScurveService = inject(ProcurementScurveServices);
  private MaterialsPlanningService = inject(MaterialsPlanningService);

  first: number = 0;
  page: number = 0;
  rowsPerPage: number = 10;
  disciplineScurveData: OverallScurveModel = {
    chartData: {} as ChartDataType,
    tableData: {} as TableData,
    metaData: [] as ScurveDataCardModel[]
  } as OverallScurveModel;
  loading: boolean = false;
  paginationOptions: {
    paginationRequired?: boolean;
    onChangePage?: (event: TablePageEvent) => void;
    onRowChange?: () => void;
    rows?: number;
    totalRecords?: number;
    first?: number;
  } | undefined = {
      first: this.first,
      rows: this.rowsPerPage,
      onChangePage: this.pageChange,
      paginationRequired: false
    }
  disciplines: { value: string }[] = []
  selectedDiscipline: string = ''
  disciplineTrigger$ = new BehaviorSubject<void>(undefined);
  isChartLoading: boolean = false

  pageChange(event: TablePageEvent) {
    this.first = event.first;
    this.rowsPerPage = event.rows;

  }
  fetchDisciplineScurveData(filters: any) {
    this.isChartLoading = true;
    this.ProcurementScurveService.getDisciplineLineChartData(filters).subscribe({
      next: (response) => {
        this.disciplineScurveData = response.data;
        this.isChartLoading = false;
      },
      error: (error) => {
        console.error("Failed to fetch discipline s curve data:", error);
        this.isChartLoading = false;
      }
    })
  }
  fetchFilters() {
    this.loading = true;
    this.MaterialsPlanningService.getFilters().subscribe({
      next: (response: any) => {
        this.disciplines = response.data.disciplines.map((el: string) => {
          return { value: el }
        })
        this.loading = false;
      },
      error: (err) => {
        console.error("error in fetching filters", err)
        this.loading = true;
      }
    })
  }
  onChangeDiscipline(event: any) {
    this.selectedDiscipline = event.target.value
    this.disciplineTrigger$.next()
  }
  ngOnInit(): void {
    this.selectedDiscipline = 'Static'
    this.fetchFilters()
    this.disciplineTrigger$.subscribe({
      next: () => {
        this.fetchDisciplineScurveData({ discipline: this.selectedDiscipline })
      }
    })
  }
}
