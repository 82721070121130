import { Component, ElementRef, HostListener, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common';
@Component({
  selector: 'app-popover',
  standalone: true,
  imports: [IconComponent, CommonModule],
  templateUrl: './popover.component.html',
  styleUrl: './popover.component.css',
})
export class PopoverComponent {
  @Input() label: string = '';
  @Input() isFilter: boolean = false;
  @Input() height: string = '';

  isOpen = false; // Control visibility of the popover

  togglePopover() {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  handleOutsideClick(event: Event) {
    if (!this.el.nativeElement.contains(event.target)) {
      this.isOpen = false;
    }
  }

  constructor(private el: ElementRef) {}
}
