import * as Highcharts from "highcharts";


// Define a custom theme
const ChartTheme: Highcharts.Options = {
  lang: {
    thousandsSep: ',',
  },
  colors: [
    "#29c8c1",
    "#335e6a",
    "#a1c38c",
    "#4cabdb",
    "#89CBD2",
    "#ff9100",
    "#85BDDA",
    "#FBCDAA",
    "#9FA9A3",
  ],
  title: {
    style: {
      color: "#29C8C1",
      fontSize: "1rem",
    },
  },
  plotOptions: {
    series: {
      dataLabels: {
        style: {
          fontSize: "0.8rem",
          fontWeight: "normal",
          textOutline: 'none',

        },
        formatter: function() {
          return this.y !== 0 ? this.y?.toLocaleString() : null;
      }
      },
    },
  },
  legend: {
    symbolRadius: 0,
  },
  yAxis: {
    labels: {
      style: {
        fontSize: "0.7rem",
        fontWeight: "normal",
      },
    },
  },
  xAxis: {
    labels: {
      style: {
        fontSize: "0.7rem",
        fontWeight: "normal",
      },
    },
  },
};

export default ChartTheme;
