import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ColumnProps } from '../../interfaces/table-interface';
import { Observable } from 'rxjs';
import { Pagination } from '../../interface/Pagination';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
const { 
  // PROJECT_SCHEDULE_CHARTDATA, PROJECT_SCHEDULE_TABLEDATA, PROJECT_SCHEDULE_MONTH_RANGE, 
  PROJECT_SCHEDULE_ALERTS,
  PROJECT_SCHEDULE_EQUIPMENT_TAG } = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class ProjectSchedulingService {

  private http = inject(HttpClient);

  getEquipmentTagData(data: Pagination & {filters: any, isDownloadClicked?: boolean}): Observable<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }> {
    return this.http.post<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }>(
      PROJECT_SCHEDULE_EQUIPMENT_TAG, data
    );
  };
  getAlerts(data: Pagination & {
    filters: {
        area?: string[];
        cwp?: string[];
    },
    isDownloadClicked?: boolean,
    type: string;}): Observable<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }> {
    return this.http.post<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }>(
      PROJECT_SCHEDULE_ALERTS, data
    );
  };

}
