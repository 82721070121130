import { Component, EventEmitter, Input, OnInit, Output ,ViewChild} from '@angular/core';
import {
  Area,
  AreaLocation,
  Contractor,
  Cwp,
  DelayForm,
  DelayReason,
  ExistingDelayForm,
} from '../../../shared/interface/DelayForm';
import { User } from '../../../shared/interface/user';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { globalModules } from '../../../shared/utils/global-modules';
import { CustomDialogComponent } from '../../../shared/components/custom-dialog/custom-dialog.component';
import { PrimeNGConfig } from 'primeng/api';
import { HeaderTextComponent } from '../../../shared/components/header-text/header-text.component';
import { SwitchComponent } from '../../../shared/components/switch/switch.component';
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { UploadButtonComponent } from '../../../shared/components/upload-button/upload-button.component';
import { SubmitButtonComponent } from '../../../shared/components/submit-button/submit-button.component';
import { CancelButtonComponent } from '../../../shared/components/cancel-button/cancel-button.component';
import { HomePageService } from '../../../shared/services/home/home-page.service';
import { UserService } from '../../../shared/services/user/user.service';
import dayjs from 'dayjs';
import { delayTrackingServices } from '../../../shared/services/delay-tracking/delay-tracking.service';
import { CardComponent } from '../../../shared/components/card/card.component';
import { DEFAULT_CIPHERS } from 'tls';
import { catchError, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { error } from 'console';
import { Location } from '@angular/common';
import { ChipModule } from 'primeng/chip';
import { isEmpty, isNull } from 'lodash-es';
import { StepperComponent } from "../../../shared/components/stepper/stepper.component";
import { DELAY_STATUS } from '../../../core/constants/commonConstants';
import { LoaderComponent } from "../../../shared/components/loader/loader.component";

type FileUploadHandlerEvent = { files: FileList };

type sections = 'reporter' | 'approver' | 'actionOwner' | 'closure';

@Component({
  selector: 'app-delay-form',
  standalone: true,
  imports: [
    ...globalModules,
    ReactiveFormsModule,
    HeaderTextComponent,
    DropdownModule,
    UploadButtonComponent,
    SubmitButtonComponent,
    CancelButtonComponent,
    CustomDialogComponent,
    ChipModule,
    StepperComponent,
    LoaderComponent
],
  templateUrl: './delay-form.component.html',
  styleUrl: './delay-form.component.css',
})
export class DelayFormComponent implements OnInit {
  @ViewChild(UploadButtonComponent) ub!: UploadButtonComponent;
  form!: FormGroup;
  reporterForm!: FormGroup;
  approverForm!: FormGroup;
  idFields: {[index: string]: number} = {};
  @Input() isFormOpen: boolean = false;
  @Input() onCloseForm: ((alertType: string) => void) | undefined;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();

  delayForm: DelayForm = {} as DelayForm;
  areas: Area[] = [];
  cwps: Cwp[] = [];
  users: User[] = [];
  uploadPicture: File | null = null;
  fileName: string = '';
  formEnable: 'reporter' | 'actionowner' = 'reporter';
  submitCustomDialog: boolean = false;
  loading: boolean = false;
  contractors: Contractor[] = [];
  delayReasons: DelayReason[] = [];
  submitDialog: boolean = false;
  visibleSections: ('reporter' | 'approver' | 'actionOwner' | 'closure')[] =
    ['reporter'];
  editableSections: ('reporter' | 'approver' | 'actionOwner' | 'closure')[] =
    ['reporter'];
  metaData: ExistingDelayForm['meta'] = {} as ExistingDelayForm['meta'];
  showStepper: boolean = false;
  uploadedFileDialog: boolean = false;
  isTextFieldDisabled: boolean = true;
  id: number = 0;
  editableSectionsCheck: boolean = true;
  imageUrlKey: string = '';
  DELAY_STATUS!: typeof DELAY_STATUS;
  formId: string | number = 0;
  status: string = '';
  reporter!: string | number;
  createdAt: string | Date | null = null;
  updatedAt: string | Date | null = null;
  updatedBy!: string | number;
  deletedAt: string | Date | null = null;

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private homePageService: HomePageService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private locationn: Location,
    private delayTrackingService: delayTrackingServices,
  ) {
    this.primengConfig.ripple = true;
    this.form = this.fb.group({
      // areaField: ['', Validators.required],
      // locationField: ['', Validators.required],
      // cwpField: ['', Validators.required],
      // activitynameField: ['', Validators.required],
      // supervisorField: ['', Validators.required],
      // contractorField: ['', Validators.required],
      // delayReasonField: ['', Validators.required],
      // delayStartDateTimeField: ['', Validators.required],
      // actionOwnerField: ['', Validators.required],
      // approverField: ['', Validators.required],
      // priorityField: ['', Validators.required],
      // uploadedFile: [null as File | null],
      // impactAssessmentField: ['', Validators.required],
      // severityField: ['', Validators.required],
      // causeRcaDelayField: ['', Validators.required],
      // expectedResolutionDateTimeField: ['', Validators.required],
      // actionTakenField: ['', Validators.required],
      // actualResolutionDateTimeField: ['', Validators.required],
      // delayStopDateTimeField: ['', Validators.required]
      area: ['', Validators.required],
      location: ['', Validators.required],
      cwp: ['', Validators.required],
      activityName: ['', Validators.required],
      supervisor: ['', Validators.required],
      contractor: ['', Validators.required],
      delayReason: ['', Validators.required],
      delayStartDateTime: [null, Validators.required],
      actionOwner: ['', Validators.required],
      approver: ['', Validators.required],
      priority: ['', Validators.required],
      uploadedFile: [null as File | null],
      impactAssessment: ['', Validators.required],
      severity: ['', Validators.required],
      causeRcaDelay: ['', Validators.required],
      expectedResolutionDateTime: [null, Validators.required],
      actionTaken: ['', Validators.required],
      actualResolutionDateTime: [null, Validators.required],
      delayStopDateTime: [null, Validators.required]
    });
  }
  get area() {
    return this.form.get('area');
  }
  get location() {
    return this.form.get('location');
  }
  get cwp() {
    return this.form.get('cwp');
  }
  get activityName() {
    return this.form.get('activityName');
  }
  get supervisor() {
    return this.form.get('supervisor');
  }
  get contractor() {
    return this.form.get('contractor');
  }
  get delayReason() {
    return this.form.get('delayReason');
  }
  get delayStartDateTime() {
    return this.form.get('delayStartDateTime');
  }
  get actionOwner() {
    return this.form.get('actionOwner');
  }
  get approver() {
    return this.form.get('approver');
  }
  get priority() {
    return this.form.get('priority');
  }
  get impactAssessment() {
    return this.form.get('impactAssessment');
  }
  get severity() {
    return this.form.get('severity');
  }
  get causeRcaDelay() {
    return this.form.get('causeRcaDelay');
  }
  get expectedResolutionDateTime() {
    return this.form.get('expectedResolutionDateTime');
  }
  get actionTaken() {
    return this.form.get('actionTaken');
  }
  get actualResolutionDateTime() {
    return this.form.get('actualResolutionDateTime');
  }
  get delayStopDateTime() {
    return this.form.get('delayStopDateTime');
  }

  configureFieldsBasedOnSection = (formData?: DelayForm) => {
    const reporterFields = [
      'area',
      'location',
      'cwp',
      'activityName',
      'supervisor',
      'contractor',
      'delayReason',
      'delayStartDateTime',
      'actionOwner',
      'approver',
      'priority',
      'uploadedFile'
    ];
  
    const approverFields = [
      'impactAssessment',
      'severity'
    ];
    console.log(this.delayForm.status);
    let actionOwnerFields
    if (formData) {
      actionOwnerFields = formData.status !== DELAY_STATUS.PENDING_ANALYSIS
        ? ['actionTaken', 'actualResolutionDateTime']
        : ['causeRcaDelay', 'expectedResolutionDateTime'];
    }
    const closureFields = [
      'delayStopDateTime'
    ];
  
    // Reset all fields before configuring
    Object.keys(this.form.controls).forEach((fieldName) => {
      this.form.get(fieldName)?.clearValidators();
      this.form.get(fieldName)?.updateValueAndValidity();
    });
  
    if (this.editableSections.includes('reporter')) {
      this.setValidators(reporterFields);
    } else if (this.editableSections.includes('approver')) {
      console.log('approver');
      
      this.setValidators(approverFields);
    } else if (this.editableSections.includes('actionOwner')) {
      this.setValidators(actionOwnerFields!);
    } else if (this.editableSections.includes('closure')) {
      this.setValidators(closureFields);
    }
  }

  private setValidators(fields: string[]): void {
    fields.forEach((fieldName) => {
      // console.log('fields', fields);
      
      const control = this.form.get(fieldName);
      if (control) {
        control.setValidators(Validators.required);
        control.updateValueAndValidity();
      }
    });
  }

  ngOnInit(): void {
    this.fetchArea();
    this.fetchCwp();
    this.fetchUsers();
    this.fetchContractors();
    this.fetchDelayReason();
    // this.syncFormWithObject();

    this.route.paramMap.subscribe(params => {
      const idParam = params.get('id');
      this.id = idParam ? Number(idParam) : 0;
      if (this.id) {
        this.fetchformDataById(this.id);
      } else {
        console.error('Invalid ID parameter');
        // Optionally navigate back or show an error message
      }
    });
    this.configureFieldsBasedOnSection();
    this.form.valueChanges.subscribe({
      next: (values) => {
        // console.log('val', values);
      }
    })
    console.log('formng', this.form.value);
  }
  // syncFormWithObject = () => {
    
  //   this.form.valueChanges.subscribe({next:(values) => {
  //     console.log('val changed', values);
  //     this.delayForm = {
  //       ...this.delayForm, // Retain previous values
  //       ...{
  //         area: values?.areaField || this.delayForm.area,
  //         location: values?.locationField || this.delayForm.location,
  //         cwp: values?.cwpField || this.delayForm.cwp,
  //         activityName: values?.activitynameField || this.delayForm.activityName,
  //         supervisor: values?.supervisorField || this.delayForm.supervisor,
  //         contractor: values?.contractorField || this.delayForm.contractor,
  //         delayReason: values?.delayReasonField || this.delayForm.delayReason,
  //         delayStartDateTime: values?.delayStartDateTimeField,
  //         actionOwner: values?.actionOwnerField || this.delayForm.actionOwner,
  //         approver: values?.approverField || this.delayForm.approver,
  //         priority: values?.priorityField || this.delayForm.priority,
  //         imageUrlKey: values?.uploadedFile?.name || this.delayForm.imageUrlKey,
  //         impactAssessment: values?.impactAssessmentField || this.delayForm.impactAssessment,
  //         severity: values?.severityField || this.delayForm.severity,
  //         causeRcaDelay: values?.causeRcaDelayField || this.delayForm.causeRcaDelay,
  //         expectedResolutionDateTime: !isNull(values?.expectedResolutionDateTimeField) ? dayjs(values?.expectedResolutionDateTimeField).toDate() : null || this.delayForm.expectedResolutionDateTime,
  //         actionTaken: values?.actionTakenField || this.delayForm.actionTaken,
  //         actualResolutionDateTime: !isNull(values?.actualResolutionDateTimeField) ? dayjs(values?.actualResolutionDateTimeField).toDate() : null || this.delayForm.actualResolutionDateTime,
  //         delayStopDateTime: !isNull(values?.delayStopDateTimeField) ? dayjs(values?.delayStopDateTimeField).toDate() : null || this.delayForm.delayStopDateTime,
  //       },
  //     };
  //   }, complete: ()=> {
  //     console.log('Updated delayForm:', this.delayForm);

  //   }});
  // };

  handleUpdate = () => {
    console.log('this.form', this.form.getRawValue());
    console.log('this.metaData', this.metaData.userIdMapper);
    console.log('delaystartdate', dayjs(this.form.getRawValue().delayStartDateTime).toDate());
    const keyToExclude = 'uploadedFile';
      
      const filteredValues: DelayForm = Object.fromEntries(
        Object.entries(this.form.getRawValue()).filter(
          ([key, value]) => key !== keyToExclude
        )
      ) as unknown as DelayForm

    const updatedData: DelayForm = {
      ...filteredValues,
      id: this.formId!,
      reporter: this.metaData.userIdMapper.reporter,
      approver: this.metaData.userIdMapper.approver,
      actionOwner: this.metaData.userIdMapper.actionOwner,
      supervisor: this.metaData.userIdMapper.supervisor,
      imageUrlKey: this.form.getRawValue().uploadedFile,
      delayStartDateTime: dayjs(this.form.getRawValue().delayStartDateTime).toDate(),
      expectedResolutionDateTime: !isNull(this.form.getRawValue().expectedResolutionDateTime) ? dayjs(this.form.getRawValue().expectedResolutionDateTime).toDate() : null,
      actualResolutionDateTime: !isNull(this.form.getRawValue().actualResolutionDateTime) ? dayjs(this.form.getRawValue().actualResolutionDateTime).toDate() : null,
      delayStopDateTime: !isNull(this.form.getRawValue().delayStopDateTime) ? dayjs(this.form.getRawValue().delayStopDateTime).toDate() : null,
      createdAt: this.createdAt as Date,
      updatedAt: this.updatedAt as Date,
      updatedBy: this.updatedBy as number,
      deletedAt: this.deletedAt as Date
    }

    console.log('updated', updatedData);
    
    
      this.loading = true;
      this.delayTrackingService.updateForm(Number(this.formId!), updatedData).subscribe({
        next: (res) => {
          if (res) {
            // showToast(
            //   "success",
            //   "Success",
            //   res.data.message || "Form updated successfully.",
            // );
            if (this.id) {
            this.fetchformDataById(this.id)
            }
          }
        },
        error: (err) => {
          console.error('Error updating form:', err);
        }
      });
  }

  fetchArea = () => {
    this.homePageService.getAreas().subscribe({
      next: (response) => {
        this.areas = response.data;
      },
      error: (error) => {
        console.error('Failed to fetch Area:', error);
      },
    });
  };

  fetchContractors = () => {
    this.delayTrackingService.getContractors().subscribe({
      next: (response) => {
        this.contractors = response.data;
      },
      error: (error) => {
        console.error('failed to fetch contractors', error);
      },
    });
  };

  contractorData = () => {
    if (this.contractors) {
      return this.contractors.map((contractors) => {
        return { value: contractors.contractorName, id: contractors?.id };
      });
    }
    return [];
  };

  userData = () => {
    return this.users.map((user) => {
      return { value: user.userName, id: user?.id };
    });
  };

  fetchDelayReason = () => {
    this.delayTrackingService.getDelayReasons().subscribe({
      next: (response) => {
        this.delayReasons = response.data;
      },
      error: (error) => {
        console.error('failed to fetch delay reasons', error);
      },
    });
  };
  DelayReasonData = () => {
    if (this.delayReasons) {
      return this.delayReasons.map((delayREason) => {
        return { value: delayREason.reason, id: delayREason?.id };
      });
    }
    return [];
  };

  areaData = () => {
    if (this.areas.length) {
      return this.areas.map((area) => {
        return { value: area.areaName, id: area.id };
      });
    }
    return [];
  };

  locationData = () => {
    if (this.areas.length && this.form.get('area')?.value) {
      const selectedArea = this.form.get('area')?.value;
      if (!selectedArea) {
        console.warn('No area selected');
        return [];
      }

      // Find the area matching the selected name or ID
      const area = this.areas.find(
        (area) =>
          area.areaName === selectedArea
      );

      // Log the matched area to debug

      // Map to location data if area exists
      if (area) {
        const locData = area.areaLocations.map((loc) => ({
          value: loc.locationName,
          id: loc.id,
        }));
        
        return locData;
      }
      // Return empty if no matching area found
      console.warn('No matching area found for:', selectedArea);
    }

    return [];
  };

  fetchCwp = () => {
    this.homePageService.getCwp().subscribe({
      next: (response) => {
        this.cwps = response.data;
      },
      error: (error) => {
        console.error('Failed to fetch Area:', error);
      },
    });
  };

  cwpData = () => {
    if (this.cwps.length) {
      return this.cwps.map((cwp) => {
        return { value: cwp.cwp, id: cwp.id };
      });
    }
    return [];
  };

  fetchUsers = () => {
    this.userService.getUsers().subscribe({
      next: (response) => {
        this.users = response.data;
      },
      error: (err) => {
        console.error('Failed to fetch users:', err);
      },
    });
  };

  onSubmitForm = () => {
    if (this.form.valid) {
      const formValues = this.form.value;
      const keyToExclude = 'uploadedFile';
      
      const filteredValues: DelayForm = Object.fromEntries(
        Object.entries(formValues).filter(
          ([key, value]) => key !== keyToExclude
        )
      ) as unknown as DelayForm
      console.log('Filtered Values:', filteredValues);
      const finalData: DelayForm = {
        ...filteredValues,
        status: DELAY_STATUS.PENDING_APPROVAL,
      };
      console.log('this.form', this.form.value);
      
      console.log('final', finalData);
      console.log('final2', {...this.delayForm});
      const userIdToReplace = new Set([
        'supervisor',
        'actionOwner',
        'approver',
        'reporter',
      ]);
      const tempDelayForm: any = {...finalData};
      

      Object.keys(finalData).forEach((key) => {
        const currValue = finalData[key as keyof DelayForm];
        if (userIdToReplace.has(key) && finalData[key as keyof DelayForm]) {
          tempDelayForm[key as keyof DelayForm] = (this.users.find((user) => {
            return user.userName === currValue;
          })?.id || null) as unknown as never;
        }
        if (key?.toLowerCase().includes('date')) {
          tempDelayForm[key as keyof DelayForm] = finalData[key as keyof DelayForm] ? dayjs(finalData[key as keyof DelayForm]).toDate() : finalData[key as keyof DelayForm]
        }
      });
      console.log('temp', tempDelayForm);

      const formData = new FormData();

      const uploadedFile = this.form.get('uploadedFile')?.value as File;
      console.log('uploadedfile', uploadedFile);
      
      let objectURL: string | null = null;
      if (uploadedFile) {
        objectURL = URL.createObjectURL(uploadedFile);
        (uploadedFile as any).objectURL = objectURL;
        formData.append('file', uploadedFile);
      }
      for (const key in tempDelayForm) {
        const property = key as keyof DelayForm;
        if (tempDelayForm[property]) {
          formData.append(`${property}`, tempDelayForm[property].toString());
        }
      }

      console.log('asdf', this.delayForm);
      

      this.submitForm(formData as unknown as DelayForm);
    }
  };

  submitForm = (formData: DelayForm) => {

    this.loading = true;
    this.delayTrackingService.submitDelayForm(formData as DelayForm).subscribe({
      next: (res) => {
        const delayId = res?.data?.id;
        if (delayId) {
          console.log('Navigating to delay form page with ID:', delayId);
          this.router.navigate([`/delay-form/${delayId}`]);
        } else {
          console.error('Failed to retrieve delay ID from API response');
        }
      },
      error: (err) => {
        console.error('Failed to submit delay form', err);
      },
    });
  };
  handleFileUpload(event: FileUploadHandlerEvent) {
    const file = event.files[0];
    this.form.get('uploadedFile')?.setValue(file as File | null);
  }

  handleFileRemove() {
    this.form.get('uploadedFile')?.setValue(null);
    this.ub.onRemoveClick.emit();
    this.ub.fileName = ''; // Clear the file name display
    this.ub.fileInput.nativeElement.value = ''; // Clear the file input value
    this.ub.closeConfirmDialog();
  
  }

  priorityData = () => [
    { value: 'High' },
    { value: 'Medium' },
    { value: 'Low' },
  ];

  fetchformDataById = (id: number) => {
   this.loading = true;
   this.delayTrackingService.getFormById(id).subscribe({
     next: (res) => {   
      console.log("response newww",res);
       
      if(res && res.data) {
        // this.delayForm = {
        //   id: res.data.formData.id,
        //   area: res.data.formData.area,
        //   location: res.data.formData.location,
        //   cwp: res.data.formData.cwp,
        //   activityName: res.data.formData.activityName,
        //   supervisor: res.data.formData.supervisor,
        //   contractor: res.data.formData.contractor,
        //   delayReason: res.data.formData.delayReason,
        //   delayStartDateTime: res.data.formData.delayStartDateTime,
        //   actionOwner: res.data.formData.actionOwner,
        //   approver: res.data.formData.approver,
        //   priority: res.data.formData.priority,
        //   imageUrlKey: res.data.formData.imageUrlKey,
        //   impactAssessment: res.data.formData.impactAssessment,
        //   severity: res.data.formData.severity,
        //   causeRcaDelay: res.data.formData.causeRcaDelay,
        //   expectedResolutionDateTime: res.data.formData.expectedResolutionDateTime,
        //   actionTaken: res.data.formData.actionTaken,
        //   actualResolutionDateTime: res.data.formData.actualResolutionDateTime,
        //   delayStopDateTime: res.data.formData.delayStopDateTime,
        //   status: res.data.formData.status,
        //   reporter: res.data.formData.reporter,
        //   createdAt: res.data.formData.createdAt,
        //   updatedAt: res.data.formData.updatedAt,
        //   updatedBy: res.data.formData.updatedBy,
        //   deletedAt: res.data.formData.deletedAt
        // };
        this.formId = res.data.formData.id;
        this.imageUrlKey = res.data.formData.imageUrlKey;
        this.visibleSections = res.data.visibleSections;
        this.editableSections = res.data.editableSections;
        this.status = res.data.formData.status;
        this.reporter = res.data.formData.reporter;
        this.createdAt = res.data.formData.createdAt;
        this.updatedAt = res.data.formData.updatedAt;
        this.updatedBy = res.data.formData.updatedBy;
        this.deletedAt = res.data.formData.deletedAt;
        this.metaData = res.data.meta;
        this.loading = false;
        this.editableSectionsCheck = this.editableSections.some(sections => ['reporter', 'actionOwner', 'approver', 'closure'].includes(sections))        
        // if(res.data.visibleSections) {
          console.log('dayjs(res.data.formData.delayStartDateTime).toDate()', res.data.formData.delayStartDateTime);
          
          this.form.patchValue({
            ...res.data.formData,
            delayStartDateTime: dayjs(res.data.formData.delayStartDateTime).format('YYYY-MM-DD HH:mm'),
            status: res.data.formData.status,
            uploadedFile: res.data.formData.imageUrlKey,
            expectedResolutionDateTime: !isNull(res.data.formData.expectedResolutionDateTime) ? dayjs(res.data.formData.expectedResolutionDateTime).format('YYYY-MM-DD HH:mm') : null,
            actualResolutionDateTime: !isNull(res.data.formData.actualResolutionDateTime) ? dayjs(res.data.formData.actualResolutionDateTime).format('YYYY-MM-DD HH:mm') : null,
            delayStopDateTime: !isNull(res.data.formData.delayStopDateTime) ? dayjs(res.data.formData.delayStopDateTime).format('YYYY-MM-DD HH:mm') : null
            // areaField: res.data.formData.area,
            // locationField: res.data.formData.location,
            // cwpField: res.data.formData.cwp,
            // activitynameField: res.data.formData.activityName,
            // supervisorField: res.data.formData.supervisor,
            // contractorField: res.data.formData.contractor,
            // delayReasonField: res.data.formData.delayReason,
            // delayStartDateTimeField: dayjs(res.data.formData.delayStartDateTime).format('YYYY-MM-DD HH:mm'),
            // actionOwnerField: res.data.formData.actionOwner,
            // approverField: res.data.formData.approver,
            // priorityField: res.data.formData.priority,
            // uploadedFile: res.data.formData.imageUrlKey,
            // impactAssessmentField: res.data.formData.impactAssessment,
            // severityField: res.data.formData.severity,
            // causeRcaDelayField: res.data.formData.causeRcaDelay,
            // expectedResolutionDateTimeField: res.data.formData.expectedResolutionDateTime,
            // actionTakenField: res.data.formData.actionTaken,
            // actualResolutionDateTimeField: res.data.formData.actualResolutionDateTime,
            // delayStopDateTimeField: res.data.formData.delayStopDateTime
          });
          console.log('patchedValue', res.data.formData.status);
        this.configureFieldsBasedOnSection(res.data.formData);
        this.form.disable()
          if (this.editableSections.includes('reporter')) {
            const controlsToValidate = [
              'area',
              'location',
              'cwp',
              'activityName',
              'supervisor',
              'contractor',
              'delayReason',
              'delayStartDateTime',
              'actionOwner',
              'approver',
              'priority'
            ];
            controlsToValidate.forEach(el => {
              
              this.form.get(el)?.enable();
            });
          }
        if (this.editableSections.includes('approver')) {
            console.log("includes approver");
            
            const controlsToValidate = [
              'impactAssessment',
              'severity'
            ]
            controlsToValidate.forEach(el => {
              this.form.get(el)?.enable();
            });
          }
        if (this.editableSections.includes('actionOwner')) {
            console.log(this.delayForm.status);
            
            const controlsToValidate = res.data.formData.status === DELAY_STATUS.PENDING_ANALYSIS
            ? ['causeRcaDelay', 'expectedResolutionDateTime']
            : ['actionTaken', 'actualResolutionDateTime']
          controlsToValidate.forEach(el => {
              console.log(el);
              
              this.form.get(el)?.enable();
            });
          }
          if(this.editableSections.includes('closure')) {
            const controlsToValidate = [
              'delayStopDateTime'
            ]
            controlsToValidate.forEach(el => {
              this.form.get(el)?.enable();
            });
          }
          
        // }
        this.passFormGroupToAnotherFunction(this.form);

      } else {
        console.error('Error fetching data:', 'No response');
        this.loading = false;
      }
    },
    complete: () => {
      this.loading = false;
    },
    error: (error) => {
      console.error('Error fetching form data:', error);
      this.loading = false;
      setTimeout(() => {
        this.locationn.back();
      }, 2000);
    },
   })
  }
  passFormGroupToAnotherFunction(form: FormGroup) {
    // console.log('Current form value:', form.value);
  
    // Do something with the form, e.g., watch for value changes
    form.valueChanges.subscribe((updatedValue) => {
      console.log('Form updated dynamically:', updatedValue);
    });
  }
  onShowStepperClick = () => {
    this.showStepper = true;
  }

  reporterDisabled = () => {
    if (this.delayForm.status === DELAY_STATUS.PENDING_CLOSURE) {
      return true
    } 
    return false
  }

  handleReporterFormSubmit(event: Event): void {
    this.form.get('area')?.valueChanges.subscribe(() => {
      this.form.get('location')?.setValue(undefined);
    });
  
    // Validate all specified controls and ensure they have values
    // const isValid = controlsToValidate.every((controlName) => {
    //   const control = this.form.get(controlName);
    //   if (control) {
    //     control.markAsTouched(); // Mark as touched to show validation errors
    //     control.updateValueAndValidity(); // Update validity status
    //     return control.valid && control.value; // Ensure control is valid and has a value
    //   }
    //   return false; // Treat as invalid if control is missing
    // });
  
    // If all controls are valid, proceed with submission
    // if (isValid) {
      this.onSubmitForm();
    // }
  }

  // handleApproverFormSubmit = async (e: any) => {
  //   e.preventDefault();
  //   const isValid = await trigger(['impactAssessment', 'severity'], {
  //     shouldFocus: true
  //   })
  //   if (isValid) {
  //     handleUpdate()
  //   }
  // }

  handleApproverFormSubmit(event: Event): void {
    // event.preventDefault();
  
    // List of controls to validate
    // const controlsToValidate = ['impactAssessmentField', 'severityField'];
  
    // Check if all controls have valid values
    // const isValid = controlsToValidate.every((controlName) => {
    //   const control = this.form.get(controlName);
    //   if (control) {
    //     control.markAsTouched(); // Mark as touched for validation messages
    //     control.updateValueAndValidity(); // Update validity
    //     return control.valid && control.value; // Check control is valid and has a value
    //   }
    //   return false; // If control doesn't exist, treat as invalid
    // });
  
    // Call handleUpdate if valid
    // if (isValid) {
      this.handleUpdate();
    // }
  }

  handleActionOwnerFormSubmit(event: Event): void {
    // event.preventDefault(); // Prevent default form submission behavior
  
    // List of controls to validate
    const controlsToValidate = [
      'causeRcaDelayField',
      'actionOwnerField',
      'actionTakenField',
      'expectedResolutionDateTimeField',
      'actualResolutionDateTimeField',
    ];
  
    // Validate all specified controls and ensure they have values
    // const isValid = controlsToValidate.every((controlName) => {
    //   const control = this.form.get(controlName);
    //   if (control) {
    //     control.markAsTouched(); // Mark as touched to show validation errors
    //     control.updateValueAndValidity(); // Update validity status
    //     return control.valid && control.value; // Ensure control is valid and has a value
    //   }
    //   return false; // Treat as invalid if control is missing
    // });
  
    // If all controls are valid, proceed with submission
    // if (isValid) {
      this.handleUpdate();
    // }
  }

  closureFormSubmit(event: Event): void {
    // event.preventDefault(); // Prevent default form submission behavior
  
    // List of controls to validate
    // const controlsToValidate = ['delayStopDateTimeField'];
  
    // Validate the control and ensure it has a value
    // const isValid = controlsToValidate.every((controlName) => {
    //   const control = this.form.get(controlName);
    //   if (control) {
    //     control.markAsTouched(); // Mark as touched to show validation errors
    //     control.updateValueAndValidity(); // Update validity status
    //     return control.valid && control.value; // Ensure control is valid and has a value
    //   }
    //   return false; // Treat as invalid if control is missing
    // });
  
    // If the control is valid, proceed with submission
    // if (isValid) {
      this.handleUpdate();
    // }
  }

  onCancel(): void {
    if (this.editableSections.includes('reporter')) {
      this.handleFileRemove();
  
      const reporterFields = [
        'area',
        'location',
        'cwp',
        'activityName',
        'supervisor',
        'contractor',
        'delayReason',
        'delayStartDateTime',
        'actionOwner',
        'approver',
        'uploadedFile',
        'priority'
      ];
  
      reporterFields.forEach((field) => {
        const control = this.form.get(field);
        if (control) {
          control.reset(''); // Reset control to its initial empty state
        }
      });
  
      this.form.patchValue({
        area: '',
        location: '',
        cwp: '',
        activityName: '',
        supervisor: '',
        contractor: '',
        delayReason: '',
        delayStartDateTime: null, // Nullable date
        actionOwner: '',
        approver: '',
        uploadedFile: '',
        priority: ''
      });
    } else if (this.editableSections.includes('approver')) {
      const approverFields = ['impactAssessment', 'severity'];
  
      approverFields.forEach((field) => {
        const control = this.form.get(field);
        if (control) {
          control.reset('');
        }
      });
  
      this.form.patchValue({
        impactAssessment: '',
        severity: ''
      });
    } else if (this.editableSections.includes('actionOwner')) {
      if (this.delayForm.status !== DELAY_STATUS.PENDING_ANALYSIS) {
        const actionOwnerFields = ['actionTaken', 'actualResolutionDateTime'];
  
        actionOwnerFields.forEach((field) => {
          const control = this.form.get(field);
          if (control) {
            control.reset('');
          }
        });
  
        this.form.patchValue({
          actionTaken: '',
          actualResolutionDateTime: null
        });
      } else {
        const actionOwnerFields = ['causeRcaDelay', 'expectedResolutionDateTime'];
  
        actionOwnerFields.forEach((field) => {
          const control = this.form.get(field);
          if (control) {
            control.reset('');
          }
        });
  
        this.form.patchValue({
          causeRcaDelay: '',
          expectedResolutionDateTime: null
        });
      }
    } else if (this.editableSections.includes('closure')) {
      const closureFields = ['delayStopDateTime'];
  
      closureFields.forEach((field) => {
        const control = this.form.get(field);
        if (control) {
          control.reset('');
        }
      });
  
      this.form.patchValue({
        delayStopDateTime: null // Nullable date
      });
    }
  }

  handleFormSubmit = (e: any) => {
    // e.preventDefault();
    if (this.editableSections.includes('reporter')) {
      this.handleReporterFormSubmit(e);
    } else if (this.editableSections.includes('approver')) {
      this.handleApproverFormSubmit(e);
    } else if (this.editableSections.includes('actionOwner')) {
      this.handleActionOwnerFormSubmit(e);
    } else if (this.editableSections.includes('closure')) {
      this.closureFormSubmit(e);
    } else {
      console.log("Unknown section");
    }
  };

  onCancelConfirmSubmitPopup = () => {
    this.submitDialog = false;
  }

  onCloseStepper = () => {
    this.showStepper = false;
  }

  onClickViewImageBtn = () => {
    this.uploadedFileDialog = true;
  }

  onCloseUploadedFilePopup = () => {
    this.uploadedFileDialog = false;
  }

  isEmptyMetaData = () => {
    return isEmpty(this.metaData);
  }

  isEmptyMetaDataApprovalLogs = () => {
    return isEmpty(this.metaData.approvalLogs);
  }

  isMinDate = (date: string | Date | null) => {
    dayjs(date).toDate();
  }

  isMaxDate = () => [
    new Date()
  ]
  onChangeDropdown = (event: any) => {
    console.log('eve', event)
  }

  ifNotPendingAnalysis = () => {
    return this.status !== DELAY_STATUS.PENDING_ANALYSIS
  }
}
