<div class="d-flex align-items-center menu-title">
  <h5 class="offcanvas-title">Menu</h5>
  <div type="button" class="icon-button ml-auto" aria-label="Close" (click)="activeOffcanvas.dismiss('Cross click')">
    <app-icon [hoverClass]="'close-hover-effect'" [icon]="'pi pi-times'" [color]="'white'"></app-icon>
  </div>
</div>
<div class="offcanvas-body">
  <ul class="list-unstyled">
    @for (menu of menus; track $index) {
    <div class="d-flex flex-column">
      <div class="menu-item mb-1">
        @if (menu.items?.length) {
        <div class="btn d-flex align-items-center menu-item-label" (click)="(menu.label === 'Admin Module' || menu.label === 'Create Service Ticket' )? '': toggleSubMenu(menu)">
          <div class=" w-100 text-start d-flex align-items-center ">
            <app-icon [icon]="menu.icon" color="#5f6368"></app-icon>
            <div>{{ menu.label }}</div>
          </div>
          <div style="margin-left: auto;">
            <app-icon [icon]="menu.expanded ? 'pi pi-angle-left': 'pi pi-angle-right'" color="black"></app-icon>
          </div>
        </div>
        } @else {
        <a [routerLink]="menu.path" [routerLinkActiveOptions]="{paths: 'exact', matrixParams: 'subset',
queryParams: 'subset', fragment: 'ignored'}" routerLinkActive="active" ariaCurrentWhenActive="page"
          class="btn w-100 text-start d-flex align-items-center menu-item-label">
          <app-icon [icon]="menu.icon" [color]="isActive(menu.path) ? '#007AD9': '#5f6368'"></app-icon>
          <div>{{ menu.label }}</div>
        </a>
        }
      </div>
      @if (menu.items?.length && menu.expanded) {

      <div class="border-1 ms-3">
        @for (subMenu of menu.items; track $index) {
        <div class="submenu-item">
          <a [routerLink]="subMenu.path" routerLinkActive="active" ariaCurrentWhenActive="page"
            class="btn w-100 text-start d-flex align-items-center ">
            <app-icon [icon]="subMenu.icon" [color]="isActive(menu.path) ? 'blue': '#5f6368'"></app-icon>
            <span class="ms-2">{{ subMenu.label }}</span>
          </a>
        </div>
        }

      </div>
      }

    </div>
    }

  </ul>
</div>