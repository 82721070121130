<div class="dropdown">
  <app-icon
    tooltip="Download Chart"
    [icon]="'pi pi-download'"
    color="#29c8c1"
    data-bs-toggle="dropdown"
  >
  </app-icon>
  <ul class="dropdown-menu" style="padding-left: 1rem">
    <li class="pointer downloadmenu" (click)="downloadImage()">
      <app-icon [color]="'skyblue'" [icon]="'pi pi-image'"></app-icon
      ><a class="dropdown-item">Image</a>
    </li>
    <li class="pointer downloadmenu" (click)="downloadHTMLAsPDF()">
      <app-icon [color]="'red'" [icon]="'pi pi-file-pdf'"></app-icon
      ><a class="dropdown-item">PDF</a>
    </li>
  </ul>
</div>
