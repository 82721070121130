import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { Router } from '@angular/router';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { DocumentControlService } from '../../shared/services/document-control/document-control.service';
import { DocumentControlChart } from '../../shared/interface/ChartTypes';
import { CommonModule } from '@angular/common';
import { PopoverComponent } from '../../shared/components/popover/popover.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule } from '@angular/forms';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TableComponent } from '../../shared/components/table/table.component';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { DocumentControlFilter } from '../../shared/interface/Filters';
import { lightenHexColor } from '../../shared/utils/utility-functions';
import { SingleSelectComponent } from '../../shared/components/single-select/single-select.component';
import { MultiselectDropdownComponent } from '../../shared/components/multiselect-dropdown/multiselect-dropdown.component';
import { isEmpty } from 'lodash-es';
import { DocumentControlFormComponent } from "./document-control-form/document-control-form.component";
import { CustomDialogComponent } from "../../shared/components/custom-dialog/custom-dialog.component";
import { ChipModule } from 'primeng/chip';
import { LoaderComponent } from "../../shared/components/loader/loader.component";
import { DashboardLinksService } from '../../shared/services/pbi-links-servics/dashboard-links.service';
import { DashboardLinks } from '../../shared/interface/DashboardLinks';

@Component({
  selector: 'app-document-control',
  standalone: true,
  imports: [
    SingleColumnComponent,
    HeaderTextComponent,
    IconButtonComponent,
    IconComponent,
    CommonModule,
    FormsModule,
    PopoverComponent,
    MultiSelectModule,
    TableComponent,
    MultiselectDropdownComponent,
    SingleSelectComponent,
    DocumentControlFormComponent,
    CustomDialogComponent,
    ChipModule,
    LoaderComponent
  ],
  templateUrl: './document-control.component.html',
  styleUrl: './document-control.component.css',
})
export class DocumentControlComponent implements OnInit {
  constructor() { }

  private router = inject(Router);
  private dashboardLinksService = inject(DashboardLinksService);
  isLinksTableVisible: boolean = false;
  linksData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  @ViewChild('openLinkTemplate', { static: false })
  openLinkTemplate!: TemplateRef<any>;
  private documentControlService = inject(DocumentControlService);
  helperText: string = '';
  deliveryTableVisible: boolean = false;
  isFilter: boolean = false
  initialChartData: DocumentControlChart = { categories: [], series: [] };
  scopeByDisc: DocumentControlChart = this.initialChartData;
  docReleasePlan: DocumentControlChart = this.initialChartData;
  varianceByDisc: DocumentControlChart = this.initialChartData;
  ifcData: DocumentControlChart = this.initialChartData;
  ifcPercent: number = 0;
  disciplineTrigger$ = new BehaviorSubject<void>(undefined);
  filter: {
    yearData: [string];
    discipline: string[];
    status: string;
  } = { yearData: [''], discipline: [''], status: '' };
  initialFilter: {
    yearData: [string];
    discipline: string[];
    status: string;
  } = { yearData: [''], discipline: [''], status: '' };
  initialStatus: string = '';
  deliveryStatusData: { data: Record<string, any>[]; columns: ColumnProps[] } =
    { columns: [], data: [] };
  cutOffYear: string = '';
  openDocControlForm: boolean = false;
  isLoading: boolean = false;
  filterdata: DocumentControlFilter = {} as DocumentControlFilter;
  isScopeByDiscLoading: boolean = false;
  isReleasePlanLoading: boolean = false;
  isVarianceByDiscLoading: boolean = false;
  isForecastByDiscLoading: boolean = false;
  onLinkClick(link: string) {
    this.router.navigate([link]);
  }

  onScurveClick() {
    this.router.navigate(['/eng-scurve']);
  }
  onMaterialsPlanningClick() {
    this.router.navigate(['/materials-planning']);
  }

  onDeliveryTableClick() {
    this.deliveryTableVisible = !this.deliveryTableVisible;
  }

  fetchScopeByDisc(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.isScopeByDiscLoading = true
    this.documentControlService.getScopeByDisc(filters).subscribe({
      next: (response) => {
        let series = response.data.series;
        series = series.map((el) => {
          return {
            ...el,
            color: '#003645',
          };
        });
        this.scopeByDisc = {
          categories: response.data.categories,
          series: series,
        };
        this.isScopeByDiscLoading = false
      },
      error: (error) => {
        console.error('error fetching scope by discipline', error);
        this.isScopeByDiscLoading = false
      },
    });
  }

  fetchDocReleaseplan(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.isReleasePlanLoading = true
    this.documentControlService.getDocReleasePlan(filters).subscribe({
      next: (response) => {
        let series = response.data.series;
        series = series.map((el) => {
          return {
            ...el,
            color: el.name === 'Plan' ? '#003645' : '#9ADA3C',
          };
        });
        this.docReleasePlan = {
          categories: response.data.categories,
          series: series,
        };
        this.isReleasePlanLoading = false
      },
      error: (error) => {
        console.error('error fetching Doc release plan', error);
        this.isReleasePlanLoading = false
      },
    });
  }

  fetchVarianceByDisc(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.isVarianceByDiscLoading = true
    this.documentControlService.getVarianceByDisc(filters).subscribe({
      next: (response) => {
        let series = response.data.series;
        series = series.map((el) => {
          return {
            ...el,
            color: '#9ADA3C',
            negativeColor: '#ffa500',
          };
        });
        this.varianceByDisc = {
          categories: response.data.categories,
          series: series,
        };
        this.isVarianceByDiscLoading = false
      },
      error: (error) => {
        console.error('error fetching variance by discipline', error);
        this.isVarianceByDiscLoading = false
      },
    });
  }

  fetchIfc(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.isForecastByDiscLoading = true
    this.documentControlService.getIfc(filters).subscribe({
      next: (response) => {
        let series = response.data.series;
        series = series.map((el, ind) => {
          return {
            ...el,
            color: lightenHexColor('#003645', ind * 30),
          };
        });
        this.ifcData = {
          categories: response.data.categories,
          series: series,
        };
        this.isForecastByDiscLoading = false
      },
      error: (error) => {
        console.error('error fetching IFC data', error);
        this.isForecastByDiscLoading = false
      },
    });
  }

  fetchIfcPercent(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.documentControlService.getIfcPercent(filters).subscribe({
      next: (response) => {
        this.ifcPercent = response.data;
      },
      error: (error) => {
        console.error('error fetching IFC percent', error);
      },
    });
  }

  fetchDeliverableStatusTableData(filters: {
    yearData: [string];
    discipline: string[];
    status: string;
  }) {
    this.documentControlService
      .getDeliverableStatusTableData(filters)
      .subscribe({
        next: (response) => {
          this.deliveryStatusData = response.data;
        },
        error: (error) => {
          console.error('error fetching Deliverable Status Table Data ', error);
        },
      });
  }

  fetchDeliverableStatusTableDataForDownload = async(): Promise<Record<string, any>[]> => {
    try {
      const res = await firstValueFrom(
        this.documentControlService.getDeliverableStatusTableData(this.filter)
      );
      return res.data.data;
    } catch (error) {
      console.error('error fetching Deliverable Status Table Data download', error);
      return [];
    }
  }

  async fetchFilters() {
    this.isLoading = true
    this.documentControlService.getFilters().subscribe({
      next: (response) => {
        this.filterdata.discipline = response.data.disciplines?.map((el) => {
          return {
            id: el,
            value: el,
            isChecked: false,
          };
        });
        this.filterdata.status = [
          { id: 'Select All', value: 'Select All', isChecked: false },
          ...response.data.status?.map((el, ind) => {
            return {
              id: el,
              value: el,
              isChecked: ind === 0,
            };
          }),
        ];
        this.cutOffYear = response.data.cutoff;
        this.initialFilter = {
          yearData: [this.cutOffYear],
          discipline:
            this.filterdata.discipline?.flatMap((el) =>
              el.isChecked ? [el.value] : []
            ) || [],
          status: this.filterdata.status?.find((el) => el.isChecked)
            ?.value as string,
        };
        this.initialStatus = this.initialFilter.status;
        this.filter = this.initialFilter;
        this.disciplineTrigger$.subscribe({
          next: () => {
            this.fetchScopeByDisc(this.filter);
            this.fetchVarianceByDisc(this.filter);
            this.fetchDocReleaseplan(this.filter);
            this.fetchIfc(this.filter);
            this.fetchIfcPercent(this.filter);
            this.fetchDeliverableStatusTableData(this.filter);
            this.isLoading = false
          },
        });
      },
      error: (error) => {
        console.error('error fetching filters', error);
        this.isLoading = false
      },
    });
  }

  onCutOffDateChange(event: any) {
    this.filter.yearData = [event.target.value];
    this.disciplineTrigger$.next();
  }

  onDisciplineChange(event: any) {
    this.filter.discipline = event.value;
    this.disciplineTrigger$.next();
  }

  onStatusChange(event: any) {
    this.filter.status = event.target.value;
    this.disciplineTrigger$.next();
  }

  onResetFilter() {
    this.filter.yearData = [this.cutOffYear]
    this.filter.discipline = [];
    this.filter.status = this.initialStatus;
    this.disciplineTrigger$.next();
  }

  onLinksTextClick() {
    this.isLinksTableVisible = true;
  }

  onClosePopup = () => {
    if (!this.isLinksTableVisible) {
      return;
    }
    this.isLinksTableVisible = false;
  };

  fetchDashboardLinks = async () => {
    const pageUrl = this.router.url;
    this.dashboardLinksService.getDashboardLinksData(pageUrl).subscribe({
      next: (response) => {
        this.linksData = {
          columns: [
            {
              field: 'iprWprDashboardPageName' as never,
              header: 'Dashboard',
            },
            {
              field: 'download' as never,
              header: 'Open Dashboard',
              bodyTemplate: this.openLinkTemplate,
style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
            },
          ],
          data: response.data.map((el) => {
            return {
              ...el,
              download: '',
            };
          }),
          count: response.data.length,
        };
      },
      error: (err) => {
        console.error('Error fetching dashboard links:', err);
      },
    });
  };

  onOpenFormLinkClick = (rowData: DashboardLinks) => {
    window.open(rowData.iprWprDashboardLink, '_blank');
  };

  ngOnInit() {
    this.fetchDashboardLinks(); 
    this.fetchFilters();
    if (
      !isEmpty(this.filter.status) ||
      !isEmpty(this.filter.discipline || !isEmpty(this.cutOffYear))
    ) {
      this.isFilter = true;
    } else {
      this.isFilter = false;
    }
  }

  onDocControlClick = () => {
    this.openDocControlForm = true;
  }

  onCloseDocControlForm = () => {
    this.openDocControlForm = false;
  }
}
