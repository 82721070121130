import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  private http = inject(HttpClient);

  searchApi(endpoint: string, query: string): Observable<any[]> {
    console.log('endp', endpoint, 'que', query)
    if (query.length < 2) {
      return of([]);
    }

    return this.http.get<any[]>(`${endpoint}?query=${query}`).pipe(
      catchError(() => of([]))
    );
  }
}
