import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { HseFilter, IHealthSafetyFilter } from '../../interface/Filters';
import { IndicatorDataType } from '../../types/Hse';

const {
  HSE_STATS,
  HSE_DETAILS,
  HSE_INDICATORS,
  HSE_TOP_RIBBON_DATA
} = API_END_POINTS;



@Injectable({
  providedIn: 'root'
})
export class HseService {

  constructor(private http: HttpClient) {}
  getIndicatorsData(filters: any): Observable<IndicatorDataType> {
    return this.http.post<IndicatorDataType>(
      HSE_INDICATORS,
      { filters }
    );
  }
  
  getHSETopRibbonData(
    filters: HseFilter| Partial<IHealthSafetyFilter>
  ): Observable<{data: { metric: string, value: number, actualValue: number, title: string }[]}> {
    return this.http.post<{data: { metric: string, value: number, actualValue: number, title: string }[]}>(
      HSE_TOP_RIBBON_DATA,  { filters }, 

    );
}



}
