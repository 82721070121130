import { Component, inject, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Field, FormOption } from '../../interface/DynamicForm';
import { globalModules } from '../../utils/global-modules';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownService } from '../../services/dropdown/dropdown.service';

@Component({
  selector: 'app-dynamic-field',
  standalone: true,
  imports: [...globalModules, ReactiveFormsModule, NgbDatepickerModule],
  templateUrl: './dynamic-field.component.html',
})
export class DynamicFieldComponent implements OnInit {
  @Input() fieldConfig!: Field;
  @Input() form!: FormGroup;
  fileError: string | null = null;

  //inject
  private dropdownService = inject(DropdownService);

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fieldConfig']) {
      this.checkAndApplyCondition();

      if (this.fieldConfig.condition && this.fieldConfig.conditionConfig) {
        const dependentControl = this.form.get(this.fieldConfig.conditionConfig.fieldName);
        dependentControl?.valueChanges.subscribe((value) => {
          this.applyCondition(value);
        });
      }
    
      if ( this.fieldConfig.dependentOnField) {
        this.form.get(this.fieldConfig.dependentOnField)?.valueChanges.subscribe({
          next: (selectedValue) => {
            this.fetchDependentDropdownValues(selectedValue);
          }
        })
      }
      if (this.fieldConfig.type === 'dropdown_dynamic' && !this.fieldConfig.dependentOnField) {
        this.fetchDropdownOptions();
      }
    }
  }

  

  get isValid() {
    if (this.form.controls && this.form.controls[this.fieldConfig.name]) {

      return this.form.controls[this.fieldConfig.name].valid;
    }
    return true;
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const fileSizeLimit = this.fieldConfig.fileSize || Infinity;
      const fileType = this.fieldConfig.fileType;

      if (Number(file.size) > Number(fileSizeLimit)) {
        this.fileError = `File size should be less than ${fileSizeLimit}KB`;
        this.form.controls[this.fieldConfig.name].setErrors({ fileSize: true });
      } else if (fileType && !file.name.endsWith(`.${fileType}`)) {
        this.fileError = `File type should be ${fileType}`;
        this.form.controls[this.fieldConfig.name].setErrors({ fileType: true });
      } else {
        this.form.controls[this.fieldConfig.name].setValue(file);
        this.form.controls[this.fieldConfig.name].setErrors(null);
        this.fileError = null;
      }
    }
  }

  fetchDependentDropdownValues = (selectedValue: any) => {
    if (selectedValue && this.fieldConfig.masterDataKey) {
      this.dropdownService.getOptionsByMasterData(this.fieldConfig.masterDataKey, selectedValue).subscribe({
        next: (options) => {

          this.fieldConfig.options = options.data;
          this.form.get(this.fieldConfig.name)?.setValue(null);
        },
      })
    } else {
      this.fieldConfig.options = [];
    }
  }

  onDropdownChange(event: Event) {
    const selectedValue = (event.target as HTMLSelectElement).value;

    this.updateRelatedFields(selectedValue);
  }

  updateRelatedFields(selectedValue: any) {
    if (this.fieldConfig.relatedFields) {
      const newValues = this.getNewValueForRelatedField(selectedValue);
      this.fieldConfig.relatedFields.forEach((relatedField) => {
        const relatedFieldControl = this.form.get(relatedField);
        if (relatedFieldControl) {
          relatedFieldControl.setValue(newValues[relatedField]);
        }
      });
    }
  }

  getNewValueForRelatedField(selectedValue: any): any {
    if (this.fieldConfig.masterDataKey && selectedValue) {
      let newValue = null;
      this.dropdownService.getValueForRelatedField(this.fieldConfig.masterDataKey, selectedValue).subscribe(({ data }) => {
        newValue = data
      });
      return newValue;
    }
  }

  fetchDropdownOptions(): void {
    // Call the service to fetch the dropdown options
    if (this.fieldConfig.masterDataKey) {

      this.dropdownService.getDropDownValues(this.fieldConfig.masterDataKey).subscribe(
        {
          next: (response) => {
            this.fieldConfig.options = response.data;
          },
          error: (error) => {
            console.error('Error fetching dropdown options', error);
          }
        });

    }
  }

  private checkAndApplyCondition(): void {
    if (this.fieldConfig.condition && this.fieldConfig?.conditionConfig?.fieldName) {

      const dependentValue = this.form.get(this.fieldConfig?.conditionConfig?.fieldName)?.value;
      this.applyCondition(dependentValue);
    }
  }

  private applyCondition(value: any): void {
    const condition = this.fieldConfig.conditionConfig;
    if (!this.fieldConfig.condition || !condition) return;

    const control = this.form.get(this.fieldConfig.name);

    if (condition.valueToMatch ===  value) {
      if (condition.action === 'hide') {
        control?.disable();
        this.fieldConfig.hidden = true;
      } else if (condition.action === 'disable') {
        control?.disable();
        this.fieldConfig.hidden = false;
      }
    } else {
      if (condition.action === 'hide') {
        control?.enable();
        this.fieldConfig.hidden = false;
      } else if (condition.action === 'disable') {
        control?.enable();
        this.fieldConfig.disabled = false;
      } else {
        control?.enable();
        this.fieldConfig.hidden = false;

      }
    }
  }

}
