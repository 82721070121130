<div class="container mt-4 p-0">
  @if (!isPreview) {
  <form [formGroup]="formBuilderForm">
    <!-- Form Sections -->
    <div formArrayName="sections" class="sections-container">
      @for (section of sections.controls; track sectionIndex; let sectionIndex =
      $index) {
      <div [formGroupName]="sectionIndex" class="mb-4 section-item">
        <ng-template #sec>
          <div
            (click)="removeSection(sectionIndex)"
            class="d-flex align-items-center link"
          >
            <app-icon ngbTooltip="Remove Section" icon="pi pi-times"></app-icon>
          </div>
        </ng-template>
        <app-card
          [title]="section.get('sectionName')?.value || 'Untitled Section'"
          [endElement]="sec"
        >
          <div class="card-body">
            <!-- Section Header -->
            <div class="mb-3 d-flex justify-content-between align-items-center">
              <input
                formControlName="sectionName"
                class="form-control w-50"
                placeholder="Section Name"
              />
            </div>

            <!-- Fields Container -->
            <div
              [id]="'fields-list-' + sectionIndex"
              class="fields-container"
              formArrayName="fields"
            >
              @for (field of getFieldsFormArray(sectionIndex).controls; track
              fieldIndex; let fieldIndex = $index) {
              <div
                [formGroupName]="fieldIndex"
                class="field-item mb-3 p-3 border rounded"
              >
                <!-- Field Configuration -->
                <div class="row gap-2">
                  <div class="col-md-3">
                    <label for="labelField{{ sectionIndex }}{{ fieldIndex }}"
                      >Field Label</label
                    >
                    <input
                      formControlName="label"
                      class="form-control"
                      id="labelField{{ sectionIndex }}{{ fieldIndex }}"
                      placeholder="Field Label"
                    />
                  </div>
                  <div class="col-md-3">
                    <label for="typeField{{ sectionIndex }}{{ fieldIndex }}"
                      >Field Type</label
                    >
                    <select
                      formControlName="type"
                      class="form-control"
                      id="typeField{{ sectionIndex }}{{ fieldIndex }}"
                    >
                      @for (item of inputTypes; track $index;) {
                      <option [value]="item.value">{{ item.text }}</option>
                      }
                    </select>
                  </div>
                  <div class="col-md-3">
                    <label for="nameField{{ sectionIndex }}{{ fieldIndex }}"
                      >Field Name <span class="mandatory ml-1">*</span>
                    </label>
                    <app-custom-search
                      [required]="true"
                      formControlName="name"
                      placeholder="Field Name"
                      [apiEndpoint]="''"
                      id="nameField{{ sectionIndex }}{{ fieldIndex }}"
                    ></app-custom-search>
                  </div>
                  <div class="col-md-3">
                    <label
                      for="placeholderField{{ sectionIndex }}{{ fieldIndex }}"
                      >Placeholder</label
                    >
                    <input
                      formControlName="placeholder"
                      class="form-control"
                      id="placeholderField{{ sectionIndex }}{{ fieldIndex }}"
                      placeholder="Placeholder"
                    />
                  </div>
                  @if (['dropdown_dynamic'].includes(field.get('type')?.value))
                  {
                  <div class="col-md-3">
                    <label for="dropdown">Key for dropdown values</label>
                    <select
                      formControlName="masterDataKey"
                      class="form-control"
                    >
                      <option value="">Select Key</option>
                      @for (opt of masterDataKeys; track opt.id;) {
                      <option [value]="opt.value">{{ opt.text }}</option>
                      }
                    </select>
                  </div>
                  }
                </div>

                <!-- Field Options -->
                <div class="row mt-3 gap-2">
                  <div class="col-md-6">
                    <div class="form-check form-switch">
                      <input
                        type="checkbox"
                        formControlName="isMandatory"
                        class="form-check-input"
                        [id]="'mandatory' + sectionIndex + fieldIndex"
                      />
                      <label
                        class="form-check-label"
                        [for]="'mandatory' + sectionIndex + fieldIndex"
                      >
                        Mandatory
                      </label>
                    </div>
                  </div>
                </div>

                <!-- Options for dropdown/radio/checkbox -->
                @if (['dropdown_static',
                'radio'].includes(field.get('type')?.value)) {
                <div class="mt-3">
                  <div formArrayName="options">
                    @for (option of getOptionsFormArray(sectionIndex,
                    fieldIndex).controls; track optionIndex; let optionIndex =
                    $index) {
                    <div
                      [formGroupName]="optionIndex"
                      class="d-flex gap-4 mb-2 align-items-center"
                    >
                      <div>
                        <label
                          ngbTooltip="Text to be visible in dropdown list."
                          for="optionTextField{{ sectionIndex }}{{
                            fieldIndex
                          }}{{ optionIndex }}"
                          >Option Text</label
                        >
                        <input
                          formControlName="text"
                          class="form-control"
                          id="optionTextField{{ sectionIndex }}{{
                            fieldIndex
                          }}{{ optionIndex }}"
                          placeholder="Option Text"
                        />
                      </div>
                      <div>
                        <label
                          ngbTooltip="Actual value of the dropdown to be stored."
                          for="optionValueField{{ sectionIndex }}{{
                            fieldIndex
                          }}{{ optionIndex }}"
                          >Option Value</label
                        >
                        <input
                          formControlName="value"
                          class="form-control"
                          id="optionValueField{{ sectionIndex }}{{
                            fieldIndex
                          }}{{ optionIndex }}"
                          placeholder="Option Value"
                        />
                      </div>
                      <app-icon
                        (click)="
                          removeOption(sectionIndex, fieldIndex, optionIndex)
                        "
                        ngbTooltip="Remove Option"
                        icon="pi pi-times"
                        class="align-self-end"
                      ></app-icon>
                    </div>
                    }
                    <div class="d-flex">
                      @if ((field.get('type')?.value === 'radio' &&
                      getFieldsFormArray(sectionIndex).at(fieldIndex).get('options')?.value?.length
                      <2 ) || field.get('type')?.value==='dropdown_static' ) {
                      <app-icon
                        ngbTooltip="Add Option"
                        icon="pi pi-plus"
                        (click)="addOption(sectionIndex, fieldIndex)"
                      >
                      </app-icon>
                      }
                    </div>
                  </div>
                </div>
                }

                <!-- Conditional Logic -->
                <div class="mt-3">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      (change)="
                        toggleCondition($event, sectionIndex, fieldIndex)
                      "
                      [checked]="field.get('condition')?.value"
                    />
                    <label for="condition" class="form-check-label">Add Condition</label>
                  </div>

                  @if (field.get('condition')?.value) {
                  <div formGroupName="conditionConfig" class="mt-2">
                    <div class="row gap-2">
                      <div class="col-md-3">
                        <div class="d-flex align-items-center gap-1">
                          <label
                            for="conditionFieldName{{ sectionIndex }}{{
                              fieldIndex
                            }}"
                            >Select Field</label
                          >
                          <app-icon
                          
                            color="#29c8c1"
                            tooltip="Select a field on which this field depends."
                            icon="pi pi-info-circle"
                          ></app-icon>
                        </div>
                        <select
                          formControlName="fieldName"
                          class="form-control"
                          id="conditionFieldName{{ sectionIndex }}{{
                            fieldIndex
                          }}"
                        >
                          <option value="">Select Field</option>
                          @for (fieldName of
                          getAllFieldNames(field.get('name')?.value); track
                          fieldName) {
                          <option [value]="fieldName">{{ fieldName }}</option>
                          }
                        </select>
                      </div>
                      <div class="col-md-3">
                        <div class="d-flex align-items-center gap-1">
                          <label
                            for="valueToMatchField{{ sectionIndex }}{{
                              fieldIndex
                            }}"
                            >Value to Match</label
                          >
                          <app-icon
                          color="#29c8c1"
                          tooltip="Value to match in order for the action to trigger."
                            icon="pi pi-info-circle"
                          ></app-icon>
                        </div>
                        <input
                          formControlName="valueToMatch"
                          class="form-control"
                          id="valueToMatchField{{ sectionIndex }}{{
                            fieldIndex
                          }}"
                          placeholder="Value to Match"
                        />
                      </div>
                      <div class="col-md-3">
                        <div class="d-flex align-items-center gap-1">
                          <label
                            for="actionField{{ sectionIndex }}{{ fieldIndex }}"
                            >Action</label
                          >
                          <app-icon
                          color="#29c8c1"                            
                          tooltip="Action to be triggered for this field once the condition matches."
                            icon="pi pi-info-circle"
                          ></app-icon>
                        </div>
                        <select
                          formControlName="action"
                          class="form-control"
                          id="actionField{{ sectionIndex }}{{ fieldIndex }}"
                        >
                          <option value="hide">Hide</option>
                          <option value="disable">Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  }
                </div>

                <!-- Remove Field Button -->
                <div class="mt-3 d-flex">
                  <div class="d-flex ml-auto">
                    <button
                      type="button"
                      class="btn btn-danger"
                      (click)="removeField(sectionIndex, fieldIndex)"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </div>
              }
            </div>

            <!-- Add Field Button -->
            <div class="d-flex w-100 justify-content-end">
              <button
                type="button"
                class="btn btn-dark mt-3"
                (click)="addField(sectionIndex)"
              >
                <div class="d-flex align-items-center">
                  <app-icon icon="pi pi-plus"></app-icon> Add Field
                </div>
              </button>
            </div>
          </div>
        </app-card>
      </div>
      }
    </div>

    <!-- Add Section Button -->
    <div class="d-flex w-100 justify-content-center mt-3">
      <button type="button" class="btn btn-success" (click)="addSection()">
        <div class="d-flex align-items-center">
          <app-icon icon="pi pi-plus"></app-icon> Add Section
        </div>
      </button>
    </div>
  </form>
  } @else {
  <div class="fst-italic">
    Drag and drop sections and fields to change their order.
  </div>
  <app-dynamic-form
    (formConfigsChange)="onFormConfigsChange($event)"
    [isFormEditable]="true"
    [fieldConfigs]="formBuilderForm.value.sections"
  >
  </app-dynamic-form>
  }

  <!-- Preview/Save Buttons -->
  <div class="mt-4 d-flex">
    <div class="d-flex ml-auto gap-3">
      <button type="button" class="btn btn-dark" (click)="previewForm()">
        {{ isPreview ? "Edit Form" : "Preview Form" }}
      </button>
      <button type="button" class="btn btn-success" (click)="saveForm()">
        Save Form
      </button>
    </div>
  </div>
</div>
