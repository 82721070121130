import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { Pagination } from '../../interface/Pagination';
import { ColumnProps } from '../../interfaces/table-interface';
import { ProjectHighlight, ProjectHighlightData } from '../../types/Project-highlight';
import { url } from 'inspector';
import { LastRefreshedDate } from '../../types/LastRefreshedDate';
import { StatCard } from '../../types/StatCard';
import { AreasResponse, CwpResponse } from '../../interface/ProjectHighlightConcern';

const {
  LAST_REFRESHED_DATE,
  REFRESH_DATA,
  DATA_QUALITY,
  HIGHLIGHTS_CONCERNS,
  HOME_CARD_DATA,
  FILES_REPORTS,
  GET_AREAS,
  CWP
} = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  constructor(private http:HttpClient) { }

  refreshDetails():Observable<{
      status: string,
      data: {
        data: Record<string, any>[],
        columns: ColumnProps[]
      }
    }>{
    return this.http.get<{
      status: string,
      data: {
        data: Record<string, any>[],
        columns: ColumnProps[]
      }
    }>(REFRESH_DATA);
  }

  getCardDataByArea(area:string):Observable<{data:{title:string;data:StatCard[]}[]}>{
    const url = `${HOME_CARD_DATA}${area ? `?area=${area}` : ''}`;
    return this.http.get<{ data: { title: string; data: StatCard[] }[] }>(url);
  }

  getDataQualityTableData(data: Pagination, isDownloadClicked?: boolean): Observable<{data: {
    columns: ColumnProps[];
  data: ProjectHighlight[];
  downloadData?: ProjectHighlight[];
  downloadCount?: number;
  count: number;
  }
  }> {
    return this.http.post<{data: {
      columns: ColumnProps[];
    data: ProjectHighlight[];
    downloadData?: ProjectHighlight[];
    downloadCount?: number;
    count: number;
    }
    }>(DATA_QUALITY, {...data, isDownloadClicked});
  }

  getReports(): Observable<{ data: { key: string}[] }> {
    return this.http.get<{ data: { key: string}[] }>(FILES_REPORTS);
  }

  getAreas(): Observable<AreasResponse> {
    return this.http.get<AreasResponse>(GET_AREAS);
  }

  getCwp(): Observable<CwpResponse> {
    return this.http.get<CwpResponse>(CWP);
  }
}
