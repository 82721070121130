<div class="h-100 container-fluid">
  @if(loading) {
    <app-loader [backdrop]="true"></app-loader>
  }
  <div class="header-container">
    <app-header-text class="pb-1" label="S Curve"></app-header-text>
    <div class="icon-container">
      <div class="icon-list">
        <app-icon-button
          [label]="'Engineering S Curve'"
          (click)="onScurveClick()"
          [icon]="'pi pi-chart-line'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        ></app-icon-button>
        <app-icon-button
          [label]="'Procurement S Curve'"
          (click)="onProcurementLinkClick()"
          [icon]="'pi pi-chart-line'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        ></app-icon-button>
        <app-icon-button
          [label]="'Construction S Curve'"
          (click)="onCPSClick()"
          [icon]="'pi pi-chart-line'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        ></app-icon-button>
        
        
      </div>
    </div>
  </div>
  <div class="datacard">
    <app-scurvedata-card [data]="overallScurveData.metaData">
    </app-scurvedata-card>
  </div>

  <div class="mb-3 singlecolumn-container">
    <single-column
      [id]="'Overall S Curve'"
      [cardTitle]="'Overall S Curve'"
      [tooltipSuffix]="'%'"
      [secondaryAxisTickPosition]="[0, 20, 40, 60, 80, 100]"
      [secondaryYAxisEnabled]="true"
      [primaryYAxisSuffix]="'%'"
      [secondaryYAxisSuffix]="'%'"
      [dataLabelsSuffix]="'%'"
      [data]="overallScurveData.chartData"
      [loading]="loading"
    >
    </single-column>
  </div>
  <div class="pb-3">
    @if (overallScurveData.tableData.count>0) {
    <app-table
    textAlign="center"
      [title]="'Details'"
      [data]="overallScurveData.tableData.rows"
      [cols]="overallScurveData.tableData.columns"
      [defaultColumns]="overallScurveData.tableData.columns"
      [paginationOptions]="paginationOptions"
      [loading]="loading"
      [expandable]="false"
      [hideCustomizeColumnIcon]="false"
      [hideDownloadIcon]="false"
    ></app-table>
    }
  </div>
</div>
