<div class="h-100">
  @if (loading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <div style="padding-left: 0.7rem; padding-right: 1rem">
    <app-header-text [label]="'Purchase Order Tracking'" />
  </div>

  <div
    style="
      display: flex;
      height: 6%;
      box-shadow: 0px 4px 4px -1px #0009, 0px 3px 3px #0008, 0px 4px 8px #0006;
      box-sizing: border-box;
      background-color: white;
      align-items: center;
      margin: 1px 10px 13px 10px;
      justify-content: space-between;
    "
  >
    <div style="display: flex; border-radius: 0; padding-left: 1.5rem">
      <div
        style="
          display: flex;
          align-items: center;
          width: 100%;
          gap: 2rem;
          font-size: 0.85rem;
          height: '100%';
        "
      >
        <div
          style="display: flex; align-items: center; cursor: pointer"
          [ngClass]="{ link: selectedStatus === 'onTime' }"
          (keydown)="
            onTimeStatusClick(
              selectedStatus === 'onTime' ? undefined : 'onTime'
            )
          "
          (click)="
            onTimeStatusClick(
              selectedStatus === 'onTime' ? undefined : 'onTime'
            )
          "
        >
          <div class="green-circle"></div>
          <div>
            On Time: <strong>{{ overAllStatus.onTime }}</strong>
          </div>
        </div>
        <div
          (keydown)="
            onTimeStatusClick(
              selectedStatus === 'riskOfDelay' ? undefined : 'riskOfDelay'
            )
          "
          style="display: flex; align-items: center; cursor: pointer"
          [ngClass]="{ link: selectedStatus === 'riskOfDelay' }"
          (click)="
            onTimeStatusClick(
              selectedStatus === 'riskOfDelay' ? undefined : 'riskOfDelay'
            )
          "
        >
          <div class="orange-circle"></div>
          <div>
            Risk Of Delay: <strong>{{ overAllStatus.riskOfDelay }}</strong>
          </div>
        </div>
        <div
          (keydown)="
            onTimeStatusClick(
              selectedStatus === 'delayed' ? undefined : 'delayed'
            )
          "
          style="display: flex; align-items: center; cursor: pointer"
          [ngClass]="{ link: selectedStatus === 'delayed' }"
          (click)="
            onTimeStatusClick(
              selectedStatus === 'delayed' ? undefined : 'delayed'
            )
          "
        >
          <div class="red-circle"></div>
          <div>
            Delayed: <strong>{{ overAllStatus.delayed }}</strong>
          </div>
        </div>
        @if(po) {
        <div
          class="ml-auto mr-2 mt-2 cursor-pointer"
          (keydown)="clearPo()"
          (click)="clearPo()"
        >
          <div
            title="Document No"
            class="flex align-items-center"
            style="height: 3rem"
          >
            <p-chip [label]="po" icon="pi pi-microsoft" [removable]="true" />
            <!-- Replace 'Chip' with an Angular component or HTML -->
          </div>
        </div>
        }
      </div>
    </div>
    <div class="me-3">
      <app-icon
        tooltip="Download"
        icon="pi pi-download"
        (click)="exportExcel($event)"
      ></app-icon>
    </div>
  </div>
  <div
    style="
      height: 90%;
      box-shadow: 0px 4px 4px -1px #0009, 0px 3px 3px #0008, 0px 4px 8px #0006;
      background-color: white;
      margin: 17px 10px 10px 10px;
    "
  >
    <div
      class="w-full"
      style="height: 90%; overflow-y: auto; padding: 2rem; margin-top: 1rem"
    >
      <!-- for loop for data here -->
      @for (el of commodityData.rows; track $index) {
      <div [id]="el.po" style="min-height: 5rem; width: 100%">
        <div
          (keydown)="onClickExpandPo(el.po)"
          style="display: flex; height: auto; width: 100%"
          (click)="onClickExpandPo(el.po)"
        >
          <div
            style="
              display: flex;
              width: 70%;
              gap: '2rem';
              justify-content: space-around;
              margin-left: -20px;
            "
            class="po-heading"
          >
            <div style="display: flex; width: 15rem">
              <div class="text-bold">
                {{ el.materialType }}
              </div>
            </div>
            <div style="width: 15rem">
              <div class="text-bold">
                {{ el.supplier }}
              </div>
            </div>

            <div style="width: 15rem">
              <div class="text-bold">
                Exp Delivery - {{ el.expectedDeliveryDate || "NA" }}
              </div>
            </div>
          </div>
          <div
            style="
              display: flex;
              align-items: center;
              width: 30%;
              font-size: 0.8rem;
            "
          >
            <div style="display: flex; align-items: center; width: 20rem">
              <div class="green-circle-small"></div>
              <div>{{ el.onTime }}</div>
            </div>
            <div style="display: flex; align-items: center; width: 20rem">
              <div class="orange-circle-small"></div>
              <div>{{ el.riskOfDelay }}</div>
            </div>
            <div style="display: flex; align-items: center; width: 20rem">
              <div class="red-circle-small"></div>
              <div>{{ el.delayed }}</div>
            </div>
            <div class="" style="width: 5rem; cursor: pointer">
              <app-icon
                [icon]="
                  expandedPo === el.po
                    ? 'pi pi-angle-up'
                    : 'pi pi-angle-down'
                "
                [tooltip]="expandedPo === el.po ? 'Collapse' : 'More Details'"
              ></app-icon>
             
            </div>
          </div>
        </div>
        @if(el.po !== expandedPo) {
        <p-divider layout="horizontal" />
        } @if(el.po === expandedPo) {
        <div class="expand-table-header">
          <div class="expand-table-subcontainer">
            <div
              class="expand-table-header-block"
              style="border-right: 2px solid lightgrey"
            >
              <div class="text-supplier">
                <div class="commodity-header">Supplier</div>
                <div class="text-supplier-data flex">
                  {{
                    poDetailsData[0].supplier ? poDetailsData[0].supplier : ""
                  }}
                </div>
              </div>
            </div>

            <div class="expand-table-header-block right-border">
              <div class="text-supplier">
                <div class="commodity-header">Material Type</div>
                <div class="text-supplier-data flex">
                  {{ el.materialType }}
                </div>
              </div>
            </div>

            <div class="expand-table-header-block right-border">
              <div class="text-supplier">
                <div class="commodity-header">PO #</div>
                <div class="text-supplier-data flex">{{ el.po }}</div>
              </div>
            </div>

            <div
              [ngStyle]="overallStatusStyle()"
              class="expand-table-header-block right-border"
            >
              <div class="text-supplier">
                <div class="commodity-header">Overall Status</div>
                <div class="text-supplier-data flex">
                  {{ poDetailsData[0].overallStatus }}
                </div>
              </div>
            </div>

            <div class="expand-table-header-block right-border">
              <div class="text-supplier">
                <div class="commodity-header">Exp/Act Delays</div>
                <div class="text-supplier-data flex">
                  {{
                    +poDetailsData[0].expActDelays < 0
                      ? "-"
                      : poDetailsData[0].expActDelays
                  }}
                </div>
              </div>
            </div>
            <div class="expand-table-header-block right-border">
              <div class="text-supplier">
                <div class="commodity-header">Exp Delivery</div>
                <div class="text-supplier-data flex">
                  {{ el.expectedDeliveryDate }}
                </div>
              </div>
            </div>

            <div class="expand-table-header-block">
              <div class="text-supplier">
                <div class="commodity-header">Buyer</div>
                <div class="text-supplier-data flex">
                  {{ poDetailsData[0].buyer }}
                </div>
              </div>
            </div>
          </div>

          <div
            class="mt-1 commodity"
            style="display: flex; width: 95%; margin-left: 30px"
          >
            <div
              style="
                margin-top: 6.4rem;
                display: flex;
                margin-right: 10px;
                flex-direction: column;
                gap: 0.3rem;
              "
            >
              @for (item of fixedColumnData; track $index) {
              <div
                style="
                  background-color: inherit;
                  margin-bottom: 1rem;
                  display: flex;
                "
                class="table-fixed-left text-sm"
              >
                {{ item.key }}
              </div>
              }
            </div>
            <table aria-label="poDetailsDataTable">
              <thead>
                @if(poDetailsData) {
                <tr
                  style="
                    width: 50rem;
                    display: flex;
                    justify-content: space-evenly;
                  "
                >
                  @for (milestoneData of poDetailsData; track $index) {
                  <th
                    [id]="milestoneData"
                    style="
                      width: 50rem;
                      display: flex;
                      padding: 0px;
                      justify-content: space-evenly;
                    "
                  ></th>
                  <td
                    style="
                      display: flex;
                      flex-direction: column;
                      border-collapse: collapse;
                      align-items: center;
                      gap: 0.5rem;
                      width: 17rem;
                      padding-right: 0px;
                      padding-left: 0px;
                    "
                  >
                    <div
                      [ngStyle]="{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: getMilestoneBadge(
                          milestoneData.status || '',
                          milestoneData.completionStatus!
                        ).color,
                        borderRadius: '50%',
                        height: '20px',
                        width: '20px'
                      }"
                      [ngClass]="
                        getMilestoneBadge(
                          milestoneData.status || '',
                          milestoneData.completionStatus!
                        ).color + '-circle-status'
                      "
                    >
                      <app-icon
                        [icon]="
                          getMilestoneBadge(
                            milestoneData.status || '',
                            milestoneData.completionStatus!
                          ).icon
                        "
                        color="white"
                        [hoverClass]="'font-smaller text-white'"
                      >
                      </app-icon>
                    </div>

                    <div
                      [ngStyle]="{
                        whiteSpace: 'nowrap',
                        width: '18rem',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 'normal',
                        fontSize: '0.97rem',
                        marginBottom: '1rem'
                      }"
                      [title]="milestoneData.milestone"
                    >
                      {{ milestoneData.milestone }}
                    </div>

                    @for (item of fixedColumnData; track $index) {
                    <div
                      [ngStyle]="{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5rem',
                        marginBottom: '1rem',
                        fontWeight: 'normal'
                      }"
                      [attr.key]="item.value + '-' + milestoneData.milestone"
                    >
                      @if (item.value === 'reasonForDelay' &&
                      !(milestoneData.status.toLowerCase() === 'completed' ||
                      milestoneData.completionStatus?.toLowerCase() === 'ontime'
                      || milestoneData.alreadyCommented)) {
                      <div
                        [ngClass]="{
                          'comment-info-target': milestoneData.alreadyCommented
                        }"
                        style="width: 15rem"
                      >
                        <p-dropdown
                          class="custom-dropdown"
                          appendTo="body"
                          [id]="milestoneData.milestoneCode?.toString()"
                          [options]="delayReasonValues"
                          (ngModelChange)="
                            handleReasonChange(
                              $event,
                              milestoneData.milestoneCode!
                            )
                          "
                          [ngModel]="
                            milestoneData.milestoneCode
                              ? reasonsAndCommentsForDelay[
                                  milestoneData.milestoneCode
                                ].reason
                              : ''
                          "
                          optionLabel="value"
                          [placeholder]="
                            milestoneData.status.toLowerCase() ===
                              'completed' ||
                            milestoneData.completionStatus?.toLowerCase() ===
                              'ontime'
                              ? 'NA'
                              : 'Enter Reason'
                          "
                          [disabled]="
                            milestoneData.status.toLowerCase() ===
                              'completed' ||
                            milestoneData.completionStatus?.toLowerCase() ===
                              'ontime' ||
                            milestoneData.alreadyCommented
                          "
                          [style]="{
                            height: '28px',
                            fontSize: '12px',
                            width: '100%'
                          }"
                        />
                      </div>
                      } @else {
                      <div
                        style="
                          font-weight: normal;
                          display: flex;
                          flex-direction: column;
                          font-size: 0.95rem;
                          margin-top: 0.5rem;
                        "
                      >
                        {{
                          getMilestoneValue(milestoneData, item.value) || "-"
                        }}

                        @if (milestoneData.alreadyCommented && item.value ===
                        'reasonForDelay') {
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            font-size: 0.5rem;
                            text-align: center;
                          "
                        >
                          <div>
                            (Modified By: <b>{{ milestoneData.updatedBy }}</b>
                          </div>
                          <div>
                            Modified At:
                            <b>{{
                              convertDateToCommonFormat(
                                milestoneData.updatedAt!
                              )
                            }}</b
                            >)
                          </div>
                        </div>
                        }
                      </div>
                      }
                    </div>
                    }
                  </td>
                  @if (poDetailsData.length - 1 !== $index) {
                  <th id="poDetailsData" [ngStyle]="{ width: 0 }">
                    <div
                      [ngStyle]="{
                        marginTop: '10px',
                        border: '0.5px solid grey',
                        marginLeft: '-7rem',
                        marginRight: '-8rem',
                        width: '14rem',
                        marginBottom: '0',
                        paddingTop: '0',
                        paddingBottom: '0'
                      }"
                    ></div>
                  </th>
                  } @else {
                  <th
                    id="poDetailsData-else"
                    [ngStyle]="{ width: 0, padding: 0, margin: 0 }"
                  ></th>
                  } }
                </tr>
                } @else {
                <div></div>
                }
              </thead>
            </table>
          </div>
          <div
            style="
              display: flex;
              margin-top: 1.5rem;
              margin-left: auto;
              width: fit-content;
              gap: 0.5rem;
            "
          >
            <button
              style="
                background-color: #ce3f42;
                color: black;
                border: #ce3f42 1px solid;
                border-radius: 216px;
                padding: 4px 12px;
              "
              [disabled]="isSubmitDisabled()"
              (click)="onReset()"
            >
              Reset
            </button>
            <button
              style="
                background-color: #81d10b;
                color: black;
                border: #81d10b 1px solid;
                border-radius: 16px;
                padding: 4px 12px;
              "
              [disabled]="isSubmitDisabled()"
              (click)="onClickSubmit()"
            >
              Submit
            </button>
          </div>
        </div>
        }
      </div>
      }
    </div>

    <div
      class="me-5"
      style="
        height: 5%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      "
    >
      @if(!po) {
      <p-paginator
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} to {last} of {totalRecords}"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [totalRecords]="commodityData.count"
        [rows]="noOfRecords"
        [first]="first"
        (onPageChange)="onPageChange($event)"
      >
      </p-paginator>
      }
    </div>
  </div>
</div>
