import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { VdrService } from '../../shared/services/vdr/vdr.service';
import { DonutChartModel } from '../../shared/interface/ChartTypes';
import { DonutChartComponent } from '../../shared/components/charts/donut-chart/donut-chart.component';
import { BarChart } from '../../shared/interface/BarChart';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import {
  ColumnProps,
  VdrCriticalDocStatus,
} from '../../shared/interfaces/table-interface';
import { VdrFilter } from '../../shared/interface/Filters';
import { TableComponent } from '../../shared/components/table/table.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { isEmpty, isNull } from 'lodash-es';
import { PopoverComponent } from '../../shared/components/popover/popover.component';
import { MaterialsPlanningService } from '../../shared/services/materials-planning/materials-planning.service';
import { Area } from '../../shared/interface/AreaTypes';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TablePageEvent } from 'primeng/table';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { globalModules } from '../../shared/utils/global-modules';
import { FormsModule } from '@angular/forms';
import { DashboardLinksService } from '../../shared/services/pbi-links-servics/dashboard-links.service';
import { DashboardLinks } from '../../shared/interface/DashboardLinks';
import download from 'downloadjs';
import { Router } from '@angular/router';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';

interface Filters {
  area: string;
  criticalPriorityCode?: string[];
}

@Component({
  selector: 'app-vdr-status',
  standalone: true,
  imports: [
    DonutChartComponent,
    SingleColumnComponent,
    TableComponent,
    HeaderTextComponent,
    PopoverComponent,
    LoaderComponent,
    FormsModule,
    ...globalModules,
    IconButtonComponent
  ],
  templateUrl: './vdr-status.component.html',
  styleUrl: './vdr-status.component.css',
})
export class VdrStatusComponent implements OnInit {
  private vdrService = inject(VdrService);
  private materialsPlanningService = inject(MaterialsPlanningService);
  private dashboardLinksService = inject(DashboardLinksService);
  private router = inject(Router);
  receiptStatusData: DonutChartModel = {} as DonutChartModel;
  reviewStatusData: DonutChartModel = {} as DonutChartModel;
  inProgressCriticalDocData: BarChart = {} as BarChart;
  selectedSeries: string | null = null;
  first: number = 0;
  loading: boolean = false;
  isFilter: boolean = false;
  rowsPerPage: number = 10;
  areaFilterTrigger$ = new BehaviorSubject<void>(undefined);
  tableChangeTrigger$ = new BehaviorSubject<void>(undefined);
  visible: boolean = false;
  selectedArea: string = history.state.area ? history.state.area : 'Overall';
  areaDropdown: Area[] = [];
  criticalDocStatusTableData: {
    columns: ColumnProps[];
    rows: VdrCriticalDocStatus[];
    count: number;
  } = { columns: [], rows: [], count: 0 };
  showAll: boolean = false;
  tableLoading: boolean = false;
  receiptChartLoading: boolean = false;
  reviewChartLoading: boolean = false;
  barChartLoading: boolean = false;
  isLinksTableVisible: boolean = false;
  linksData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  @ViewChild('openLinkTemplate', { static: false })
  openLinkTemplate!: TemplateRef<any>;

  color = [
    '#ffd588', // Lightest
    '#ffcc73', // Light
    '#ffc25f', // Light
    '#ffb84a', // Light
    '#ffb84a', // Light
    '#ffad36', // Moderate
    '#ffad36', // Moderate
    '#ff9932', // Moderate
    '#ffa01b', // Darkest
  ];

  initialColumnData: ColumnProps[] = [
    {
      field: 'area',
      header: 'Area',
    },
    {
      field: 'discipline',
      header: 'Discipline',
    },
    {
      field: 'documentNo',
      header: 'Document No',
    },
    {
      field: 'supplierName',
      header: 'Supplier Name',
    },
    {
      field: 'criticalPriorityCode',
      header: 'VDR Critical Code',
    },
    {
      field: 'targetSubmissionDate',
      header: 'Target Submission Date',
    },
    {
      field: 'expectedSubmissionDate',
      header: 'Expected Submission Date',
    },
    {
      field: 'delayReason',
      header: 'Delay Reason',
    },
    {
      field: 'actionOwner',
      header: 'Action Owner',
    },
    {
      field: 'resolution',
      header: 'Resolution',
    },
  ];
  async fetchReceiptStatus(filters: any) {
    this.receiptChartLoading = true;
    this.vdrService.getReceiptStatus(filters).subscribe({
      next: (response) => {
        this.receiptStatusData = response.data;
        this.receiptChartLoading = false;
      },
      error: (err) => {
        console.error('error in get Receipt Status', err);
        this.receiptChartLoading = false;
      },
    });
  }

  async fetchReviewtStatus(filters: any) {
    this.reviewChartLoading = true;
    this.vdrService.getReviewStatus(filters).subscribe({
      next: (response) => {
        this.reviewStatusData = response.data;
        this.reviewChartLoading = false;
      },
      error: (err) => {
        console.error('error in get Review Status', err);
        this.reviewChartLoading = false;
      },
    });
  }

  async fetchInProgressCriticalDocs() {
    if (this.selectedArea === 'Overall') {
      this.selectedArea = '';
    }
    let filters: any = {};
    this.barChartLoading = true;
    if (!this.showAll) {
      filters.criticalPriorityCode = ['1A', '1B', '2'];
    }
    filters.area = this.selectedArea;
    this.vdrService.getInProgressCriticalDocs(filters).subscribe({
      next: (response) => {
        let categories = response.data.categories;
        let series = response.data.series;
        let data: BarChart = {} as BarChart;
        data.categories = categories;
        data.series = isNull(this.selectedSeries)
          ? series
          : series.filter((el) => {
              return el.name === this.selectedSeries;
            });
        this.inProgressCriticalDocData = data;
        this.barChartLoading = false;
      },
      error: (err) => {
        console.error('error in get in progress critical docs data', err);
        this.barChartLoading = false;
      },
    });
  }

  async fetchCriticalDocStatusTableData(
    pageNo: number,
    rows: number,
    filters?: VdrFilter,
    isDownloadClicked?: boolean
  ) {
    this.tableLoading = true;
    this.vdrService
      .getCriticalDocStatusTableData(
        Math.floor(pageNo / rows),
        rows,
        filters,
        isDownloadClicked
      )
      .subscribe({
        next: (response) => {
          if (isDownloadClicked) {
          } else {
            this.criticalDocStatusTableData = response.data;
          }
          this.tableLoading = false;
        },
        error: (err) => {
          console.error('error in get Critical Doc Status Table Data', err);
          this.tableLoading = false;
        },
      });
  }

  fetchCriticalDocStatusTableDataForDowload = async (): Promise<
    Record<string, any>[]
  > => {
    let filter: VdrFilter;
    if (this.selectedArea) {
      filter = { area: this.selectedArea };
    } else {
      filter = {};
    }
    try {
      const res = await firstValueFrom(
        this.vdrService.getCriticalDocStatusTableData(
          Math.floor(this.first / this.rowsPerPage),
          this.rowsPerPage,
          filter,
          true
        )
      );
      return res.data.rows;
    } catch (error) {
      console.error('Failed to fetch Critical Doc Status Data:', error);
      return [];
    }
  };

  onShowAllClick() {
    this.showAll = !this.showAll;
    this.fetchInProgressCriticalDocs();
  }

  async fetchAreas() {
    this.loading = true;
    this.materialsPlanningService.getAreas().subscribe({
      next: (response) => {
        this.areaDropdown = response.data;
        this.loading = false;
      },
      error: (err) => {
        console.error('error in get Areas', err);
        this.loading = false;
      },
    });
  }

  onLinksTextClick() {
    this.isLinksTableVisible = true;
  }

  onClosePopup = () => {
    if (!this.isLinksTableVisible) {
      return;
    }
    this.isLinksTableVisible = false;
  };

  fetchDashboardLinks = async () => {
    const pageUrl = this.router.url;
    this.dashboardLinksService.getDashboardLinksData(pageUrl).subscribe({
      next: (response) => {
        this.linksData = {
          columns: [
            {
              field: 'iprWprDashboardPageName' as never,
              header: 'Dashboard',
            },
            {
              field: 'download' as never,
              header: 'Open Dashboard',
              bodyTemplate: this.openLinkTemplate,
style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
            },
          ],
          data: response.data.map((el) => {
            return {
              ...el,
              download: '',
            };
          }),
          count: response.data.length,
        };
      },
      error: (err) => {
        console.error('Error fetching dashboard links:', err);
      },
    });
  };

  onOpenFormLinkClick = (rowData: DashboardLinks) => {
    window.open(rowData.iprWprDashboardLink, '_blank');
  };

  handleDrillDown = (seriesNm: string) => {
    if (seriesNm === this.selectedSeries) {
      this.selectedSeries = null;
      this.fetchInProgressCriticalDocs();
    } else {
      this.selectedSeries = seriesNm;
      this.fetchInProgressCriticalDocs();
    }
  };

  onAreaSelect(event: any): void {
    this.selectedArea = event.target.value;
    this.areaFilterTrigger$.next();
  }

  onResetFilter() {
    this.selectedArea = '';
    this.areaFilterTrigger$.next();
  }

  pageChange = (event: TablePageEvent) => {
    if (event.first !== undefined && event.rows !== undefined) {
      this.first = event.first;
      this.rowsPerPage = event.rows;
      this.tableChangeTrigger$.next();
    }
  };

  ngOnInit() {
    this.fetchDashboardLinks();
    if (this.selectedArea === 'Overall') {
      this.selectedArea = '';
    }
    this.fetchAreas();
    this.areaFilterTrigger$.subscribe({
      next: () => {
        if (isEmpty(this.selectedArea)) {
          this.isFilter = false;
        } else {
          this.isFilter = true;
        }
        this.fetchReceiptStatus({ area: this.selectedArea });
        this.fetchReviewtStatus({ area: this.selectedArea });
        this.fetchInProgressCriticalDocs();
        this.tableChangeTrigger$.subscribe({
          next: () => {
            let filter: VdrFilter;
            if (this.selectedArea) {
              filter = { area: this.selectedArea };
            } else {
              filter = {};
            }

            this.fetchCriticalDocStatusTableData(
              this.first,
              this.rowsPerPage,
              filter
            );
          },
        });
      },
    });
  }
}
