<div class="d-flex w-100 gap-2 mt-1">
    <input
      #fileInput
      type="file"
      class="d-none"
      (change)="onFileSelected($event)"
      accept="image/*"
    />

    <ng-container *ngIf="!fileName; else fileInfo">
      <button
        class="btn btn-upload d-flex align-items-center"
        (click)="fileInput.click()"
        type="button"
        [attr.disabled]="disabled ? true : null"
      >
        <app-icon icon="pi pi-plus" class="me-2"></app-icon> 
        Upload
      </button>
    </ng-container>

    <ng-template #fileInfo>
      <div class="d-flex align-items-center">
        <span class="text-truncate fw-bold" style="max-width: 150px;">{{ fileName }}</span>
        <span
          *ngIf="!disableRemove"
          class="ms-2 text-danger fw-bold"
          style="cursor: pointer;"
          (click)="openConfirmDialog()"
        >
          X
        </span>
      </div>
    </ng-template>

    <!-- Confirmation Modal for Removing File -->
    <div *ngIf="dialogVisible" class="modal-overlay">
    <div class="modal show d-block" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Remove File</h5>
            <button type="button" class="btn-close" (click)="closeConfirmDialog()"></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to remove the uploaded file?</p>
          </div>
          <div class="modal-footer">
            <app-cancel-button label="No" (onClick)="closeConfirmDialog()"></app-cancel-button>
            <app-submit-button label="Yes" (onClick)="confirmFileRemoveClick()"></app-submit-button>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>