import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { MultiSelectChangeEvent } from 'primeng/multiselect';
import { HeaderTextComponent } from "../../shared/components/header-text/header-text.component";
import { LoaderComponent } from "../../shared/components/loader/loader.component";
import { MultiselectDropdownComponent } from '../../shared/components/multiselect-dropdown/multiselect-dropdown.component';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { ConstructionManagmentChart } from '../../shared/interface/ConstructionManagement';
import { ConstructionManagmentFilter } from '../../shared/interface/Filters';
import { ConstructionManagementService } from '../../shared/services/construction-management/construction-management.service';
import { IconComponent } from "../../shared/components/icon/icon.component";
import { IconButtonComponent } from "../../shared/components/icon-button/icon-button.component";
import { DashboardLinks } from '../../shared/interface/DashboardLinks';
import { DashboardLinksService } from '../../shared/services/pbi-links-servics/dashboard-links.service';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { TableComponent } from '../../shared/components/table/table.component';

@Component({
  selector: 'app-construction-management',
  standalone: true,
  imports: [
    SingleColumnComponent,
    CommonModule,
    MultiselectDropdownComponent,
    HeaderTextComponent,
    LoaderComponent,
    IconComponent,
    IconButtonComponent,
    TableComponent
],
  templateUrl: './construction-status.component.html',
  styleUrl: './construction-status.component.css',
})
export class ConstructionManagementComponent implements OnInit {
  private dashboardLinksService = inject(DashboardLinksService);
  private router = inject(Router);
  isLinksTableVisible: boolean = false;
  linksData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  @ViewChild('openLinkTemplate', { static: false })
  openLinkTemplate!: TemplateRef<any>;
  chartData: ConstructionManagmentChart[] = [];
  selectedCraftValues: string[] = [];
  locationState: string = history.state.area;
  processedData: { title: string; data: any }[] = [];
  ids: string[] = ['asd', 'sdf', 'dfg', 'fgh'];
  craftVals: { value: string; id: string | number }[] = [];
  constructor(
    private constructionManagementService: ConstructionManagementService,
  ) { }
  loading: boolean = false;
  isManpowerChartLoading: boolean = false;
  isCivilStructuralLoading: boolean = false;

  onLinkClick(link: string) {
    this.router.navigate([link]);
  }

  fetchconstructionManagementData(allArea?: boolean, reset?: boolean) {
    // const currentNavigation = this.router.getCurrentNavigation();
   
    if (allArea || isEmpty(this.locationState)) {
      this.locationState = 'Overall';
    }
    const filterss: ConstructionManagmentFilter =
      {} as ConstructionManagmentFilter;
    if (this.selectedCraftValues) {
      filterss.craft = this.selectedCraftValues;
    }
    const areaParam = allArea
      ? 'Overall'
      : this.locationState;
    if (reset) {
      this.isCivilStructuralLoading = true
      const civilWorksData = this.processedData.find(item => item.title === 'Civil Works (CuM)');
      const structuralData = this.processedData.find(item => item.title === 'Structural (MT)');
      this.constructionManagementService
        .getConstructionManagementData({
          area: areaParam,
          craft: filterss.craft ?? [],
        })
        .subscribe({
          next: (response) => {
            if (response.data) {
              civilWorksData!.data = response.data.find(data => data.title === 'Civil Works (CuM)');
              structuralData!.data = response.data.find(data => data.title === 'Structural (MT)');
              this.isCivilStructuralLoading = false;
            }
          },
          error: (err) => {
            this.isCivilStructuralLoading = false
            console.error('Failed to fetch civil, structural data:', err);
          },
        });
    } else {
      this.constructionManagementService
        .getConstructionManagementData({
          area: areaParam,
          craft: filterss.craft ?? [],
        })
        .subscribe({
          next: (response) => {
            this.chartData = response.data;
            this.processedData = response.data.map(({ title, ...data }) => ({
              title,
              data,
            }));
          },
          error: (err) => {
            console.error('Failed to fetch construction management data:', err);
          },
        });
    }
  }

  fetchCraftsData = () => {
    this.loading = true
    this.constructionManagementService.getCraftData().subscribe({
      next: (res) => {
        this.craftVals = res.data.map((el) => {
          return {
            value: el.value,
            id: el.id,
            name: el.value,
          };
        });
        this.loading = false
      },
      error: (err) => {
        console.error('Failed to fetch craft data:', err);
        this.loading = false
      },
    });
  };

  onResetArea() {
    this.fetchconstructionManagementData(true, true);
  }




  onCraftChange = (event: MultiSelectChangeEvent) => {
    this.selectedCraftValues = event.value;
    const manpowerData = this.processedData.find(item => item.title === 'Manpower Deployment (Nos)');
    if (manpowerData) {
      this.isManpowerChartLoading = true;
      this.constructionManagementService
        .getConstructionManagementData({
          area: this.locationState,
          craft: this.selectedCraftValues,
        })
        .subscribe({
          next: (response) => {
            if (response.data) {
              manpowerData.data = response.data.find(data => data.title === 'Manpower Deployment (Nos)');
              this.isManpowerChartLoading = false;
            }
          },
          error: (err) => {
            console.error('Failed to update manpower data:', err);
            this.isManpowerChartLoading = false;
          },
        });
    }

  };

  onLinksTextClick() {
    this.isLinksTableVisible = true;
  }

  onClosePopup = () => {
    if (!this.isLinksTableVisible) {
      return;
    }
    this.isLinksTableVisible = false;
  };

  fetchDashboardLinks = async () => {
    const pageUrl = this.router.url;
    this.dashboardLinksService.getDashboardLinksData(pageUrl).subscribe({
      next: (response) => {
        this.linksData = {
          columns: [
            {
              field: 'iprWprDashboardPageName' as never,
              header: 'Dashboard',
            },
            {
              field: 'download' as never,
              header: 'Open Dashboard',
              bodyTemplate: this.openLinkTemplate,
style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
            },
          ],
          data: response.data.map((el) => {
            return {
              ...el,
              download: '',
            };
          }),
          count: response.data.length,
        };
      },
      error: (err) => {
        console.error('Error fetching dashboard links:', err);
      },
    });
  };

  onOpenFormLinkClick = (rowData: DashboardLinks) => {
    window.open(rowData.iprWprDashboardLink, '_blank');
  };

  ngOnInit(): void {
    this.fetchDashboardLinks(); 
    this.fetchCraftsData();
    this.fetchconstructionManagementData();
  }
}
