import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appCopy]',
  standalone: true
})
export class CopyDirective {
  @HostListener('copy', ['$event'])
  onCopy(event: ClipboardEvent): void {
    const selection = window.getSelection()?.toString();
    if (selection) {
      const trimmedText = selection.trim();
      event.clipboardData?.setData('text/plain', trimmedText);
      event.preventDefault();
    }
  }

}
