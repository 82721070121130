import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from "../icon/icon.component";
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-icon-button',
  standalone: true,
  imports: [CommonModule, IconComponent, NgbTooltip],
  templateUrl: './icon-button.component.html',
  styleUrl: './icon-button.component.css'
})
export class IconButtonComponent {
  @Input() icon: string = '';
  @Input() onClick?: (event: Event) => void;
  @Input() transparent?: boolean;
  @Input() iconColor: string = '';
  @Input() iconSize?: 'small' | 'large';
  @Input() label?: string;
  @Input() disabled?: boolean;
  @Input() title?: string;
  @Input() labelStyle: any;
  @Input() superScriptIconVal?: boolean;
  @Output() buttonClick = new EventEmitter<MouseEvent>();

  onClickButton = (event: MouseEvent) => {
    // if (this.onClick) {
    //   console.log("onclick");
    //   this.onClick(e)
    // }
    this.buttonClick.emit(event);
  }
}
