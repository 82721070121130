import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { MultiSelectModule } from 'primeng/multiselect';
@Component({
  selector: 'app-multiselect-dropdown',
  standalone: true,
  imports: [MultiSelectModule, FormsModule],
  templateUrl: './multiselect-dropdown.component.html',
  styleUrl: './multiselect-dropdown.component.css'
})
export class MultiselectDropdownComponent {
  @Input() id?: string;
  @Input() label?: string;
  @Input() name?: string;
  @Input() placeholder?: string;
  @Input() value: string[] | undefined;
  @Input() options: { value: string, id?: number | string }[] = [];
  @Input() disabled?: boolean = false;
  @Output() onChange = new EventEmitter<any>();
  get mappedOptions(): SelectItem[] {
    return this.options?.map(el => ({
      ...el,
      label: el.value?.trim() ? el.value : 'Not Available',
      value: el.value
    }));
  }

  handleChange(event: any): void {
    this.onChange.emit(event);
  }
}
