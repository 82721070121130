import { ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CdkDragDrop, DragDropModule, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop'; // Drag-and-drop import
import { globalModules } from '../../../shared/utils/global-modules';
import { CardComponent } from '../../../shared/components/card/card.component';
import { Field, Form, FormOption } from '../../../shared/interface/DynamicForm';
import { toTitleCase } from '../../../shared/utils/utility-functions';
import { DynamicFormComponent } from "../../../shared/components/dynamic-form/dynamic-form.component";
import { CustomSearchComponent } from "../../../shared/components/custom-search/custom-search.component";
import { IconButtonComponent } from "../../../shared/components/icon-button/icon-button.component";
import { DropdownService } from '../../../shared/services/dropdown/dropdown.service';

@Component({
  selector: 'app-form-builder',
  standalone: true,
  templateUrl: './form-builder.component.html',
  styleUrl: './form-builder.component.css',
  imports: [
    ...globalModules,
    ReactiveFormsModule,
    FormsModule,
    CardComponent,
    DynamicFormComponent,
    CustomSearchComponent,
    IconButtonComponent
  ],
})
export class FormBuilderComponent implements OnInit {
  formBuilderForm!: FormGroup;
  isPreview: boolean = false;
  inputTypes: FormOption[] = [
    'checkbox', 'date', 'datetime', 'dropdown_dynamic',
    'dropdown_static', 'file', 'number', 'radio', 'text', 'textarea'
  ].map((el, ind) => ({
    id: ind + 1,
    text: el === 'dropdown_dynamic' ? 'Dropdown (Dynamic)' :
      el === 'dropdown_static' ? 'Dropdown (Static)' : toTitleCase(el),
    value: el,
  }));
  masterDataKeys: FormOption[] = [];
  formConfigs: Form = [];
  private dropdownService = inject(DropdownService);

  constructor(private fb: FormBuilder, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.initForm();
    this.addSection();
    this.updateFormConfigs();
    this.setMasterDataKeyDropDownValues();
  }

  private setMasterDataKeyDropDownValues = () => {
    this.dropdownService.getMasterDataKeys().subscribe({
      next: (value) => {
        this.masterDataKeys = value.data;
      },
      error: (err) => {
        console.error(err);
      },
    })
  }

  private initForm() {
    this.formBuilderForm = this.fb.group({
      sections: this.fb.array([])
    });
  }

  private updateFormConfigs() {
    // Convert form value to Form type
    this.formConfigs = this.sections.controls.map((section, sectionIndex) => ({
      sectionName: section.get('sectionName')?.value || '',
      sectionSortOrder: section.get('sectionSortOrder')?.value || sectionIndex,
      fields: this.getFieldsFormArray(sectionIndex).controls.map((field, fieldIndex) => ({
        name: field.get('name')?.value || '',
        label: field.get('label')?.value || '',
        type: field.get('type')?.value || 'text',
        fieldSortOrder: field.get('fieldSortOrder')?.value || fieldIndex,
        isMandatory: field.get('isMandatory')?.value || false,
        placeholder: field.get('placeholder')?.value || '',
        options: field.get('options')?.value || [],
        masterDataKey: field.get('masterDataKey')?.value || '',
        condition: field.get('condition')?.value || false,
        conditionConfig: field.get('conditionConfig')?.value || {}
      }))
    }));
  }

  onFormConfigsChange(newConfigs: Form) {
    this.formConfigs = [...newConfigs];  // Create new reference

    // Clear and rebuild sections array
    this.sections.clear();

    newConfigs.forEach(section => {
      const fieldsArray = this.fb.array([]);

      // Build fields array
      section.fields.forEach(field => {
        const optionsArray = this.fb.array((field.options || []).map(option =>
          this.fb.group({
            text: [option.text],
            value: [option.value]
          })
        ));

        const fieldGroup = this.fb.group({
          type: [field.type],
          fieldSortOrder: [field.fieldSortOrder],
          name: [field.name],
          label: [field.label],
          options: optionsArray,
          placeholder: [field.placeholder],
          isMandatory: [field.isMandatory],
          condition: [field.condition],
          masterDataKey: [field.masterDataKey],
          conditionConfig: this.fb.group({
            fieldName: [field.conditionConfig?.fieldName || ''],
            valueToMatch: [field.conditionConfig?.valueToMatch || ''],
            action: [field.conditionConfig?.action || 'hide']
          })
        });

        fieldsArray.push(fieldGroup as any);
      });

      // Create section group and add to sections
      const sectionGroup = this.fb.group({
        sectionName: [section.sectionName],
        sectionSortOrder: [section.sectionSortOrder],
        fields: fieldsArray
      });

      this.sections.push(sectionGroup);
    });

    this.cdr.detectChanges();
  }

  get sections() {
    return this.formBuilderForm.get('sections') as FormArray;
  }

  getFieldsFormArray(sectionIndex: number): FormArray {
    return this.sections.at(sectionIndex).get('fields') as FormArray;
  }

  getOptionsFormArray(sectionIndex: number, fieldIndex: number): FormArray {
    return this.getFieldsFormArray(sectionIndex).at(fieldIndex).get('options') as FormArray;
  }

  private updateSectionSortOrders() {
    this.sections.controls.forEach((section, index) => {
      section.patchValue({
        ...section.value,
        sectionSortOrder: index
      }, { emitEvent: false });
    });
  }

  private updateFieldSortOrders(sectionIndex: number) {
    const fields = this.getFieldsFormArray(sectionIndex);
    fields.controls.forEach((field, index) => {
      field.patchValue({
        ...field.value,
        fieldSortOrder: index
      }, { emitEvent: false });
    });
  }

  addSection() {
    const sectionGroup = this.fb.group({
      sectionName: [''],
      sectionSortOrder: [this.sections.length],
      fields: this.fb.array([])
    });
    this.sections.push(sectionGroup);
  }

  addField(sectionIndex: number) {
    const fieldGroup = this.fb.group({
      type: ['text'],
      fieldSortOrder: [this.getFieldsFormArray(sectionIndex).length],
      name: [''],
      label: [''],
      options: this.fb.array([]),
      placeholder: [''],
      isMandatory: [false],
      condition: [false],
      masterDataKey: [''],
      conditionConfig: this.fb.group({
        fieldName: [''],
        valueToMatch: [''],
        action: ['hide']
      })
    });
    this.getFieldsFormArray(sectionIndex).push(fieldGroup);
  }

  addOption(sectionIndex: number, fieldIndex: number) {
    const optionGroup = this.fb.group({
      text: [''],
      value: ['']
    });
    this.getOptionsFormArray(sectionIndex, fieldIndex).push(optionGroup);
  }

  removeSection(sectionIndex: number) {
    this.sections.removeAt(sectionIndex);
    this.updateSectionSortOrders();
  }

  removeField(sectionIndex: number, fieldIndex: number) {
    this.getFieldsFormArray(sectionIndex).removeAt(fieldIndex);
    this.updateFieldSortOrders(sectionIndex);
  }

  removeOption(sectionIndex: number, fieldIndex: number, optionIndex: number) {
    this.getOptionsFormArray(sectionIndex, fieldIndex).removeAt(optionIndex);
  }

  toggleCondition(event: any, sectionIndex: number, fieldIndex: number) {
    const field = this.getFieldsFormArray(sectionIndex).at(fieldIndex);
    field.patchValue({ condition: event.target.checked });
  }

  getAllFieldNames(currentFieldName: string): string[] {
    return this.sections.controls.flatMap(section =>
      (section.get('fields') as FormArray).controls.map(field => field.get('name')?.value || '')
    ).filter(name => name !== currentFieldName);
  }


  previewForm() {
    console.log(this.formBuilderForm.value);
    this.isPreview = !this.isPreview;
  }

  saveForm() {
    if (this.formBuilderForm.valid) {
    } else {
      this.formBuilderForm.markAllAsTouched();
      console.log('Form has errors.');
    }
  }


}
