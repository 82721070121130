@if(label){
  <label class="custom-label">
    {{ label }}
  </label>
}

<select  class="form-select select-dropdown" [(ngModel)]="value" [style]="{ width: '100%', height: '1.8rem', padding: '0 0.5rem' }" (change)="handleChange($event)">
  @if(placeholder){
  <option  [selected]="!value" value="">
    {{placeholder}}
  </option>
  }
  @for (option of options; track $index) {
  <option [value]="option.value">{{ option.value }}</option>
  }
</select>
