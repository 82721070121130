<div class="container-fluid h-100">
  @if (isLoading) {
  <app-loader [backdrop]="true"></app-loader>
  }@else {
  <app-custom-dialog [alreadyMaximized]="true" [visible]="deliveryTableVisible">
    <app-table
      [title]="'Deliverable Status'"
      textAlign="center"
      [showGridLines]="true"
      [isExpanded]="true"
      [avoidExpand]="true"
      [moreEndElement]="deliveryTableEndEle"
      [data]="deliveryStatusData.data"
      [cols]="deliveryStatusData.columns"
      [defaultColumns]="deliveryStatusData.columns"
      [onDownloadClick]="fetchDeliverableStatusTableDataForDownload"
    >
    </app-table>
  </app-custom-dialog>

  <div class="row">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <app-header-text [label]="'Engineering Status'"></app-header-text>
      <div style="display: flex; justify-content: end; align-items: center">
        <div style="display: flex; gap: 1rem">
          <app-icon-button
            [label]="'Dashboard'"
            [icon]="'pi pi-clipboard'"
            [iconColor]="'#6f6f6f'"
            [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
            (click)="onLinksTextClick()"
          ></app-icon-button>
          <app-icon-button
            [label]="'Engineering S Curve'"
            (click)="onScurveClick()"
            [icon]="'pi pi-chart-line'"
            [iconColor]="'#6f6f6f'"
            [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          ></app-icon-button>
          <app-icon-button
            [label]="'Materials Planning'"
            (click)="onMaterialsPlanningClick()"
            [icon]="'pi pi-chart-line'"
            [iconColor]="'#6f6f6f'"
            [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          ></app-icon-button>
          <app-icon-button
            [label]="'Document Control Form'"
            (click)="onDocControlClick()"
            [icon]="'pi pi-file-plus'"
            [iconColor]="'#6f6f6f'"
            [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          ></app-icon-button>
          <app-popover [isFilter]="isFilter" [label]="'Filter'">
            <div
              style="
                display: flex;
                flex-direction: column;
                padding: 1rem 2rem;
                height: 30rem;
              "
            >
            <div class="d-flex flex-column gap-3">
              <div>
              <label
                class="custom-label"
                >Select Cut-off Date
              </label>
              <input
                id="startDate"
                [value]="filter.yearData"
                (change)="onCutOffDateChange($event)"
                class="custom-input"
                type="date"
              />
            </div>
              <app-multiselect-dropdown
                [label]="'Discipline'"
                [placeholder]="'-- Select Discipline --'"
                [options]="filterdata.discipline!"
                (onChange)="onDisciplineChange($event)"
                [value]="filter.discipline"
              >
              </app-multiselect-dropdown>
              <app-single-select
                [label]="'Status'"
                [options]="filterdata.status!"
                (onChange)="onStatusChange($event)"
                [value]="filter.status"
              >
              </app-single-select>
              </div>
              <div style="margin-top: 2rem">
                <span
                  class="link underline"
                  style="font-size: medium"
                  (click)="onResetFilter()"
                  >Reset</span
                >
              </div>
            </div>
          </app-popover>
        </div>
        <app-document-control-form
          [isFormOpen]="openDocControlForm"
          [onCloseForm]="onCloseDocControlForm"
        />
      </div>
    </div>
  </div>
  <div class="row gap-1 gap-lg-0 pb-1" style="height: 48%">
    <!-- Scope By Discipline -->
    <div class="col-12 col-md-12 col-lg-6 chart-container">
      <single-column
        #chartRef
        [id]="'scopeByDisc'"
        [titleTemplate]="scopeByDiscTitle"
        [chartType]="'bar'"
        [cardTitle]="'Scope By Discipline'"
        [yAxisDisabled]="true"
        [legendDisabled]="true"
        [data]="scopeByDisc"
        [loading]="isScopeByDiscLoading"
      >
      </single-column>
    </div>
    <!-- Release Plan -->
    <div class="col-12 col-md-12 col-lg-6 chart-container">
      <single-column
        #chartRef
        [id]="'releasePlan'"
        [titleTemplate]="releasePlanTitle"
        [chartType]="'column'"
        [cardTitle]="'Release Plan'"
        [data]="docReleasePlan"
        [categoryClass]="'ternary-text'"
        [loading]="isReleasePlanLoading"
      >
      </single-column>
    </div>
    <!-- Variance By Discipline -->
    <div class="col-12 col-md-12 col-lg-6 chart-container">
      <single-column
        #chartRef
        [id]="'varianceByDisc'"
        [titleTemplate]="varianceByDiscTitle"
        [cardTitle]="'Variance By Discipline'"
        [chartType]="'bar'"
        [legendDisabled]="true"
        [yAxisDisabled]="true"
        [data]="varianceByDisc"
        [loading]="isReleasePlanLoading"
      >
      </single-column>
    </div>
    <!-- IFC -->
    <div class="col-12 col-md-12 col-lg-6 chart-container">
      <single-column
        #chartRef
        [id]="'ifc'"
        [titleTemplate]="ifcTitle"
        [cardTitle]="'Forecast By Discipline'"
        [chartType]="'bar'"
        [data]="ifcData"
        [yAxisDisabled]="true"
        [stacked]="true"
        [endElement]="ifcEndEle"
        [loading]="isForecastByDiscLoading"
      >
      </single-column>
    </div>
  </div>
  }
</div>

<ng-template #deliveryTableEndEle>
  <app-icon
    [icon]="'pi pi-times'"
    tooltip="Close"
    [color]="'#29c8c1'"
    (click)="onDeliveryTableClick()"
  ></app-icon>
</ng-template>

<ng-template #scopeByDiscTitle>
  <div class="d-flex align-items-center gap-1">
    <div class="card-header-text">Scope By Discipline</div>
    <app-icon
      [tooltip]="'Sum of budgeted quantity for IFI,IFD & IFC Status'"
      [color]="'#29c8c1'"
      [icon]="'pi pi-info-circle'"
    ></app-icon>
  </div>
</ng-template>

<ng-template #releasePlanTitle>
  <div class="d-flex align-items-center gap-1">
    <div class="card-header-text">Release Plan</div>
    <app-icon
      [tooltip]="'Sum of budgeted quantity of Actual & Plan for IFC Status'"
      [color]="'#29c8c1'"
      [icon]="'pi pi-info-circle'"
    ></app-icon>
  </div>
</ng-template>

<ng-template #varianceByDiscTitle>
  <div class="d-flex align-items-center gap-1">
    <div class="card-header-text">Variance By Discipline</div>
    <app-icon
      [tooltip]="
        'Difference between planned and actual budgeted quantities for the status selected in the filter pane.'
      "
      [color]="'#29c8c1'"
      [icon]="'pi pi-info-circle'"
    ></app-icon>
  </div>
</ng-template>

<ng-template #ifcTitle>
  <div class="d-flex align-items-center gap-1">
    <div class="card-header-text">Forecast By Discipline</div>
    <app-icon
      [tooltip]="
        'Forecast values based on selected status & cut off date in the filter pane.'
      "
      [color]="'#29c8c1'"
      [icon]="'pi pi-info-circle'"
    ></app-icon>
  </div>
</ng-template>

<ng-template #ifcEndEle>
  <app-icon
    [color]="'#29c8c1'"
    [icon]="'pi pi-table'"
    (click)="onDeliveryTableClick()"
  ></app-icon>
</ng-template>

<ng-template #openLinkTemplate let-rowData>
  <div>
    <app-icon
      color="#29c8c1"
      (click)="onOpenFormLinkClick(rowData)"
      [icon]="'pi pi-external-link'"
    ></app-icon>
  </div>
</ng-template>

@if(isLinksTableVisible){
<app-table
  [title]="'Dashboard'"
  [inTableDialog]="true"
  [isTableDialogVisible]="isLinksTableVisible"
  (onClosePopup)="onClosePopup()"
  [cols]="linksData.columns"
  [defaultColumns]="linksData.columns"
  [data]="linksData.data"
  [hideDownloadIcon]="true"
  [hideCustomizeColumnIcon]="true"
></app-table>
}
