<div class="h-100 container-fluid">
  @if(loading) {
  <app-loader [backdrop]="true" />
  }
  <div class="header-container">
    <app-header-text class="pb-1" label="Engineering S Curve"></app-header-text>
  </div>
  <div class="datacard">
    <app-scurvedata-card [data]="scurveData.metaData"> </app-scurvedata-card>
  </div>

  <div class="mb-3 singlecolumn-container">
    <single-column
      [id]="'Engineering S Curve'"
      [cardTitle]="'Engineering S Curve'"
      [tooltipSuffix]="'%'"
      [secondaryAxisTickPosition]="[0, 20, 40, 60, 80, 100]"
      [secondaryYAxisEnabled]="true"
      [primaryYAxisSuffix]="'%'"
      [secondaryYAxisSuffix]="'%'"
      [dataLabelsSuffix]="'%'"
      [data]="scurveData.chartData"
      [loading]="loading"
    >
    </single-column>
  </div>
  <div class="pb-3">
    @if (scurveData.tableData.count>0) {
    <app-table
      textAlign="center"
      [title]="'Details'"
      [data]="scurveData.tableData.rows"
      [cols]="scurveData.tableData.columns"
      [defaultColumns]="scurveData.tableData.columns"
      [paginationOptions]="paginationOptions"
      [loading]="loading"
      [expandable]="false"
      [hideCustomizeColumnIcon]="false"
      [hideDownloadIcon]="false"
    ></app-table>
    }
  </div>
</div>
