import { Component, Input, Output, EventEmitter, inject } from '@angular/core';
import { IconComponent } from "../icon/icon.component";
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-area-image',
  templateUrl: './area-image.component.html',
  styleUrls: ['./area-image.component.css'],
  standalone: true,
  imports: [IconComponent, CommonModule]
})
export class AreaImageComponent {
  private router = inject(Router);
  @Input() selectedArea?: string;         
  @Input() isImageDisabled: boolean = false;
  @Input() imageClass: string = '';
  @Input() actionButtonsRequired?: boolean;
  @Input() isGanttChart?: boolean;
  
  @Output() areaClick = new EventEmitter<string>();
  @Output() on3dClick = new EventEmitter<void>();
  @Output() on4dClick = new EventEmitter<void>();
  @Output() onVideoClick = new EventEmitter<void>();


  handle3DClick() {
    window.open('/3d-viewer','popup', `width=${window.screen.width},height=${window.screen.height},scrollbars=yes,resizable=yes`);
  }

  handle4DClick() {
    this.on4dClick.emit();
  }

  handleVideoClick() {
    this.onVideoClick.emit();
  }


  onAreaClick(area: string) {
    if (!this.isImageDisabled) {
      if (this.selectedArea === area) {
        this.areaClick.emit(''); 
      } else {
        this.areaClick.emit(area); 
      }
    }
    
  }
  
}
