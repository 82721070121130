import { Component, Input } from '@angular/core';
import { convertDateToCommonFormat } from '../../utils/utility-functions';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { IconComponent } from '../icon/icon.component';
import { CommonModule } from '@angular/common'
type ActionData = {
  status: string;
  actionBy?: string | null;
  actionOn?: string | null;
  assignedTo?: string | null;
  actionName: string;
  action?: string | null;
};
@Component({
  selector: 'app-stepper',
  standalone: true,
  imports: [IconButtonComponent,CommonModule,IconComponent],
  templateUrl: './stepper.component.html',
  styleUrl: './stepper.component.css'
})
export class StepperComponent {

  @Input() data: ActionData[] = [];
  convertDateToCommonFormat = convertDateToCommonFormat; 
  getStatusIcon(status: string) {
    switch (status) {
      case 'completed':
        return {
          icon: 'pi pi-check', // Icon name for app-icon component
          style: { backgroundColor: '#4CAF50', color: '#fff' }
        };
      case 'inprogress':
        return {
          icon: 'pi pi-pencil',
          style: { backgroundColor: '#FFC107', color: '#fff' }
        };
      case 'upcoming':
        return {
          icon: 'pi pi-clock',
          style: { backgroundColor: '#9E9E9E', color: '#fff' }
        };
      default:
        return {
          icon: 'pi pi-question',
          style: { backgroundColor: '#9E9E9E', color: '#fff' }
        };
    }
  }
}
