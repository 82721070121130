import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { GanttChartType } from '../../interface/ChartTypes';

const { AREA_STATUS, AREA_TASK_STATUS } = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class AreaStatusService {

  private http = inject(HttpClient);

  fetchGanttChartData(filterLevel: string): Observable<{ data: GanttChartType }> {
    return this.http.post<{ data: GanttChartType }>(
      `${AREA_STATUS}`, {
      filterLevel
    }
    );
  }

  fetchTaskLevelChartData(location: string, area: string, task?:string): Observable<{ data: GanttChartType }> {
    return this.http.post<{ data: GanttChartType }>(AREA_TASK_STATUS, {
      location, area, task
    })
  }

}
