<div class="h-100 container-fluid">
  @if(loading) {
  <app-loader [backdrop]="true" />
  }
  <div class="header-container">
    <app-header-text class="pb-1" label="Construction S Curve"></app-header-text>
  </div>
  <div class="datacard">
    <app-scurvedata-card [data]="projectScheduleChartData.metaData">
    </app-scurvedata-card>
  </div>

  <div class="mb-3 singlecolumn-container">
    <single-column
      [id]="'project-scheduling'"
      [cardTitle]="'Construction S Curve'"
      [tooltipSuffix]="'%'"
      [secondaryYAxisEnabled]="true"
      [primaryYAxisSuffix]="'%'"
      [secondaryYAxisSuffix]="'%'"
      [primaryYAxisMax]="100"
      [secondaryYAxisMax]="10"
      [dataLabelsSuffix]="'%'"
      [data]="projectScheduleChartData.chartData"
      [loading]="loading"
    >
    </single-column>
  </div>
  <div class="pb-3">
    @if (projectScheduleChartData.tableData.count>0) {
    <app-table
      [title]="'Details'"
      [data]="projectScheduleChartData.tableData.rows"
      [cols]="projectScheduleChartData.tableData.columns"
      [defaultColumns]="projectScheduleChartData.tableData.columns"
      [paginationOptions]="paginationOptions"
      [loading]="loading"
      [expandable]="false"
      [hideCustomizeColumnIcon]="false"
      [hideDownloadIcon]="false"
      [textAlign]="'center'"
    ></app-table>
    }
  </div>
</div>

