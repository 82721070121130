<div style="display: flex; align-items: center">

  @for(val of values;track $index ) {
    <div>
      <input
        class="form-check-input"
        type="radio"
        [id]="val.key"
        [value]="val.key"
        [checked]="val.key === value?.key"
        [disabled]="val.disabled!"
        (click)="onRadioChange(val)"
      />
    </div>
    <div>
      <label [for]="val.key" class="primary-text" style="margin-right: 1rem;margin-left: 0.3rem; font-weight: 550; color: #29c8c1;">{{
        val.name
      }}</label>
    </div>
  }
</div>
<div *ngFor="let val of values" style="display: flex; align-items: center">

</div>

