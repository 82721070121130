import { AfterViewInit, Component, inject, OnInit } from '@angular/core';
import { Model3dServiceService } from '../../shared/services/3d-model-service/model3d-service.service';
@Component({
  selector: 'three-d-viewer',
  standalone: true,
  imports: [],
  templateUrl: './three-d-viewer.component.html',
  styleUrl: './three-d-viewer.component.css',
})
export class ThreeDViewerComponent implements AfterViewInit, OnInit {
  model3dService = inject(Model3dServiceService);

  fetchSecrets(projectName: string) {
    this.model3dService.getSecrets(projectName).subscribe({
      next: (response) => {
        const { access_token, urn } = response.data;
        initializeViewer(access_token, urn);
      },
      error: (error) => {
        console.error('Error fetching secrets:', error);
      },
    });
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.fetchSecrets('Reliance Polysilicon Project');
  }
}
