import { Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DashboardLinks } from '../../interface/DashboardLinks';


const { DASHBOARD_LINK} = API_END_POINTS
@Injectable({
  providedIn: 'root'
})
export class DashboardLinksService {

  constructor(private http: HttpClient) { }
  getDashboardLinksData(pageName: string): Observable<{data: DashboardLinks[]}> {
    return this.http.get<{data: DashboardLinks[]}>(DASHBOARD_LINK(pageName) )
  }

}
