import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminComponent } from './pages/admin/admin.component';
import { FormBuilderComponent } from './pages/admin/form/form-builder.component';
import { CommodityTrackingComponent } from './pages/commodity-tracking/commodity-tracking.component';
import { ConstructionManagementComponent } from './pages/construction-status/construction-status.component';
import { ConstructionProjectSchedulingComponent } from './pages/construction-project-scheduling/construction-project-scheduling.component';
import { DelayTrackingComponent } from './pages/delay-tracking/delay-tracking.component';
import { DisciplineScurveComponent } from './pages/discipline-scurve/discipline-scurve.component';
import { DocumentControlComponent } from './pages/document-control/document-control.component';
import { FailedComponent } from './pages/failed/failed.component';
import { HomeComponent } from './pages/home/home.component';
import { HseComponent } from './pages/hse/hse.component';
import { MaterialsPlanningComponent } from './pages/materials-planning/materials-planning.component';
import { ProcurementStatusComponent } from './pages/procurement-status/procurement-status.component';
import { ProjectSchedulingComponent } from './pages/project-scheduling/project-scheduling.component';
import { EngScurveComponent } from './pages/eng-scurve/eng-scurve.component';
import { ScurveComponent } from './pages/scurve/scurve.component';
import { ThreeDViewerComponent } from './pages/three-d-viewer/three-d-viewer.component';
import { VdrStatusComponent } from './pages/vdr-status/vdr-status.component';
import { ProcurementScurveComponent } from './pages/procurement-scurve/procurement-scurve.component';
import { DelayFormComponent } from './pages/delay-form/delay-form/delay-form.component';
import { AiAssistComponent } from './pages/ai-assist/ai-assist.component';
export const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  {
    path: 'admin',
    canActivate: [MsalGuard],
    children: [
      {
        path: '',
        loadComponent: () => AdminComponent,
        canActivate: [MsalGuard],
      },
      {
        path: 'forms',
        loadComponent: () => FormBuilderComponent,
        canActivate: [MsalGuard],
      },
    ],
  },
  {
    path: 'login-failed',
    loadComponent: () => FailedComponent,
  },
  {
    path: 'materials-planning',
    loadComponent: () => MaterialsPlanningComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'vdr-status',
    loadComponent: () => VdrStatusComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'construction-status',
    loadComponent: () => ConstructionManagementComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'hse',
    loadComponent: () => HseComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'purchase-order-tracking',
    loadComponent: () => CommodityTrackingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'engineering-status',
    loadComponent: () => DocumentControlComponent,
  },
  {
    path: 'project-scheduling',
    loadComponent: () => ProjectSchedulingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'construction-project-scheduling',
    loadComponent: () => ConstructionProjectSchedulingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'delay-tracking',
    loadComponent: () => DelayTrackingComponent,
    canActivate: [MsalGuard],
  },
  {
    path: '3d-viewer',
    loadComponent: () => ThreeDViewerComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'procurement-status',
    loadComponent: () => ProcurementStatusComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'eng-scurve',
    loadComponent: () => EngScurveComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 's-curve',
    loadComponent: () => ScurveComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'discipline-scurve',
    loadComponent: () => DisciplineScurveComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'procurement-scurve',
    loadComponent: () => ProcurementScurveComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'delay-form/:id',
    loadComponent: () => DelayFormComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ai-assist',
    component: AiAssistComponent,
    canActivate: [MsalGuard]
  },
];
