@if (form && !fieldConfig.hidden) {
<div class="d-flex flex-column" [formGroup]="form">
    <div class="d-flex">
        <label>{{ fieldConfig.label || fieldConfig.name }}</label>
        @if (fieldConfig.isMandatory) {
        <span class="mandatory ml-1">*</span>
        }
    </div>
    <div class="input-group">
        @switch (fieldConfig.type) {
        @case ('text') {
        <input  class="form-control" [formControlName]="fieldConfig.name" type="text"
            [placeholder]="fieldConfig.placeholder" />
        }
        @case ('number') {
        <input  class="form-control" [formControlName]="fieldConfig.name" type="number"
            [placeholder]="fieldConfig.placeholder" />
        }
        @case ('date') {
        <input  ngbDatepicker [placeholder]="fieldConfig.placeholder || 'yyyy-mm-dd'" class="form-control"
            [formControlName]="fieldConfig.name" type="date" />
        }
        @case ('dropdown_dynamic') {
        <select  (change)="onDropdownChange($event)" class="form-select" [formControlName]="fieldConfig.name">
            <option value="" disabled selected>{{fieldConfig.placeholder || 'Select your option'}}</option>

            @for (option of fieldConfig.options; track $index) {
            <option [value]="option.value">{{ option.text }}</option>
            }
        </select>
        }
        @case ('dropdown_static') {
        <select  (change)="onDropdownChange($event)" class="form-select" [formControlName]="fieldConfig.name">
            <option value="" disabled selected>{{fieldConfig.placeholder || 'Select your option'}}</option>

            @for (option of fieldConfig.options; track $index) {
            <option [value]="option.value">{{ option.text }}</option>
            }
        </select>
        }
        @case ('checkbox') {
        <input  class="form-check-input" [formControlName]="fieldConfig.name" type="checkbox" />

        }
        @case ('radio') {
        <div>
            @for (option of fieldConfig.options; track option.text) {
            <input  class="form-check-input" [formControlName]="fieldConfig.name" type="radio" [value]="option.value" />
            {{ option.value }}

            }
        </div>
        }
        @case ('datetime') {
        <input  class="form-control" [formControlName]="fieldConfig.name" type="datetime-local" />
        }
        @case ('file') {
        <input  class="form-control" type="file" (change)="onFileChange($event)"
            [accept]="fieldConfig.fileType || '*'" />
        }
        @case ('textarea') {
        <textarea  class="form-control" [formControlName]="fieldConfig.name" type="text"
        [placeholder]="fieldConfig.placeholder"></textarea>
        }
        @default {
        <span></span>
        }

        }
    </div>
    @if (!isValid && form.controls[fieldConfig.name].touched) {
    @if (form.controls[fieldConfig.name].errors?.['required'] ||
    form.controls[fieldConfig.name].errors?.['whitespace'])
    {
    <div class="error-message">This field is required.</div>
    }
    @if (form.controls[fieldConfig.name].errors?.['pattern']) {
    <div class="error-message">Invalid Format.</div>
    }
    }
    @if (fileError) {

    <div class="error-message">{{ fileError }}</div>
    }

</div>
}