<div class="main-container">
  <app-header-text label="AI Assist" />
  @if(loading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <!-- Left Side Panel for Search History -->
  <!-- 
  <div class="side-panel">
    <label style="display: flex; font-size: 1rem; font-weight: 700"
      >Search History</label
    >
    @for(chat of allChats; track $index) {
    <div class="history-item" (click)="onChatHistoryClick(chat)">
      {{ chat }}
    </div>
    }
  </div>
   -->
  <!-- Main Content Area -->
  <div #mainContainer class="main-content">
    <div #categoriesContainer class="categories-style">
      @for (categry of categoryData; track $index;) {
      <div
        class="sub-categories-style"
        [ngStyle]="{
          'background-color':
            selectedCategory === categry.name ? '#3295ff' : 'white',
          color: selectedCategory === categry.name ? 'white' : '#494F55'
        }"
        (click)="onCategoryClick(categry.name)"
      >
        {{ categry.name }}
      </div>
      }
    </div>

    <div #chatContainer class="chat-container">
      @if(checkChatMessage()) {
      <!-- Chat Messages Section -->
      <div #chatMsg class="chat-messages mt-2 pb-3">
        @for(msg of chatMessages; track $index) { @if(loading) {
        <div class="chat-message system">
          <div class="message-content">
            <app-loader [backdrop]="true" />
          </div>
        </div>
        } @if(msg.type === 'sent' && msg.message.length) {
        <div class="chat-message user me-2">
          <div class="message-content">{{ msg.message }}</div>
        </div>
        } @else if(msg.type === 'recieved') {
        @if(checkArrayObjects(msg.message)) {
        <div class="chat-message system ms-2">
          <div
            class="message-table-content"
            [ngStyle]="{ padding: '8px 12px' }"
          >
            <p-table
              [columns]="getKeys(msg.message)"
              [value]="msg.message"
              [scrollable]="true"
              [scrollHeight]="'flex'"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track $index) {
                  <th scope="col" style="flex: 0 0 10%">
                    {{ convertTitleCase(col.header) }}
                  </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  @for (col of columns; track $index) {
                  <td style="flex: 0 0 10%">
                    {{ rowData[col.field] }}
                  </td>
                  }
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        }@else {
        <div class="chat-message system ms-2">
          <div class="message-content">{{ msg.message }}</div>
        </div>
        } } @else if (msg.type === 'assistant' && msg.message.length) {
        @if(checkArrayObjects(msg.message)) {
        <div class="chat-message system">
          <div
            class="message-table-content"
            [ngStyle]="{ padding: '8px 12px' }"
          >
            <p-table
              [columns]="getKeys(msg.message)"
              [value]="msg.message"
              [scrollable]="true"
              [scrollHeight]="'flex'"
              [tableStyle]="{ height: '90px' }"
            >
              <ng-template pTemplate="header" let-columns>
                <tr>
                  @for (col of columns; track $index) {

                  <th scope="col" style="flex: 0 0 10%">
                    {{ convertTitleCase(col.header) }}
                  </th>
                  }
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns" style="flex: 0 0 10%">
                    {{ rowData[col.field] }}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
        } @else {
        <div class="chat-message system">
          <div class="message-content">{{ msg.message }}</div>
        </div>
        } } @else if (msg.type === 'user' && msg.message.length) {
        <div class="chat-message user me-2">
          <div class="message-content">{{ msg.message }}</div>
        </div>
        } }
      </div>
      }@else {
      <p
        style="font-size: x-large; padding-top: 10rem"
        class="text-center fw-bolder"
      >
        What do you want to know about?
      </p>
      } @if(isLoading){
      <div class="chat-message system ms-2 p-3">
        <div><app-loader [size]="6" /></div>
      </div>

      }
    </div>
    <!-- @if(sampleQuestions.length>0){ -->
    <div #faqs class="chat-input-container">
      <div
        [ngClass]="{
          ' py-2': !!sampleQuestions.length
        }"
        class="faqs"
      >
        @for (que of sampleQuestions; track $index;) {
        <div
          class="sub-categories-style"
          [ngStyle]="{ 'background-color': getHighlightedClass(que) }"
          (click)="onSelectQuestion(que)"
        >
          {{ que }}
        </div>
        }
      </div>
      <div class="chat-input">
        <input
          [disabled]="selectedCategory ? false : true"
          type="text"
          pInputText
          [placeholder]="
            selectedCategory
              ? 'Type your message...'
              : 'Select category from top to start the chat...'
          "
          [(ngModel)]="selectedQue"
          class="message-input"
        />
        @if (selectedQue) {
        <app-icon
          [icon]="'pi pi-send'"
          class="send-button"
          [iconSize]="'larger'"
          (click)="sendMessages()"
        />
        } @else {
        <app-icon
          [icon]="'pi pi-send'"
          class="send-button"
          [iconSize]="'larger'"
          [color]="'gray'"
          [ngStyle]="{ cursor: 'none' }"
        />
        }
      </div>
    </div>

    <!-- } -->

    <!-- Input Section -->
  </div>
</div>
