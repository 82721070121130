import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { IconComponent } from "../icon/icon.component";
import { DownloadChartComponent } from '../download-chart/download-chart.component';
import { IconButtonComponent } from "../icon-button/icon-button.component";
import { LoaderComponent } from "../loader/loader.component";
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css'],
  standalone: true,
  imports: [CommonModule, IconComponent, DownloadChartComponent, LoaderComponent, NgbTooltip],
})
export class CardComponent {
  @Input() id?: string;
  @Input() title = '';
  @Input() titleTemplate?: TemplateRef<any>;
  @Input() chartTitle?: string;
  @Input() downloadChart?: boolean
  @Input() loading?: boolean = false;
  @Input() style: any;
  @Input() drillDownReset?: boolean = false;
  @Input() helperText?:  TemplateRef<any>;
  @Input() expandable?: boolean = true;
  @Input() endElement?: TemplateRef<any>;
  @Input() redirectUrl?: any = null; // string | ((param: string) => { path: string; state?: any });
  @Input() selectedArea?: string = 'Overall';
  @Input() overRideDialogStyle: any;
  @Input() headerStyle: any;
  @Input() avoidExpand: boolean = false
  @Input() alreadyMaximized?: boolean = false;
  @Input() downloadIcon?: { enable: boolean; onClick: () => void };
  @Input() customizeColumn?: { enable: boolean; onClick: () => void };
  
  @Output() onCollapse = new EventEmitter<void>();
  @Output() onExpand = new EventEmitter<boolean>();
  @Output() onReset = new EventEmitter<void>();  

  visible: boolean = false; 
  @Input() isExpanded: boolean = false; 

  constructor(private router: Router) {
  }

  ngOnInit() {
    if (this.isExpanded) {
      this.toggleFullscreen()
    }
  }

 
  isFullscreen: boolean = false;

   
  toggleFullscreen() {
    const currentState = this.isFullscreen;
    this.onExpand.emit(!currentState)
    this.isFullscreen = !this.isFullscreen;
  }

  handleReset() {
    this.onReset.emit();
  }

  handleCollapse() {
    this.onCollapse.emit();
  }

  handleRedirect() {
    if (this.redirectUrl) {
      const path = typeof this.redirectUrl === 'function'
        ? this.redirectUrl(this.selectedArea)
        : this.redirectUrl;

      if (path?.path) {
        this.router.navigate([path.path], { state: path.state });
      }
    }
  }

  getTypeOfTitle = () => {
    return typeof this.title
  }
}
