import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { ChartDataType } from '../../interface/ChartTypes';
import { ColumnProps } from '../../interfaces/table-interface';
import { OverallScurveModel } from '../../types/OverallScurveModel';

const {
  DOCUMENT_SCURVE
} = API_END_POINTS

@Injectable({
  providedIn: 'root'
})
export class EngScurveService {

  constructor(private http: HttpClient) { }
  getEngScurveData():Observable<{data: OverallScurveModel }>{
    return this.http.post<{data:OverallScurveModel}>(DOCUMENT_SCURVE,null)
  }
}
