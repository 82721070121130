<app-custom-dialog
  [maximizable]="false"
  [alreadyMaximized]="true"
  [visible]="isFormOpen"
  [alreadyMaximized]="true"
  [title]="title"
  (visibleChange)="isFormOpen = $event"
  (closeDialogClick)="onCloseFormClick()"
  [style]="{ width: '100vw', height: '100vh', top: '0', left: '0' }"
>
  <ng-template #title>
    <div class="flex title" style="display: flex; align-items: center;">
      <div>
        <app-icon [icon]="'pi pi-file'" ></app-icon>
      </div>
      <div>
        <span style="color: #29c8c1; font-size: 1.1rem; font-weight: bold;">
          Project Highlights/Concerns
        </span>
      </div>
    </div>
  </ng-template>
  <div class="form-container">
    <form [formGroup]="form" (ngSubmit)="onSubmitForm()">
      <div
        class="section-div"
      >
        <label
          for="projectDetails"
          class="section-header-text"
          >Project Details</label
        >
        <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
          <label for="projectname" class="label-text">Project Name</label>
          <input
            id="projectname"
            class="custom-textField"
            pInputText
            formControlName="projectNameField"
            [disabled]="isTextFieldDisabled"
          />
        </div>
      </div>
      <div style="margin-top: 30px; margin-bottom: 5px">
        <app-switch
          [id]="'isConcern'"
          [checked]="form.get('isConcern')?.value"
          [uncheckedLabel]="'Highlights'"
          [checkedLabel]="'Concerns'"
          (onToggleSwitch)="onToggleSwitch($event)"
          formControlName="isConcern"
        />
      </div>
      <div
        class="section-div"
      >
        <label
          for="reporter"
          class="section-header-text"
          >Reporter</label
        >
        
          <div class="row">
            <div
              class="col-12 col-md-3"
              style="display: grid; margin-top: 10px"
            >
              <label for="area" class="label-text">Area<span style="color: red; font-weight: bold; font-size: 0.8rem"> *</span></label>
              <p-dropdown
                id="area"
                class="custom-dropdown"
                [options]="areaData()"
                optionLabel="value"
                formControlName="areaField"
                placeholder="Select Area"
              />
              <div style="height: 1rem;">
              @if(areaField?.invalid && areaField?.touched) {
              <small class="p-error">Dropdown selection is required.</small>
              }
            </div>
            </div>
            <div
              class="col-12 col-md-3"
              style="display: grid; margin-top: 10px"
            >
              <label for="dropdownField" class="label-text"
                >Location<span style="color: red; font-weight: bold; font-size: 0.8rem"> *</span></label
              >
              <p-dropdown
                id="location"
                class="custom-dropdown"
                [options]="locationData()"
                optionLabel="value"
                formControlName="locationField"
                class="custom-dropdown"
                placeholder="Select Location"
                [disabled]="!(form.get('areaField')?.value && form.get('areaField')?.value.length)"
                
              />
              <div style="height: 1rem;">
              @if(locationField?.invalid && locationField?.touched) {
              <small class="p-error">Dropdown selection is required.</small>
              }
            </div>
            </div>
            <div
              class="col-12 col-md-3"
              style="display: grid; margin-top: 10px"
            >
              <label for="cwp" class="label-text">CWP</label>
              <p-dropdown
                id="cwp"
                class="custom-dropdown"
                [options]="cwpData()"
                optionLabel="value"
                formControlName="cwpField"
                placeholder="Select CWP"
                
              />
              <div style="height: 1rem;"></div>
            </div>
            <div
              class="col-12 col-md-3"
              style="display: grid; margin-top: 10px"
            >
              <label for="actionowner" class="label-text"
                >Action Owner</label
              >
              <p-dropdown
                id="actionowner"
                class="custom-dropdown"
                [options]="userData()"
                optionLabel="value"
                formControlName="actionOwnerField"
                placeholder="Select Action Owner"
                
              />
              <div style="height: 1rem;"></div>
            </div>
            <div
              class="col-12 col-md-6"
              style="display: grid; margin-top: 10px"
            >
              @if(isConcernn) {
              <label for="concerns" class="label-text">Concerns<span style="color: red; font-weight: bold; font-size: 0.8rem"> *</span></label>
              <textarea
              id="concerns"
              class="custom-textarea"
              placeholder="Enter Concerns"
                rows="5"
                cols="30"
                pInputTextarea
                formControlName="highlightsConcernsField"
              >
              </textarea>
            
              <div style="height: 1rem;">
              @if(highlightsConcernsField?.invalid && highlightsConcernsField?.touched) {
              <small class="p-error">Text field is required.</small>
              }
            </div>
             } 
             @else {
              <label for="highlights" class="label-text">Highlights<span style="color: red; font-weight: bold; font-size: 0.8rem"> *</span></label>
              <textarea
              id="highlights"
              class="custom-textarea"
              placeholder="Enter Highlights"
                rows="5"
                cols="30"
                pInputTextarea
                formControlName="highlightsConcernsField"
              >
              </textarea>
              <div style="height: 1rem;">
              @if(highlightsConcernsField?.invalid && highlightsConcernsField?.touched) {
              <small class="p-error">Text field is required.</small>
              }</div> }
            </div>
            <div
              class="col-12 col-md-6"
              style="display: grid; margin-top: 10px; height: fit-content;"
            >
            <label for="upload" class="label-text">Upload</label>
            <app-upload-button
            [id]="'upload'"
            [disabled]="false"
            [disableRemove]="false"
            (onUpload)="handleFileUpload($event)"
            (onRemoveClick)="handleFileRemove()"
            />
          </div>
          </div>
        
      </div>
      <div style="display: flex; justify-content: flex-end; gap: 10px; margin-top: 20px;">
        <app-cancel-button
        [label]="'Cancel'"
        [buttonStyles]="{}"
        (onClick)="onCloseFormClick()"
        />
        <app-submit-button
        [label]="'Submit'"
        [disabled]="form.invalid"
        [type]="'submit'"
        [buttonStyles]="{}"
        />
      </div>
      

