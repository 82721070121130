<div style="display: flex; width: fit-content; column-gap: 1rem; align-items: center;">
    <div>{{ uncheckedLabel }}</div>
    <p-inputSwitch [name]="name" [disabled]="disabled" [(ngModel)]="checked" (onChange)="onToggleChanges($event)"
    [ngStyle]="{
        'background-color': 'none',
        'font-size': '1px',
        'width': 'fit-content',
        'margin-right':'-0.5rem',
        
    }"
    />
    <div>{{ checkedLabel }}</div>
</div>
