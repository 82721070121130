import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../environments/environment';

const excludedUrls: string[] = [];

export const APIInterceptor: HttpInterceptorFn = (req, next) => {
  const isExcluded = excludedUrls.some((excludedUrl) =>
    req.url.includes(excludedUrl)
  );
  let apiReq;
  if (isExcluded) {
    apiReq = req.clone({ url: `${req.url}` });
    return next(apiReq);
  }
  apiReq = req.clone({ url: `${environment.apiConfig.uri}${req.url}` });
  return next(apiReq);
};
