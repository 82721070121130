import { Component,Input,Output,EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '@angular/forms';
import { RadioBtn } from '../../../types/RadioBtn';


@Component({
  selector: 'app-radio',
  standalone: true,
  imports: [CommonModule, RadioButtonModule, FormsModule],
  templateUrl: './radio.component.html',
  styleUrl: './radio.component.css'
})
export class RadioComponent implements OnChanges {


  @Input() values: RadioBtn[] = [];
  @Input() value?: RadioBtn;
  @Output() onChange: EventEmitter<RadioBtn> = new EventEmitter<RadioBtn>();

  onRadioChange(selectedValue: RadioBtn) {
    this.value = selectedValue
    if (!selectedValue.disabled) {
      this.onChange.emit(selectedValue);
    }
  }

  ngOnChanges(changes: SimpleChanges): void { 
  }
  
}
