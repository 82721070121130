export const TOKEN_EXCL_LIST: string[] = [];


export const monthDropDownValues = {
    '01': 'January',
    '02': 'February',
    '03': 'March',
    '04': 'April',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'August',
    '09': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'December'
}

export const DELAY_STATUS = {
    REPORTED: 'Reported',
    PENDING_APPROVAL: 'Pending Approval',
    PENDING_ANALYSIS: 'Pending Analysis',
    PENDING_ACTION: 'Pending Action',
    PENDING_CLOSURE: 'Pending Closure',
    CLOSED: 'Closed',
    OPEN_ITEMS: 'Open Items',
    PENDING: 'Pending'
};


export const dashboardRedirectUrl = 'https://app.powerbi.com/groups/me/apps/7b51081f-4913-4f87-a046-dcb1ca33accb?experience=power-bi';