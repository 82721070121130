import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartDataType, ChartWithTable } from '../../interface/ChartTypes';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { MaterialPlanningChartFilter } from '../../interface/Filters';
import { AreasResponse } from '../../interface/AreaTypes';

const {
  MATERIAL_ACTUAL_STATUS,
  MATERIAL_DELIVERY_STATUS,
  MATERIAL_RELEASE_STATUS,
  GET_AREAS,
  MATERIAL_PLANNING_FILTERS,
  EXPEDITING_S_CURVE_FILTERS,
} = API_END_POINTS;
@Injectable({
  providedIn: 'root',
})
export class MaterialsPlanningService {
  // private readonly apiUrl = 'https://api.worley.alignedautomation.com/api/v1/';
  private http = inject(HttpClient);
  constructor() {}

  getMaterialActualStatus(
    filters: any
  ): Observable<{ data: { chartData: ChartDataType } }> {
    return this.http.post<{ data: { chartData: ChartDataType } }>(
      MATERIAL_ACTUAL_STATUS,
      {
        filters,
      }
    );
  }

  getOrderStatusData(
    filters: MaterialPlanningChartFilter | Partial<MaterialPlanningChartFilter>
  ): Observable<{ data: ChartDataType }> {
    return this.http.post<{ data: ChartDataType }>(MATERIAL_DELIVERY_STATUS, {
      filters,
    });
  }

  getMaterialPlanStatus(filters: {}): Observable<{
    data: { chartData: ChartDataType };
  }> {
    return this.http.post<{ data: { chartData: ChartDataType } }>(
      MATERIAL_RELEASE_STATUS,
      {
        filters,
      }
    );
  }

  getAreas(): Observable<AreasResponse> {
    return this.http.get<AreasResponse>(GET_AREAS);
  }

  getMaterialFilters(): Observable<{ data: MaterialPlanningChartFilter }> {
    return this.http.get<{ data: MaterialPlanningChartFilter }>(
      MATERIAL_PLANNING_FILTERS
    );
  }

  getFilters(): Observable<{
    data: { disciplines: string[]; status: string };
  }> {
    return this.http.get<{ data: { disciplines: string[]; status: string } }>(
      EXPEDITING_S_CURVE_FILTERS
    );
  }
}
