import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, TemplateRef, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { InputSwitchChangeEvent, InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-switch',
  standalone: true,
  imports: [InputSwitchModule, CommonModule, FormsModule],
  templateUrl: './switch.component.html',
  styleUrl: './switch.component.css'
})
export class SwitchComponent implements OnInit, OnChanges {
  @Input() checked: boolean = false;
  @Output() onToggleSwitch = new EventEmitter<InputSwitchChangeEvent>();
  @Input() name: string = '';
  @Input() id?: string;
  @Input() uncheckedLabel?: any;
  @Input() checkedLabel?: any;
  @Input() disabled: boolean = false;

  change: any;


  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
  }

  ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    if(changes['checked']) {
    }
  }

  onToggleChanges(event: InputSwitchChangeEvent) {
    this.onToggleSwitch.emit(event);
  }
}
