import { Component, Input } from '@angular/core';
import { ScurveDataCard } from '../../interface/scurve-data-card';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-scurvedata-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './scurvedata-card.component.html',
  styleUrl: './scurvedata-card.component.css'
})
export class ScurvedataCardComponent {
  @Input() data: ScurveDataCard[] = [];
}
