import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { ChartWithTable } from '../../interface/ChartTypes';
import { OverallScurveModel } from '../../types/OverallScurveModel';
const {
  PROCUREMENT_S_CURVE_LINE_CHARTDATA,
  DISCIPLINE_S_CURVE_CHARTDATA,
} = API_END_POINTS;
@Injectable({
  providedIn: 'root'
})
export class ProcurementScurveServices {
  private http = inject(HttpClient);
  constructor() { }
  getProcurementSCurve(filters: any): Observable<{ data: OverallScurveModel }> {
    return this.http.post<{ data: OverallScurveModel }>(
      PROCUREMENT_S_CURVE_LINE_CHARTDATA,
      { filters }
    );
  }


  getDisciplineLineChartData(filters: {
    discipline: string;
  }): Observable<{ data: OverallScurveModel }> {
    return this.http.post<{ data: OverallScurveModel }>(
      DISCIPLINE_S_CURVE_CHARTDATA,
      { filters }
    );
  }
}

