<div class="h-100">
  <ng-template class="h-100" #tabs>
    <ul class="nav nav-underline custom-tabs h-full">
      @for (tab of model; track $index) {
      <li class="nav-item">
        <a class="nav-link" [class.active]="$index === activeIndex" href="#" (click)="onTabClick($event, $index, tab)">
          {{ tab.label }}
        </a>
      </li>
    }
  </ul>
  </ng-template>
  <app-card [downloadIcon]="{enable: true, onClick: onDownloadClick!}" [titleTemplate]="tabs">

    @if (loading) {
    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
      <app-loader></app-loader>
    </div>
    } @else {
      
    <ng-container class="h-full" *ngTemplateOutlet="templateToLoad"></ng-container>
    }
  </app-card>
</div>