<div class="main-container">
  <div class="header-container">
    <app-header-text label="Discipline S Curve"></app-header-text>
  </div>
  @if(loading) {
  <app-loader [backdrop]="true" />
  }

  <div class="singlecolumn-container">
    <single-column
      [id]="'disciplineLineChartData'"
      [cardTitle]="'Discipline - S Curve'"
      [secondaryYAxisEnabled]="true"
      [primaryYAxisSuffix]="'%'"
      [dataLabelsSuffix]="'%'"
      [tooltipSuffix]="'%'"
      [secondaryYAxisSuffix]="'%'"
      [primaryAxisTickPosition]="[0, 20, 40, 60, 80, 100]"
      [data]="disciplineScurveData.chartData"
      [endElement]="sCurveEndEle"
      [loading]="isChartLoading"
    ></single-column>
  </div>
</div>
<ng-template #sCurveEndEle>
  <div class="filter-container">
    <span class="filter-label">Discipline-</span>
    <app-single-select
      [options]="disciplines"
      [placeholder]="'-- Select Area --'"
      (onChange)="onChangeDiscipline($event)"
      [value]="selectedDiscipline"
      [style]="'width: 12rem'"
    ></app-single-select>
  </div>
</ng-template>
