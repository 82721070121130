import { Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DonutChartModel } from '../../interface/ChartTypes';
import { PaginationWithFilters } from '../../interface/Pagination';
import {
  CommodityTracking,
  CommodityTrackingDetail,
  CommodityTrackingResponse,
  ICommodityTrackingForm,
  ICommodityTrackingOverallStatus,
} from '../../interface/CommodityTracking';
import { DelayReasonResponse } from '../../interface/delay-tracking';

const {
  COMMODITY_OVERALL_STATUS,
  COMMODITY_DATA,
  PO_LEVEL_DETAILS,
  COMMODITY_TRACKING_SAVE,
  DELAY_REASONS,
} = API_END_POINTS;
@Injectable({
  providedIn: 'root',
})
export class CommodityTrackingService {
  // private readonly apiUrl = 'http://localhost/api/v1';
  constructor(private http: HttpClient) {}

  getOverallStatusPie(area?: string): Observable<{ data: DonutChartModel }> {
    return this.http.get<{ data: DonutChartModel }>(
      `${COMMODITY_OVERALL_STATUS}?piechart=${true}&area=${area}`
    );
  }

  getCommodityData(
    data: PaginationWithFilters<CommodityTracking>, isDownload?: boolean
  ): Observable<{ data: CommodityTrackingResponse }> {
    return this.http.post<{ data: CommodityTrackingResponse }>(
      COMMODITY_DATA,
      { ...data, downloadData: Boolean(isDownload) }
    );
  }

  getPoDetail(po: string): Observable<{ data: CommodityTrackingDetail[] }> {
    return this.http.get<{ data: CommodityTrackingDetail[] }>(
      PO_LEVEL_DETAILS(po)
    );
  }

  saveCommentsAndReasons(
    data: ICommodityTrackingForm[]
  ): Observable<{ data: ICommodityTrackingForm[] }> {
    return this.http.post<{ data: ICommodityTrackingForm[] }>(
      COMMODITY_TRACKING_SAVE,
      data
    );
  }

  getOverallStatus(): Observable<{ data: ICommodityTrackingOverallStatus }> {
    return this.http.get<{ data: ICommodityTrackingOverallStatus }>(
      `${COMMODITY_OVERALL_STATUS}`
    );
  }
  getDelayReasons(): Observable<DelayReasonResponse> {
    return this.http.get<DelayReasonResponse>(DELAY_REASONS);
  }
}
