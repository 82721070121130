import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonutChartModel } from '../../interface/ChartTypes';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { BarChart } from '../../interface/BarChart';
import { VdrCriticalDocStatusTable } from '../../interfaces/table-interface';

const {
  VDR_RECEIPT_STATUS,
  VDR_REVIEW_STATUS,
  VDR_CRITICAL_DOCUMENTS,
  VDR_CRITICAL_DOCUMENT_STATUS
} = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})

export class VdrService {
 
  constructor() { }
  private http =  inject(HttpClient);

  getReceiptStatus(filters: any): Observable<{ data: DonutChartModel }> {
    return this.http.post<{ data: DonutChartModel }>(
      VDR_RECEIPT_STATUS,
      { filters }
    );
  }

  getReviewStatus(filters: any): Observable<{ data: DonutChartModel }> {
    return this.http.post<{ data: DonutChartModel }>(
      VDR_REVIEW_STATUS,
      { filters }
    );
  }

  getInProgressCriticalDocs(filters: any): Observable<{ data: BarChart }> {
    return this.http.post<{ data: BarChart }>(
      VDR_CRITICAL_DOCUMENTS,
      { filters }
    );
  }

  getCriticalDocStatusTableData( pageNo: number,
    noOfRecords: number,
    filters?: any,
    isDownloadClicked?: boolean): Observable<VdrCriticalDocStatusTable> {
    return this.http.post<VdrCriticalDocStatusTable>(
      VDR_CRITICAL_DOCUMENT_STATUS,
      { pageNo, noOfRecords, filters: filters || {}, isDownloadClicked }
    );
  }
}
