<div class="col-12 col-lg-4 w-100 h-100">
  <div
    class="text-center border rounded font-bold"
    style="height: 95%; border-radius: 10px"
  >
    <div
      class="d-flex w-100 border-bottom bg-white p-2 shadow-sm align-items-center area-image-header"
    >
      <div class="d-flex align-items-center">Site Map</div>

      <div class="d-flex ms-auto gap-2 ml-auto align-items-center">
        @if (selectedArea && selectedArea !== 'Overall') {
        <div (click)="onAreaClick('')" class="cursor-pointer">
          <app-icon icon="pi pi-sync"></app-icon>
        </div>
        } @if (isGanttChart) {
        <div class="cursor-pointer" (click)="handleVideoClick()">
          <app-icon icon="pi pi-video"></app-icon>
        </div>
        @if (selectedArea && selectedArea !== 'Overall') {
        <div class="cursor-pointer fs-5" (click)="handle4DClick()">4D</div>
        } } @else {
        <div class="mr-1 cursor-pointer fs-5" (click)="handle3DClick()">3D</div>
        }
      </div>
    </div>

    <div
      class="d-flex flex-column {{ imageClass || 'bg-image' }} home-bg-img-size"
      style="
        height: 92%;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        font-weight: bold;
      "
    >
      <!-- New Code -->
      <!-- Row 1 Empty -->
      <div class="d-flex" style="height: 18.5%; width: 100%"></div>

      <!-- Row 2 -->
      <div class="d-flex" style="height: 7.5%; width: 100%">
        <div class="d-flex" style="height: 100%; width: 2%"></div>
        <div
          (click)="onAreaClick('Area 4')"
          class="d-flex justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 4' ? 'selected-area' : 'unselected-area' }}"
          style="height: 90%; width: 17%"
        >
          <span>Area 4</span>
          <i class="pi pi-sort-up-fill font-xsmaller green"></i>
        </div>
        <div class="d-flex" style="height: 100%; width: 70%"></div>
      </div>

      <!-- Row 3 -->
      <div class="d-flex" style="height: 21%; width: 100%">
        <div class="d-flex" style="height: 100%; width: 3%"></div>
        <div class="d-flex" style="height: 100%; width: 12%">
          <div
            (click)="onAreaClick('Area 3')"
            class="d-flex flex-column justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 3' ? 'selected-area' : 'unselected-area' }}"
            style="height: 100%; width: 80%"
          >
            <span>Area 3</span>
            <i class="pi pi-sort-up-fill font-xsmaller green"></i>
          </div>
        </div>
        <div
          (click)="onAreaClick('Area 4')"
          class="d-flex flex-column justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 4' ? 'selected-area' : 'unselected-area' }}"
          style="height: 90%; width: 10%"
        >
          <div>Area 4</div>
          <div><i class="pi pi-sort-up-fill font-xsmaller green"></i></div>
        </div>
      </div>

      <!-- Row 4 empty -->
      <div class="d-flex" style="height: 10%; width: 100%"></div>

      <!-- Row 5 -->
      <div class="d-flex" style="height: 18%; width: 100%">
        <div class="d-flex" style="height: 100%; width: 2%"></div>
        <div class="d-flex flex-column" style="height: 100%; width: 21%">
          <div
            (click)="onAreaClick('Area 2')"
            class="d-flex justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 2' ? 'selected-area' : 'unselected-area' }}"
            style="height: 50%; width: 100%"
          >
            <span>Area 2</span>
            <i class="pi pi-sort-up-fill font-xsmaller green"></i>
          </div>
          <div
            (click)="onAreaClick('Area 1')"
            class="d-flex justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 1' ? 'selected-area' : 'unselected-area' }}"
            style="height: 50%; width: 100%"
          >
            <span>Area 1</span>
            <i class="pi pi-sort-up-fill font-xsmaller green"></i>
          </div>
        </div>
        <div class="d-flex" style="height: 100%; width: 2%"></div>
        <div
          (click)="onAreaClick('Area 4')"
          class="d-flex flex-column justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 4' ? 'selected-area' : 'unselected-area' }}"
          style="height: 100%; width: 9%"
        >
          <div>Area 4</div>
          <div><i class="pi pi-sort-up-fill font-xsmaller green"></i></div>
        </div>
        <div class="d-flex" style="height: 100%; width: 17%"></div>
        <div class="d-flex flex-column" style="height: 100%; width: 24%">
          <div class="d-flex" style="height: 50%; width: 2%"></div>
          <div
            (click)="onAreaClick('Area 5')"
            class="d-flex justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
            style="height: 50%; width: 100%"
          >
            <span>Area 5</span>
            <i class="pi pi-sort-up-fill font-xsmaller green"></i>
          </div>
        </div>
      </div>

      <!-- Row 6 empty -->
      <div class="d-flex" style="height: 1%; width: 100%"></div>

      <!-- Row 7 -->
      <div class="d-flex" style="height: 14%; width: 100%">
        <div class="d-flex" style="height: 100%; width: 2%"></div>
        <div
          (click)="onAreaClick('Area 5')"
          class="d-flex justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
          style="height: 90%; width: 23%"
        >
          <span>Area 5</span>
          <i class="pi pi-sort-up-fill font-xsmaller green"></i>
        </div>
        <div class="d-flex" style="height: 100%; width: 1%"></div>

        <!-- Multiple small Area 5 sections -->
        <div
          (click)="onAreaClick('Area 5')"
          class="d-flex flex-column justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
          style="height: 70%; width: 4%"
        >
          <div>5</div>
          <div class="pi pi-sort-up-fill font-xsmaller green"></div>
        </div>

        <div class="d-flex" style="height: 100%; width: 4%"></div>

        <div
          (click)="onAreaClick('Area 5')"
          class="d-flex flex-column justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
          style="height: 70%; width: 4%"
        >
          <div>5</div>
          <div class="pi pi-sort-up-fill font-xsmaller green"></div>
        </div>

        <div class="d-flex" style="height: 100%; width: 2%"></div>

        <div class="d-flex flex-column" style="height: 100%; width: 4%">
          <div class="d-flex" style="height: 40%; width: 100%"></div>
          <div
            (click)="onAreaClick('Area 5')"
            class="d-flex flex-column justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
            style="height: 70%; width: 100%"
          >
            <div>5</div>
            <div class="pi pi-sort-up-fill font-xsmaller green"></div>
          </div>
        </div>

        <div class="d-flex" style="height: 100%; width: 0.6%"></div>

        <div class="d-flex flex-column" style="height: 100%; width: 4%">
          <div class="d-flex" style="height: 60%; width: 100%"></div>
          <div
            (click)="onAreaClick('Area 5')"
            class="d-flex flex-column justify-content-center align-items-center 
        {{ isImageDisabled ? 'area-disabled' : 'area' }} 
        {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
            style="height: 40%; width: 100%"
          >
            <div>5</div>
            <div class="pi pi-sort-up-fill font-xsmaller green"></div>
          </div>
        </div>

        <div class="d-flex" style="height: 100%; width: 3%"></div>

        <div
          (click)="onAreaClick('Area 5')"
          class="d-flex justify-content-center align-items-center 
      {{ isImageDisabled ? 'area-disabled' : 'area' }} 
      {{ selectedArea === 'Area 5' ? 'selected-area' : 'unselected-area' }}"
          style="height: 100%; width: 35%"
        >
          <div>Area 5</div>
          <div class="pi pi-sort-up-fill font-xsmaller green"></div>
        </div>
      </div>
      <!-- New Code Ends-->
    </div>
  </div>
</div>
