!
/**
* Highcharts Gantt JS v11.4.8 (2024-08-29)
*
* Gantt series
*
* (c) 2016-2024 Lars A. V. Cabrera
*
* License: www.highcharts.com/license
*/
function (t) {
  "object" == typeof module && module.exports ? (t.default = t, module.exports = t) : "function" == typeof define && define.amd ? define("highcharts/modules/gantt", ["highcharts"], function (e) {
    return t(e), t.Highcharts = e, t;
  }) : t("undefined" != typeof Highcharts ? Highcharts : void 0);
}(function (t) {
  "use strict";

  var e = t ? t._modules : {};
  function i(e, i, s, o) {
    e.hasOwnProperty(i) || (e[i] = o.apply(null, s), "function" == typeof CustomEvent && t.win.dispatchEvent(new CustomEvent("HighchartsModuleLoaded", {
      detail: {
        path: i,
        module: e[i]
      }
    })));
  }
  i(e, "Extensions/ArrowSymbols.js", [], function () {
    function t(t, e, i, s) {
      return [["M", t, e + s / 2], ["L", t + i, e], ["L", t, e + s / 2], ["L", t + i, e + s]];
    }
    function e(e, i, s, o) {
      return t(e, i, s / 2, o);
    }
    function i(t, e, i, s) {
      return [["M", t + i, e], ["L", t, e + s / 2], ["L", t + i, e + s], ["Z"]];
    }
    function s(t, e, s, o) {
      return i(t, e, s / 2, o);
    }
    return {
      compose: function (o) {
        let r = o.prototype.symbols;
        r.arrow = t, r["arrow-filled"] = i, r["arrow-filled-half"] = s, r["arrow-half"] = e, r["triangle-left"] = i, r["triangle-left-half"] = s;
      }
    };
  }), i(e, "Gantt/Connection.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        defined: i,
        error: s,
        merge: o,
        objectEach: r
      } = e,
      n = t.deg2rad,
      a = Math.max,
      l = Math.min;
    return class {
      constructor(t, e, i) {
        this.init(t, e, i);
      }
      init(t, e, i) {
        this.fromPoint = t, this.toPoint = e, this.options = i, this.chart = t.series.chart, this.pathfinder = this.chart.pathfinder;
      }
      renderPath(t, e) {
        let i = this.chart,
          s = i.styledMode,
          o = this.pathfinder,
          r = {},
          n = this.graphics && this.graphics.path;
        o.group || (o.group = i.renderer.g().addClass("highcharts-pathfinder-group").attr({
          zIndex: -1
        }).add(i.seriesGroup)), o.group.translate(i.plotLeft, i.plotTop), n && n.renderer || (n = i.renderer.path().add(o.group), s || n.attr({
          opacity: 0
        })), n.attr(e), r.d = t, s || (r.opacity = 1), n.animate(r), this.graphics = this.graphics || {}, this.graphics.path = n;
      }
      addMarker(t, e, i) {
        let s, o, r, a, l, h, d, c;
        let p = this.fromPoint.series.chart,
          u = p.pathfinder,
          g = p.renderer,
          x = "start" === t ? this.fromPoint : this.toPoint,
          f = x.getPathfinderAnchorPoint(e);
        e.enabled && ((c = "start" === t ? i[1] : i[i.length - 2]) && "M" === c[0] || "L" === c[0]) && (d = {
          x: c[1],
          y: c[2]
        }, o = x.getRadiansToVector(d, f), s = x.getMarkerVector(o, e.radius, f), r = -o / n, e.width && e.height ? (l = e.width, h = e.height) : l = h = 2 * e.radius, this.graphics = this.graphics || {}, a = {
          x: s.x - l / 2,
          y: s.y - h / 2,
          width: l,
          height: h,
          rotation: r,
          rotationOriginX: s.x,
          rotationOriginY: s.y
        }, this.graphics[t] ? this.graphics[t].animate(a) : (this.graphics[t] = g.symbol(e.symbol).addClass("highcharts-point-connecting-path-" + t + "-marker highcharts-color-" + this.fromPoint.colorIndex).attr(a).add(u.group), g.styledMode || this.graphics[t].attr({
          fill: e.color || this.fromPoint.color,
          stroke: e.lineColor,
          "stroke-width": e.lineWidth,
          opacity: 0
        }).animate({
          opacity: 1
        }, x.series.options.animation)));
      }
      getPath(t) {
        let e = this.pathfinder,
          i = this.chart,
          r = e.algorithms[t.type],
          n = e.chartObstacles;
        return "function" != typeof r ? (s('"' + t.type + '" is not a Pathfinder algorithm.'), {
          path: [],
          obstacles: []
        }) : (r.requiresObstacles && !n && (n = e.chartObstacles = e.getChartObstacles(t), i.options.connectors.algorithmMargin = t.algorithmMargin, e.chartObstacleMetrics = e.getObstacleMetrics(n)), r(this.fromPoint.getPathfinderAnchorPoint(t.startMarker), this.toPoint.getPathfinderAnchorPoint(t.endMarker), o({
          chartObstacles: n,
          lineObstacles: e.lineObstacles || [],
          obstacleMetrics: e.chartObstacleMetrics,
          hardBounds: {
            xMin: 0,
            xMax: i.plotWidth,
            yMin: 0,
            yMax: i.plotHeight
          },
          obstacleOptions: {
            margin: t.algorithmMargin
          },
          startDirectionX: e.getAlgorithmStartDirection(t.startMarker)
        }, t)));
      }
      render() {
        let t = this.fromPoint,
          e = t.series,
          s = e.chart,
          r = s.pathfinder,
          n = {},
          h = o(s.options.connectors, e.options.connectors, t.options.connectors, this.options);
        !s.styledMode && (n.stroke = h.lineColor || t.color, n["stroke-width"] = h.lineWidth, h.dashStyle && (n.dashstyle = h.dashStyle)), n.class = "highcharts-point-connecting-path highcharts-color-" + t.colorIndex, i((h = o(n, h)).marker.radius) || (h.marker.radius = l(a(Math.ceil((h.algorithmMargin || 8) / 2) - 1, 1), 5));
        let d = this.getPath(h),
          c = d.path;
        d.obstacles && (r.lineObstacles = r.lineObstacles || [], r.lineObstacles = r.lineObstacles.concat(d.obstacles)), this.renderPath(c, n), this.addMarker("start", o(h.marker, h.startMarker), c), this.addMarker("end", o(h.marker, h.endMarker), c);
      }
      destroy() {
        this.graphics && (r(this.graphics, function (t) {
          t.destroy();
        }), delete this.graphics);
      }
    };
  }), i(e, "Extensions/CurrentDateIndication.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        composed: i
      } = t,
      {
        addEvent: s,
        merge: o,
        pushUnique: r,
        wrap: n
      } = e,
      a = {
        color: "#ccd3ff",
        width: 2,
        label: {
          format: "%a, %b %d %Y, %H:%M",
          formatter: function (t, e) {
            return this.axis.chart.time.dateFormat(e || "", t);
          },
          rotation: 0,
          style: {
            fontSize: "0.7em"
          }
        }
      };
    function l() {
      let t = this.options,
        e = t.currentDateIndicator;
      if (e) {
        let i = "object" == typeof e ? o(a, e) : o(a);
        i.value = Date.now(), i.className = "highcharts-current-date-indicator", t.plotLines || (t.plotLines = []), t.plotLines.push(i);
      }
    }
    function h() {
      this.label && this.label.attr({
        text: this.getLabelText(this.options.label)
      });
    }
    function d(t, e) {
      let i = this.options;
      return i && i.className && -1 !== i.className.indexOf("highcharts-current-date-indicator") && i.label && "function" == typeof i.label.formatter ? (i.value = Date.now(), i.label.formatter.call(this, i.value, i.label.format)) : t.call(this, e);
    }
    return {
      compose: function (t, e) {
        r(i, "CurrentDateIndication") && (s(t, "afterSetOptions", l), s(e, "render", h), n(e.prototype, "getLabelText", d));
      }
    };
  }), i(e, "Core/Chart/GanttChart.js", [e["Core/Chart/Chart.js"], e["Core/Defaults.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        defaultOptions: o
      } = e,
      {
        isArray: r,
        merge: n,
        splat: a
      } = i;
    class l extends t {
      init(t, e) {
        let i;
        let s = t.xAxis,
          l = t.yAxis;
        t.xAxis = t.yAxis = void 0;
        let h = n(!0, {
          chart: {
            type: "gantt"
          },
          title: {
            text: ""
          },
          legend: {
            enabled: !1
          },
          navigator: {
            series: {
              type: "gantt"
            },
            yAxis: {
              type: "category"
            }
          }
        }, t, {
          isGantt: !0
        });
        t.xAxis = s, t.yAxis = l, h.xAxis = (r(t.xAxis) ? t.xAxis : [t.xAxis || {}, {}]).map((t, e) => (1 === e && (i = 0), n({
          grid: {
            borderColor: "#cccccc",
            enabled: !0
          },
          opposite: o.xAxis?.opposite ?? t.opposite ?? !0,
          linkedTo: i
        }, t, {
          type: "datetime"
        }))), h.yAxis = a(t.yAxis || {}).map(t => n({
          grid: {
            borderColor: "#cccccc",
            enabled: !0
          },
          staticScale: 50,
          reversed: !0,
          type: t.categories ? t.type : "treegrid"
        }, t)), super.init(h, e);
      }
    }
    return (s = l || (l = {})).ganttChart = function (t, e, i) {
      return new s(t, e, i);
    }, l;
  }), i(e, "Stock/Navigator/ChartNavigatorComposition.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let i;
    let {
        isTouchDevice: s
      } = t,
      {
        addEvent: o,
        merge: r,
        pick: n
      } = e,
      a = [];
    function l() {
      this.navigator && this.navigator.setBaseSeries(null, !1);
    }
    function h() {
      let t, e, i;
      let s = this.legend,
        o = this.navigator;
      if (o) {
        t = s && s.options, e = o.xAxis, i = o.yAxis;
        let {
          scrollbarHeight: r,
          scrollButtonSize: a
        } = o;
        this.inverted ? (o.left = o.opposite ? this.chartWidth - r - o.height : this.spacing[3] + r, o.top = this.plotTop + a) : (o.left = n(e.left, this.plotLeft + a), o.top = o.navigatorOptions.top || this.chartHeight - o.height - r - (this.scrollbar?.options.margin || 0) - this.spacing[2] - (this.rangeSelector && this.extraBottomMargin ? this.rangeSelector.getHeight() : 0) - (t && "bottom" === t.verticalAlign && "proximate" !== t.layout && t.enabled && !t.floating ? s.legendHeight + n(t.margin, 10) : 0) - (this.titleOffset ? this.titleOffset[2] : 0)), e && i && (this.inverted ? e.options.left = i.options.left = o.left : e.options.top = i.options.top = o.top, e.setAxisSize(), i.setAxisSize());
      }
    }
    function d(t) {
      !this.navigator && !this.scroller && (this.options.navigator.enabled || this.options.scrollbar.enabled) && (this.scroller = this.navigator = new i(this), n(t.redraw, !0) && this.redraw(t.animation));
    }
    function c() {
      let t = this.options;
      (t.navigator.enabled || t.scrollbar.enabled) && (this.scroller = this.navigator = new i(this));
    }
    function p() {
      let t = this.options,
        e = t.navigator,
        i = t.rangeSelector;
      if ((e && e.enabled || i && i.enabled) && (!s && "x" === this.zooming.type || s && "x" === this.zooming.pinchType)) return !1;
    }
    function u(t) {
      let e = t.navigator;
      if (e && t.xAxis[0]) {
        let i = t.xAxis[0].getExtremes();
        e.render(i.min, i.max);
      }
    }
    function g(t) {
      let e = t.options.navigator || {},
        i = t.options.scrollbar || {};
      !this.navigator && !this.scroller && (e.enabled || i.enabled) && (r(!0, this.options.navigator, e), r(!0, this.options.scrollbar, i), delete t.options.navigator, delete t.options.scrollbar);
    }
    return {
      compose: function (t, s) {
        if (e.pushUnique(a, t)) {
          let e = t.prototype;
          i = s, e.callbacks.push(u), o(t, "afterAddSeries", l), o(t, "afterSetChartSize", h), o(t, "afterUpdate", d), o(t, "beforeRender", c), o(t, "beforeShowResetZoom", p), o(t, "update", g);
        }
      }
    };
  }), i(e, "Core/Axis/NavigatorAxisComposition.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        isTouchDevice: i
      } = t,
      {
        addEvent: s,
        correctFloat: o,
        defined: r,
        isNumber: n,
        pick: a
      } = e;
    function l() {
      this.navigatorAxis || (this.navigatorAxis = new d(this));
    }
    function h(t) {
      let e;
      let s = this.chart,
        o = s.options,
        n = o.navigator,
        a = this.navigatorAxis,
        l = s.zooming.pinchType,
        h = o.rangeSelector,
        d = s.zooming.type;
      if (this.isXAxis && (n?.enabled || h?.enabled)) {
        if ("y" === d && "zoom" === t.trigger) e = !1;else if (("zoom" === t.trigger && "xy" === d || i && "xy" === l) && this.options.range) {
          let e = a.previousZoom;
          r(t.min) ? a.previousZoom = [this.min, this.max] : e && (t.min = e[0], t.max = e[1], a.previousZoom = void 0);
        }
      }
      void 0 !== e && t.preventDefault();
    }
    class d {
      static compose(t) {
        t.keepProps.includes("navigatorAxis") || (t.keepProps.push("navigatorAxis"), s(t, "init", l), s(t, "setExtremes", h));
      }
      constructor(t) {
        this.axis = t;
      }
      destroy() {
        this.axis = void 0;
      }
      toFixedRange(t, e, i, s) {
        let l = this.axis,
          h = (l.pointRange || 0) / 2,
          d = a(i, l.translate(t, !0, !l.horiz)),
          c = a(s, l.translate(e, !0, !l.horiz));
        return r(i) || (d = o(d + h)), r(s) || (c = o(c - h)), n(d) && n(c) || (d = c = void 0), {
          min: d,
          max: c
        };
      }
    }
    return d;
  }), i(e, "Stock/Navigator/NavigatorDefaults.js", [e["Core/Color/Color.js"], e["Core/Series/SeriesRegistry.js"]], function (t, e) {
    let {
        parse: i
      } = t,
      {
        seriesTypes: s
      } = e;
    return {
      height: 40,
      margin: 25,
      maskInside: !0,
      handles: {
        width: 7,
        borderRadius: 0,
        height: 15,
        symbols: ["navigator-handle", "navigator-handle"],
        enabled: !0,
        lineWidth: 1,
        backgroundColor: "#f2f2f2",
        borderColor: "#999999"
      },
      maskFill: i("#667aff").setOpacity(.3).get(),
      outlineColor: "#999999",
      outlineWidth: 1,
      series: {
        type: void 0 === s.areaspline ? "line" : "areaspline",
        fillOpacity: .05,
        lineWidth: 1,
        compare: null,
        sonification: {
          enabled: !1
        },
        dataGrouping: {
          approximation: "average",
          enabled: !0,
          groupPixelWidth: 2,
          firstAnchor: "firstPoint",
          anchor: "middle",
          lastAnchor: "lastPoint",
          units: [["millisecond", [1, 2, 5, 10, 20, 25, 50, 100, 200, 500]], ["second", [1, 2, 5, 10, 15, 30]], ["minute", [1, 2, 5, 10, 15, 30]], ["hour", [1, 2, 3, 4, 6, 8, 12]], ["day", [1, 2, 3, 4]], ["week", [1, 2, 3]], ["month", [1, 3, 6]], ["year", null]]
        },
        dataLabels: {
          enabled: !1,
          zIndex: 2
        },
        id: "highcharts-navigator-series",
        className: "highcharts-navigator-series",
        lineColor: null,
        marker: {
          enabled: !1
        },
        threshold: null
      },
      xAxis: {
        className: "highcharts-navigator-xaxis",
        tickLength: 0,
        lineWidth: 0,
        gridLineColor: "#e6e6e6",
        id: "navigator-x-axis",
        gridLineWidth: 1,
        tickPixelInterval: 200,
        labels: {
          align: "left",
          style: {
            color: "#000000",
            fontSize: "0.7em",
            opacity: .6,
            textOutline: "2px contrast"
          },
          x: 3,
          y: -4
        },
        crosshair: !1
      },
      yAxis: {
        className: "highcharts-navigator-yaxis",
        gridLineWidth: 0,
        startOnTick: !1,
        endOnTick: !1,
        minPadding: .1,
        id: "navigator-y-axis",
        maxPadding: .1,
        labels: {
          enabled: !1
        },
        crosshair: !1,
        title: {
          text: null
        },
        tickLength: 0,
        tickWidth: 0
      }
    };
  }), i(e, "Stock/Navigator/NavigatorSymbols.js", [e["Core/Renderer/SVG/Symbols.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      relativeLength: i
    } = e;
    return {
      "navigator-handle": function (e, s, o, r, n = {}) {
        let a = n.width ? n.width / 2 : o,
          l = i(n.borderRadius || 0, Math.min(2 * a, r));
        return [["M", -1.5, (r = n.height || r) / 2 - 3.5], ["L", -1.5, r / 2 + 4.5], ["M", .5, r / 2 - 3.5], ["L", .5, r / 2 + 4.5], ...t.rect(-a - 1, .5, 2 * a + 1, r, {
          r: l
        })];
      }
    };
  }), i(e, "Stock/Utilities/StockUtilities.js", [e["Core/Utilities.js"]], function (t) {
    let {
      defined: e
    } = t;
    return {
      setFixedRange: function (t) {
        let i = this.xAxis[0];
        e(i.dataMax) && e(i.dataMin) && t ? this.fixedRange = Math.min(t, i.dataMax - i.dataMin) : this.fixedRange = t;
      }
    };
  }), i(e, "Stock/Navigator/NavigatorComposition.js", [e["Core/Defaults.js"], e["Core/Globals.js"], e["Core/Axis/NavigatorAxisComposition.js"], e["Stock/Navigator/NavigatorDefaults.js"], e["Stock/Navigator/NavigatorSymbols.js"], e["Core/Renderer/RendererRegistry.js"], e["Stock/Utilities/StockUtilities.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, n, a) {
    let {
        setOptions: l
      } = t,
      {
        composed: h
      } = e,
      {
        getRendererType: d
      } = r,
      {
        setFixedRange: c
      } = n,
      {
        addEvent: p,
        extend: u,
        pushUnique: g
      } = a;
    function x() {
      this.chart.navigator && !this.options.isInternal && this.chart.navigator.setBaseSeries(null, !1);
    }
    return {
      compose: function (t, e, r) {
        i.compose(e), g(h, "Navigator") && (t.prototype.setFixedRange = c, u(d().prototype.symbols, o), p(r, "afterUpdate", x), l({
          navigator: s
        }));
      }
    };
  }), i(e, "Core/Axis/ScrollbarAxis.js", [e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e) {
    var i;
    let {
        composed: s
      } = t,
      {
        addEvent: o,
        defined: r,
        pick: n,
        pushUnique: a
      } = e;
    return function (t) {
      let e;
      function i(t) {
        let e = n(t.options && t.options.min, t.min),
          i = n(t.options && t.options.max, t.max);
        return {
          axisMin: e,
          axisMax: i,
          scrollMin: r(t.dataMin) ? Math.min(e, t.min, t.dataMin, n(t.threshold, 1 / 0)) : e,
          scrollMax: r(t.dataMax) ? Math.max(i, t.max, t.dataMax, n(t.threshold, -1 / 0)) : i
        };
      }
      function l() {
        let t = this.scrollbar,
          e = t && !t.options.opposite,
          i = this.horiz ? 2 : e ? 3 : 1;
        t && (this.chart.scrollbarsOffsets = [0, 0], this.chart.axisOffset[i] += t.size + (t.options.margin || 0));
      }
      function h() {
        let t = this;
        t.options && t.options.scrollbar && t.options.scrollbar.enabled && (t.options.scrollbar.vertical = !t.horiz, t.options.startOnTick = t.options.endOnTick = !1, t.scrollbar = new e(t.chart.renderer, t.options.scrollbar, t.chart), o(t.scrollbar, "changed", function (e) {
          let s, o;
          let {
              axisMin: n,
              axisMax: a,
              scrollMin: l,
              scrollMax: h
            } = i(t),
            d = h - l;
          if (r(n) && r(a)) {
            if (t.horiz && !t.reversed || !t.horiz && t.reversed ? (s = l + d * this.to, o = l + d * this.from) : (s = l + d * (1 - this.from), o = l + d * (1 - this.to)), this.shouldUpdateExtremes(e.DOMType)) {
              let i = "mousemove" !== e.DOMType && "touchmove" !== e.DOMType && void 0;
              t.setExtremes(o, s, !0, i, e);
            } else this.setRange(this.from, this.to);
          }
        }));
      }
      function d() {
        let t, e, s;
        let {
            scrollMin: o,
            scrollMax: n
          } = i(this),
          a = this.scrollbar,
          l = this.axisTitleMargin + (this.titleOffset || 0),
          h = this.chart.scrollbarsOffsets,
          d = this.options.margin || 0;
        if (a && h) {
          if (this.horiz) this.opposite || (h[1] += l), a.position(this.left, this.top + this.height + 2 + h[1] - (this.opposite ? d : 0), this.width, this.height), this.opposite || (h[1] += d), t = 1;else {
            let e;
            this.opposite && (h[0] += l), e = a.options.opposite ? this.left + this.width + 2 + h[0] - (this.opposite ? 0 : d) : this.opposite ? 0 : d, a.position(e, this.top, this.width, this.height), this.opposite && (h[0] += d), t = 0;
          }
          if (h[t] += a.size + (a.options.margin || 0), isNaN(o) || isNaN(n) || !r(this.min) || !r(this.max) || this.dataMin === this.dataMax) a.setRange(0, 1);else if (this.min === this.max) {
            let t = this.pointRange / (this.dataMax + 1);
            e = t * this.min, s = t * (this.max + 1), a.setRange(e, s);
          } else e = (this.min - o) / (n - o), s = (this.max - o) / (n - o), this.horiz && !this.reversed || !this.horiz && this.reversed ? a.setRange(e, s) : a.setRange(1 - s, 1 - e);
        }
      }
      t.compose = function (t, i) {
        a(s, "Axis.Scrollbar") && (e = i, o(t, "afterGetOffset", l), o(t, "afterInit", h), o(t, "afterRender", d));
      };
    }(i || (i = {})), i;
  }), i(e, "Stock/Scrollbar/ScrollbarDefaults.js", [], function () {
    return {
      height: 10,
      barBorderRadius: 5,
      buttonBorderRadius: 0,
      buttonsEnabled: !1,
      liveRedraw: void 0,
      margin: void 0,
      minWidth: 6,
      opposite: !0,
      step: .2,
      zIndex: 3,
      barBackgroundColor: "#cccccc",
      barBorderWidth: 0,
      barBorderColor: "#cccccc",
      buttonArrowColor: "#333333",
      buttonBackgroundColor: "#e6e6e6",
      buttonBorderColor: "#cccccc",
      buttonBorderWidth: 1,
      rifleColor: "none",
      trackBackgroundColor: "rgba(255, 255, 255, 0.001)",
      trackBorderColor: "#cccccc",
      trackBorderRadius: 5,
      trackBorderWidth: 1
    };
  }), i(e, "Stock/Scrollbar/Scrollbar.js", [e["Core/Defaults.js"], e["Core/Globals.js"], e["Core/Axis/ScrollbarAxis.js"], e["Stock/Scrollbar/ScrollbarDefaults.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    let {
        defaultOptions: r
      } = t,
      {
        addEvent: n,
        correctFloat: a,
        crisp: l,
        defined: h,
        destroyObjectProperties: d,
        fireEvent: c,
        merge: p,
        pick: u,
        removeEvent: g
      } = o;
    class x {
      static compose(t) {
        i.compose(t, x);
      }
      static swapXY(t, e) {
        return e && t.forEach(t => {
          let e;
          let i = t.length;
          for (let s = 0; s < i; s += 2) "number" == typeof (e = t[s + 1]) && (t[s + 1] = t[s + 2], t[s + 2] = e);
        }), t;
      }
      constructor(t, e, i) {
        this._events = [], this.chartX = 0, this.chartY = 0, this.from = 0, this.scrollbarButtons = [], this.scrollbarLeft = 0, this.scrollbarStrokeWidth = 1, this.scrollbarTop = 0, this.size = 0, this.to = 0, this.trackBorderWidth = 1, this.x = 0, this.y = 0, this.init(t, e, i);
      }
      addEvents() {
        let t = this.options.inverted ? [1, 0] : [0, 1],
          e = this.scrollbarButtons,
          i = this.scrollbarGroup.element,
          s = this.track.element,
          o = this.mouseDownHandler.bind(this),
          r = this.mouseMoveHandler.bind(this),
          a = this.mouseUpHandler.bind(this),
          l = [[e[t[0]].element, "click", this.buttonToMinClick.bind(this)], [e[t[1]].element, "click", this.buttonToMaxClick.bind(this)], [s, "click", this.trackClick.bind(this)], [i, "mousedown", o], [i.ownerDocument, "mousemove", r], [i.ownerDocument, "mouseup", a], [i, "touchstart", o], [i.ownerDocument, "touchmove", r], [i.ownerDocument, "touchend", a]];
        l.forEach(function (t) {
          n.apply(null, t);
        }), this._events = l;
      }
      buttonToMaxClick(t) {
        let e = (this.to - this.from) * u(this.options.step, .2);
        this.updatePosition(this.from + e, this.to + e), c(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: t
        });
      }
      buttonToMinClick(t) {
        let e = a(this.to - this.from) * u(this.options.step, .2);
        this.updatePosition(a(this.from - e), a(this.to - e)), c(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: t
        });
      }
      cursorToScrollbarPosition(t) {
        let e = this.options,
          i = e.minWidth > this.calculatedWidth ? e.minWidth : 0;
        return {
          chartX: (t.chartX - this.x - this.xOffset) / (this.barWidth - i),
          chartY: (t.chartY - this.y - this.yOffset) / (this.barWidth - i)
        };
      }
      destroy() {
        let t = this,
          e = t.chart.scroller;
        t.removeEvents(), ["track", "scrollbarRifles", "scrollbar", "scrollbarGroup", "group"].forEach(function (e) {
          t[e] && t[e].destroy && (t[e] = t[e].destroy());
        }), e && t === e.scrollbar && (e.scrollbar = null, d(e.scrollbarButtons));
      }
      drawScrollbarButton(t) {
        let e = this.renderer,
          i = this.scrollbarButtons,
          s = this.options,
          o = this.size,
          r = e.g().add(this.group);
        if (i.push(r), s.buttonsEnabled) {
          let n = e.rect().addClass("highcharts-scrollbar-button").add(r);
          this.chart.styledMode || n.attr({
            stroke: s.buttonBorderColor,
            "stroke-width": s.buttonBorderWidth,
            fill: s.buttonBackgroundColor
          }), n.attr(n.crisp({
            x: -.5,
            y: -.5,
            width: o,
            height: o,
            r: s.buttonBorderRadius
          }, n.strokeWidth()));
          let a = e.path(x.swapXY([["M", o / 2 + (t ? -1 : 1), o / 2 - 3], ["L", o / 2 + (t ? -1 : 1), o / 2 + 3], ["L", o / 2 + (t ? 2 : -2), o / 2]], s.vertical)).addClass("highcharts-scrollbar-arrow").add(i[t]);
          this.chart.styledMode || a.attr({
            fill: s.buttonArrowColor
          });
        }
      }
      init(t, e, i) {
        this.scrollbarButtons = [], this.renderer = t, this.userOptions = e, this.options = p(s, r.scrollbar, e), this.options.margin = u(this.options.margin, 10), this.chart = i, this.size = u(this.options.size, this.options.height), e.enabled && (this.render(), this.addEvents());
      }
      mouseDownHandler(t) {
        let e = this.chart.pointer?.normalize(t) || t,
          i = this.cursorToScrollbarPosition(e);
        this.chartX = i.chartX, this.chartY = i.chartY, this.initPositions = [this.from, this.to], this.grabbedCenter = !0;
      }
      mouseMoveHandler(t) {
        let e;
        let i = this.chart.pointer?.normalize(t) || t,
          s = this.options.vertical ? "chartY" : "chartX",
          o = this.initPositions || [];
        this.grabbedCenter && (!t.touches || 0 !== t.touches[0][s]) && (e = this.cursorToScrollbarPosition(i)[s] - this[s], this.hasDragged = !0, this.updatePosition(o[0] + e, o[1] + e), this.hasDragged && c(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMType: t.type,
          DOMEvent: t
        }));
      }
      mouseUpHandler(t) {
        this.hasDragged && c(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMType: t.type,
          DOMEvent: t
        }), this.grabbedCenter = this.hasDragged = this.chartX = this.chartY = null;
      }
      position(t, e, i, s) {
        let {
            buttonsEnabled: o,
            margin: r = 0,
            vertical: n
          } = this.options,
          a = this.rendered ? "animate" : "attr",
          l = s,
          h = 0;
        this.group.show(), this.x = t, this.y = e + this.trackBorderWidth, this.width = i, this.height = s, this.xOffset = l, this.yOffset = h, n ? (this.width = this.yOffset = i = h = this.size, this.xOffset = l = 0, this.yOffset = h = o ? this.size : 0, this.barWidth = s - (o ? 2 * i : 0), this.x = t += r) : (this.height = s = this.size, this.xOffset = l = o ? this.size : 0, this.barWidth = i - (o ? 2 * s : 0), this.y = this.y + r), this.group[a]({
          translateX: t,
          translateY: this.y
        }), this.track[a]({
          width: i,
          height: s
        }), this.scrollbarButtons[1][a]({
          translateX: n ? 0 : i - l,
          translateY: n ? s - h : 0
        });
      }
      removeEvents() {
        this._events.forEach(function (t) {
          g.apply(null, t);
        }), this._events.length = 0;
      }
      render() {
        let t = this.renderer,
          e = this.options,
          i = this.size,
          s = this.chart.styledMode,
          o = t.g("scrollbar").attr({
            zIndex: e.zIndex
          }).hide().add();
        this.group = o, this.track = t.rect().addClass("highcharts-scrollbar-track").attr({
          r: e.trackBorderRadius || 0,
          height: i,
          width: i
        }).add(o), s || this.track.attr({
          fill: e.trackBackgroundColor,
          stroke: e.trackBorderColor,
          "stroke-width": e.trackBorderWidth
        });
        let r = this.trackBorderWidth = this.track.strokeWidth();
        this.track.attr({
          x: -l(0, r),
          y: -l(0, r)
        }), this.scrollbarGroup = t.g().add(o), this.scrollbar = t.rect().addClass("highcharts-scrollbar-thumb").attr({
          height: i - r,
          width: i - r,
          r: e.barBorderRadius || 0
        }).add(this.scrollbarGroup), this.scrollbarRifles = t.path(x.swapXY([["M", -3, i / 4], ["L", -3, 2 * i / 3], ["M", 0, i / 4], ["L", 0, 2 * i / 3], ["M", 3, i / 4], ["L", 3, 2 * i / 3]], e.vertical)).addClass("highcharts-scrollbar-rifles").add(this.scrollbarGroup), s || (this.scrollbar.attr({
          fill: e.barBackgroundColor,
          stroke: e.barBorderColor,
          "stroke-width": e.barBorderWidth
        }), this.scrollbarRifles.attr({
          stroke: e.rifleColor,
          "stroke-width": 1
        })), this.scrollbarStrokeWidth = this.scrollbar.strokeWidth(), this.scrollbarGroup.translate(-l(0, this.scrollbarStrokeWidth), -l(0, this.scrollbarStrokeWidth)), this.drawScrollbarButton(0), this.drawScrollbarButton(1);
      }
      setRange(t, e) {
        let i, s;
        let o = this.options,
          r = o.vertical,
          n = o.minWidth,
          l = this.barWidth,
          d = !this.rendered || this.hasDragged || this.chart.navigator && this.chart.navigator.hasDragged ? "attr" : "animate";
        if (!h(l)) return;
        let c = l * Math.min(e, 1);
        i = Math.ceil(l * (t = Math.max(t, 0))), this.calculatedWidth = s = a(c - i), s < n && (i = (l - n + s) * t, s = n);
        let p = Math.floor(i + this.xOffset + this.yOffset),
          u = s / 2 - .5;
        this.from = t, this.to = e, r ? (this.scrollbarGroup[d]({
          translateY: p
        }), this.scrollbar[d]({
          height: s
        }), this.scrollbarRifles[d]({
          translateY: u
        }), this.scrollbarTop = p, this.scrollbarLeft = 0) : (this.scrollbarGroup[d]({
          translateX: p
        }), this.scrollbar[d]({
          width: s
        }), this.scrollbarRifles[d]({
          translateX: u
        }), this.scrollbarLeft = p, this.scrollbarTop = 0), s <= 12 ? this.scrollbarRifles.hide() : this.scrollbarRifles.show(), !1 === o.showFull && (t <= 0 && e >= 1 ? this.group.hide() : this.group.show()), this.rendered = !0;
      }
      shouldUpdateExtremes(t) {
        return u(this.options.liveRedraw, e.svg && !e.isTouchDevice && !this.chart.boosted) || "mouseup" === t || "touchend" === t || !h(t);
      }
      trackClick(t) {
        let e = this.chart.pointer?.normalize(t) || t,
          i = this.to - this.from,
          s = this.y + this.scrollbarTop,
          o = this.x + this.scrollbarLeft;
        this.options.vertical && e.chartY > s || !this.options.vertical && e.chartX > o ? this.updatePosition(this.from + i, this.to + i) : this.updatePosition(this.from - i, this.to - i), c(this, "changed", {
          from: this.from,
          to: this.to,
          trigger: "scrollbar",
          DOMEvent: t
        });
      }
      update(t) {
        this.destroy(), this.init(this.chart.renderer, p(!0, this.options, t), this.chart);
      }
      updatePosition(t, e) {
        e > 1 && (t = a(1 - a(e - t)), e = 1), t < 0 && (e = a(e - t), t = 0), this.from = t, this.to = e;
      }
    }
    return x.defaultOptions = s, r.scrollbar = p(!0, x.defaultOptions, r.scrollbar), x;
  }), i(e, "Stock/Navigator/Navigator.js", [e["Core/Axis/Axis.js"], e["Stock/Navigator/ChartNavigatorComposition.js"], e["Core/Defaults.js"], e["Core/Globals.js"], e["Core/Axis/NavigatorAxisComposition.js"], e["Stock/Navigator/NavigatorComposition.js"], e["Stock/Scrollbar/Scrollbar.js"], e["Core/Renderer/SVG/SVGRenderer.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, n, a, l) {
    let {
        defaultOptions: h
      } = i,
      {
        isTouchDevice: d
      } = s,
      {
        prototype: {
          symbols: c
        }
      } = a,
      {
        addEvent: p,
        clamp: u,
        correctFloat: g,
        defined: x,
        destroyObjectProperties: f,
        erase: m,
        extend: b,
        find: y,
        fireEvent: v,
        isArray: M,
        isNumber: k,
        merge: A,
        pick: S,
        removeEvent: C,
        splat: w
      } = l;
    function P(t, ...e) {
      let i = [].filter.call(e, k);
      if (i.length) return Math[t].apply(0, i);
    }
    class O {
      static compose(t, i, s) {
        e.compose(t, O), r.compose(t, i, s);
      }
      constructor(t) {
        this.isDirty = !1, this.scrollbarHeight = 0, this.init(t);
      }
      drawHandle(t, e, i, s) {
        let o = this.navigatorOptions.handles.height;
        this.handles[e][s](i ? {
          translateX: Math.round(this.left + this.height / 2),
          translateY: Math.round(this.top + parseInt(t, 10) + .5 - o)
        } : {
          translateX: Math.round(this.left + parseInt(t, 10)),
          translateY: Math.round(this.top + this.height / 2 - o / 2 - 1)
        });
      }
      drawOutline(t, e, i, s) {
        let o = this.navigatorOptions.maskInside,
          r = this.outline.strokeWidth(),
          n = r / 2,
          a = r % 2 / 2,
          l = this.scrollButtonSize,
          h = this.size,
          d = this.top,
          c = this.height,
          p = d - n,
          u = d + c,
          g = this.left,
          x,
          f;
        i ? (x = d + e + a, e = d + t + a, f = [["M", g + c, d - l - a], ["L", g + c, x], ["L", g, x], ["M", g, e], ["L", g + c, e], ["L", g + c, d + h + l]], o && f.push(["M", g + c, x - n], ["L", g + c, e + n])) : (g -= l, t += g + l - a, e += g + l - a, f = [["M", g, p], ["L", t, p], ["L", t, u], ["M", e, u], ["L", e, p], ["L", g + h + 2 * l, p]], o && f.push(["M", t - n, p], ["L", e + n, p])), this.outline[s]({
          d: f
        });
      }
      drawMasks(t, e, i, s) {
        let o, r, n, a;
        let l = this.left,
          h = this.top,
          d = this.height;
        i ? (n = [l, l, l], a = [h, h + t, h + e], r = [d, d, d], o = [t, e - t, this.size - e]) : (n = [l, l + t, l + e], a = [h, h, h], r = [t, e - t, this.size - e], o = [d, d, d]), this.shades.forEach((t, e) => {
          t[s]({
            x: n[e],
            y: a[e],
            width: r[e],
            height: o[e]
          });
        });
      }
      renderElements() {
        let t = this,
          e = t.navigatorOptions,
          i = e.maskInside,
          s = t.chart,
          o = s.inverted,
          r = s.renderer,
          n = {
            cursor: o ? "ns-resize" : "ew-resize"
          },
          a = t.navigatorGroup ?? (t.navigatorGroup = r.g("navigator").attr({
            zIndex: 8,
            visibility: "hidden"
          }).add());
        if ([!i, i, !i].forEach((i, o) => {
          let l = t.shades[o] ?? (t.shades[o] = r.rect().addClass("highcharts-navigator-mask" + (1 === o ? "-inside" : "-outside")).add(a));
          s.styledMode || (l.attr({
            fill: i ? e.maskFill : "rgba(0,0,0,0)"
          }), 1 === o && l.css(n));
        }), t.outline || (t.outline = r.path().addClass("highcharts-navigator-outline").add(a)), s.styledMode || t.outline.attr({
          "stroke-width": e.outlineWidth,
          stroke: e.outlineColor
        }), e.handles?.enabled) {
          let i = e.handles,
            {
              height: o,
              width: l
            } = i;
          [0, 1].forEach(e => {
            let h = i.symbols[e];
            if (t.handles[e] && t.handles[e].symbolUrl === h) {
              if (!t.handles[e].isImg && t.handles[e].symbolName !== h) {
                let i = c[h].call(c, -l / 2 - 1, 0, l, o);
                t.handles[e].attr({
                  d: i
                }), t.handles[e].symbolName = h;
              }
            } else t.handles[e]?.destroy(), t.handles[e] = r.symbol(h, -l / 2 - 1, 0, l, o, i), t.handles[e].attr({
              zIndex: 7 - e
            }).addClass("highcharts-navigator-handle highcharts-navigator-handle-" + ["left", "right"][e]).add(a), t.addMouseEvents();
            s.inverted && t.handles[e].attr({
              rotation: 90,
              rotationOriginX: Math.floor(-l / 2),
              rotationOriginY: (o + l) / 2
            }), s.styledMode || t.handles[e].attr({
              fill: i.backgroundColor,
              stroke: i.borderColor,
              "stroke-width": i.lineWidth,
              width: i.width,
              height: i.height,
              x: -l / 2 - 1,
              y: 0
            }).css(n);
          });
        }
      }
      update(t, e = !1) {
        let i = this.chart,
          s = i.options.chart.inverted !== i.scrollbar?.options.vertical;
        if (A(!0, i.options.navigator, t), this.navigatorOptions = i.options.navigator || {}, this.setOpposite(), x(t.enabled) || s) return this.destroy(), this.navigatorEnabled = t.enabled || this.navigatorEnabled, this.init(i);
        if (this.navigatorEnabled && (this.isDirty = !0, !1 === t.adaptToUpdatedData && this.baseSeries.forEach(t => {
          C(t, "updatedData", this.updatedDataHandler);
        }, this), t.adaptToUpdatedData && this.baseSeries.forEach(t => {
          t.eventsToUnbind.push(p(t, "updatedData", this.updatedDataHandler));
        }, this), (t.series || t.baseSeries) && this.setBaseSeries(void 0, !1), t.height || t.xAxis || t.yAxis)) {
          this.height = t.height ?? this.height;
          let e = this.getXAxisOffsets();
          this.xAxis.update({
            ...t.xAxis,
            offsets: e,
            [i.inverted ? "width" : "height"]: this.height,
            [i.inverted ? "height" : "width"]: void 0
          }, !1), this.yAxis.update({
            ...t.yAxis,
            [i.inverted ? "width" : "height"]: this.height
          }, !1);
        }
        e && i.redraw();
      }
      render(t, e, i, s) {
        let o = this.chart,
          r = this.xAxis,
          n = r.pointRange || 0,
          a = r.navigatorAxis.fake ? o.xAxis[0] : r,
          l = this.navigatorEnabled,
          h = this.rendered,
          d = o.inverted,
          c = o.xAxis[0].minRange,
          p = o.xAxis[0].options.maxRange,
          f = this.scrollButtonSize,
          m,
          b,
          y,
          M = this.scrollbarHeight,
          A,
          C;
        if (this.hasDragged && !x(i)) return;
        if (this.isDirty && this.renderElements(), t = g(t - n / 2), e = g(e + n / 2), !k(t) || !k(e)) {
          if (!h) return;
          i = 0, s = S(r.width, a.width);
        }
        this.left = S(r.left, o.plotLeft + f + (d ? o.plotWidth : 0));
        let w = this.size = A = S(r.len, (d ? o.plotHeight : o.plotWidth) - 2 * f);
        m = d ? M : A + 2 * f, i = S(i, r.toPixels(t, !0)), s = S(s, r.toPixels(e, !0)), k(i) && Math.abs(i) !== 1 / 0 || (i = 0, s = m);
        let P = r.toValue(i, !0),
          O = r.toValue(s, !0),
          B = Math.abs(g(O - P));
        B < c ? this.grabbedLeft ? i = r.toPixels(O - c - n, !0) : this.grabbedRight && (s = r.toPixels(P + c + n, !0)) : x(p) && g(B - n) > p && (this.grabbedLeft ? i = r.toPixels(O - p - n, !0) : this.grabbedRight && (s = r.toPixels(P + p + n, !0))), this.zoomedMax = u(Math.max(i, s), 0, w), this.zoomedMin = u(this.fixedWidth ? this.zoomedMax - this.fixedWidth : Math.min(i, s), 0, w), this.range = this.zoomedMax - this.zoomedMin, w = Math.round(this.zoomedMax);
        let E = Math.round(this.zoomedMin);
        l && (this.navigatorGroup.attr({
          visibility: "inherit"
        }), C = h && !this.hasDragged ? "animate" : "attr", this.drawMasks(E, w, d, C), this.drawOutline(E, w, d, C), this.navigatorOptions.handles.enabled && (this.drawHandle(E, 0, d, C), this.drawHandle(w, 1, d, C))), this.scrollbar && (d ? (y = this.top - f, b = this.left - M + (l || !a.opposite ? 0 : (a.titleOffset || 0) + a.axisTitleMargin), M = A + 2 * f) : (y = this.top + (l ? this.height : -M), b = this.left - f), this.scrollbar.position(b, y, m, M), this.scrollbar.setRange(this.zoomedMin / (A || 1), this.zoomedMax / (A || 1))), this.rendered = !0, this.isDirty = !1, v(this, "afterRender");
      }
      addMouseEvents() {
        let t = this,
          e = t.chart,
          i = e.container,
          s = [],
          o,
          r;
        t.mouseMoveHandler = o = function (e) {
          t.onMouseMove(e);
        }, t.mouseUpHandler = r = function (e) {
          t.onMouseUp(e);
        }, (s = t.getPartsEvents("mousedown")).push(p(e.renderTo, "mousemove", o), p(i.ownerDocument, "mouseup", r), p(e.renderTo, "touchmove", o), p(i.ownerDocument, "touchend", r)), s.concat(t.getPartsEvents("touchstart")), t.eventsToUnbind = s, t.series && t.series[0] && s.push(p(t.series[0].xAxis, "foundExtremes", function () {
          e.navigator.modifyNavigatorAxisExtremes();
        }));
      }
      getPartsEvents(t) {
        let e = this,
          i = [];
        return ["shades", "handles"].forEach(function (s) {
          e[s].forEach(function (o, r) {
            i.push(p(o.element, t, function (t) {
              e[s + "Mousedown"](t, r);
            }));
          });
        }), i;
      }
      shadesMousedown(t, e) {
        t = this.chart.pointer?.normalize(t) || t;
        let i = this.chart,
          s = this.xAxis,
          o = this.zoomedMin,
          r = this.size,
          n = this.range,
          a = this.left,
          l = t.chartX,
          h,
          d,
          c,
          p;
        i.inverted && (l = t.chartY, a = this.top), 1 === e ? (this.grabbedCenter = l, this.fixedWidth = n, this.dragOffset = l - o) : (p = l - a - n / 2, 0 === e ? p = Math.max(0, p) : 2 === e && p + n >= r && (p = r - n, this.reversedExtremes ? (p -= n, d = this.getUnionExtremes().dataMin) : h = this.getUnionExtremes().dataMax), p !== o && (this.fixedWidth = n, x((c = s.navigatorAxis.toFixedRange(p, p + n, d, h)).min) && v(this, "setRange", {
          min: Math.min(c.min, c.max),
          max: Math.max(c.min, c.max),
          redraw: !0,
          eventArguments: {
            trigger: "navigator"
          }
        })));
      }
      handlesMousedown(t, e) {
        t = this.chart.pointer?.normalize(t) || t;
        let i = this.chart,
          s = i.xAxis[0],
          o = this.reversedExtremes;
        0 === e ? (this.grabbedLeft = !0, this.otherHandlePos = this.zoomedMax, this.fixedExtreme = o ? s.min : s.max) : (this.grabbedRight = !0, this.otherHandlePos = this.zoomedMin, this.fixedExtreme = o ? s.max : s.min), i.setFixedRange(void 0);
      }
      onMouseMove(t) {
        let e = this,
          i = e.chart,
          s = e.navigatorSize,
          o = e.range,
          r = e.dragOffset,
          n = i.inverted,
          a = e.left,
          l;
        (!t.touches || 0 !== t.touches[0].pageX) && (l = (t = i.pointer?.normalize(t) || t).chartX, n && (a = e.top, l = t.chartY), e.grabbedLeft ? (e.hasDragged = !0, e.render(0, 0, l - a, e.otherHandlePos)) : e.grabbedRight ? (e.hasDragged = !0, e.render(0, 0, e.otherHandlePos, l - a)) : e.grabbedCenter && (e.hasDragged = !0, l < r ? l = r : l > s + r - o && (l = s + r - o), e.render(0, 0, l - r, l - r + o)), e.hasDragged && e.scrollbar && S(e.scrollbar.options.liveRedraw, !d && !this.chart.boosted) && (t.DOMType = t.type, setTimeout(function () {
          e.onMouseUp(t);
        }, 0)));
      }
      onMouseUp(t) {
        let e, i, s, o, r, n;
        let a = this.chart,
          l = this.xAxis,
          h = this.scrollbar,
          d = t.DOMEvent || t,
          c = a.inverted,
          p = this.rendered && !this.hasDragged ? "animate" : "attr";
        (this.hasDragged && (!h || !h.hasDragged) || "scrollbar" === t.trigger) && (s = this.getUnionExtremes(), this.zoomedMin === this.otherHandlePos ? o = this.fixedExtreme : this.zoomedMax === this.otherHandlePos && (r = this.fixedExtreme), this.zoomedMax === this.size && (r = this.reversedExtremes ? s.dataMin : s.dataMax), 0 === this.zoomedMin && (o = this.reversedExtremes ? s.dataMax : s.dataMin), x((n = l.navigatorAxis.toFixedRange(this.zoomedMin, this.zoomedMax, o, r)).min) && v(this, "setRange", {
          min: Math.min(n.min, n.max),
          max: Math.max(n.min, n.max),
          redraw: !0,
          animation: !this.hasDragged && null,
          eventArguments: {
            trigger: "navigator",
            triggerOp: "navigator-drag",
            DOMEvent: d
          }
        })), "mousemove" !== t.DOMType && "touchmove" !== t.DOMType && (this.grabbedLeft = this.grabbedRight = this.grabbedCenter = this.fixedWidth = this.fixedExtreme = this.otherHandlePos = this.hasDragged = this.dragOffset = null), this.navigatorEnabled && k(this.zoomedMin) && k(this.zoomedMax) && (i = Math.round(this.zoomedMin), e = Math.round(this.zoomedMax), this.shades && this.drawMasks(i, e, c, p), this.outline && this.drawOutline(i, e, c, p), this.navigatorOptions.handles.enabled && Object.keys(this.handles).length === this.handles.length && (this.drawHandle(i, 0, c, p), this.drawHandle(e, 1, c, p)));
      }
      removeEvents() {
        this.eventsToUnbind && (this.eventsToUnbind.forEach(function (t) {
          t();
        }), this.eventsToUnbind = void 0), this.removeBaseSeriesEvents();
      }
      removeBaseSeriesEvents() {
        let t = this.baseSeries || [];
        this.navigatorEnabled && t[0] && (!1 !== this.navigatorOptions.adaptToUpdatedData && t.forEach(function (t) {
          C(t, "updatedData", this.updatedDataHandler);
        }, this), t[0].xAxis && C(t[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes));
      }
      getXAxisOffsets() {
        return this.chart.inverted ? [this.scrollButtonSize, 0, -this.scrollButtonSize, 0] : [0, -this.scrollButtonSize, 0, this.scrollButtonSize];
      }
      init(e) {
        let i = e.options,
          s = i.navigator || {},
          r = s.enabled,
          a = i.scrollbar || {},
          l = a.enabled,
          h = r && s.height || 0,
          d = l && a.height || 0,
          c = a.buttonsEnabled && d || 0;
        this.handles = [], this.shades = [], this.chart = e, this.setBaseSeries(), this.height = h, this.scrollbarHeight = d, this.scrollButtonSize = c, this.scrollbarEnabled = l, this.navigatorEnabled = r, this.navigatorOptions = s, this.scrollbarOptions = a, this.setOpposite();
        let u = this,
          g = u.baseSeries,
          x = e.xAxis.length,
          f = e.yAxis.length,
          m = g && g[0] && g[0].xAxis || e.xAxis[0] || {
            options: {}
          };
        if (e.isDirtyBox = !0, u.navigatorEnabled) {
          let i = this.getXAxisOffsets();
          u.xAxis = new t(e, A({
            breaks: m.options.breaks,
            ordinal: m.options.ordinal,
            overscroll: m.options.overscroll
          }, s.xAxis, {
            type: "datetime",
            yAxis: s.yAxis?.id,
            index: x,
            isInternal: !0,
            offset: 0,
            keepOrdinalPadding: !0,
            startOnTick: !1,
            endOnTick: !1,
            minPadding: m.options.ordinal ? 0 : m.options.minPadding,
            maxPadding: m.options.ordinal ? 0 : m.options.maxPadding,
            zoomEnabled: !1
          }, e.inverted ? {
            offsets: i,
            width: h
          } : {
            offsets: i,
            height: h
          }), "xAxis"), u.yAxis = new t(e, A(s.yAxis, {
            alignTicks: !1,
            offset: 0,
            index: f,
            isInternal: !0,
            reversed: S(s.yAxis && s.yAxis.reversed, e.yAxis[0] && e.yAxis[0].reversed, !1),
            zoomEnabled: !1
          }, e.inverted ? {
            width: h
          } : {
            height: h
          }), "yAxis"), g || s.series.data ? u.updateNavigatorSeries(!1) : 0 === e.series.length && (u.unbindRedraw = p(e, "beforeRedraw", function () {
            e.series.length > 0 && !u.series && (u.setBaseSeries(), u.unbindRedraw());
          })), u.reversedExtremes = e.inverted && !u.xAxis.reversed || !e.inverted && u.xAxis.reversed, u.renderElements(), u.addMouseEvents();
        } else u.xAxis = {
          chart: e,
          navigatorAxis: {
            fake: !0
          },
          translate: function (t, i) {
            let s = e.xAxis[0],
              o = s.getExtremes(),
              r = s.len - 2 * c,
              n = P("min", s.options.min, o.dataMin),
              a = P("max", s.options.max, o.dataMax) - n;
            return i ? t * a / r + n : r * (t - n) / a;
          },
          toPixels: function (t) {
            return this.translate(t);
          },
          toValue: function (t) {
            return this.translate(t, !0);
          }
        }, u.xAxis.navigatorAxis.axis = u.xAxis, u.xAxis.navigatorAxis.toFixedRange = o.prototype.toFixedRange.bind(u.xAxis.navigatorAxis);
        if (e.options.scrollbar.enabled) {
          let t = A(e.options.scrollbar, {
            vertical: e.inverted
          });
          !k(t.margin) && u.navigatorEnabled && (t.margin = e.inverted ? -3 : 3), e.scrollbar = u.scrollbar = new n(e.renderer, t, e), p(u.scrollbar, "changed", function (t) {
            let e = u.size,
              i = e * this.to,
              s = e * this.from;
            u.hasDragged = u.scrollbar.hasDragged, u.render(0, 0, s, i), this.shouldUpdateExtremes(t.DOMType) && setTimeout(function () {
              u.onMouseUp(t);
            });
          });
        }
        u.addBaseSeriesEvents(), u.addChartEvents();
      }
      setOpposite() {
        let t = this.navigatorOptions,
          e = this.navigatorEnabled,
          i = this.chart;
        this.opposite = S(t.opposite, !!(!e && i.inverted));
      }
      getUnionExtremes(t) {
        let e;
        let i = this.chart.xAxis[0],
          s = this.xAxis,
          o = s.options,
          r = i.options;
        return t && null === i.dataMin || (e = {
          dataMin: S(o && o.min, P("min", r.min, i.dataMin, s.dataMin, s.min)),
          dataMax: S(o && o.max, P("max", r.max, i.dataMax, s.dataMax, s.max))
        }), e;
      }
      setBaseSeries(t, e) {
        let i = this.chart,
          s = this.baseSeries = [];
        t = t || i.options && i.options.navigator.baseSeries || (i.series.length ? y(i.series, t => !t.options.isInternal).index : 0), (i.series || []).forEach((e, i) => {
          !e.options.isInternal && (e.options.showInNavigator || (i === t || e.options.id === t) && !1 !== e.options.showInNavigator) && s.push(e);
        }), this.xAxis && !this.xAxis.navigatorAxis.fake && this.updateNavigatorSeries(!0, e);
      }
      updateNavigatorSeries(t, e) {
        let i = this,
          s = i.chart,
          o = i.baseSeries,
          r = {
            enableMouseTracking: !1,
            index: null,
            linkedTo: null,
            group: "nav",
            padXAxis: !1,
            xAxis: this.navigatorOptions.xAxis?.id,
            yAxis: this.navigatorOptions.yAxis?.id,
            showInLegend: !1,
            stacking: void 0,
            isInternal: !0,
            states: {
              inactive: {
                opacity: 1
              }
            }
          },
          n = i.series = (i.series || []).filter(t => {
            let e = t.baseSeries;
            return !(0 > o.indexOf(e)) || (e && (C(e, "updatedData", i.updatedDataHandler), delete e.navigatorSeries), t.chart && t.destroy(), !1);
          }),
          a,
          l,
          d = i.navigatorOptions.series,
          c;
        o && o.length && o.forEach(t => {
          let p = t.navigatorSeries,
            u = b({
              color: t.color,
              visible: t.visible
            }, M(d) ? h.navigator.series : d);
          if (p && !1 === i.navigatorOptions.adaptToUpdatedData) return;
          r.name = "Navigator " + o.length, c = (a = t.options || {}).navigatorOptions || {}, u.dataLabels = w(u.dataLabels), (l = A(a, r, u, c)).pointRange = S(u.pointRange, c.pointRange, h.plotOptions[l.type || "line"].pointRange);
          let g = c.data || u.data;
          i.hasNavigatorData = i.hasNavigatorData || !!g, l.data = g || a.data && a.data.slice(0), p && p.options ? p.update(l, e) : (t.navigatorSeries = s.initSeries(l), s.setSortedData(), t.navigatorSeries.baseSeries = t, n.push(t.navigatorSeries));
        }), (d.data && !(o && o.length) || M(d)) && (i.hasNavigatorData = !1, (d = w(d)).forEach((t, e) => {
          r.name = "Navigator " + (n.length + 1), (l = A(h.navigator.series, {
            color: s.series[e] && !s.series[e].options.isInternal && s.series[e].color || s.options.colors[e] || s.options.colors[0]
          }, r, t)).data = t.data, l.data && (i.hasNavigatorData = !0, n.push(s.initSeries(l)));
        })), t && this.addBaseSeriesEvents();
      }
      addBaseSeriesEvents() {
        let t = this,
          e = t.baseSeries || [];
        e[0] && e[0].xAxis && e[0].eventsToUnbind.push(p(e[0].xAxis, "foundExtremes", this.modifyBaseAxisExtremes)), e.forEach(i => {
          i.eventsToUnbind.push(p(i, "show", function () {
            this.navigatorSeries && this.navigatorSeries.setVisible(!0, !1);
          })), i.eventsToUnbind.push(p(i, "hide", function () {
            this.navigatorSeries && this.navigatorSeries.setVisible(!1, !1);
          })), !1 !== this.navigatorOptions.adaptToUpdatedData && i.xAxis && i.eventsToUnbind.push(p(i, "updatedData", this.updatedDataHandler)), i.eventsToUnbind.push(p(i, "remove", function () {
            e && m(e, i), this.navigatorSeries && (m(t.series, this.navigatorSeries), x(this.navigatorSeries.options) && this.navigatorSeries.remove(!1), delete this.navigatorSeries);
          }));
        });
      }
      getBaseSeriesMin(t) {
        return this.baseSeries.reduce(function (t, e) {
          return Math.min(t, e.xData && e.xData.length ? e.xData[0] : t);
        }, t);
      }
      modifyNavigatorAxisExtremes() {
        let t = this.xAxis;
        if (void 0 !== t.getExtremes) {
          let e = this.getUnionExtremes(!0);
          e && (e.dataMin !== t.min || e.dataMax !== t.max) && (t.min = e.dataMin, t.max = e.dataMax);
        }
      }
      modifyBaseAxisExtremes() {
        let t, e;
        let i = this.chart.navigator,
          s = this.getExtremes(),
          o = s.min,
          r = s.max,
          n = s.dataMin,
          a = s.dataMax,
          l = r - o,
          h = i.stickToMin,
          d = i.stickToMax,
          c = S(this.ordinal?.convertOverscroll(this.options.overscroll), 0),
          p = i.series && i.series[0],
          u = !!this.setExtremes;
        !(this.eventArgs && "rangeSelectorButton" === this.eventArgs.trigger) && (h && (t = (e = n) + l), d && (t = a + c, h || (e = Math.max(n, t - l, i.getBaseSeriesMin(p && p.xData ? p.xData[0] : -Number.MAX_VALUE)))), u && (h || d) && k(e) && (this.min = this.userMin = e, this.max = this.userMax = t)), i.stickToMin = i.stickToMax = null;
      }
      updatedDataHandler() {
        let t = this.chart.navigator,
          e = this.navigatorSeries,
          i = t.reversedExtremes ? 0 === Math.round(t.zoomedMin) : Math.round(t.zoomedMax) >= Math.round(t.size);
        t.stickToMax = S(this.chart.options.navigator && this.chart.options.navigator.stickToMax, i), t.stickToMin = t.shouldStickToMin(this, t), e && !t.hasNavigatorData && (e.options.pointStart = this.xData[0], e.setData(this.options.data, !1, null, !1));
      }
      shouldStickToMin(t, e) {
        let i = e.getBaseSeriesMin(t.xData[0]),
          s = t.xAxis,
          o = s.max,
          r = s.min,
          n = s.options.range;
        return !!(k(o) && k(r)) && (n && o - i > 0 ? o - i < n : r <= i);
      }
      addChartEvents() {
        this.eventsToUnbind || (this.eventsToUnbind = []), this.eventsToUnbind.push(p(this.chart, "redraw", function () {
          let t = this.navigator,
            e = t && (t.baseSeries && t.baseSeries[0] && t.baseSeries[0].xAxis || this.xAxis[0]);
          e && t.render(e.min, e.max);
        }), p(this.chart, "getMargins", function () {
          let t = this.navigator,
            e = t.opposite ? "plotTop" : "marginBottom";
          this.inverted && (e = t.opposite ? "marginRight" : "plotLeft"), this[e] = (this[e] || 0) + (t.navigatorEnabled || !this.inverted ? t.height + t.scrollbarHeight : 0) + t.navigatorOptions.margin;
        }), p(O, "setRange", function (t) {
          this.chart.xAxis[0].setExtremes(t.min, t.max, t.redraw, t.animation, t.eventArguments);
        }));
      }
      destroy() {
        this.removeEvents(), this.xAxis && (m(this.chart.xAxis, this.xAxis), m(this.chart.axes, this.xAxis)), this.yAxis && (m(this.chart.yAxis, this.yAxis), m(this.chart.axes, this.yAxis)), (this.series || []).forEach(t => {
          t.destroy && t.destroy();
        }), ["series", "xAxis", "yAxis", "shades", "outline", "scrollbarTrack", "scrollbarRifles", "scrollbarGroup", "scrollbar", "navigatorGroup", "rendered"].forEach(t => {
          this[t] && this[t].destroy && this[t].destroy(), this[t] = null;
        }), [this.handles].forEach(t => {
          f(t);
        }), this.navigatorEnabled = !1;
      }
    }
    return O;
  }), i(e, "Stock/RangeSelector/RangeSelectorDefaults.js", [], function () {
    return {
      lang: {
        rangeSelectorZoom: "Zoom",
        rangeSelectorFrom: "",
        rangeSelectorTo: "→"
      },
      rangeSelector: {
        allButtonsEnabled: !1,
        buttons: void 0,
        buttonSpacing: 5,
        dropdown: "responsive",
        enabled: void 0,
        verticalAlign: "top",
        buttonTheme: {
          width: 28,
          height: 18,
          padding: 2,
          zIndex: 7
        },
        floating: !1,
        x: 0,
        y: 0,
        height: void 0,
        inputBoxBorderColor: "none",
        inputBoxHeight: 17,
        inputBoxWidth: void 0,
        inputDateFormat: "%e %b %Y",
        inputDateParser: void 0,
        inputEditDateFormat: "%Y-%m-%d",
        inputEnabled: !0,
        inputPosition: {
          align: "right",
          x: 0,
          y: 0
        },
        inputSpacing: 5,
        selected: void 0,
        buttonPosition: {
          align: "left",
          x: 0,
          y: 0
        },
        inputStyle: {
          color: "#334eff",
          cursor: "pointer",
          fontSize: "0.8em"
        },
        labelStyle: {
          color: "#666666",
          fontSize: "0.8em"
        }
      }
    };
  }), i(e, "Stock/RangeSelector/RangeSelectorComposition.js", [e["Core/Defaults.js"], e["Core/Globals.js"], e["Stock/RangeSelector/RangeSelectorDefaults.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    let o;
    let {
        defaultOptions: r
      } = t,
      {
        composed: n
      } = e,
      {
        addEvent: a,
        defined: l,
        extend: h,
        isNumber: d,
        merge: c,
        pick: p,
        pushUnique: u
      } = s,
      g = [];
    function x() {
      let t, e;
      let i = this.range,
        s = i.type,
        o = this.max,
        r = this.chart.time,
        n = function (t, e) {
          let i = "year" === s ? "FullYear" : "Month",
            o = new r.Date(t),
            n = r.get(i, o);
          return r.set(i, o, n + e), n === r.get(i, o) && r.set("Date", o, 0), o.getTime() - t;
        };
      d(i) ? (t = o - i, e = i) : i && (t = o + n(o, -(i.count || 1)), this.chart && this.chart.setFixedRange(o - t));
      let a = p(this.dataMin, Number.MIN_VALUE);
      return d(t) || (t = a), t <= a && (t = a, void 0 === e && (e = n(t, i.count)), this.newMax = Math.min(t + e, p(this.dataMax, Number.MAX_VALUE))), d(o) ? !d(i) && i && i._offsetMin && (t += i._offsetMin) : t = void 0, t;
    }
    function f() {
      this.options.rangeSelector && this.options.rangeSelector.enabled && (this.rangeSelector = new o(this));
    }
    function m() {
      let t = this.rangeSelector;
      if (t) {
        d(t.deferredYTDClick) && (t.clickButton(t.deferredYTDClick), delete t.deferredYTDClick);
        let e = t.options.verticalAlign;
        t.options.floating || ("bottom" === e ? this.extraBottomMargin = !0 : "middle" === e || (this.extraTopMargin = !0));
      }
    }
    function b() {
      let t;
      let e = this.rangeSelector;
      if (!e) return;
      let i = this.xAxis[0].getExtremes(),
        s = this.legend,
        o = e && e.options.verticalAlign;
      d(i.min) && e.render(i.min, i.max), s.display && "top" === o && o === s.options.verticalAlign && (t = c(this.spacingBox), "vertical" === s.options.layout ? t.y = this.plotTop : t.y += e.getHeight(), s.group.placed = !1, s.align(t));
    }
    function y() {
      for (let t = 0, e = g.length; t < e; ++t) {
        let e = g[t];
        if (e[0] === this) {
          e[1].forEach(t => t()), g.splice(t, 1);
          return;
        }
      }
    }
    function v() {
      let t = this.rangeSelector;
      if (t) {
        let e = t.getHeight();
        this.extraTopMargin && (this.plotTop += e), this.extraBottomMargin && (this.marginBottom += e);
      }
    }
    function M(t) {
      let e = t.options.rangeSelector,
        i = this.extraBottomMargin,
        s = this.extraTopMargin,
        r = this.rangeSelector;
      if (e && e.enabled && !l(r) && this.options.rangeSelector && (this.options.rangeSelector.enabled = !0, this.rangeSelector = r = new o(this)), this.extraBottomMargin = !1, this.extraTopMargin = !1, r) {
        let t = e && e.verticalAlign || r.options && r.options.verticalAlign;
        r.options.floating || ("bottom" === t ? this.extraBottomMargin = !0 : "middle" === t || (this.extraTopMargin = !0)), (this.extraBottomMargin !== i || this.extraTopMargin !== s) && (this.isDirtyBox = !0);
      }
    }
    return {
      compose: function (t, e, s) {
        if (o = s, u(n, "RangeSelector")) {
          let s = e.prototype;
          t.prototype.minFromRange = x, a(e, "afterGetContainer", f), a(e, "beforeRender", m), a(e, "destroy", y), a(e, "getMargins", v), a(e, "redraw", b), a(e, "update", M), s.callbacks.push(b), h(r, {
            rangeSelector: i.rangeSelector
          }), h(r.lang, i.lang);
        }
      }
    };
  }), i(e, "Core/Axis/OrdinalAxis.js", [e["Core/Axis/Axis.js"], e["Core/Globals.js"], e["Core/Series/Series.js"], e["Core/Utilities.js"]], function (t, e, i, s) {
    var o;
    let {
      addEvent: r,
      correctFloat: n,
      css: a,
      defined: l,
      error: h,
      isNumber: d,
      pick: c,
      timeUnits: p,
      isString: u
    } = s;
    return function (t) {
      function s(t, e, i, o, r = [], n = 0, a) {
        let d = {},
          c = this.options.tickPixelInterval,
          u = this.chart.time,
          g = [],
          x,
          f,
          m,
          b,
          y,
          v = 0,
          M = [],
          k = -Number.MAX_VALUE;
        if (!this.options.ordinal && !this.options.breaks || !r || r.length < 3 || void 0 === e) return u.getTimeTicks.apply(u, arguments);
        let A = r.length;
        for (x = 0; x < A; x++) {
          if (y = x && r[x - 1] > i, r[x] < e && (v = x), x === A - 1 || r[x + 1] - r[x] > 5 * n || y) {
            if (r[x] > k) {
              for (f = u.getTimeTicks(t, r[v], r[x], o); f.length && f[0] <= k;) f.shift();
              f.length && (k = f[f.length - 1]), g.push(M.length), M = M.concat(f);
            }
            v = x + 1;
          }
          if (y) break;
        }
        if (f) {
          if (b = f.info, a && b.unitRange <= p.hour) {
            for (v = 1, x = M.length - 1; v < x; v++) u.dateFormat("%d", M[v]) !== u.dateFormat("%d", M[v - 1]) && (d[M[v]] = "day", m = !0);
            m && (d[M[0]] = "day"), b.higherRanks = d;
          }
          b.segmentStarts = g, M.info = b;
        } else h(12, !1, this.chart);
        if (a && l(c)) {
          let t = M.length,
            e = [],
            s = [],
            o,
            r,
            n,
            a,
            l,
            h = t;
          for (; h--;) r = this.translate(M[h]), n && (s[h] = n - r), e[h] = n = r;
          for (s.sort((t, e) => t - e), (a = s[Math.floor(s.length / 2)]) < .6 * c && (a = null), h = M[t - 1] > i ? t - 1 : t, n = void 0; h--;) l = Math.abs(n - (r = e[h])), n && l < .8 * c && (null === a || l < .8 * a) ? (d[M[h]] && !d[M[h + 1]] ? (o = h + 1, n = r) : o = h, M.splice(o, 1)) : n = r;
        }
        return M;
      }
      function o(t) {
        let e = this.ordinal.positions;
        if (!e) return t;
        let i = e.length - 1,
          s;
        return (t < 0 ? t = e[0] : t > i ? t = e[i] : (i = Math.floor(t), s = t - i), void 0 !== s && void 0 !== e[i]) ? e[i] + (s ? s * (e[i + 1] - e[i]) : 0) : t;
      }
      function g(t) {
        let e = this.ordinal,
          i = this.old ? this.old.min : this.min,
          s = this.old ? this.old.transA : this.transA,
          o = e.getExtendedPositions();
        if (o && o.length) {
          let r = n((t - i) * s + this.minPixelPadding),
            a = n(e.getIndexOfPoint(r, o)),
            l = n(a % 1);
          if (a >= 0 && a <= o.length - 1) {
            let t = o[Math.floor(a)],
              e = o[Math.ceil(a)];
            return o[Math.floor(a)] + l * (e - t);
          }
        }
        return t;
      }
      function x(e, i) {
        let s = t.Additions.findIndexOf(e, i, !0);
        if (e[s] === i) return s;
        let o = (i - e[s]) / (e[s + 1] - e[s]);
        return s + o;
      }
      function f() {
        this.ordinal || (this.ordinal = new t.Additions(this));
      }
      function m() {
        let {
          eventArgs: t,
          options: e
        } = this;
        if (this.isXAxis && l(e.overscroll) && 0 !== e.overscroll && d(this.max) && d(this.min) && (this.options.ordinal && !this.ordinal.originalOrdinalRange && this.ordinal.getExtendedPositions(!1), this.max === this.dataMax && (t?.trigger !== "pan" || this.isInternal) && t?.trigger !== "navigator")) {
          let i = this.ordinal.convertOverscroll(e.overscroll);
          this.max += i, !this.isInternal && l(this.userMin) && t?.trigger !== "mousewheel" && (this.min += i);
        }
      }
      function b() {
        this.horiz && !this.isDirty && (this.isDirty = this.isOrdinal && this.chart.navigator && !this.chart.navigator.adaptToUpdatedData);
      }
      function y() {
        this.ordinal && (this.ordinal.beforeSetTickPositions(), this.tickInterval = this.ordinal.postProcessTickInterval(this.tickInterval));
      }
      function v(t) {
        let e = this.xAxis[0],
          i = e.ordinal.convertOverscroll(e.options.overscroll),
          s = t.originalEvent.chartX,
          o = this.options.chart.panning,
          r = !1;
        if (o && "y" !== o.type && e.options.ordinal && e.series.length && (!t.touches || t.touches.length <= 1)) {
          let t, o;
          let n = this.mouseDownX,
            l = e.getExtremes(),
            h = l.dataMin,
            d = l.dataMax,
            c = l.min,
            p = l.max,
            u = this.hoverPoints,
            g = e.closestPointRange || e.ordinal && e.ordinal.overscrollPointsRange,
            x = Math.round((n - s) / (e.translationSlope * (e.ordinal.slope || g))),
            f = e.ordinal.getExtendedPositions(),
            m = {
              ordinal: {
                positions: f,
                extendedOrdinalPositions: f
              }
            },
            b = e.index2val,
            y = e.val2lin;
          if (c <= h && x < 0 || p + i >= d && x > 0) return;
          m.ordinal.positions ? Math.abs(x) > 1 && (u && u.forEach(function (t) {
            t.setState();
          }), d > (o = m.ordinal.positions)[o.length - 1] && o.push(d), this.setFixedRange(p - c), (t = e.navigatorAxis.toFixedRange(void 0, void 0, b.apply(m, [y.apply(m, [c, !0]) + x]), b.apply(m, [y.apply(m, [p, !0]) + x]))).min >= Math.min(l.dataMin, c) && t.max <= Math.max(d, p) + i && e.setExtremes(t.min, t.max, !0, !1, {
            trigger: "pan"
          }), this.mouseDownX = s, a(this.container, {
            cursor: "move"
          })) : r = !0;
        } else r = !0;
        r || o && /y/.test(o.type) ? i && (e.max = e.dataMax + i) : t.preventDefault();
      }
      function M() {
        let t = this.xAxis;
        t && t.options.ordinal && (delete t.ordinal.index, delete t.ordinal.originalOrdinalRange);
      }
      function k(t, e) {
        let i;
        let s = this.ordinal,
          o = s.positions,
          r = s.slope,
          n;
        if (!o) return t;
        let a = o.length;
        if (o[0] <= t && o[a - 1] >= t) i = x(o, t);else {
          if (!((n = s.getExtendedPositions && s.getExtendedPositions()) && n.length)) return t;
          let a = n.length;
          r || (r = (n[a - 1] - n[0]) / a);
          let l = x(n, o[0]);
          if (t >= n[0] && t <= n[a - 1]) i = x(n, t) - l;else {
            if (!e) return t;
            i = t < n[0] ? -l - (n[0] - t) / r : (t - n[a - 1]) / r + a - l;
          }
        }
        return e ? i : r * (i || 0) + s.offset;
      }
      t.compose = function (t, e, i) {
        let n = t.prototype;
        return n.ordinal2lin || (n.getTimeTicks = s, n.index2val = o, n.lin2val = g, n.val2lin = k, n.ordinal2lin = n.val2lin, r(t, "afterInit", f), r(t, "foundExtremes", m), r(t, "afterSetScale", b), r(t, "initialAxisTranslation", y), r(i, "pan", v), r(i, "touchpan", v), r(e, "updatedData", M)), t;
      };
      class A {
        constructor(t) {
          this.index = {}, this.axis = t;
        }
        beforeSetTickPositions() {
          let t = this.axis,
            e = t.ordinal,
            i = t.getExtremes(),
            s = i.min,
            o = i.max,
            r = t.brokenAxis?.hasBreaks,
            n = t.options.ordinal,
            a,
            l,
            h,
            d,
            p,
            u,
            g,
            x = [],
            f = Number.MAX_VALUE,
            m = !1,
            b = !1,
            y = !1;
          if (n || r) {
            let i = 0;
            if (t.series.forEach(function (t, e) {
              if (l = [], e > 0 && "highcharts-navigator-series" !== t.options.id && t.processedXData.length > 1 && (b = i !== t.processedXData[1] - t.processedXData[0]), i = t.processedXData[1] - t.processedXData[0], t.boosted && (y = t.boosted), t.reserveSpace() && (!1 !== t.takeOrdinalPosition || r) && (a = (x = x.concat(t.processedXData)).length, x.sort(function (t, e) {
                return t - e;
              }), f = Math.min(f, c(t.closestPointRange, f)), a)) {
                for (e = 0; e < a - 1;) x[e] !== x[e + 1] && l.push(x[e + 1]), e++;
                l[0] !== x[0] && l.unshift(x[0]), x = l;
              }
            }), t.ordinal.originalOrdinalRange || (t.ordinal.originalOrdinalRange = (x.length - 1) * f), b && y && (x.pop(), x.shift()), (a = x.length) > 2) {
              for (h = x[1] - x[0], g = a - 1; g-- && !m;) x[g + 1] - x[g] !== h && (m = !0);
              !t.options.keepOrdinalPadding && (x[0] - s > h || o - x[x.length - 1] > h) && (m = !0);
            } else t.options.overscroll && (2 === a ? f = x[1] - x[0] : 1 === a ? (f = t.ordinal.convertOverscroll(t.options.overscroll), x = [x[0], x[0] + f]) : f = e.overscrollPointsRange);
            m || t.forceOrdinal ? (t.options.overscroll && (e.overscrollPointsRange = f, x = x.concat(e.getOverscrollPositions())), e.positions = x, d = t.ordinal2lin(Math.max(s, x[0]), !0), p = Math.max(t.ordinal2lin(Math.min(o, x[x.length - 1]), !0), 1), e.slope = u = (o - s) / (p - d), e.offset = s - d * u) : (e.overscrollPointsRange = c(t.closestPointRange, e.overscrollPointsRange), e.positions = t.ordinal.slope = e.offset = void 0);
          }
          t.isOrdinal = n && m, e.groupIntervalFactor = null;
        }
        static findIndexOf(t, e, i) {
          let s = 0,
            o = t.length - 1,
            r;
          for (; s < o;) t[r = Math.ceil((s + o) / 2)] <= e ? s = r : o = r - 1;
          return t[s] === e ? s : i ? s : -1;
        }
        getExtendedPositions(t = !0) {
          let s = this,
            o = s.axis,
            r = o.constructor.prototype,
            n = o.chart,
            a = o.series[0]?.currentDataGrouping,
            l = a ? a.count + a.unitName : "raw",
            h = t ? o.ordinal.convertOverscroll(o.options.overscroll) : 0,
            d = o.getExtremes(),
            c,
            p,
            u = s.index;
          return u || (u = s.index = {}), u[l] || ((c = {
            series: [],
            chart: n,
            forceOrdinal: !1,
            getExtremes: function () {
              return {
                min: d.dataMin,
                max: d.dataMax + h
              };
            },
            applyGrouping: r.applyGrouping,
            getGroupPixelWidth: r.getGroupPixelWidth,
            getTimeTicks: r.getTimeTicks,
            options: {
              ordinal: !0
            },
            ordinal: {
              getGroupIntervalFactor: this.getGroupIntervalFactor
            },
            ordinal2lin: r.ordinal2lin,
            getIndexOfPoint: r.getIndexOfPoint,
            val2lin: r.val2lin
          }).ordinal.axis = c, o.series.forEach(function (o) {
            p = {
              xAxis: c,
              xData: o.xData.slice(),
              chart: n,
              groupPixelWidth: o.groupPixelWidth,
              destroyGroupedData: e.noop,
              getProcessedData: i.prototype.getProcessedData,
              applyGrouping: i.prototype.applyGrouping,
              reserveSpace: i.prototype.reserveSpace,
              visible: o.visible
            }, t && (p.xData = p.xData.concat(s.getOverscrollPositions())), p.options = {
              dataGrouping: a ? {
                firstAnchor: o.options.dataGrouping?.firstAnchor,
                anchor: o.options.dataGrouping?.anchor,
                lastAnchor: o.options.dataGrouping?.firstAnchor,
                enabled: !0,
                forced: !0,
                approximation: "open",
                units: [[a.unitName, [a.count]]]
              } : {
                enabled: !1
              }
            }, c.series.push(p), o.processData.apply(p);
          }), c.applyGrouping({
            hasExtremesChanged: !0
          }), p?.closestPointRange !== p?.basePointRange && p.currentDataGrouping && (c.forceOrdinal = !0), o.ordinal.beforeSetTickPositions.apply({
            axis: c
          }), !o.ordinal.originalOrdinalRange && c.ordinal.originalOrdinalRange && (o.ordinal.originalOrdinalRange = c.ordinal.originalOrdinalRange), u[l] = c.ordinal.positions), u[l];
        }
        getGroupIntervalFactor(t, e, i) {
          let s = i.processedXData,
            o = s.length,
            r = [],
            n,
            a,
            l = this.groupIntervalFactor;
          if (!l) {
            for (a = 0; a < o - 1; a++) r[a] = s[a + 1] - s[a];
            r.sort(function (t, e) {
              return t - e;
            }), n = r[Math.floor(o / 2)], t = Math.max(t, s[0]), e = Math.min(e, s[o - 1]), this.groupIntervalFactor = l = o * n / (e - t);
          }
          return l;
        }
        getIndexOfPoint(t, e) {
          let i;
          let s = this.axis,
            o = 0,
            r = function (t) {
              let {
                min: e,
                max: i
              } = s;
              return !!(l(e) && l(i)) && t.points.some(t => t.x >= e && t.x <= i);
            };
          s.series.forEach(t => {
            let e = t.points?.[0];
            l(e?.plotX) && (e.plotX < i || !l(i)) && r(t) && (i = e.plotX, o = e.x);
          }), i ?? (i = s.minPixelPadding);
          let a = s.translationSlope * (this.slope || s.closestPointRange || this.overscrollPointsRange),
            h = n((t - i) / a);
          return A.findIndexOf(e, o, !0) + h;
        }
        getOverscrollPositions() {
          let t = this.axis,
            e = this.convertOverscroll(t.options.overscroll),
            i = this.overscrollPointsRange,
            s = [],
            o = t.dataMax;
          if (l(i)) for (; o <= t.dataMax + e;) s.push(o += i);
          return s;
        }
        postProcessTickInterval(t) {
          let e = this.axis,
            i = this.slope;
          return i ? e.options.breaks ? e.closestPointRange || t : t / (i / e.closestPointRange) : t;
        }
        convertOverscroll(t = 0) {
          let e = this,
            i = e.axis,
            s = function (t) {
              return c(e.originalOrdinalRange, l(i.dataMax) && l(i.dataMin) ? i.dataMax - i.dataMin : 0) * t;
            };
          if (u(t)) {
            let e = parseInt(t, 10);
            if (/%$/.test(t)) return s(e / 100);
            if (/px/.test(t)) {
              let t = Math.min(e, .9 * i.len) / i.len;
              return s(t / (1 - t));
            }
            return 0;
          }
          return t;
        }
      }
      t.Additions = A;
    }(o || (o = {})), o;
  }), i(e, "Stock/RangeSelector/RangeSelector.js", [e["Core/Axis/Axis.js"], e["Core/Defaults.js"], e["Core/Globals.js"], e["Stock/RangeSelector/RangeSelectorComposition.js"], e["Core/Renderer/SVG/SVGElement.js"], e["Core/Utilities.js"], e["Core/Axis/OrdinalAxis.js"]], function (t, e, i, s, o, r, n) {
    let {
        defaultOptions: a
      } = e,
      {
        addEvent: l,
        createElement: h,
        css: d,
        defined: c,
        destroyObjectProperties: p,
        discardElement: u,
        extend: g,
        fireEvent: x,
        isNumber: f,
        merge: m,
        objectEach: b,
        pad: y,
        pick: v,
        pInt: M,
        splat: k
      } = r;
    class A {
      static compose(t, e) {
        s.compose(t, e, A);
      }
      constructor(t) {
        this.buttonOptions = A.prototype.defaultButtons, this.initialButtonGroupWidth = 0, this.init(t);
      }
      clickButton(e, i) {
        let s = this.chart,
          o = this.buttonOptions[e],
          r = s.xAxis[0],
          n = s.scroller && s.scroller.getUnionExtremes() || r || {},
          a = o.type,
          h = o.dataGrouping,
          d = n.dataMin,
          p = n.dataMax,
          u,
          g = r && Math.round(Math.min(r.max, v(p, r.max))),
          m,
          b = o._range,
          y,
          M,
          A,
          S = !0;
        if (null !== d && null !== p) {
          if (this.setSelected(e), h && (this.forcedDataGrouping = !0, t.prototype.setDataGrouping.call(r || {
            chart: this.chart
          }, h, !1), this.frozenStates = o.preserveDataGrouping), "month" === a || "year" === a) r ? (M = {
            range: o,
            max: g,
            chart: s,
            dataMin: d,
            dataMax: p
          }, u = r.minFromRange.call(M), f(M.newMax) && (g = M.newMax), S = !1) : b = o;else if (b) g = Math.min((u = Math.max(g - b, d)) + b, p), S = !1;else if ("ytd" === a) {
            if (r) (void 0 === p || void 0 === d) && (d = Number.MAX_VALUE, p = Number.MIN_VALUE, s.series.forEach(t => {
              let e = t.xData;
              e && (d = Math.min(e[0], d), p = Math.max(e[e.length - 1], p));
            }), i = !1), u = y = (A = this.getYTDExtremes(p, d, s.time.useUTC)).min, g = A.max;else {
              this.deferredYTDClick = e;
              return;
            }
          } else "all" === a && r && (s.navigator && s.navigator.baseSeries[0] && (s.navigator.baseSeries[0].xAxis.options.range = void 0), u = d, g = p);
          if (S && o._offsetMin && c(u) && (u += o._offsetMin), o._offsetMax && c(g) && (g += o._offsetMax), this.dropdown && (this.dropdown.selectedIndex = e + 1), r) r.setExtremes(u, g, v(i, !0), void 0, {
            trigger: "rangeSelectorButton",
            rangeSelectorButton: o
          }), s.setFixedRange(o._range);else {
            m = k(s.options.xAxis)[0];
            let t = l(s, "afterGetAxes", function () {
              let t = s.xAxis[0];
              t.range = t.options.range = b, t.min = t.options.min = y;
            });
            l(s, "load", function () {
              let e = s.xAxis[0];
              s.setFixedRange(o._range), e.options.range = m.range, e.options.min = m.min, t();
            });
          }
          x(this, "afterBtnClick");
        }
      }
      setSelected(t) {
        this.selected = this.options.selected = t;
      }
      init(t) {
        let e = this,
          i = t.options.rangeSelector,
          s = i.buttons || e.defaultButtons.slice(),
          o = i.selected,
          r = function () {
            let t = e.minInput,
              i = e.maxInput;
            t && t.blur && x(t, "blur"), i && i.blur && x(i, "blur");
          };
        e.chart = t, e.options = i, e.buttons = [], e.buttonOptions = s, this.eventsToUnbind = [], this.eventsToUnbind.push(l(t.container, "mousedown", r)), this.eventsToUnbind.push(l(t, "resize", r)), s.forEach(e.computeButtonRange), void 0 !== o && s[o] && this.clickButton(o, !1), this.eventsToUnbind.push(l(t, "load", function () {
          t.xAxis && t.xAxis[0] && l(t.xAxis[0], "setExtremes", function (i) {
            f(this.max) && f(this.min) && this.max - this.min !== t.fixedRange && "rangeSelectorButton" !== i.trigger && "updatedData" !== i.trigger && e.forcedDataGrouping && !e.frozenStates && this.setDataGrouping(!1, !1);
          });
        })), this.createElements();
      }
      updateButtonStates() {
        let t = this,
          e = this.chart,
          i = this.dropdown,
          s = this.dropdownLabel,
          o = e.xAxis[0],
          r = Math.round(o.max - o.min),
          l = !o.hasVisibleSeries,
          h = 24 * 36e5,
          c = e.scroller && e.scroller.getUnionExtremes() || o,
          p = c.dataMin,
          u = c.dataMax,
          g = t.getYTDExtremes(u, p, e.time.useUTC),
          x = g.min,
          m = g.max,
          b = t.selected,
          y = t.options.allButtonsEnabled,
          v = Array(t.buttonOptions.length).fill(0),
          M = f(b),
          k = t.buttons,
          A = !1,
          S = null;
        t.buttonOptions.forEach((e, i) => {
          let s = e._range,
            a = e.type,
            d = e.count || 1,
            c = e._offsetMax - e._offsetMin,
            g = i === b,
            f = s > u - p,
            k = s < o.minRange,
            C = !1,
            w = s === r;
          if (g && f && (A = !0), o.isOrdinal && o.ordinal?.positions && s && r < s) {
            let t = o.ordinal.positions,
              e = n.Additions.findIndexOf(t, o.min, !0),
              i = Math.min(n.Additions.findIndexOf(t, o.max, !0) + 1, t.length - 1);
            t[i] - t[e] > s && (w = !0);
          } else ("month" === a || "year" === a) && r + 36e5 >= {
            month: 28,
            year: 365
          }[a] * h * d - c && r - 36e5 <= {
            month: 31,
            year: 366
          }[a] * h * d + c ? w = !0 : "ytd" === a ? (w = m - x + c === r, C = !g) : "all" === a && (w = o.max - o.min >= u - p);
          let P = !y && !(A && "all" === a) && (f || k || l),
            O = A && "all" === a || !C && w || g && t.frozenStates;
          P ? v[i] = 3 : O && (!M || i === b) && (S = i);
        }), null !== S ? (v[S] = 2, t.setSelected(S)) : (t.setSelected(), s && (s.setState(0), s.attr({
          text: (a.lang.rangeSelectorZoom || "") + " ▾"
        })));
        for (let e = 0; e < v.length; e++) {
          let o = v[e],
            r = k[e];
          if (r.state !== o && (r.setState(o), i)) {
            i.options[e + 1].disabled = 3 === o, 2 === o && (s && (s.setState(2), s.attr({
              text: t.buttonOptions[e].text + " ▾"
            })), i.selectedIndex = e + 1);
            let r = s.getBBox();
            d(i, {
              width: `${r.width}px`,
              height: `${r.height}px`
            });
          }
        }
      }
      computeButtonRange(t) {
        let e = t.type,
          i = t.count || 1,
          s = {
            millisecond: 1,
            second: 1e3,
            minute: 6e4,
            hour: 36e5,
            day: 864e5,
            week: 6048e5
          };
        s[e] ? t._range = s[e] * i : ("month" === e || "year" === e) && (t._range = 24 * {
          month: 30,
          year: 365
        }[e] * 36e5 * i), t._offsetMin = v(t.offsetMin, 0), t._offsetMax = v(t.offsetMax, 0), t._range += t._offsetMax - t._offsetMin;
      }
      getInputValue(t) {
        let e = "min" === t ? this.minInput : this.maxInput,
          i = this.chart.options.rangeSelector,
          s = this.chart.time;
        return e ? ("text" === e.type && i.inputDateParser || this.defaultInputDateParser)(e.value, s.useUTC, s) : 0;
      }
      setInputValue(t, e) {
        let i = this.options,
          s = this.chart.time,
          o = "min" === t ? this.minInput : this.maxInput,
          r = "min" === t ? this.minDateBox : this.maxDateBox;
        if (o) {
          let t = o.getAttribute("data-hc-time"),
            n = c(t) ? Number(t) : void 0;
          if (c(e)) {
            let t = n;
            c(t) && o.setAttribute("data-hc-time-previous", t), o.setAttribute("data-hc-time", e), n = e;
          }
          o.value = s.dateFormat(this.inputTypeFormats[o.type] || i.inputEditDateFormat, n), r && r.attr({
            text: s.dateFormat(i.inputDateFormat, n)
          });
        }
      }
      setInputExtremes(t, e, i) {
        let s = "min" === t ? this.minInput : this.maxInput;
        if (s) {
          let t = this.inputTypeFormats[s.type],
            o = this.chart.time;
          if (t) {
            let r = o.dateFormat(t, e);
            s.min !== r && (s.min = r);
            let n = o.dateFormat(t, i);
            s.max !== n && (s.max = n);
          }
        }
      }
      showInput(t) {
        let e = "min" === t ? this.minDateBox : this.maxDateBox,
          i = "min" === t ? this.minInput : this.maxInput;
        if (i && e && this.inputGroup) {
          let t = "text" === i.type,
            {
              translateX: s = 0,
              translateY: o = 0
            } = this.inputGroup,
            {
              x: r = 0,
              width: n = 0,
              height: a = 0
            } = e,
            {
              inputBoxWidth: l
            } = this.options;
          d(i, {
            width: t ? n + (l ? -2 : 20) + "px" : "auto",
            height: a - 2 + "px",
            border: "2px solid silver"
          }), t && l ? d(i, {
            left: s + r + "px",
            top: o + "px"
          }) : d(i, {
            left: Math.min(Math.round(r + s - (i.offsetWidth - n) / 2), this.chart.chartWidth - i.offsetWidth) + "px",
            top: o - (i.offsetHeight - a) / 2 + "px"
          });
        }
      }
      hideInput(t) {
        let e = "min" === t ? this.minInput : this.maxInput;
        e && d(e, {
          top: "-9999em",
          border: 0,
          width: "1px",
          height: "1px"
        });
      }
      defaultInputDateParser(t, e, s) {
        let o = t.split("/").join("-").split(" ").join("T");
        if (-1 === o.indexOf("T") && (o += "T00:00"), e) o += "Z";else {
          let t;
          if (i.isSafari && !((t = o).length > 6 && (t.lastIndexOf("-") === t.length - 6 || t.lastIndexOf("+") === t.length - 6))) {
            let t = new Date(o).getTimezoneOffset() / 60;
            o += t <= 0 ? `+${y(-t)}:00` : `-${y(t)}:00`;
          }
        }
        let r = Date.parse(o);
        if (!f(r)) {
          let e = t.split("-");
          r = Date.UTC(M(e[0]), M(e[1]) - 1, M(e[2]));
        }
        return s && e && f(r) && (r += s.getTimezoneOffset(r)), r;
      }
      drawInput(t) {
        let {
            chart: e,
            div: s,
            inputGroup: o
          } = this,
          r = this,
          n = e.renderer.style || {},
          l = e.renderer,
          c = e.options.rangeSelector,
          p = a.lang,
          u = "min" === t;
        function x(t) {
          let {
              maxInput: i,
              minInput: s
            } = r,
            o = e.xAxis[0],
            n = e.scroller?.getUnionExtremes() || o,
            a = n.dataMin,
            l = n.dataMax,
            h = e.xAxis[0].getExtremes()[t],
            d = r.getInputValue(t);
          f(d) && d !== h && (u && i && f(a) ? d > Number(i.getAttribute("data-hc-time")) ? d = void 0 : d < a && (d = a) : s && f(l) && (d < Number(s.getAttribute("data-hc-time")) ? d = void 0 : d > l && (d = l)), void 0 !== d && o.setExtremes(u ? d : o.min, u ? o.max : d, void 0, void 0, {
            trigger: "rangeSelectorInput"
          }));
        }
        let b = p[u ? "rangeSelectorFrom" : "rangeSelectorTo"] || "",
          y = l.label(b, 0).addClass("highcharts-range-label").attr({
            padding: b ? 2 : 0,
            height: b ? c.inputBoxHeight : 0
          }).add(o),
          v = l.label("", 0).addClass("highcharts-range-input").attr({
            padding: 2,
            width: c.inputBoxWidth,
            height: c.inputBoxHeight,
            "text-align": "center"
          }).on("click", function () {
            r.showInput(t), r[t + "Input"].focus();
          });
        e.styledMode || v.attr({
          stroke: c.inputBoxBorderColor,
          "stroke-width": 1
        }), v.add(o);
        let M = h("input", {
          name: t,
          className: "highcharts-range-selector"
        }, void 0, s);
        M.setAttribute("type", function (t) {
          if (-1 !== t.indexOf("%L")) return "text";
          let e = ["a", "A", "d", "e", "w", "b", "B", "m", "o", "y", "Y"].some(e => -1 !== t.indexOf("%" + e)),
            i = ["H", "k", "I", "l", "M", "S"].some(e => -1 !== t.indexOf("%" + e));
          return e && i ? "datetime-local" : e ? "date" : i ? "time" : "text";
        }(c.inputDateFormat || "%e %b %Y")), e.styledMode || (y.css(m(n, c.labelStyle)), v.css(m({
          color: "#333333"
        }, n, c.inputStyle)), d(M, g({
          position: "absolute",
          border: 0,
          boxShadow: "0 0 15px rgba(0,0,0,0.3)",
          width: "1px",
          height: "1px",
          padding: 0,
          textAlign: "center",
          fontSize: n.fontSize,
          fontFamily: n.fontFamily,
          top: "-9999em"
        }, c.inputStyle))), M.onfocus = () => {
          r.showInput(t);
        }, M.onblur = () => {
          M === i.doc.activeElement && x(t), r.hideInput(t), r.setInputValue(t), M.blur();
        };
        let k = !1;
        return M.onchange = () => {
          k || (x(t), r.hideInput(t), M.blur());
        }, M.onkeypress = e => {
          13 === e.keyCode && x(t);
        }, M.onkeydown = e => {
          k = !0, ("ArrowUp" === e.key || "ArrowDown" === e.key || "Tab" === e.key) && x(t);
        }, M.onkeyup = () => {
          k = !1;
        }, {
          dateBox: v,
          input: M,
          label: y
        };
      }
      getPosition() {
        let t = this.chart,
          e = t.options.rangeSelector,
          i = "top" === e.verticalAlign ? t.plotTop - t.axisOffset[0] : 0;
        return {
          buttonTop: i + e.buttonPosition.y,
          inputTop: i + e.inputPosition.y - 10
        };
      }
      getYTDExtremes(t, e, i) {
        let s = this.chart.time,
          o = new s.Date(t),
          r = s.get("FullYear", o),
          n = Math.max(e, i ? s.Date.UTC(r, 0, 1) : +new s.Date(r, 0, 1)),
          a = o.getTime();
        return {
          max: Math.min(t || a, a),
          min: n
        };
      }
      createElements() {
        let t = this.chart,
          e = t.renderer,
          i = t.container,
          s = t.options,
          o = s.rangeSelector,
          r = o.inputEnabled,
          n = v(s.chart.style?.zIndex, 0) + 1;
        if (!1 !== o.enabled && (this.group = e.g("range-selector-group").attr({
          zIndex: 7
        }).add(), this.div = h("div", void 0, {
          position: "relative",
          height: 0,
          zIndex: n
        }), this.buttonOptions.length && this.renderButtons(), i.parentNode && i.parentNode.insertBefore(this.div, i), r)) {
          this.inputGroup = e.g("input-group").add(this.group);
          let t = this.drawInput("min");
          this.minDateBox = t.dateBox, this.minLabel = t.label, this.minInput = t.input;
          let i = this.drawInput("max");
          this.maxDateBox = i.dateBox, this.maxLabel = i.label, this.maxInput = i.input;
        }
      }
      render(t, e) {
        let i = this.chart,
          s = i.options.rangeSelector,
          o = s.inputEnabled;
        if (!1 !== s.enabled) {
          if (o) {
            this.setInputValue("min", t), this.setInputValue("max", e);
            let o = i.scroller && i.scroller.getUnionExtremes() || i.xAxis[0] || {};
            if (c(o.dataMin) && c(o.dataMax)) {
              let t = i.xAxis[0].minRange || 0;
              this.setInputExtremes("min", o.dataMin, Math.min(o.dataMax, this.getInputValue("max")) - t), this.setInputExtremes("max", Math.max(o.dataMin, this.getInputValue("min")) + t, o.dataMax);
            }
            if (this.inputGroup) {
              let t = 0;
              [this.minLabel, this.minDateBox, this.maxLabel, this.maxDateBox].forEach(e => {
                if (e) {
                  let {
                    width: i
                  } = e.getBBox();
                  i && (e.attr({
                    x: t
                  }), t += i + s.inputSpacing);
                }
              });
            }
          }
          this.alignElements(), this.updateButtonStates();
        }
      }
      renderButtons() {
        let {
            buttons: t,
            chart: e,
            options: s
          } = this,
          o = a.lang,
          r = e.renderer,
          n = m(s.buttonTheme),
          d = n && n.states,
          c = n.width || 28;
        delete n.width, delete n.states, this.buttonGroup = r.g("range-selector-buttons").add(this.group);
        let p = this.dropdown = h("select", void 0, {
            position: "absolute",
            padding: 0,
            border: 0,
            cursor: "pointer",
            opacity: 1e-4
          }, this.div),
          u = e.userOptions.rangeSelector?.buttonTheme;
        this.dropdownLabel = r.button("", 0, 0, () => {}, m(n, {
          "stroke-width": v(n["stroke-width"], 0),
          width: "auto",
          paddingLeft: v(s.buttonTheme.paddingLeft, u?.padding, 8),
          paddingRight: v(s.buttonTheme.paddingRight, u?.padding, 8)
        }), d && d.hover, d && d.select, d && d.disabled).hide().add(this.group), l(p, "touchstart", () => {
          p.style.fontSize = "16px";
        });
        let g = i.isMS ? "mouseover" : "mouseenter",
          f = i.isMS ? "mouseout" : "mouseleave";
        l(p, g, () => {
          x(this.dropdownLabel.element, g);
        }), l(p, f, () => {
          x(this.dropdownLabel.element, f);
        }), l(p, "change", () => {
          x(this.buttons[p.selectedIndex - 1].element, "click");
        }), this.zoomText = r.label(o.rangeSelectorZoom || "", 0).attr({
          padding: s.buttonTheme.padding,
          height: s.buttonTheme.height,
          paddingLeft: 0,
          paddingRight: 0
        }).add(this.buttonGroup), this.chart.styledMode || (this.zoomText.css(s.labelStyle), n["stroke-width"] = v(n["stroke-width"], 0)), h("option", {
          textContent: this.zoomText.textStr,
          disabled: !0
        }, void 0, p), this.buttonOptions.forEach((e, i) => {
          h("option", {
            textContent: e.title || e.text
          }, void 0, p), t[i] = r.button(e.text, 0, 0, t => {
            let s;
            let o = e.events && e.events.click;
            o && (s = o.call(e, t)), !1 !== s && this.clickButton(i), this.isActive = !0;
          }, n, d && d.hover, d && d.select, d && d.disabled).attr({
            "text-align": "center",
            width: c
          }).add(this.buttonGroup), e.title && t[i].attr("title", e.title);
        });
      }
      alignElements() {
        let {
            buttonGroup: t,
            buttons: e,
            chart: i,
            group: s,
            inputGroup: o,
            options: r,
            zoomText: n
          } = this,
          a = i.options,
          l = a.exporting && !1 !== a.exporting.enabled && a.navigation && a.navigation.buttonOptions,
          {
            buttonPosition: h,
            inputPosition: d,
            verticalAlign: c
          } = r,
          p = (t, e) => l && this.titleCollision(i) && "top" === c && "right" === e.align && e.y - t.getBBox().height - 12 < (l.y || 0) + (l.height || 0) + i.spacing[0] ? -40 : 0,
          u = i.plotLeft;
        if (s && h && d) {
          let a = h.x - i.spacing[3];
          if (t) {
            if (this.positionButtons(), !this.initialButtonGroupWidth) {
              let t = 0;
              n && (t += n.getBBox().width + 5), e.forEach((i, s) => {
                t += i.width || 0, s !== e.length - 1 && (t += r.buttonSpacing);
              }), this.initialButtonGroupWidth = t;
            }
            u -= i.spacing[3];
            let o = p(t, h);
            this.alignButtonGroup(o), this.buttonGroup?.translateY && this.dropdownLabel.attr({
              y: this.buttonGroup.translateY
            }), s.placed = t.placed = i.hasLoaded;
          }
          let l = 0;
          o && (l = p(o, d), "left" === d.align ? a = u : "right" === d.align && (a = -Math.max(i.axisOffset[1], -l)), o.align({
            y: d.y,
            width: o.getBBox().width,
            align: d.align,
            x: d.x + a - 2
          }, !0, i.spacingBox), o.placed = i.hasLoaded), this.handleCollision(l), s.align({
            verticalAlign: c
          }, !0, i.spacingBox);
          let g = s.alignAttr.translateY,
            x = s.getBBox().height + 20,
            f = 0;
          if ("bottom" === c) {
            let t = i.legend && i.legend.options;
            f = g - (x = x + (t && "bottom" === t.verticalAlign && t.enabled && !t.floating ? i.legend.legendHeight + v(t.margin, 10) : 0) - 20) - (r.floating ? 0 : r.y) - (i.titleOffset ? i.titleOffset[2] : 0) - 10;
          }
          "top" === c ? (r.floating && (f = 0), i.titleOffset && i.titleOffset[0] && (f = i.titleOffset[0]), f += i.margin[0] - i.spacing[0] || 0) : "middle" === c && (d.y === h.y ? f = g : (d.y || h.y) && (d.y < 0 || h.y < 0 ? f -= Math.min(d.y, h.y) : f = g - x)), s.translate(r.x, r.y + Math.floor(f));
          let {
            minInput: m,
            maxInput: b,
            dropdown: y
          } = this;
          r.inputEnabled && m && b && (m.style.marginTop = s.translateY + "px", b.style.marginTop = s.translateY + "px"), y && (y.style.marginTop = s.translateY + "px");
        }
      }
      alignButtonGroup(t, e) {
        let {
            chart: i,
            options: s,
            buttonGroup: o
          } = this,
          {
            buttonPosition: r
          } = s,
          n = i.plotLeft - i.spacing[3],
          a = r.x - i.spacing[3];
        "right" === r.align ? a += t - n : "center" === r.align && (a -= n / 2), o && o.align({
          y: r.y,
          width: v(e, this.initialButtonGroupWidth),
          align: r.align,
          x: a
        }, !0, i.spacingBox);
      }
      positionButtons() {
        let {
            buttons: t,
            chart: e,
            options: i,
            zoomText: s
          } = this,
          o = e.hasLoaded ? "animate" : "attr",
          {
            buttonPosition: r
          } = i,
          n = e.plotLeft,
          a = n;
        s && "hidden" !== s.visibility && (s[o]({
          x: v(n + r.x, n)
        }), a += r.x + s.getBBox().width + 5);
        for (let e = 0, s = this.buttonOptions.length; e < s; ++e) "hidden" !== t[e].visibility ? (t[e][o]({
          x: a
        }), a += (t[e].width || 0) + i.buttonSpacing) : t[e][o]({
          x: n
        });
      }
      handleCollision(t) {
        let {
            chart: e,
            buttonGroup: i,
            inputGroup: s
          } = this,
          {
            buttonPosition: o,
            dropdown: r,
            inputPosition: n
          } = this.options,
          a = () => {
            let t = 0;
            return this.buttons.forEach(e => {
              let i = e.getBBox();
              i.width > t && (t = i.width);
            }), t;
          },
          l = e => {
            if (s?.alignOptions && i) {
              let r = s.alignAttr.translateX + s.alignOptions.x - t + s.getBBox().x + 2,
                a = s.alignOptions.width || 0,
                l = i.alignAttr.translateX + i.getBBox().x;
              return l + e > r && r + a > l && o.y < n.y + s.getBBox().height;
            }
            return !1;
          },
          h = () => {
            s && i && s.attr({
              translateX: s.alignAttr.translateX + (e.axisOffset[1] >= -t ? 0 : -t),
              translateY: s.alignAttr.translateY + i.getBBox().height + 10
            });
          };
        if (i) {
          if ("always" === r) {
            this.collapseButtons(), l(a()) && h();
            return;
          }
          "never" === r && this.expandButtons();
        }
        s && i ? n.align === o.align || l(this.initialButtonGroupWidth + 20) ? "responsive" === r ? (this.collapseButtons(), l(a()) && h()) : h() : "responsive" === r && this.expandButtons() : i && "responsive" === r && (this.initialButtonGroupWidth > e.plotWidth ? this.collapseButtons() : this.expandButtons());
      }
      collapseButtons() {
        let {
          buttons: t,
          zoomText: e
        } = this;
        !0 !== this.isCollapsed && (this.isCollapsed = !0, e.hide(), t.forEach(t => void t.hide()), this.showDropdown());
      }
      expandButtons() {
        let {
          buttons: t,
          zoomText: e
        } = this;
        !1 !== this.isCollapsed && (this.isCollapsed = !1, this.hideDropdown(), e.show(), t.forEach(t => void t.show()), this.positionButtons());
      }
      showDropdown() {
        let {
          buttonGroup: t,
          chart: e,
          dropdownLabel: i,
          dropdown: s
        } = this;
        if (t && s) {
          let {
              translateX: o = 0,
              translateY: r = 0
            } = t,
            n = e.plotLeft + o;
          i.attr({
            x: n,
            y: r
          }).show(), d(s, {
            left: n + "px",
            top: r + "px",
            visibility: "inherit"
          }), this.hasVisibleDropdown = !0;
        }
      }
      hideDropdown() {
        let {
          dropdown: t
        } = this;
        t && (this.dropdownLabel.hide(), d(t, {
          visibility: "hidden",
          width: "1px",
          height: "1px"
        }), this.hasVisibleDropdown = !1);
      }
      getHeight() {
        let t = this.options,
          e = this.group,
          i = t.inputPosition,
          s = t.buttonPosition,
          o = t.y,
          r = s.y,
          n = i.y,
          a = 0;
        if (t.height) return t.height;
        this.alignElements(), a = e ? e.getBBox(!0).height + 13 + o : 0;
        let l = Math.min(n, r);
        return (n < 0 && r < 0 || n > 0 && r > 0) && (a += Math.abs(l)), a;
      }
      titleCollision(t) {
        return !(t.options.title.text || t.options.subtitle.text);
      }
      update(t, e = !0) {
        let i = this.chart;
        m(!0, i.options.rangeSelector, t), this.destroy(), this.init(i), e && this.render();
      }
      destroy() {
        let t = this,
          e = t.minInput,
          i = t.maxInput;
        t.eventsToUnbind && (t.eventsToUnbind.forEach(t => t()), t.eventsToUnbind = void 0), p(t.buttons), e && (e.onfocus = e.onblur = e.onchange = null), i && (i.onfocus = i.onblur = i.onchange = null), b(t, function (e, i) {
          e && "chart" !== i && (e instanceof o ? e.destroy() : e instanceof window.HTMLElement && u(e)), e !== A.prototype[i] && (t[i] = null);
        }, this);
      }
    }
    return g(A.prototype, {
      defaultButtons: [{
        type: "month",
        count: 1,
        text: "1m",
        title: "View 1 month"
      }, {
        type: "month",
        count: 3,
        text: "3m",
        title: "View 3 months"
      }, {
        type: "month",
        count: 6,
        text: "6m",
        title: "View 6 months"
      }, {
        type: "ytd",
        text: "YTD",
        title: "View year to date"
      }, {
        type: "year",
        count: 1,
        text: "1y",
        title: "View 1 year"
      }, {
        type: "all",
        text: "All",
        title: "View all"
      }],
      inputTypeFormats: {
        "datetime-local": "%Y-%m-%dT%H:%M:%S",
        date: "%Y-%m-%d",
        time: "%H:%M:%S"
      }
    }), A;
  }), i(e, "Series/PathUtilities.js", [], function () {
    function t(t, e) {
      let i = [];
      for (let s = 0; s < t.length; s++) {
        let o = t[s][1],
          r = t[s][2];
        if ("number" == typeof o && "number" == typeof r) {
          if (0 === s) i.push(["M", o, r]);else if (s === t.length - 1) i.push(["L", o, r]);else if (e) {
            let n = t[s - 1],
              a = t[s + 1];
            if (n && a) {
              let t = n[1],
                s = n[2],
                l = a[1],
                h = a[2];
              if ("number" == typeof t && "number" == typeof l && "number" == typeof s && "number" == typeof h && t !== l && s !== h) {
                let n = t < l ? 1 : -1,
                  a = s < h ? 1 : -1;
                i.push(["L", o - n * Math.min(Math.abs(o - t), e), r - a * Math.min(Math.abs(r - s), e)], ["C", o, r, o, r, o + n * Math.min(Math.abs(o - l), e), r + a * Math.min(Math.abs(r - h), e)]);
              }
            }
          } else i.push(["L", o, r]);
        }
      }
      return i;
    }
    return {
      applyRadius: t,
      getLinkPath: {
        default: function (e) {
          let {
              x1: i,
              y1: s,
              x2: o,
              y2: r,
              width: n = 0,
              inverted: a = !1,
              radius: l,
              parentVisible: h
            } = e,
            d = [["M", i, s], ["L", i, s], ["C", i, s, i, r, i, r], ["L", i, r], ["C", i, s, i, r, i, r], ["L", i, r]];
          return h ? t([["M", i, s], ["L", i + n * (a ? -.5 : .5), s], ["L", i + n * (a ? -.5 : .5), r], ["L", o, r]], l) : d;
        },
        straight: function (t) {
          let {
            x1: e,
            y1: i,
            x2: s,
            y2: o,
            width: r = 0,
            inverted: n = !1,
            parentVisible: a
          } = t;
          return a ? [["M", e, i], ["L", e + r * (n ? -1 : 1), o], ["L", s, o]] : [["M", e, i], ["L", e, o], ["L", e, o]];
        },
        curved: function (t) {
          let {
            x1: e,
            y1: i,
            x2: s,
            y2: o,
            offset: r = 0,
            width: n = 0,
            inverted: a = !1,
            parentVisible: l
          } = t;
          return l ? [["M", e, i], ["C", e + r, i, e - r + n * (a ? -1 : 1), o, e + n * (a ? -1 : 1), o], ["L", s, o]] : [["M", e, i], ["C", e, i, e, o, e, o], ["L", s, o]];
        }
      }
    };
  }), i(e, "Gantt/PathfinderAlgorithms.js", [e["Series/PathUtilities.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        pick: i
      } = e,
      {
        min: s,
        max: o,
        abs: r
      } = Math;
    function n(t, e, i) {
      let s = e - 1e-7,
        o = i || 0,
        r = t.length - 1,
        n,
        a;
      for (; o <= r;) if ((a = s - t[n = r + o >> 1].xMin) > 0) o = n + 1;else {
        if (!(a < 0)) return n;
        r = n - 1;
      }
      return o > 0 ? o - 1 : 0;
    }
    function a(t, e) {
      let i = n(t, e.x + 1) + 1;
      for (; i--;) {
        var s;
        if (t[i].xMax >= e.x && (s = t[i], e.x <= s.xMax && e.x >= s.xMin && e.y <= s.yMax && e.y >= s.yMin)) return i;
      }
      return -1;
    }
    function l(t) {
      let e = [];
      if (t.length) {
        e.push(["M", t[0].start.x, t[0].start.y]);
        for (let i = 0; i < t.length; ++i) e.push(["L", t[i].end.x, t[i].end.y]);
      }
      return e;
    }
    function h(t, e) {
      t.yMin = o(t.yMin, e.yMin), t.yMax = s(t.yMax, e.yMax), t.xMin = o(t.xMin, e.xMin), t.xMax = s(t.xMax, e.xMax);
    }
    let d = function (e, s, o) {
      let n = [],
        h = o.chartObstacles,
        d = a(h, e),
        c = a(h, s),
        p,
        u = i(o.startDirectionX, r(s.x - e.x) > r(s.y - e.y)) ? "x" : "y",
        g,
        x,
        f,
        m;
      function b(t, e, i, s, o) {
        let r = {
          x: t.x,
          y: t.y
        };
        return r[e] = i[s || e] + (o || 0), r;
      }
      function y(t, e, i) {
        let s = r(e[i] - t[i + "Min"]) > r(e[i] - t[i + "Max"]);
        return b(e, i, t, i + (s ? "Max" : "Min"), s ? 1 : -1);
      }
      c > -1 ? (p = {
        start: x = y(h[c], s, u),
        end: s
      }, m = x) : m = s, d > -1 && (x = y(g = h[d], e, u), n.push({
        start: e,
        end: x
      }), x[u] >= e[u] == x[u] >= m[u] && (f = e[u = "y" === u ? "x" : "y"] < s[u], n.push({
        start: x,
        end: b(x, u, g, u + (f ? "Max" : "Min"), f ? 1 : -1)
      }), u = "y" === u ? "x" : "y"));
      let v = n.length ? n[n.length - 1].end : e;
      x = b(v, u, m), n.push({
        start: v,
        end: x
      });
      let M = b(x, u = "y" === u ? "x" : "y", m);
      return n.push({
        start: x,
        end: M
      }), n.push(p), {
        path: t.applyRadius(l(n), o.radius),
        obstacles: n
      };
    };
    function c(t, e, d) {
      let c = i(d.startDirectionX, r(e.x - t.x) > r(e.y - t.y)),
        p = c ? "x" : "y",
        u = [],
        g = d.obstacleMetrics,
        x = s(t.x, e.x) - g.maxWidth - 10,
        f = o(t.x, e.x) + g.maxWidth + 10,
        m = s(t.y, e.y) - g.maxHeight - 10,
        b = o(t.y, e.y) + g.maxHeight + 10,
        y,
        v,
        M,
        k = !1,
        A = d.chartObstacles,
        S = n(A, f),
        C = n(A, x);
      function w(t, e, i) {
        let o, r, a, l;
        let h = t.x < e.x ? 1 : -1;
        t.x < e.x ? (o = t, r = e) : (o = e, r = t), t.y < e.y ? (l = t, a = e) : (l = e, a = t);
        let d = h < 0 ? s(n(A, r.x), A.length - 1) : 0;
        for (; A[d] && (h > 0 && A[d].xMin <= r.x || h < 0 && A[d].xMax >= o.x);) {
          if (A[d].xMin <= r.x && A[d].xMax >= o.x && A[d].yMin <= a.y && A[d].yMax >= l.y) {
            if (i) return {
              y: t.y,
              x: t.x < e.x ? A[d].xMin - 1 : A[d].xMax + 1,
              obstacle: A[d]
            };
            return {
              x: t.x,
              y: t.y < e.y ? A[d].yMin - 1 : A[d].yMax + 1,
              obstacle: A[d]
            };
          }
          d += h;
        }
        return e;
      }
      function P(t, e, i, s, o) {
        let n = o.soft,
          a = o.hard,
          l = s ? "x" : "y",
          h = {
            x: e.x,
            y: e.y
          },
          d = {
            x: e.x,
            y: e.y
          },
          c = t[l + "Max"] >= n[l + "Max"],
          p = t[l + "Min"] <= n[l + "Min"],
          u = t[l + "Max"] >= a[l + "Max"],
          g = t[l + "Min"] <= a[l + "Min"],
          x = r(t[l + "Min"] - e[l]),
          f = r(t[l + "Max"] - e[l]),
          m = 10 > r(x - f) ? e[l] < i[l] : f < x;
        d[l] = t[l + "Min"], h[l] = t[l + "Max"];
        let b = w(e, d, s)[l] !== d[l],
          y = w(e, h, s)[l] !== h[l];
        return m = b ? !y || m : !y && m, m = p ? !c || m : !c && m, m = g ? !u || m : !u && m;
      }
      for ((S = a(A = A.slice(C, S + 1), e)) > -1 && (M = function (t, e, i) {
        let o = s(t.xMax - e.x, e.x - t.xMin) < s(t.yMax - e.y, e.y - t.yMin),
          r = P(t, e, i, o, {
            soft: d.hardBounds,
            hard: d.hardBounds
          });
        return o ? {
          y: e.y,
          x: t[r ? "xMax" : "xMin"] + (r ? 1 : -1)
        } : {
          x: e.x,
          y: t[r ? "yMax" : "yMin"] + (r ? 1 : -1)
        };
      }(A[S], e, t), u.push({
        end: e,
        start: M
      }), e = M); (S = a(A, e)) > -1;) v = e[p] - t[p] < 0, (M = {
        x: e.x,
        y: e.y
      })[p] = A[S][v ? p + "Max" : p + "Min"] + (v ? 1 : -1), u.push({
        end: e,
        start: M
      }), e = M;
      return {
        path: l(y = (y = function t(e, i, r) {
          let n, l, c, p, u, g, y;
          if (e.x === i.x && e.y === i.y) return [];
          let v = r ? "x" : "y",
            M = d.obstacleOptions.margin,
            S = {
              soft: {
                xMin: x,
                xMax: f,
                yMin: m,
                yMax: b
              },
              hard: d.hardBounds
            };
          return (u = a(A, e)) > -1 ? (p = P(u = A[u], e, i, r, S), h(u, d.hardBounds), y = r ? {
            y: e.y,
            x: u[p ? "xMax" : "xMin"] + (p ? 1 : -1)
          } : {
            x: e.x,
            y: u[p ? "yMax" : "yMin"] + (p ? 1 : -1)
          }, (g = a(A, y)) > -1 && (h(g = A[g], d.hardBounds), y[v] = p ? o(u[v + "Max"] - M + 1, (g[v + "Min"] + u[v + "Max"]) / 2) : s(u[v + "Min"] + M - 1, (g[v + "Max"] + u[v + "Min"]) / 2), e.x === y.x && e.y === y.y ? (k && (y[v] = p ? o(u[v + "Max"], g[v + "Max"]) + 1 : s(u[v + "Min"], g[v + "Min"]) - 1), k = !k) : k = !1), l = [{
            start: e,
            end: y
          }]) : (n = w(e, {
            x: r ? i.x : e.x,
            y: r ? e.y : i.y
          }, r), l = [{
            start: e,
            end: {
              x: n.x,
              y: n.y
            }
          }], n[r ? "x" : "y"] !== i[r ? "x" : "y"] && (p = P(n.obstacle, n, i, !r, S), h(n.obstacle, d.hardBounds), c = {
            x: r ? n.x : n.obstacle[p ? "xMax" : "xMin"] + (p ? 1 : -1),
            y: r ? n.obstacle[p ? "yMax" : "yMin"] + (p ? 1 : -1) : n.y
          }, r = !r, l = l.concat(t({
            x: n.x,
            y: n.y
          }, c, r)))), l = l.concat(t(l[l.length - 1].end, i, !r));
        }(t, e, c)).concat(u.reverse())),
        obstacles: y
      };
    }
    return d.requiresObstacles = !0, c.requiresObstacles = !0, {
      fastAvoid: c,
      straight: function (t, e) {
        return {
          path: [["M", t.x, t.y], ["L", e.x, e.y]],
          obstacles: [{
            start: t,
            end: e
          }]
        };
      },
      simpleConnect: d
    };
  }), i(e, "Gantt/ConnectorsDefaults.js", [], function () {
    return {
      connectors: {
        type: "straight",
        radius: 0,
        lineWidth: 1,
        marker: {
          enabled: !1,
          align: "center",
          verticalAlign: "middle",
          inside: !1,
          lineWidth: 1
        },
        startMarker: {
          symbol: "diamond"
        },
        endMarker: {
          symbol: "arrow-filled"
        }
      }
    };
  }), i(e, "Gantt/PathfinderComposition.js", [e["Gantt/ConnectorsDefaults.js"], e["Core/Defaults.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s;
    let {
        setOptions: o
      } = e,
      {
        defined: r,
        error: n,
        merge: a
      } = i;
    function l(t) {
      let e = t.shapeArgs;
      if (e) return {
        xMin: e.x || 0,
        xMax: (e.x || 0) + (e.width || 0),
        yMin: e.y || 0,
        yMax: (e.y || 0) + (e.height || 0)
      };
      let i = t.graphic && t.graphic.getBBox();
      return i ? {
        xMin: t.plotX - i.width / 2,
        xMax: t.plotX + i.width / 2,
        yMin: t.plotY - i.height / 2,
        yMax: t.plotY + i.height / 2
      } : null;
    }
    return function (e) {
      function i(t) {
        let e, i;
        let s = l(this);
        switch (t.align) {
          case "right":
            e = "xMax";
            break;
          case "left":
            e = "xMin";
        }
        switch (t.verticalAlign) {
          case "top":
            i = "yMin";
            break;
          case "bottom":
            i = "yMax";
        }
        return {
          x: e ? s[e] : (s.xMin + s.xMax) / 2,
          y: i ? s[i] : (s.yMin + s.yMax) / 2
        };
      }
      function s(t, e) {
        let i;
        return !r(e) && (i = l(this)) && (e = {
          x: (i.xMin + i.xMax) / 2,
          y: (i.yMin + i.yMax) / 2
        }), Math.atan2(e.y - t.y, t.x - e.x);
      }
      function h(t, e, i) {
        let s = 2 * Math.PI,
          o = l(this),
          r = o.xMax - o.xMin,
          n = o.yMax - o.yMin,
          a = Math.atan2(n, r),
          h = r / 2,
          d = n / 2,
          c = o.xMin + h,
          p = o.yMin + d,
          u = {
            x: c,
            y: p
          },
          g = t,
          x = 1,
          f = !1,
          m = 1,
          b = 1;
        for (; g < -Math.PI;) g += s;
        for (; g > Math.PI;) g -= s;
        return x = Math.tan(g), g > -a && g <= a ? (b = -1, f = !0) : g > a && g <= Math.PI - a ? b = -1 : g > Math.PI - a || g <= -(Math.PI - a) ? (m = -1, f = !0) : m = -1, f ? (u.x += m * h, u.y += b * h * x) : (u.x += n / (2 * x) * m, u.y += b * d), i.x !== c && (u.x = i.x), i.y !== p && (u.y = i.y), {
          x: u.x + e * Math.cos(g),
          y: u.y - e * Math.sin(g)
        };
      }
      e.compose = function (e, r, l) {
        let d = l.prototype;
        d.getPathfinderAnchorPoint || (e.prototype.callbacks.push(function (t) {
          !1 !== t.options.connectors.enabled && ((t.options.pathfinder || t.series.reduce(function (t, e) {
            return e.options && a(!0, e.options.connectors = e.options.connectors || {}, e.options.pathfinder), t || e.options && e.options.pathfinder;
          }, !1)) && (a(!0, t.options.connectors = t.options.connectors || {}, t.options.pathfinder), n('WARNING: Pathfinder options have been renamed. Use "chart.connectors" or "series.connectors" instead.')), this.pathfinder = new r(this), this.pathfinder.update(!0));
        }), d.getMarkerVector = h, d.getPathfinderAnchorPoint = i, d.getRadiansToVector = s, o(t));
      };
    }(s || (s = {})), s;
  }), i(e, "Gantt/Pathfinder.js", [e["Gantt/Connection.js"], e["Gantt/PathfinderAlgorithms.js"], e["Gantt/PathfinderComposition.js"], e["Core/Series/Point.js"], e["Core/Utilities.js"]], function (t, e, i, s, o) {
    let {
        addEvent: r,
        defined: n,
        pick: a,
        splat: l
      } = o,
      h = Math.max,
      d = Math.min;
    class c {
      static compose(t, e) {
        i.compose(t, c, e);
      }
      constructor(t) {
        this.init(t);
      }
      init(t) {
        this.chart = t, this.connections = [], r(t, "redraw", function () {
          this.pathfinder.update();
        });
      }
      update(e) {
        let i = this.chart,
          o = this,
          r = o.connections;
        o.connections = [], i.series.forEach(function (e) {
          e.visible && !e.options.isInternal && e.points.forEach(function (e) {
            let r;
            let n = e.options;
            n && n.dependency && (n.connect = n.dependency);
            let a = e.options?.connect && l(e.options.connect);
            e.visible && !1 !== e.isInside && a && a.forEach(function (n) {
              (r = i.get("string" == typeof n ? n : n.to)) instanceof s && r.series.visible && r.visible && !1 !== r.isInside && o.connections.push(new t(e, r, "string" == typeof n ? {} : n));
            });
          });
        });
        for (let t = 0, e, i, s = r.length, n = o.connections.length; t < s; ++t) {
          i = !1;
          let s = r[t];
          for (e = 0; e < n; ++e) {
            let t = o.connections[e];
            if ((s.options && s.options.type) === (t.options && t.options.type) && s.fromPoint === t.fromPoint && s.toPoint === t.toPoint) {
              t.graphics = s.graphics, i = !0;
              break;
            }
          }
          i || s.destroy();
        }
        delete this.chartObstacles, delete this.lineObstacles, o.renderConnections(e);
      }
      renderConnections(t) {
        t ? this.chart.series.forEach(function (t) {
          let e = function () {
            let e = t.chart.pathfinder;
            (e && e.connections || []).forEach(function (e) {
              e.fromPoint && e.fromPoint.series === t && e.render();
            }), t.pathfinderRemoveRenderEvent && (t.pathfinderRemoveRenderEvent(), delete t.pathfinderRemoveRenderEvent);
          };
          !1 === t.options.animation ? e() : t.pathfinderRemoveRenderEvent = r(t, "afterAnimate", e);
        }) : this.connections.forEach(function (t) {
          t.render();
        });
      }
      getChartObstacles(t) {
        let e = this.chart.series,
          i = a(t.algorithmMargin, 0),
          s = [],
          o;
        for (let t = 0, o = e.length; t < o; ++t) if (e[t].visible && !e[t].options.isInternal) for (let o = 0, r = e[t].points.length, n, a; o < r; ++o) (a = e[t].points[o]).visible && (n = function (t) {
          let e = t.shapeArgs;
          if (e) return {
            xMin: e.x || 0,
            xMax: (e.x || 0) + (e.width || 0),
            yMin: e.y || 0,
            yMax: (e.y || 0) + (e.height || 0)
          };
          let i = t.graphic && t.graphic.getBBox();
          return i ? {
            xMin: t.plotX - i.width / 2,
            xMax: t.plotX + i.width / 2,
            yMin: t.plotY - i.height / 2,
            yMax: t.plotY + i.height / 2
          } : null;
        }(a)) && s.push({
          xMin: n.xMin - i,
          xMax: n.xMax + i,
          yMin: n.yMin - i,
          yMax: n.yMax + i
        });
        return s = s.sort(function (t, e) {
          return t.xMin - e.xMin;
        }), n(t.algorithmMargin) || (o = t.algorithmMargin = function (t) {
          let e;
          let i = t.length,
            s = [];
          for (let o = 0; o < i; ++o) for (let r = o + 1; r < i; ++r) (e = function t(e, i, s) {
            let o = a(s, 10),
              r = e.yMax + o > i.yMin - o && e.yMin - o < i.yMax + o,
              n = e.xMax + o > i.xMin - o && e.xMin - o < i.xMax + o,
              l = r ? e.xMin > i.xMax ? e.xMin - i.xMax : i.xMin - e.xMax : 1 / 0,
              h = n ? e.yMin > i.yMax ? e.yMin - i.yMax : i.yMin - e.yMax : 1 / 0;
            return n && r ? o ? t(e, i, Math.floor(o / 2)) : 1 / 0 : d(l, h);
          }(t[o], t[r])) < 80 && s.push(e);
          return s.push(80), h(Math.floor(s.sort(function (t, e) {
            return t - e;
          })[Math.floor(s.length / 10)] / 2 - 1), 1);
        }(s), s.forEach(function (t) {
          t.xMin -= o, t.xMax += o, t.yMin -= o, t.yMax += o;
        })), s;
      }
      getObstacleMetrics(t) {
        let e = 0,
          i = 0,
          s,
          o,
          r = t.length;
        for (; r--;) s = t[r].xMax - t[r].xMin, o = t[r].yMax - t[r].yMin, e < s && (e = s), i < o && (i = o);
        return {
          maxHeight: i,
          maxWidth: e
        };
      }
      getAlgorithmStartDirection(t) {
        let e = "left" !== t.align && "right" !== t.align,
          i = "top" !== t.verticalAlign && "bottom" !== t.verticalAlign;
        return e ? !!i && void 0 : !!i || void 0;
      }
    }
    return c.prototype.algorithms = e, c;
  }), i(e, "masters/modules/pathfinder.src.js", [e["Core/Globals.js"], e["Gantt/Pathfinder.js"], e["Extensions/ArrowSymbols.js"]], function (t, e, i) {
    return t.Pathfinder = t.Pathfinder || e, i.compose(t.SVGRenderer), t.Pathfinder.compose(t.Chart, t.Point), t;
  }), i(e, "Extensions/StaticScale.js", [e["Core/Utilities.js"]], function (t) {
    let {
      addEvent: e,
      defined: i,
      isNumber: s,
      pick: o
    } = t;
    function r() {
      let t = this.chart.options.chart;
      !this.horiz && s(this.options.staticScale) && (!t.height || t.scrollablePlotArea && t.scrollablePlotArea.minHeight) && (this.staticScale = this.options.staticScale);
    }
    function n() {
      if ("adjustHeight" !== this.redrawTrigger) {
        for (let t of this.axes || []) {
          let e = t.chart,
            s = !!e.initiatedScale && e.options.animation,
            r = t.options.staticScale;
          if (t.staticScale && i(t.min)) {
            let i = o(t.brokenAxis && t.brokenAxis.unitLength, t.max + t.tickInterval - t.min) * r,
              n = (i = Math.max(i, r)) - e.plotHeight;
            !e.scrollablePixelsY && Math.abs(n) >= 1 && (e.plotHeight = i, e.redrawTrigger = "adjustHeight", e.setSize(void 0, e.chartHeight + n, s)), t.series.forEach(function (t) {
              let i = t.sharedClipKey && e.sharedClips[t.sharedClipKey];
              i && i.attr(e.inverted ? {
                width: e.plotHeight
              } : {
                height: e.plotHeight
              });
            });
          }
        }
        this.initiatedScale = !0;
      }
      this.redrawTrigger = null;
    }
    return {
      compose: function (t, i) {
        let s = i.prototype;
        s.adjustHeight || (e(t, "afterSetOptions", r), s.adjustHeight = n, e(i, "render", s.adjustHeight));
      }
    };
  }), i(e, "masters/modules/static-scale.src.js", [e["Core/Globals.js"], e["Extensions/StaticScale.js"]], function (t, e) {
    return e.compose(t.Axis, t.Chart), t;
  }), i(e, "Series/XRange/XRangeSeriesDefaults.js", [e["Core/Utilities.js"]], function (t) {
    let {
      correctFloat: e,
      isNumber: i,
      isObject: s
    } = t;
    return {
      colorByPoint: !0,
      dataLabels: {
        formatter: function () {
          let t = this.point.partialFill;
          if (s(t) && (t = t.amount), i(t) && t > 0) return e(100 * t) + "%";
        },
        inside: !0,
        verticalAlign: "middle",
        style: {
          whiteSpace: "nowrap"
        }
      },
      tooltip: {
        headerFormat: '<span style="font-size: 0.8em">{point.x} - {point.x2}</span><br/>',
        pointFormat: '<span style="color:{point.color}">●</span> {series.name}: <b>{point.yCategory}</b><br/>'
      },
      borderRadius: 3,
      pointRange: 0
    };
  }), i(e, "Series/XRange/XRangePoint.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        column: {
          prototype: {
            pointClass: i
          }
        }
      } = t.seriesTypes,
      {
        extend: s
      } = e;
    class o extends i {
      static getColorByCategory(t, e) {
        let i = t.options.colors || t.chart.options.colors,
          s = i ? i.length : t.chart.options.chart.colorCount,
          o = e.y % s,
          r = i && i[o];
        return {
          colorIndex: o,
          color: r
        };
      }
      resolveColor() {
        let t = this.series;
        if (t.options.colorByPoint && !this.options.color) {
          let e = o.getColorByCategory(t, this);
          t.chart.styledMode || (this.color = e.color), this.options.colorIndex || (this.colorIndex = e.colorIndex);
        } else this.color = this.options.color || t.color;
      }
      constructor(t, e) {
        super(t, e), this.y || (this.y = 0);
      }
      setState() {
        super.setState.apply(this, arguments), this.series.drawPoint(this, this.series.getAnimationVerb());
      }
      getLabelConfig() {
        let t = super.getLabelConfig.call(this),
          e = this.series.yAxis.categories;
        return t.x2 = this.x2, t.yCategory = this.yCategory = e && e[this.y], t.key = this.category || this.name, t;
      }
      isValid() {
        return "number" == typeof this.x && "number" == typeof this.x2;
      }
    }
    return s(o.prototype, {
      ttBelow: !1,
      tooltipDateKeys: ["x", "x2"]
    }), o;
  }), i(e, "Series/XRange/XRangeSeries.js", [e["Core/Globals.js"], e["Core/Color/Color.js"], e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"], e["Series/XRange/XRangeSeriesDefaults.js"], e["Series/XRange/XRangePoint.js"]], function (t, e, i, s, o, r) {
    let {
        composed: n,
        noop: a
      } = t,
      {
        parse: l
      } = e,
      {
        column: h
      } = i.seriesTypes,
      {
        addEvent: d,
        clamp: c,
        crisp: p,
        defined: u,
        extend: g,
        find: x,
        isNumber: f,
        isObject: m,
        merge: b,
        pick: y,
        pushUnique: v,
        relativeLength: M
      } = s;
    function k() {
      let t, e;
      if (this.isXAxis) {
        for (let i of (t = y(this.dataMax, -Number.MAX_VALUE), this.series)) if (i.x2Data) for (let s of i.x2Data) s && s > t && (t = s, e = !0);
        e && (this.dataMax = t);
      }
    }
    class A extends h {
      static compose(t) {
        v(n, "Series.XRange") && d(t, "afterGetSeriesExtremes", k);
      }
      init() {
        super.init.apply(this, arguments), this.options.stacking = void 0;
      }
      getColumnMetrics() {
        let t = () => {
          for (let t of this.chart.series) {
            let e = t.xAxis;
            t.xAxis = t.yAxis, t.yAxis = e;
          }
        };
        t();
        let e = super.getColumnMetrics();
        return t(), e;
      }
      cropData(t, e, i, s) {
        let o = super.cropData(this.x2Data, e, i, s);
        return o.xData = t.slice(o.start, o.end), o;
      }
      findPointIndex(t) {
        let e;
        let {
            cropStart: i,
            points: s
          } = this,
          {
            id: o
          } = t;
        if (o) {
          let t = x(s, t => t.id === o);
          e = t ? t.index : void 0;
        }
        if (void 0 === e) {
          let i = x(s, e => e.x === t.x && e.x2 === t.x2 && !e.touched);
          e = i ? i.index : void 0;
        }
        return this.cropped && f(e) && f(i) && e >= i && (e -= i), e;
      }
      alignDataLabel(t) {
        let e = t.plotX;
        t.plotX = y(t.dlBox && t.dlBox.centerX, t.plotX), t.dataLabel && t.shapeArgs?.width && t.dataLabel.css({
          width: `${t.shapeArgs.width}px`
        }), super.alignDataLabel.apply(this, arguments), t.plotX = e;
      }
      translatePoint(t) {
        let e = this.xAxis,
          i = this.yAxis,
          s = this.columnMetrics,
          o = this.options,
          r = o.minPointLength || 0,
          n = (t.shapeArgs && t.shapeArgs.width || 0) / 2,
          a = this.pointXOffset = s.offset,
          l = y(t.x2, t.x + (t.len || 0)),
          h = o.borderRadius,
          d = this.chart.plotTop,
          g = this.chart.plotLeft,
          x = t.plotX,
          v = e.translate(l, 0, 0, 0, 1),
          k = Math.abs(v - x),
          A = this.chart.inverted,
          S = y(o.borderWidth, 1),
          C,
          w,
          P = s.offset,
          O = Math.round(s.width),
          B,
          E,
          T,
          D;
        r && ((C = r - k) < 0 && (C = 0), x -= C / 2, v += C / 2), x = Math.max(x, -10), v = c(v, -10, e.len + 10), u(t.options.pointWidth) && (P -= (Math.ceil(t.options.pointWidth) - O) / 2, O = Math.ceil(t.options.pointWidth)), o.pointPlacement && f(t.plotY) && i.categories && (t.plotY = i.translate(t.y, 0, 1, 0, 1, o.pointPlacement));
        let G = p(Math.min(x, v), S),
          R = p(Math.max(x, v), S) - G,
          I = Math.min(M("object" == typeof h ? h.radius : h || 0, O), Math.min(R, O) / 2),
          j = {
            x: G,
            y: p((t.plotY || 0) + P, S),
            width: R,
            height: O,
            r: I
          };
        t.shapeArgs = j, A ? t.tooltipPos[1] += a + n : t.tooltipPos[0] -= n + a - j.width / 2, E = (B = j.x) + j.width, B < 0 || E > e.len ? (B = c(B, 0, e.len), T = (E = c(E, 0, e.len)) - B, t.dlBox = b(j, {
          x: B,
          width: E - B,
          centerX: T ? T / 2 : null
        })) : t.dlBox = null;
        let L = t.tooltipPos,
          z = A ? 1 : 0,
          N = A ? 0 : 1,
          U = this.columnMetrics ? this.columnMetrics.offset : -s.width / 2;
        A ? L[z] += j.width / 2 : L[z] = c(L[z] + (e.reversed ? -1 : 0) * j.width, e.left - g, e.left + e.len - g - 1), L[N] = c(L[N] + (A ? -1 : 1) * U, i.top - d, i.top + i.len - d - 1), (w = t.partialFill) && (m(w) && (w = w.amount), f(w) || (w = 0), t.partShapeArgs = b(j), D = Math.max(Math.round(k * w + t.plotX - x), 0), t.clipRectArgs = {
          x: e.reversed ? j.x + k - D : j.x,
          y: j.y,
          width: D,
          height: j.height
        });
      }
      translate() {
        for (let t of (super.translate.apply(this, arguments), this.points)) this.translatePoint(t);
      }
      drawPoint(t, e) {
        let i = this.options,
          s = this.chart.renderer,
          o = t.shapeType,
          r = t.shapeArgs,
          n = t.partShapeArgs,
          a = t.clipRectArgs,
          h = t.state,
          d = i.states[h || "normal"] || {},
          c = void 0 === h ? "attr" : e,
          p = this.pointAttribs(t, h),
          u = y(this.chart.options.chart.animation, d.animation),
          g = t.graphic,
          x = t.partialFill;
        if (t.isNull || !1 === t.visible) g && (t.graphic = g.destroy());else if (g ? g.rect[e](r) : (t.graphic = g = s.g("point").addClass(t.getClassName()).add(t.group || this.group), g.rect = s[o](b(r)).addClass(t.getClassName()).addClass("highcharts-partfill-original").add(g)), n && (g.partRect ? (g.partRect[e](b(n)), g.partialClipRect[e](b(a))) : (g.partialClipRect = s.clipRect(a.x, a.y, a.width, a.height), g.partRect = s[o](n).addClass("highcharts-partfill-overlay").add(g).clip(g.partialClipRect))), !this.chart.styledMode && (g.rect[e](p, u).shadow(i.shadow), n)) {
          m(x) || (x = {}), m(i.partialFill) && (x = b(i.partialFill, x));
          let e = x.fill || l(p.fill).brighten(-.3).get() || l(t.color || this.color).brighten(-.3).get();
          p.fill = e, g.partRect[c](p, u).shadow(i.shadow);
        }
      }
      drawPoints() {
        let t = this.getAnimationVerb();
        for (let e of this.points) this.drawPoint(e, t);
      }
      getAnimationVerb() {
        return this.chart.pointCount < (this.options.animationLimit || 250) ? "animate" : "attr";
      }
      isPointInside(t) {
        let e = t.shapeArgs,
          i = t.plotX,
          s = t.plotY;
        return e ? void 0 !== i && void 0 !== s && s >= 0 && s <= this.yAxis.len && (e.x || 0) + (e.width || 0) >= 0 && i <= this.xAxis.len : super.isPointInside.apply(this, arguments);
      }
    }
    return A.defaultOptions = b(h.defaultOptions, o), g(A.prototype, {
      pointClass: r,
      pointArrayMap: ["x2", "y"],
      getExtremesFromAll: !0,
      parallelArrays: ["x", "x2", "y"],
      requireSorting: !1,
      type: "xrange",
      animate: i.series.prototype.animate,
      autoIncrement: a,
      buildKDTree: a
    }), i.registerSeriesType("xrange", A), A;
  }), i(e, "masters/modules/xrange.src.js", [e["Core/Globals.js"], e["Series/XRange/XRangeSeries.js"]], function (t, e) {
    return e.compose(t.Axis), t;
  }), i(e, "Series/Gantt/GanttPoint.js", [e["Core/Series/SeriesRegistry.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
        xrange: {
          prototype: {
            pointClass: i
          }
        }
      } = t.seriesTypes,
      {
        pick: s
      } = e;
    class o extends i {
      static setGanttPointAliases(t) {
        function e(e, i) {
          void 0 !== i && (t[e] = i);
        }
        e("x", s(t.start, t.x)), e("x2", s(t.end, t.x2)), e("partialFill", s(t.completed, t.partialFill));
      }
      applyOptions(t, e) {
        let i = super.applyOptions(t, e);
        return o.setGanttPointAliases(i), i;
      }
      isValid() {
        return ("number" == typeof this.start || "number" == typeof this.x) && ("number" == typeof this.end || "number" == typeof this.x2 || this.milestone);
      }
    }
    return o;
  }), i(e, "Series/Gantt/GanttSeriesDefaults.js", [e["Core/Utilities.js"]], function (t) {
    let {
      isNumber: e
    } = t;
    return {
      grouping: !1,
      dataLabels: {
        enabled: !0
      },
      tooltip: {
        headerFormat: '<span style="font-size: 0.8em">{series.name}</span><br/>',
        pointFormat: null,
        pointFormatter: function () {
          let t = this.series,
            i = t.xAxis,
            s = t.tooltipOptions.dateTimeLabelFormats,
            o = i.options.startOfWeek,
            r = t.tooltipOptions,
            n = this.options.milestone,
            a = r.xDateFormat,
            l = "<b>" + (this.name || this.yCategory) + "</b>";
          if (r.pointFormat) return this.tooltipFormatter(r.pointFormat);
          !a && e(this.start) && (a = t.chart.time.getDateFormat(i.closestPointRange, this.start, o, s || {}));
          let h = t.chart.time.dateFormat(a, this.start),
            d = t.chart.time.dateFormat(a, this.end);
          return l += "<br/>", n ? l += h + "<br/>" : l += "Start: " + h + "<br/>End: " + d + "<br/>", l;
        }
      },
      connectors: {
        type: "simpleConnect",
        animation: {
          reversed: !0
        },
        radius: 0,
        startMarker: {
          enabled: !0,
          symbol: "arrow-filled",
          radius: 4,
          fill: "#fa0",
          align: "left"
        },
        endMarker: {
          enabled: !1,
          align: "right"
        }
      }
    };
  }), i(e, "Core/Axis/BrokenAxis.js", [e["Core/Axis/Stacking/StackItem.js"], e["Core/Utilities.js"]], function (t, e) {
    var i;
    let {
      addEvent: s,
      find: o,
      fireEvent: r,
      isArray: n,
      isNumber: a,
      pick: l
    } = e;
    return function (e) {
      function i() {
        void 0 !== this.brokenAxis && this.brokenAxis.setBreaks(this.options.breaks, !1);
      }
      function h() {
        this.brokenAxis?.hasBreaks && (this.options.ordinal = !1);
      }
      function d() {
        let t = this.brokenAxis;
        if (t?.hasBreaks) {
          let e = this.tickPositions,
            i = this.tickPositions.info,
            s = [];
          for (let i = 0; i < e.length; i++) t.isInAnyBreak(e[i]) || s.push(e[i]);
          this.tickPositions = s, this.tickPositions.info = i;
        }
      }
      function c() {
        this.brokenAxis || (this.brokenAxis = new f(this));
      }
      function p() {
        let {
          isDirty: t,
          options: {
            connectNulls: e
          },
          points: i,
          xAxis: s,
          yAxis: o
        } = this;
        if (t) {
          let t = i.length;
          for (; t--;) {
            let r = i[t],
              n = !(null === r.y && !1 === e) && (s?.brokenAxis?.isInAnyBreak(r.x, !0) || o?.brokenAxis?.isInAnyBreak(r.y, !0));
            r.visible = !n && !1 !== r.options.visible;
          }
        }
      }
      function u() {
        this.drawBreaks(this.xAxis, ["x"]), this.drawBreaks(this.yAxis, l(this.pointArrayMap, ["y"]));
      }
      function g(t, e) {
        let i, s, o;
        let n = this,
          h = n.points;
        if (t?.brokenAxis?.hasBreaks) {
          let d = t.brokenAxis;
          e.forEach(function (e) {
            i = d?.breakArray || [], s = t.isXAxis ? t.min : l(n.options.threshold, t.min);
            let c = t?.options?.breaks?.filter(function (t) {
              let e = !0;
              for (let s = 0; s < i.length; s++) {
                let o = i[s];
                if (o.from === t.from && o.to === t.to) {
                  e = !1;
                  break;
                }
              }
              return e;
            });
            h.forEach(function (n) {
              o = l(n["stack" + e.toUpperCase()], n[e]), i.forEach(function (e) {
                if (a(s) && a(o)) {
                  let i = "";
                  s < e.from && o > e.to || s > e.from && o < e.from ? i = "pointBreak" : (s < e.from && o > e.from && o < e.to || s > e.from && o > e.to && o < e.from) && (i = "pointInBreak"), i && r(t, i, {
                    point: n,
                    brk: e
                  });
                }
              }), c?.forEach(function (e) {
                r(t, "pointOutsideOfBreak", {
                  point: n,
                  brk: e
                });
              });
            });
          });
        }
      }
      function x() {
        let e = this.currentDataGrouping,
          i = e?.gapSize,
          s = this.points.slice(),
          o = this.yAxis,
          r = this.options.gapSize,
          n = s.length - 1;
        if (r && n > 0) {
          let e, a;
          for ("value" !== this.options.gapUnit && (r *= this.basePointRange), i && i > r && i >= this.basePointRange && (r = i); n--;) if (a && !1 !== a.visible || (a = s[n + 1]), e = s[n], !1 !== a.visible && !1 !== e.visible) {
            if (a.x - e.x > r) {
              let i = (e.x + a.x) / 2;
              s.splice(n + 1, 0, {
                isNull: !0,
                x: i
              }), o.stacking && this.options.stacking && ((o.stacking.stacks[this.stackKey][i] = new t(o, o.options.stackLabels, !1, i, this.stack)).total = 0);
            }
            a = e;
          }
        }
        return this.getGraphPath(s);
      }
      e.compose = function (t, e) {
        if (!t.keepProps.includes("brokenAxis")) {
          t.keepProps.push("brokenAxis"), s(t, "init", c), s(t, "afterInit", i), s(t, "afterSetTickPositions", d), s(t, "afterSetOptions", h);
          let o = e.prototype;
          o.drawBreaks = g, o.gappedPath = x, s(e, "afterGeneratePoints", p), s(e, "afterRender", u);
        }
        return t;
      };
      class f {
        static isInBreak(t, e) {
          let i = t.repeat || 1 / 0,
            s = t.from,
            o = t.to - t.from,
            r = e >= s ? (e - s) % i : i - (s - e) % i;
          return t.inclusive ? r <= o : r < o && 0 !== r;
        }
        static lin2Val(t) {
          let e = this.brokenAxis,
            i = e && e.breakArray;
          if (!i || !a(t)) return t;
          let s = t,
            o,
            r;
          for (r = 0; r < i.length && !((o = i[r]).from >= s); r++) o.to < s ? s += o.len : f.isInBreak(o, s) && (s += o.len);
          return s;
        }
        static val2Lin(t) {
          let e = this.brokenAxis,
            i = e && e.breakArray;
          if (!i || !a(t)) return t;
          let s = t,
            o,
            r;
          for (r = 0; r < i.length; r++) if ((o = i[r]).to <= t) s -= o.len;else if (o.from >= t) break;else if (f.isInBreak(o, t)) {
            s -= t - o.from;
            break;
          }
          return s;
        }
        constructor(t) {
          this.hasBreaks = !1, this.axis = t;
        }
        findBreakAt(t, e) {
          return o(e, function (e) {
            return e.from < t && t < e.to;
          });
        }
        isInAnyBreak(t, e) {
          let i = this.axis,
            s = i.options.breaks || [],
            o = s.length,
            r,
            n,
            h;
          if (o && a(t)) {
            for (; o--;) f.isInBreak(s[o], t) && (r = !0, n || (n = l(s[o].showPoints, !i.isXAxis)));
            h = r && e ? r && !n : r;
          }
          return h;
        }
        setBreaks(t, e) {
          let i = this,
            s = i.axis,
            o = n(t) && !!t.length && !!Object.keys(t[0]).length;
          s.isDirty = i.hasBreaks !== o, i.hasBreaks = o, t !== s.options.breaks && (s.options.breaks = s.userOptions.breaks = t), s.forceRedraw = !0, s.series.forEach(function (t) {
            t.isDirty = !0;
          }), o || s.val2lin !== f.val2Lin || (delete s.val2lin, delete s.lin2val), o && (s.userOptions.ordinal = !1, s.lin2val = f.lin2Val, s.val2lin = f.val2Lin, s.setExtremes = function (t, e, o, r, n) {
            if (i.hasBreaks) {
              let s;
              let o = this.options.breaks || [];
              for (; s = i.findBreakAt(t, o);) t = s.to;
              for (; s = i.findBreakAt(e, o);) e = s.from;
              e < t && (e = t);
            }
            s.constructor.prototype.setExtremes.call(this, t, e, o, r, n);
          }, s.setAxisTranslation = function () {
            if (s.constructor.prototype.setAxisTranslation.call(this), i.unitLength = void 0, i.hasBreaks) {
              let t = s.options.breaks || [],
                e = [],
                o = [],
                n = l(s.pointRangePadding, 0),
                h = 0,
                d,
                c,
                p = s.userMin || s.min,
                u = s.userMax || s.max,
                g,
                x;
              t.forEach(function (t) {
                c = t.repeat || 1 / 0, a(p) && a(u) && (f.isInBreak(t, p) && (p += t.to % c - p % c), f.isInBreak(t, u) && (u -= u % c - t.from % c));
              }), t.forEach(function (t) {
                if (g = t.from, c = t.repeat || 1 / 0, a(p) && a(u)) {
                  for (; g - c > p;) g -= c;
                  for (; g < p;) g += c;
                  for (x = g; x < u; x += c) e.push({
                    value: x,
                    move: "in"
                  }), e.push({
                    value: x + t.to - t.from,
                    move: "out",
                    size: t.breakSize
                  });
                }
              }), e.sort(function (t, e) {
                return t.value === e.value ? ("in" === t.move ? 0 : 1) - ("in" === e.move ? 0 : 1) : t.value - e.value;
              }), d = 0, g = p, e.forEach(function (t) {
                1 === (d += "in" === t.move ? 1 : -1) && "in" === t.move && (g = t.value), 0 === d && a(g) && (o.push({
                  from: g,
                  to: t.value,
                  len: t.value - g - (t.size || 0)
                }), h += t.value - g - (t.size || 0));
              }), i.breakArray = o, a(p) && a(u) && a(s.min) && (i.unitLength = u - p - h + n, r(s, "afterBreaks"), s.staticScale ? s.transA = s.staticScale : i.unitLength && (s.transA *= (u - s.min + n) / i.unitLength), n && (s.minPixelPadding = s.transA * (s.minPointOffset || 0)), s.min = p, s.max = u);
            }
          }), l(e, !0) && s.chart.redraw();
        }
      }
      e.Additions = f;
    }(i || (i = {})), i;
  }), i(e, "Core/Axis/GridAxis.js", [e["Core/Axis/Axis.js"], e["Core/Globals.js"], e["Core/Utilities.js"]], function (t, e, i) {
    var s, o;
    let {
        dateFormats: r
      } = e,
      {
        addEvent: n,
        defined: a,
        erase: l,
        find: h,
        isArray: d,
        isNumber: c,
        merge: p,
        pick: u,
        timeUnits: g,
        wrap: x
      } = i;
    function f(t) {
      return i.isObject(t, !0);
    }
    function m(t, e) {
      let i = {
        width: 0,
        height: 0
      };
      if (e.forEach(function (e) {
        let s = t[e],
          o = 0,
          r = 0,
          n;
        f(s) && (o = (n = f(s.label) ? s.label : {}).getBBox ? n.getBBox().height : 0, n.textStr && !c(n.textPxLength) && (n.textPxLength = n.getBBox().width), r = c(n.textPxLength) ? Math.round(n.textPxLength) : 0, n.textStr && (r = Math.round(n.getBBox().width)), i.height = Math.max(o, i.height), i.width = Math.max(r, i.width));
      }), "treegrid" === this.type && this.treeGrid && this.treeGrid.mapOfPosToGridNode) {
        let t = this.treeGrid.mapOfPosToGridNode[-1].height || 0;
        i.width += this.options.labels.indentation * (t - 1);
      }
      return i;
    }
    function b(t) {
      let {
          grid: e
        } = this,
        i = 3 === this.side;
      if (i || t.apply(this), !e?.isColumn) {
        let t = e?.columns || [];
        i && (t = t.slice().reverse()), t.forEach(t => {
          t.getOffset();
        });
      }
      i && t.apply(this);
    }
    function y(t) {
      if (!0 === (this.options.grid || {}).enabled) {
        let {
            axisTitle: e,
            height: i,
            horiz: o,
            left: r,
            offset: n,
            opposite: a,
            options: l,
            top: h,
            width: d
          } = this,
          c = this.tickSize(),
          p = e && e.getBBox().width,
          g = l.title.x,
          x = l.title.y,
          f = u(l.title.margin, o ? 5 : 10),
          m = e ? this.chart.renderer.fontMetrics(e).f : 0,
          b = (o ? h + i : r) + (o ? 1 : -1) * (a ? -1 : 1) * (c ? c[0] / 2 : 0) + (this.side === s.bottom ? m : 0);
        t.titlePosition.x = o ? r - (p || 0) / 2 - f + g : b + (a ? d : 0) + n + g, t.titlePosition.y = o ? b - (a ? i : 0) + (a ? m : -m) / 2 + n + x : h - f + x;
      }
    }
    function v() {
      let {
        chart: e,
        options: {
          grid: i = {}
        },
        userOptions: s
      } = this;
      if (i.enabled && function (t) {
        let e = t.options;
        e.labels.align = u(e.labels.align, "center"), t.categories || (e.showLastLabel = !1), t.labelRotation = 0, e.labels.rotation = 0, e.minTickInterval = 1;
      }(this), i.columns) {
        let o = this.grid.columns = [],
          r = this.grid.columnIndex = 0;
        for (; ++r < i.columns.length;) {
          let n = p(s, i.columns[r], {
              isInternal: !0,
              linkedTo: 0,
              scrollbar: {
                enabled: !1
              }
            }, {
              grid: {
                columns: void 0
              }
            }),
            a = new t(this.chart, n, "yAxis");
          a.grid.isColumn = !0, a.grid.columnIndex = r, l(e.axes, a), l(e[this.coll] || [], a), o.push(a);
        }
      }
    }
    function M() {
      let {
        axisTitle: t,
        grid: e,
        options: i
      } = this;
      if (!0 === (i.grid || {}).enabled) {
        let o = this.min || 0,
          r = this.max || 0,
          n = this.ticks[this.tickPositions[0]];
        if (t && !this.chart.styledMode && n?.slotWidth && !this.options.title.style.width && t.css({
          width: `${n.slotWidth}px`
        }), this.maxLabelDimensions = this.getMaxLabelDimensions(this.ticks, this.tickPositions), this.rightWall && this.rightWall.destroy(), this.grid && this.grid.isOuterAxis() && this.axisLine) {
          let t = i.lineWidth;
          if (t) {
            let e = this.getLinePath(t),
              n = e[0],
              a = e[1],
              l = (this.tickSize("tick") || [1])[0] * (this.side === s.top || this.side === s.left ? -1 : 1);
            if ("M" === n[0] && "L" === a[0] && (this.horiz ? (n[2] += l, a[2] += l) : (n[1] += l, a[1] += l)), !this.horiz && this.chart.marginRight) {
              let t = ["L", this.left, n[2] || 0],
                e = [n, t],
                s = ["L", this.chart.chartWidth - this.chart.marginRight, this.toPixels(r + this.tickmarkOffset)],
                l = [["M", a[1] || 0, this.toPixels(r + this.tickmarkOffset)], s];
              this.grid.upperBorder || o % 1 == 0 || (this.grid.upperBorder = this.grid.renderBorder(e)), this.grid.upperBorder && (this.grid.upperBorder.attr({
                stroke: i.lineColor,
                "stroke-width": i.lineWidth
              }), this.grid.upperBorder.animate({
                d: e
              })), this.grid.lowerBorder || r % 1 == 0 || (this.grid.lowerBorder = this.grid.renderBorder(l)), this.grid.lowerBorder && (this.grid.lowerBorder.attr({
                stroke: i.lineColor,
                "stroke-width": i.lineWidth
              }), this.grid.lowerBorder.animate({
                d: l
              }));
            }
            this.grid.axisLineExtra ? (this.grid.axisLineExtra.attr({
              stroke: i.lineColor,
              "stroke-width": i.lineWidth
            }), this.grid.axisLineExtra.animate({
              d: e
            })) : this.grid.axisLineExtra = this.grid.renderBorder(e), this.axisLine[this.showAxis ? "show" : "hide"]();
          }
        }
        if ((e && e.columns || []).forEach(t => t.render()), !this.horiz && this.chart.hasRendered && (this.scrollbar || this.linkedParent && this.linkedParent.scrollbar) && this.tickPositions.length) {
          let t, e;
          let i = this.tickmarkOffset,
            s = this.tickPositions[this.tickPositions.length - 1],
            n = this.tickPositions[0];
          for (; (t = this.hiddenLabels.pop()) && t.element;) t.show();
          for (; (e = this.hiddenMarks.pop()) && e.element;) e.show();
          (t = this.ticks[n].label) && (o - n > i ? this.hiddenLabels.push(t.hide()) : t.show()), (t = this.ticks[s].label) && (s - r > i ? this.hiddenLabels.push(t.hide()) : t.show());
          let a = this.ticks[s].mark;
          a && s - r < i && s - r > 0 && this.ticks[s].isLast && this.hiddenMarks.push(a.hide());
        }
      }
    }
    function k() {
      let t = this.tickPositions && this.tickPositions.info,
        e = this.options,
        i = e.grid || {},
        s = this.userOptions.labels || {};
      i.enabled && (this.horiz ? (this.series.forEach(t => {
        t.options.pointRange = 0;
      }), t && e.dateTimeLabelFormats && e.labels && !a(s.align) && (!1 === e.dateTimeLabelFormats[t.unitName].range || t.count > 1) && (e.labels.align = "left", a(s.x) || (e.labels.x = 3))) : "treegrid" !== this.type && this.grid && this.grid.columns && (this.minPointOffset = this.tickInterval));
    }
    function A(t) {
      let e;
      let i = this.options,
        s = t.userOptions,
        o = i && f(i.grid) ? i.grid : {};
      !0 === o.enabled && (e = p(!0, {
        className: "highcharts-grid-axis " + (s.className || ""),
        dateTimeLabelFormats: {
          hour: {
            list: ["%H:%M", "%H"]
          },
          day: {
            list: ["%A, %e. %B", "%a, %e. %b", "%E"]
          },
          week: {
            list: ["Week %W", "W%W"]
          },
          month: {
            list: ["%B", "%b", "%o"]
          }
        },
        grid: {
          borderWidth: 1
        },
        labels: {
          padding: 2,
          style: {
            fontSize: "0.9em"
          }
        },
        margin: 0,
        title: {
          text: null,
          reserveSpace: !1,
          rotation: 0,
          style: {
            textOverflow: "ellipsis"
          }
        },
        units: [["millisecond", [1, 10, 100]], ["second", [1, 10]], ["minute", [1, 5, 15]], ["hour", [1, 6]], ["day", [1]], ["week", [1]], ["month", [1]], ["year", null]]
      }, s), "xAxis" !== this.coll || (a(s.linkedTo) && !a(s.tickPixelInterval) && (e.tickPixelInterval = 350), !(!a(s.tickPixelInterval) && a(s.linkedTo)) || a(s.tickPositioner) || a(s.tickInterval) || a(s.units) || (e.tickPositioner = function (t, i) {
        let s = this.linkedParent && this.linkedParent.tickPositions && this.linkedParent.tickPositions.info;
        if (s) {
          let o = e.units || [],
            r,
            n = 1,
            a = "year";
          for (let t = 0; t < o.length; t++) {
            let e = o[t];
            if (e && e[0] === s.unitName) {
              r = t;
              break;
            }
          }
          let l = c(r) && o[r + 1];
          if (l) {
            a = l[0] || "year";
            let t = l[1];
            n = t && t[0] || 1;
          } else "year" === s.unitName && (n = 10 * s.count);
          let h = g[a];
          return this.tickInterval = h * n, this.chart.time.getTimeTicks({
            unitRange: h,
            count: n,
            unitName: a
          }, t, i, this.options.startOfWeek);
        }
      })), p(!0, this.options, e), this.horiz && (i.minPadding = u(s.minPadding, 0), i.maxPadding = u(s.maxPadding, 0)), c(i.grid.borderWidth) && (i.tickWidth = i.lineWidth = o.borderWidth));
    }
    function S(t) {
      let e = t.userOptions,
        i = e && e.grid || {},
        s = i.columns;
      i.enabled && s && p(!0, this.options, s[0]);
    }
    function C() {
      (this.grid.columns || []).forEach(t => t.setScale());
    }
    function w(t) {
      let {
        horiz: e,
        maxLabelDimensions: i,
        options: {
          grid: s = {}
        }
      } = this;
      if (s.enabled && i) {
        let o = 2 * this.options.labels.distance,
          r = e ? s.cellHeight || o + i.height : o + i.width;
        d(t.tickSize) ? t.tickSize[0] = r : t.tickSize = [r, 0];
      }
    }
    function P() {
      this.axes.forEach(t => {
        (t.grid && t.grid.columns || []).forEach(t => {
          t.setAxisSize(), t.setAxisTranslation();
        });
      });
    }
    function O(t) {
      let {
        grid: e
      } = this;
      (e.columns || []).forEach(e => e.destroy(t.keepEvents)), e.columns = void 0;
    }
    function B(t) {
      let e = t.userOptions || {},
        i = e.grid || {};
      i.enabled && a(i.borderColor) && (e.tickColor = e.lineColor = i.borderColor), this.grid || (this.grid = new R(this)), this.hiddenLabels = [], this.hiddenMarks = [];
    }
    function E(t) {
      let e = this.label,
        i = this.axis,
        o = i.reversed,
        r = i.chart,
        n = i.options.grid || {},
        a = i.options.labels,
        l = a.align,
        h = s[i.side],
        d = t.tickmarkOffset,
        p = i.tickPositions,
        u = this.pos - d,
        g = c(p[t.index + 1]) ? p[t.index + 1] - d : (i.max || 0) + d,
        x = i.tickSize("tick"),
        f = x ? x[0] : 0,
        m = x ? x[1] / 2 : 0;
      if (!0 === n.enabled) {
        let s, n, d, c;
        if ("top" === h ? n = (s = i.top + i.offset) - f : "bottom" === h ? s = (n = r.chartHeight - i.bottom + i.offset) + f : (s = i.top + i.len - (i.translate(o ? g : u) || 0), n = i.top + i.len - (i.translate(o ? u : g) || 0)), "right" === h ? c = (d = r.chartWidth - i.right + i.offset) + f : "left" === h ? d = (c = i.left + i.offset) - f : (d = Math.round(i.left + (i.translate(o ? g : u) || 0)) - m, c = Math.min(Math.round(i.left + (i.translate(o ? u : g) || 0)) - m, i.left + i.len)), this.slotWidth = c - d, t.pos.x = "left" === l ? d : "right" === l ? c : d + (c - d) / 2, t.pos.y = n + (s - n) / 2, e) {
          let i = r.renderer.fontMetrics(e),
            s = e.getBBox().height;
          if (a.useHTML) t.pos.y += i.b + -(s / 2);else {
            let e = Math.round(s / i.h);
            t.pos.y += (i.b - (i.h - i.f)) / 2 + -((e - 1) * i.h / 2);
          }
        }
        t.pos.x += i.horiz && a.x || 0;
      }
    }
    function T(t) {
      let {
        axis: i,
        value: s
      } = t;
      if (i.options.grid && i.options.grid.enabled) {
        let o;
        let r = i.tickPositions,
          n = (i.linkedParent || i).series[0],
          a = s === r[0],
          l = s === r[r.length - 1],
          d = n && h(n.options.data, function (t) {
            return t[i.isXAxis ? "x" : "y"] === s;
          });
        d && n.is("gantt") && (o = p(d), e.seriesTypes.gantt.prototype.pointClass.setGanttPointAliases(o)), t.isFirst = a, t.isLast = l, t.point = o;
      }
    }
    function D() {
      let t = this.options,
        e = t.grid || {},
        i = this.categories,
        s = this.tickPositions,
        o = s[0],
        r = s[1],
        n = s[s.length - 1],
        a = s[s.length - 2],
        l = this.linkedParent && this.linkedParent.min,
        h = this.linkedParent && this.linkedParent.max,
        d = l || this.min,
        p = h || this.max,
        u = this.tickInterval,
        g = c(d) && d >= o + u && d < r,
        x = c(d) && o < d && o + u > d,
        f = c(p) && n > p && n - u < p,
        m = c(p) && p <= n - u && p > a;
      !0 === e.enabled && !i && (this.isXAxis || this.isLinked) && ((x || g) && !t.startOnTick && (s[0] = d), (f || m) && !t.endOnTick && (s[s.length - 1] = p));
    }
    function G(t) {
      var e;
      let {
        options: {
          grid: i = {}
        }
      } = this;
      return !0 === i.enabled && this.categories ? this.tickInterval : t.apply(this, (e = arguments, Array.prototype.slice.call(e, 1)));
    }
    (o = s || (s = {}))[o.top = 0] = "top", o[o.right = 1] = "right", o[o.bottom = 2] = "bottom", o[o.left = 3] = "left";
    class R {
      constructor(t) {
        this.axis = t;
      }
      isOuterAxis() {
        let t = this.axis,
          e = t.chart,
          i = t.grid.columnIndex,
          s = t.linkedParent?.grid.columns || t.grid.columns || [],
          o = i ? t.linkedParent : t,
          r = -1,
          n = 0;
        return 3 === t.side && !e.inverted && s.length ? !t.linkedParent : ((e[t.coll] || []).forEach((e, i) => {
          e.side !== t.side || e.options.isInternal || (n = i, e !== o || (r = i));
        }), n === r && (!c(i) || s.length === i));
      }
      renderBorder(t) {
        let e = this.axis,
          i = e.chart.renderer,
          s = e.options,
          o = i.path(t).addClass("highcharts-axis-line").add(e.axisGroup);
        return i.styledMode || o.attr({
          stroke: s.lineColor,
          "stroke-width": s.lineWidth,
          zIndex: 7
        }), o;
      }
    }
    return r.E = function (t) {
      return this.dateFormat("%a", t, !0).charAt(0);
    }, r.W = function (t) {
      let e = this,
        i = new this.Date(t);
      ["Hours", "Milliseconds", "Minutes", "Seconds"].forEach(function (t) {
        e.set(t, i, 0);
      });
      let s = (this.get("Day", i) + 6) % 7,
        o = new this.Date(i.valueOf());
      this.set("Date", o, this.get("Date", i) - s + 3);
      let r = new this.Date(this.get("FullYear", o), 0, 1);
      return 4 !== this.get("Day", r) && (this.set("Month", i, 0), this.set("Date", i, 1 + (11 - this.get("Day", r)) % 7)), (1 + Math.floor((o.valueOf() - r.valueOf()) / 6048e5)).toString();
    }, {
      compose: function (t, e, i) {
        return t.keepProps.includes("grid") || (t.keepProps.push("grid"), t.prototype.getMaxLabelDimensions = m, x(t.prototype, "unsquish", G), x(t.prototype, "getOffset", b), n(t, "init", B), n(t, "afterGetTitlePosition", y), n(t, "afterInit", v), n(t, "afterRender", M), n(t, "afterSetAxisTranslation", k), n(t, "afterSetOptions", A), n(t, "afterSetOptions", S), n(t, "afterSetScale", C), n(t, "afterTickSize", w), n(t, "trimTicks", D), n(t, "destroy", O), n(e, "afterSetChartSize", P), n(i, "afterGetLabelPosition", E), n(i, "labelFormat", T)), t;
      }
    };
  }), i(e, "Gantt/Tree.js", [e["Core/Utilities.js"]], function (t) {
    let {
      extend: e,
      isNumber: i,
      pick: s
    } = t;
    function o(t, r, n, a, l, h) {
      let d = h && h.after,
        c = h && h.before,
        p = {
          data: a,
          depth: n - 1,
          id: t,
          level: n,
          parent: r || ""
        },
        u = 0,
        g = 0,
        x,
        f;
      "function" == typeof c && c(p, h);
      let m = (l[t] || []).map(e => {
        let s = o(e.id, t, n + 1, e, l, h),
          r = e.start || NaN,
          a = !0 === e.milestone ? r : e.end || NaN;
        return x = !i(x) || r < x ? r : x, f = !i(f) || a > f ? a : f, u = u + 1 + s.descendants, g = Math.max(s.height + 1, g), s;
      });
      return a && (a.start = s(a.start, x), a.end = s(a.end, f)), e(p, {
        children: m,
        descendants: u,
        height: g
      }), "function" == typeof d && d(p, h), p;
    }
    return {
      getNode: o,
      getTree: function (t, e) {
        return o("", null, 1, null, function (t) {
          let e = [],
            i = t.reduce((t, i) => {
              let {
                parent: s = "",
                id: o
              } = i;
              return void 0 === t[s] && (t[s] = []), t[s].push(i), o && e.push(o), t;
            }, {});
          return Object.keys(i).forEach(t => {
            if ("" !== t && -1 === e.indexOf(t)) {
              let e = i[t].map(function (t) {
                let {
                  ...e
                } = t;
                return e;
              });
              i[""].push(...e), delete i[t];
            }
          }), i;
        }(t), e);
      }
    };
  }), i(e, "Core/Axis/TreeGrid/TreeGridTick.js", [e["Core/Utilities.js"]], function (t) {
    let {
      addEvent: e,
      removeEvent: i,
      isObject: s,
      isNumber: o,
      pick: r,
      wrap: n
    } = t;
    function a() {
      this.treeGrid || (this.treeGrid = new d(this));
    }
    function l(t, e, i, n, a, l, h, d, c) {
      let p, u, g;
      let x = r(this.options && this.options.labels, l),
        f = this.pos,
        m = this.axis,
        b = "treegrid" === m.type,
        y = t.apply(this, [e, i, n, a, x, h, d, c]);
      if (b) {
        let {
            width: t = 0,
            padding: e = m.linkedParent ? 0 : 5
          } = x && s(x.symbol, !0) ? x.symbol : {},
          i = x && o(x.indentation) ? x.indentation : 0;
        g = (u = (p = m.treeGrid.mapOfPosToGridNode) && p[f]) && u.depth || 1, y.x += t + 2 * e + (g - 1) * i;
      }
      return y;
    }
    function h(t) {
      let n, a, l;
      let {
          pos: h,
          axis: d,
          label: c,
          treeGrid: p,
          options: u
        } = this,
        g = p?.labelIcon,
        x = c?.element,
        {
          treeGrid: f,
          options: m,
          chart: b,
          tickPositions: y
        } = d,
        v = f.mapOfPosToGridNode,
        M = r(u?.labels, m?.labels),
        k = M && s(M.symbol, !0) ? M.symbol : {},
        A = v && v[h],
        {
          descendants: S,
          depth: C
        } = A || {},
        w = A && S && S > 0,
        P = "treegrid" === d.type && x,
        O = y.indexOf(h) > -1,
        B = "highcharts-treegrid-node-",
        E = B + "level-",
        T = b.styledMode;
      P && A && c.removeClass(RegExp(E + ".*")).addClass(E + C), t.apply(this, Array.prototype.slice.call(arguments, 1)), P && w ? (n = f.isCollapsed(A), function (t, e) {
        let i = t.treeGrid,
          s = !i.labelIcon,
          n = e.renderer,
          a = e.xy,
          l = e.options,
          h = l.width || 0,
          d = l.height || 0,
          c = l.padding ?? t.axis.linkedParent ? 0 : 5,
          p = {
            x: a.x - h / 2 - c,
            y: a.y - d / 2
          },
          u = e.collapsed ? 90 : 180,
          g = e.show && o(p.y),
          x = i.labelIcon;
        x || (i.labelIcon = x = n.path(n.symbols[l.type](l.x || 0, l.y || 0, h, d)).addClass("highcharts-label-icon").add(e.group)), x[g ? "show" : "hide"](), n.styledMode || x.attr({
          cursor: "pointer",
          fill: r(e.color, "#666666"),
          "stroke-width": 1,
          stroke: l.lineColor,
          strokeWidth: l.lineWidth || 0
        }), x[s ? "attr" : "animate"]({
          translateX: p.x,
          translateY: p.y,
          rotation: u
        });
      }(this, {
        color: !T && c.styles.color || "",
        collapsed: n,
        group: c.parentGroup,
        options: k,
        renderer: c.renderer,
        show: O,
        xy: c.xy
      }), a = B + (n ? "collapsed" : "expanded"), l = B + (n ? "expanded" : "collapsed"), c.addClass(a).removeClass(l), T || c.css({
        cursor: "pointer"
      }), [c, g].forEach(t => {
        t && !t.attachedTreeGridEvents && (e(t.element, "mouseover", function () {
          c.addClass("highcharts-treegrid-node-active"), c.renderer.styledMode || c.css({
            textDecoration: "underline"
          });
        }), e(t.element, "mouseout", function () {
          !function (t, e) {
            let i = s(e.style) ? e.style : {};
            t.removeClass("highcharts-treegrid-node-active"), t.renderer.styledMode || t.css({
              textDecoration: i.textDecoration || "none"
            });
          }(c, M);
        }), e(t.element, "click", function () {
          p.toggleCollapse();
        }), t.attachedTreeGridEvents = !0);
      })) : g && (i(x), c?.css({
        cursor: "default"
      }), g.destroy());
    }
    class d {
      static compose(t) {
        let i = t.prototype;
        i.toggleCollapse || (e(t, "init", a), n(i, "getLabelPosition", l), n(i, "renderLabel", h), i.collapse = function (t) {
          this.treeGrid.collapse(t);
        }, i.expand = function (t) {
          this.treeGrid.expand(t);
        }, i.toggleCollapse = function (t) {
          this.treeGrid.toggleCollapse(t);
        });
      }
      constructor(t) {
        this.tick = t;
      }
      collapse(t) {
        let e = this.tick,
          i = e.axis,
          s = i.brokenAxis;
        if (s && i.treeGrid.mapOfPosToGridNode) {
          let o = e.pos,
            n = i.treeGrid.mapOfPosToGridNode[o],
            a = i.treeGrid.collapse(n);
          s.setBreaks(a, r(t, !0));
        }
      }
      destroy() {
        this.labelIcon && this.labelIcon.destroy();
      }
      expand(t) {
        let {
            pos: e,
            axis: i
          } = this.tick,
          {
            treeGrid: s,
            brokenAxis: o
          } = i,
          n = s.mapOfPosToGridNode;
        if (o && n) {
          let i = n[e],
            a = s.expand(i);
          o.setBreaks(a, r(t, !0));
        }
      }
      toggleCollapse(t) {
        let e = this.tick,
          i = e.axis,
          s = i.brokenAxis;
        if (s && i.treeGrid.mapOfPosToGridNode) {
          let o = e.pos,
            n = i.treeGrid.mapOfPosToGridNode[o],
            a = i.treeGrid.toggleCollapse(n);
          s.setBreaks(a, r(t, !0));
        }
      }
    }
    return d;
  }), i(e, "Series/TreeUtilities.js", [e["Core/Color/Color.js"], e["Core/Utilities.js"]], function (t, e) {
    let {
      extend: i,
      isArray: s,
      isNumber: o,
      isObject: r,
      merge: n,
      pick: a,
      relativeLength: l
    } = e;
    return {
      getColor: function (e, i) {
        let s, o, r, n, l, h;
        let d = i.index,
          c = i.mapOptionsToLevel,
          p = i.parentColor,
          u = i.parentColorIndex,
          g = i.series,
          x = i.colors,
          f = i.siblings,
          m = g.points,
          b = g.chart.options.chart;
        return e && (s = m[e.i], o = c[e.level] || {}, s && o.colorByPoint && (n = s.index % (x ? x.length : b.colorCount), r = x && x[n]), g.chart.styledMode || (l = a(s && s.options.color, o && o.color, r, p && (e => {
          let i = o && o.colorVariation;
          return i && "brightness" === i.key && d && f ? t.parse(e).brighten(i.to * (d / f)).get() : e;
        })(p), g.color)), h = a(s && s.options.colorIndex, o && o.colorIndex, n, u, i.colorIndex)), {
          color: l,
          colorIndex: h
        };
      },
      getLevelOptions: function (t) {
        let e, i, l, h, d, c;
        let p = {};
        if (r(t)) for (h = o(t.from) ? t.from : 1, c = t.levels, i = {}, e = r(t.defaults) ? t.defaults : {}, s(c) && (i = c.reduce((t, i) => {
          let s, l, d;
          return r(i) && o(i.level) && (l = a((d = n({}, i)).levelIsConstant, e.levelIsConstant), delete d.levelIsConstant, delete d.level, r(t[s = i.level + (l ? 0 : h - 1)]) ? n(!0, t[s], d) : t[s] = d), t;
        }, {})), d = o(t.to) ? t.to : 1, l = 0; l <= d; l++) p[l] = n({}, e, r(i[l]) ? i[l] : {});
        return p;
      },
      getNodeWidth: function (t, e) {
        let {
            chart: i,
            options: s
          } = t,
          {
            nodeDistance: o = 0,
            nodeWidth: r = 0
          } = s,
          {
            plotSizeX: n = 1
          } = i;
        if ("auto" === r) {
          if ("string" == typeof o && /%$/.test(o)) return n / (e + parseFloat(o) / 100 * (e - 1));
          let t = Number(o);
          return (n + t) / (e || 1) - t;
        }
        return l(r, n);
      },
      setTreeValues: function t(e, s) {
        let o = s.before,
          r = s.idRoot,
          n = s.mapIdToNode[r],
          l = !1 !== s.levelIsConstant,
          h = s.points[e.i],
          d = h && h.options || {},
          c = [],
          p = 0;
        e.levelDynamic = e.level - (l ? 0 : n.level), e.name = a(h && h.name, ""), e.visible = r === e.id || !0 === s.visible, "function" == typeof o && (e = o(e, s)), e.children.forEach((o, r) => {
          let n = i({}, s);
          i(n, {
            index: r,
            siblings: e.children.length,
            visible: e.visible
          }), o = t(o, n), c.push(o), o.visible && (p += o.val);
        });
        let u = a(d.value, p);
        return e.visible = u >= 0 && (p > 0 || e.visible), e.children = c, e.childrenTotal = p, e.isLeaf = e.visible && !p, e.val = u, e;
      },
      updateRootId: function (t) {
        let e, i;
        return r(t) && (i = r(t.options) ? t.options : {}, e = a(t.rootNode, i.rootId, ""), r(t.userOptions) && (t.userOptions.rootId = e), t.rootNode = e), e;
      }
    };
  }), i(e, "Core/Axis/TreeGrid/TreeGridAxis.js", [e["Core/Axis/BrokenAxis.js"], e["Core/Axis/GridAxis.js"], e["Gantt/Tree.js"], e["Core/Axis/TreeGrid/TreeGridTick.js"], e["Series/TreeUtilities.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r) {
    let n;
    let {
        getLevelOptions: a
      } = o,
      {
        addEvent: l,
        find: h,
        fireEvent: d,
        isArray: c,
        isObject: p,
        isString: u,
        merge: g,
        pick: x,
        removeEvent: f,
        wrap: m
      } = r;
    function b(t, e) {
      let i = t.collapseEnd || 0,
        s = t.collapseStart || 0;
      return i >= e && (s -= .5), {
        from: s,
        to: i,
        showPoints: !1
      };
    }
    function y(t, e, s) {
      let o = [],
        r = [],
        n = {},
        a = e || !1,
        l = {},
        d = -1,
        c = i.getTree(t, {
          after: function (t) {
            let e = l[t.pos],
              i = 0,
              s = 0;
            e.children.forEach(function (t) {
              s += (t.descendants || 0) + 1, i = Math.max((t.height || 0) + 1, i);
            }), e.descendants = s, e.height = i, e.collapsed && r.push(e);
          },
          before: function (t) {
            let e, i;
            let s = p(t.data, !0) ? t.data : {},
              r = u(s.name) ? s.name : "",
              c = n[t.parent],
              g = p(c, !0) ? l[c.pos] : null;
            a && p(g, !0) && (e = h(g.children, function (t) {
              return t.name === r;
            })) ? (i = e.pos, e.nodes.push(t)) : i = d++, !l[i] && (l[i] = e = {
              depth: g ? g.depth + 1 : 0,
              name: r,
              id: s.id,
              nodes: [t],
              children: [],
              pos: i
            }, -1 !== i && o.push(r), p(g, !0) && g.children.push(e)), u(t.id) && (n[t.id] = t), e && !0 === s.collapsed && (e.collapsed = !0), t.pos = i;
          }
        });
      return {
        categories: o,
        mapOfIdToNode: n,
        mapOfPosToGridNode: l = function (t, e) {
          let i = function (t, s, o) {
            let r = t.nodes,
              n = s + (-1 === s ? 0 : e - 1),
              a = (n - s) / 2,
              l = s + a;
            return r.forEach(function (t) {
              let e = t.data;
              p(e, !0) && (e.y = s + (e.seriesIndex || 0), delete e.seriesIndex), t.pos = l;
            }), o[l] = t, t.pos = l, t.tickmarkOffset = a + .5, t.collapseStart = n + .5, t.children.forEach(function (t) {
              i(t, n + 1, o), n = (t.collapseEnd || 0) - .5;
            }), t.collapseEnd = n + .5, o;
          };
          return i(t["-1"], -1, {});
        }(l, s),
        collapsedNodes: r,
        tree: c
      };
    }
    function v(t) {
      t.target.axes.filter(t => "treegrid" === t.type).forEach(function (e) {
        let i = e.options || {},
          s = i.labels,
          o = e.uniqueNames,
          r = i.max,
          n = !e.treeGrid.mapOfPosToGridNode || e.series.some(function (t) {
            return !t.hasRendered || t.isDirtyData || t.isDirty;
          }),
          l = 0,
          h,
          d;
        if (n) {
          if (h = e.series.reduce(function (t, e) {
            return e.visible && ((e.options.data || []).forEach(function (i) {
              e.options.keys && e.options.keys.length && (i = e.pointClass.prototype.optionsToObject.call({
                series: e
              }, i), e.pointClass.setGanttPointAliases(i)), p(i, !0) && (i.seriesIndex = l, t.push(i));
            }), !0 === o && l++), t;
          }, []), r && h.length < r) for (let t = h.length; t <= r; t++) h.push({
            name: t + "​"
          });
          d = y(h, o || !1, !0 === o ? l : 1), e.categories = d.categories, e.treeGrid.mapOfPosToGridNode = d.mapOfPosToGridNode, e.hasNames = !0, e.treeGrid.tree = d.tree, e.series.forEach(function (t) {
            let e = (t.options.data || []).map(function (e) {
              return c(e) && t.options.keys && t.options.keys.length && h.forEach(function (t) {
                e.indexOf(t.x) >= 0 && e.indexOf(t.x2) >= 0 && (e = t);
              }), p(e, !0) ? g(e) : e;
            });
            t.visible && t.setData(e, !1);
          }), e.treeGrid.mapOptionsToLevel = a({
            defaults: s,
            from: 1,
            levels: s && s.levels,
            to: e.treeGrid.tree && e.treeGrid.tree.height
          }), "beforeRender" === t.type && (e.treeGrid.collapsedNodes = d.collapsedNodes);
        }
      });
    }
    function M(t, e) {
      let i = this.treeGrid.mapOptionsToLevel || {},
        s = "treegrid" === this.type,
        o = this.ticks,
        r = o[e],
        a,
        l,
        h;
      s && this.treeGrid.mapOfPosToGridNode ? ((a = i[(h = this.treeGrid.mapOfPosToGridNode[e]).depth]) && (l = {
        labels: a
      }), !r && n ? o[e] = r = new n(this, e, void 0, void 0, {
        category: h.name,
        tickmarkOffset: h.tickmarkOffset,
        options: l
      }) : (r.parameters.category = h.name, r.options = l, r.addLabel())) : t.apply(this, Array.prototype.slice.call(arguments, 1));
    }
    function k(t, e, i, s) {
      let o = this,
        r = "treegrid" === i.type;
      o.treeGrid || (o.treeGrid = new C(o)), r && (l(e, "beforeRender", v), l(e, "beforeRedraw", v), l(e, "addSeries", function (t) {
        if (t.options.data) {
          let e = y(t.options.data, i.uniqueNames || !1, 1);
          o.treeGrid.collapsedNodes = (o.treeGrid.collapsedNodes || []).concat(e.collapsedNodes);
        }
      }), l(o, "foundExtremes", function () {
        o.treeGrid.collapsedNodes && o.treeGrid.collapsedNodes.forEach(function (t) {
          let e = o.treeGrid.collapse(t);
          o.brokenAxis && (o.brokenAxis.setBreaks(e, !1), o.treeGrid.collapsedNodes && (o.treeGrid.collapsedNodes = o.treeGrid.collapsedNodes.filter(e => t.collapseStart !== e.collapseStart || t.collapseEnd !== e.collapseEnd)));
        });
      }), l(o, "afterBreaks", function () {
        "yAxis" === o.coll && !o.staticScale && o.chart.options.chart.height && (o.isDirty = !0);
      }), i = g({
        grid: {
          enabled: !0
        },
        labels: {
          align: "left",
          levels: [{
            level: void 0
          }, {
            level: 1,
            style: {
              fontWeight: "bold"
            }
          }],
          symbol: {
            type: "triangle",
            x: -5,
            y: -5,
            height: 10,
            width: 10
          }
        },
        uniqueNames: !1
      }, i, {
        reversed: !0
      })), t.apply(o, [e, i, s]), r && (o.hasNames = !0, o.options.showLastLabel = !0);
    }
    function A(t) {
      let e = this.options,
        i = "number" == typeof e.linkedTo ? this.chart[this.coll]?.[e.linkedTo] : void 0;
      if ("treegrid" === this.type) {
        if (this.min = x(this.userMin, e.min, this.dataMin), this.max = x(this.userMax, e.max, this.dataMax), d(this, "foundExtremes"), this.setAxisTranslation(), this.tickInterval = 1, this.tickmarkOffset = .5, this.tickPositions = this.treeGrid.mapOfPosToGridNode ? this.treeGrid.getTickPositions() : [], i) {
          let t = i.getExtremes();
          this.min = x(t.min, t.dataMin), this.max = x(t.max, t.dataMax), this.tickPositions = i.tickPositions;
        }
        this.linkedParent = i;
      } else t.apply(this, Array.prototype.slice.call(arguments, 1));
    }
    function S(t) {
      let e = this;
      "treegrid" === this.type && e.visible && e.tickPositions.forEach(function (t) {
        let i = e.ticks[t];
        i.label && i.label.attachedTreeGridEvents && (f(i.label.element), i.label.attachedTreeGridEvents = !1);
      }), t.apply(e, Array.prototype.slice.call(arguments, 1));
    }
    class C {
      static compose(o, r, a, l) {
        if (!o.keepProps.includes("treeGrid")) {
          let t = o.prototype;
          o.keepProps.push("treeGrid"), m(t, "generateTick", M), m(t, "init", k), m(t, "setTickInterval", A), m(t, "redraw", S), t.utils = {
            getNode: i.getNode
          }, n || (n = l);
        }
        return e.compose(o, r, l), t.compose(o, a), s.compose(l), o;
      }
      constructor(t) {
        this.axis = t;
      }
      setCollapsedStatus(t) {
        let e = this.axis,
          i = e.chart;
        e.series.forEach(function (e) {
          let s = e.options.data;
          if (t.id && s) {
            let o = i.get(t.id),
              r = s[e.data.indexOf(o)];
            o && r && (o.collapsed = t.collapsed, r.collapsed = t.collapsed);
          }
        });
      }
      collapse(t) {
        let e = this.axis,
          i = e.options.breaks || [],
          s = b(t, e.max);
        return i.push(s), t.collapsed = !0, e.treeGrid.setCollapsedStatus(t), i;
      }
      expand(t) {
        let e = this.axis,
          i = e.options.breaks || [],
          s = b(t, e.max);
        return t.collapsed = !1, e.treeGrid.setCollapsedStatus(t), i.reduce(function (t, e) {
          return (e.to !== s.to || e.from !== s.from) && t.push(e), t;
        }, []);
      }
      getTickPositions() {
        let t = this.axis,
          e = Math.floor(t.min / t.tickInterval) * t.tickInterval,
          i = Math.ceil(t.max / t.tickInterval) * t.tickInterval;
        return Object.keys(t.treeGrid.mapOfPosToGridNode || {}).reduce(function (s, o) {
          let r = +o;
          return r >= e && r <= i && !(t.brokenAxis && t.brokenAxis.isInAnyBreak(r)) && s.push(r), s;
        }, []);
      }
      isCollapsed(t) {
        let e = this.axis,
          i = e.options.breaks || [],
          s = b(t, e.max);
        return i.some(function (t) {
          return t.from === s.from && t.to === s.to;
        });
      }
      toggleCollapse(t) {
        return this.isCollapsed(t) ? this.expand(t) : this.collapse(t);
      }
    }
    return C;
  }), i(e, "Series/Gantt/GanttSeries.js", [e["Series/Gantt/GanttPoint.js"], e["Series/Gantt/GanttSeriesDefaults.js"], e["Gantt/Pathfinder.js"], e["Core/Series/SeriesRegistry.js"], e["Extensions/StaticScale.js"], e["Core/Axis/TreeGrid/TreeGridAxis.js"], e["Core/Utilities.js"]], function (t, e, i, s, o, r, n) {
    let {
        series: a,
        seriesTypes: {
          xrange: l
        }
      } = s,
      {
        extend: h,
        isNumber: d,
        merge: c
      } = n;
    class p extends l {
      static compose(t, e, s, n) {
        l.compose(t), e && (o.compose(t, e), s) && (i.compose(e, s.prototype.pointClass), n && r.compose(t, e, s, n));
      }
      drawPoint(t, e) {
        let i = this.options,
          s = this.chart.renderer,
          o = t.shapeArgs,
          r = t.plotY,
          n = t.selected && "select",
          a = i.stacking && !i.borderRadius,
          l = t.graphic,
          h;
        t.options.milestone ? d(r) && null !== t.y && !1 !== t.visible ? (h = s.symbols.diamond(o.x || 0, o.y || 0, o.width || 0, o.height || 0), l ? l[e]({
          d: h
        }) : t.graphic = l = s.path(h).addClass(t.getClassName(), !0).add(t.group || this.group), this.chart.styledMode || t.graphic.attr(this.pointAttribs(t, n)).shadow(i.shadow, null, a)) : l && (t.graphic = l.destroy()) : super.drawPoint(t, e);
      }
      translatePoint(t) {
        let e, i;
        super.translatePoint(t), t.options.milestone && (i = (e = t.shapeArgs).height || 0, t.shapeArgs = {
          x: (e.x || 0) - i / 2,
          y: e.y,
          width: i,
          height: i
        });
      }
    }
    return p.defaultOptions = c(l.defaultOptions, e), h(p.prototype, {
      pointArrayMap: ["start", "end", "y"],
      pointClass: t,
      setData: a.prototype.setData
    }), s.registerSeriesType("gantt", p), p;
  }), i(e, "masters/modules/gantt.src.js", [e["Core/Globals.js"], e["Extensions/ArrowSymbols.js"], e["Gantt/Connection.js"], e["Extensions/CurrentDateIndication.js"], e["Core/Chart/GanttChart.js"], e["Stock/Navigator/Navigator.js"], e["Stock/RangeSelector/RangeSelector.js"], e["Stock/Scrollbar/Scrollbar.js"], e["Series/Gantt/GanttSeries.js"]], function (t, e, i, s, o, r, n, a, l) {
    return t.Connection = t.Connection || i, t.GanttChart = t.GanttChart || o, t.Navigator = t.Navigator || r, t.RangeSelector = t.RangeSelector || n, t.Scrollbar = t.Scrollbar || a, t.ganttChart = t.GanttChart.ganttChart, e.compose(t.SVGRenderer), s.compose(t.Axis, t.PlotLineOrBand), l.compose(t.Axis, t.Chart, t.Series, t.Tick), t.Navigator.compose(t.Chart, t.Axis, t.Series), t.RangeSelector.compose(t.Axis, t.Chart), t.Scrollbar.compose(t.Axis), t;
  });
});