import { Component, inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MaterialsPlanningService } from '../../shared/services/materials-planning/materials-planning.service';
import { CommodityTrackingService } from '../../shared/services/commodity-tracking/commodity-tracking.service';
import {
  ChartDataType,
  DonutChartModel,
} from '../../shared/interface/ChartTypes';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { DonutChartComponent } from '../../shared/components/charts/donut-chart/donut-chart.component';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import {
  MaterialPlanningChartFilter,
  RadioBtn,
} from '../../shared/interface/Filters';
import { Router } from '@angular/router';
import { MultiselectDropdownComponent } from '../../shared/components/multiselect-dropdown/multiselect-dropdown.component';
import { PopoverComponent } from '../../shared/components/popover/popover.component';
import { BehaviorSubject } from 'rxjs';
import { isEmpty } from 'lodash-es';
import { SingleSelectComponent } from '../../shared/components/single-select/single-select.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { CommonModule } from '@angular/common';
import { RadioComponent } from '../../shared/components/buttons/radio/radio.component';
import { DashboardLinksService } from '../../shared/services/pbi-links-servics/dashboard-links.service';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { DashboardLinks } from '../../shared/interface/DashboardLinks';
import { TableComponent } from '../../shared/components/table/table.component';

@Component({
  selector: 'app-procurement-status',
  standalone: true,
  imports: [
    HeaderTextComponent,
    IconButtonComponent,
    DonutChartComponent,
    SingleColumnComponent,
    IconButtonComponent,
    IconComponent,
    MultiselectDropdownComponent,
    PopoverComponent,
    SingleSelectComponent,
    RadioComponent,
    LoaderComponent,
    CommonModule,
    TableComponent
  ],
  templateUrl: './procurement-status.component.html',
  styleUrl: './procurement-status.component.css',
})
export class ProcurementStatusComponent implements OnInit {
  private materialsPlanningService = inject(MaterialsPlanningService);
  private commodityTrackingService = inject(CommodityTrackingService);
  private router = inject(Router);
  private dashboardLinksService = inject(DashboardLinksService);
  isLinksTableVisible: boolean = false;
  linksData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  @ViewChild('openLinkTemplate', { static: false })
  openLinkTemplate!: TemplateRef<any>;

  overAllStatusData: DonutChartModel = { name: 'Overall Status', data: [] };
  orderStatusData: ChartDataType = { categories: [], series: [] };
  areaDropdown: { value: string; id?: string | number }[] = [];
  selectedOverallArea: string = history.state.area
    ? history.state.area
    : 'Overall';
  selectedOrderArea: string = history.state.area
    ? history.state.area
    : 'Overall';
  selectedDiscipline: string[] = [];
  isFilter: boolean = false;
  disciplines: { value: string; id?: any }[] = [];
  disciplineGroup: string = 'Pre-Order';
  selectedRadio: RadioBtn = {
    key: this.disciplineGroup,
    name: this.disciplineGroup,
    disabled: false,
  };
  orderStatusFilterTrigger = new BehaviorSubject<void>(undefined);
  overAllStatusFilterTrigger = new BehaviorSubject<void>(undefined);
  materialFilters: MaterialPlanningChartFilter =
    {} as MaterialPlanningChartFilter;
  orderStatusRadioBtn: RadioBtn[] = [
    { key: 'Pre-Order', name: 'Pre-Order', disabled: false },
    { key: 'Post-Order', name: 'Post-Order', disabled: false },
  ];
  isBarChartLoading: boolean = false;
  isDonutChartLoading: boolean = false;
  isLoading: boolean = false;

  async fetchOverAllStatus(area: string) {
    this.isDonutChartLoading = true;
    this.commodityTrackingService.getOverallStatusPie(area).subscribe({
      next: (response) => {
        this.overAllStatusData = response.data;
        this.isDonutChartLoading = false;
      },
      error: (err) => {
        console.error('error in get Material Actual Status', err);
        this.isDonutChartLoading = false;
      },
    });
  }

  async fetchOrderStatus(filters: any) {
    this.isBarChartLoading = true;
    this.materialsPlanningService.getOrderStatusData(filters).subscribe({
      next: (response) => {
        this.orderStatusData = response.data;
        this.isBarChartLoading = false;
      },
      error: (err) => {
        console.error('error in get Material Actual Status', err);
        this.isBarChartLoading = false;
      },
    });
  }

  async fetchAreas() {
    this.isLoading = true;
    this.materialsPlanningService.getAreas().subscribe({
      next: (response) => {
        this.areaDropdown = response.data.map((el) => {
          return {
            value: el.areaName,
            id: el.id,
          };
        });
        this.isLoading = false;
      },
      error: (err) => {
        console.error('error in get Areas', err);
        this.isLoading = false;
      },
    });
  }

  fetchMaterialFilters() {
    this.materialsPlanningService.getMaterialFilters().subscribe({
      next: (response) => {
        this.materialFilters = response.data;
        this.disciplines = this.materialFilters.discipline.map((el) => {
          return { value: el };
        });
      },
      error: (err) => {
        console.error('error in get Material Filters', err);
      },
    });
  }

  overAllAreaSelect(event: any) {
    this.selectedOverallArea = event.target.value;
    this.overAllStatusFilterTrigger.next();
  }

  onChangeDiscipline(event: any) {
    this.selectedDiscipline = event.value;
    this.orderStatusFilterTrigger.next();
  }

  onOverAllAreaSelectReset() {
    // this.fetchOverAllStatus('');
    this.selectedOverallArea = '';
    this.overAllStatusFilterTrigger.next();
  }

  orderStatusAreaSelect(event: any) {
    this.selectedOrderArea = event.target.value;
    this.orderStatusFilterTrigger.next();
  }

  orderStatusRadioSelect(event: any) {
    this.selectedRadio = event;
    this.disciplineGroup = event.key;
    this.orderStatusFilterTrigger.next();
  }

  onFilterReset() {
    if(this.isFilter){
    this.selectedDiscipline = [];
    this.selectedOrderArea = '';
    this.orderStatusFilterTrigger.next();
    }
  }

  onLinkClick(link: string) {
    this.router.navigate([link]);
  }

  onLinksTextClick() {
    this.isLinksTableVisible = true;
  }

  onClosePopup = () => {
    if (!this.isLinksTableVisible) {
      return;
    }
    this.isLinksTableVisible = false;
  };

  fetchDashboardLinks = async () => {
    const pageUrl = this.router.url;
    this.dashboardLinksService.getDashboardLinksData(pageUrl).subscribe({
      next: (response) => {
        this.linksData = {
          columns: [
            {
              field: 'iprWprDashboardPageName' as never,
              header: 'Dashboard',
            },
            {
              field: 'download' as never,
              header: 'Open Dashboard',
              bodyTemplate: this.openLinkTemplate,
style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
            },
          ],
          data: response.data.map((el) => {
            return {
              ...el,
              download: '',
            };
          }),
          count: response.data.length,
        };
      },
      error: (err) => {
        console.error('Error fetching dashboard links:', err);
      },
    });
  };

  onOpenFormLinkClick = (rowData: DashboardLinks) => {
    window.open(rowData.iprWprDashboardLink, '_blank');
  };

  ngOnInit(): void {
    this.fetchDashboardLinks();
    if (this.selectedOrderArea === 'Overall') {
      this.selectedOrderArea = '';
    }
    if (this.selectedOverallArea === 'Overall') {
      this.selectedOverallArea = '';
    }
    this.orderStatusFilterTrigger.subscribe({
      next: () => {
        this.fetchOrderStatus({
          discipline: this.selectedDiscipline,
          area: this.selectedOrderArea,
          disciplinegroup: this.disciplineGroup,
        });
        if (
          isEmpty(this.selectedDiscipline) &&
          isEmpty(this.selectedOrderArea)
        ) {
          this.isFilter = false;
        } else {
          this.isFilter = true;
        }
      },
    });
    this.overAllStatusFilterTrigger.subscribe({
      next: () => {
        this.fetchOverAllStatus(this.selectedOverallArea);
      },
    });
    this.fetchAreas();
    this.fetchMaterialFilters();
  }
}
