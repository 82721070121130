import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectHighlight, ProjectHighlightData } from '../../types/Project-highlight';
import { APIInterceptor } from '../../../core/interceptors/api.interceptor';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
const {
  HIGHLIGHTS_CONCERNS_ALL,
  HIGHLIGHTS_CONCERNS
} = API_END_POINTS
@Injectable({
  providedIn: 'root'
})
export class ProjectHighlightConcernService {

  constructor(private http: HttpClient) { }

  getData({ filters, pageNo, rows, isDownloadClicked}: {
    filters?: any,
    pageNo?: number,
    rows?: number,
    isDownloadClicked?: boolean

  }): Observable<{data: ProjectHighlightData}> {
    return this.http.post<{ data: ProjectHighlightData }>(HIGHLIGHTS_CONCERNS_ALL , {
      filters, pageNo, noOfRecords: rows, isDownloadClicked
    })
  }

  submitProjectHighlightConcernForm(data: unknown): Observable<any> {
    return this.http.post(HIGHLIGHTS_CONCERNS, data)
  }
}
