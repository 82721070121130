import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, forwardRef, inject, Input, Output } from '@angular/core';
import { NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { Observable, debounceTime, distinctUntilChanged, switchMap, of, catchError } from 'rxjs';
import { globalModules } from '../../utils/global-modules';
import { SearchService } from '../../services/search/search.service';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-custom-search',
  standalone: true,
  imports: [...globalModules, NgbTypeaheadModule, ReactiveFormsModule,
    FormsModule,],
  templateUrl: './custom-search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSearchComponent),
      multi: true
    }
  ],
})
export class CustomSearchComponent {
  @Input() apiEndpoint!: string; // API endpoint
  @Input() placeholder: string = 'Search...'; // Placeholder for input
  @Output() resultSelected = new EventEmitter<any>(); // EventEmitter for result selection

  value: string = ''; // Internal value for the input
  manualInput: string = ''; // For manual input
  showManualInput: boolean = false; // Flag to control visibility of manual input

  // ControlValueAccessor methods
  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private searchService: SearchService) {}

  writeValue(value: any): void {
    this.value = value || '';
    this.manualInput = ''; // Reset manual input when value is set
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Search method for ngbTypeahead
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => 
        this.searchService.searchApi(this.apiEndpoint, term).pipe(
          catchError(error => {
            this.showManualInput = true; // Show manual input on error
            return of([]); // Return empty array on error
          })
        )
      )
    );

  // Formatter for displaying results
  formatter = (x: any) => x;

  // Handle input change
  onInputChange(value: string) {
    this.value = value;
    this.onChange(value); // Notify Angular forms about the value change
    this.onTouched(); // Mark control as touched
    this.showManualInput = false; // Hide manual input when typing in the main input
  }

  // Handle manual input change
  onManualInputChange(value: string) {
    this.value = value; // Update the value to the manual input
    this.onChange(value); // Notify Angular forms about the value change
    this.onTouched(); // Mark control as touched
  }
}
