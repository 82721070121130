import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { globalModules } from '../../utils/global-modules';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { Location } from '@angular/common';
import { filter } from 'rxjs';

const routesArray = ['/areastatus', '/hse', '/project-schedule'];


@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [...globalModules, SidebarComponent, NgbPopoverModule],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.css'
})
export class LayoutComponent {
  visible: boolean = false;
  userName: string = '';
  currentPath: string = '';
  locationState: {
    from: string
  };
  history: string[] = [];
  isProfileOpen: boolean = false;
  location = inject(Location);
  route = inject(ActivatedRoute);

  constructor(private router: Router, private authService: MsalService,
  ) {

    const navigation = this.router.getCurrentNavigation();
    this.locationState = navigation?.extras.state as typeof this.locationState;
    const allAccounts = this.authService.instance.getAllAccounts();
    this.userName = allAccounts.length ? allAccounts[0].name || allAccounts[0].username : '';
    this.currentPath = location.pathname;
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe({
        next: (event) => {
          this.history.push(event.urlAfterRedirects);
          this.currentPath = event.url;
        },
      });
  }


  onHideSideBar = () => {
    this.visible = false;
  }
  onShowSideBar = () => {
    this.visible = true;
  }

  goBackRoute = () => {
    console.log('routesArray', this.route)
    if (this.currentPath === '/3d-viewer') {
      window.close()
    }
    else if (routesArray.includes(this.currentPath)) {
      this.router.navigate(['/'])
    }
    else if (this.locationState?.from) {
      this.router.navigate([this.locationState.from], {
        state: {
          ...this.locationState,
          from: this.currentPath
        }
      });
    } else if (this.history.length > 1) {
      this.history.pop(); 
      const previousRoute = this.history.pop(); 
      if (previousRoute) {
        this.router.navigateByUrl(previousRoute);
      }
    } else {
      this.router.navigate(['/']);
    }
  }

  toggleProfile = () => {
    this.isProfileOpen = !this.isProfileOpen;
  }

  goHome = () => {
    this.router.navigate(['/'])
  }

  logOut = async () => {
    await this.authService.instance.logout();
  }

  onSettings = () => {

  }
  onProfile = () => {

  }
  onSignOut = () => {
    this.authService.logout()
  }


}
