import { Component, inject, Input } from '@angular/core';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from '../../interface/Menu';
import { IconComponent } from '../icon/icon.component';
import { globalModules } from '../../utils/global-modules';
import { Router } from '@angular/router';

@Component({
  selector: 'app-custom-sidebar-content',
  imports: [...globalModules],
  standalone: true,
  templateUrl: './sidebar-content.component.html',
  styleUrl: './sidebar.component.css',
  styles: [
    `
    :host {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    `,
    
    
  ],
})
export class SidebarContentComponent {
  activeOffcanvas = inject(NgbActiveOffcanvas);
  private router = inject(Router);

  @Input() menus: MenuItem[] = [];

  navigate(path?: string) {
    if (path) {
      // Handle navigation logic
      this.activeOffcanvas.dismiss();
    }
  }
  toggleSubMenu(menu: MenuItem) {
    menu.expanded = !menu.expanded;
  }

  isActive(path?: string){
    return this.router.url === path
  }
}
