import {
  Component,
  inject,
  OnInit
} from '@angular/core';
import { TablePageEvent } from 'primeng/table';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ScurvedataCardComponent } from '../../shared/components/scurvedata-card/scurvedata-card.component';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { TableComponent } from '../../shared/components/table/table.component';
import {
  ChartDataType,
  ProjectScheduleTableData,
} from '../../shared/interface/ChartTypes';
import { ScurveDataCardModel } from '../../shared/interface/ScurveDataCardModel';
import { ConstructionProjectSchedulingService } from '../../shared/services/construction-project-scheduling/construction-project-scheduling.service';
import { OverallScurveModel } from '../../shared/types/OverallScurveModel';
import { TableData } from '../../shared/types/TableProps';
import { globalModules } from '../../shared/utils/global-modules';

@Component({
  selector: 'app-construction-project-scheduling',
  standalone: true,
  imports: [
    LoaderComponent,
    SingleColumnComponent,
    TableComponent,
    ScurvedataCardComponent,
    HeaderTextComponent,
    globalModules,
  ],
  templateUrl: './construction-project-scheduling.component.html',
  styleUrl: './construction-project-scheduling.component.css',
})
export class ConstructionProjectSchedulingComponent implements OnInit {
  private constructionProjectSchedulingService = inject(
    ConstructionProjectSchedulingService
  );
  first: number = 0;
  page: number = 0;
  rowsPerPage: number = 10;
  projectScheduleChartData: OverallScurveModel = {
    chartData: {} as ChartDataType,
    tableData: {} as TableData,
    metaData: [] as ScurveDataCardModel[],
    monthVal: '',
  } as OverallScurveModel;
  projectScheduleTableData: ProjectScheduleTableData =
    {} as ProjectScheduleTableData;
  loading: boolean = false;
  paginationOptions:
    | {
        paginationRequired?: boolean;
        onChangePage?: (event: TablePageEvent) => void;
        onRowChange?: () => void;
        rows?: number;
        totalRecords?: number;
        first?: number;
      }
    | undefined = {
    first: this.first,
    rows: this.rowsPerPage,
    onChangePage: this.pageChange,
    paginationRequired: false,
  };

  constructor() {}

  pageChange(event: TablePageEvent) {
    this.first = event.first;
    this.rowsPerPage = event.rows;
    this.fetchProjectScheduleTableData(this.page, this.rowsPerPage);
  }

  fetchProjectScheduleChartData() {
    this.loading = true;
    this.constructionProjectSchedulingService
      .getProjectScheduleChartData()
      .subscribe({
        next: (response) => {
       
          this.projectScheduleChartData = {
            ...response.data,

            tableData: {
              columns: response.data.tableData.columns.map((el) => {
                return {
                  ...el,
                  style:
                    response.data.monthVal === el.field
                      ? {
                        backgroundColor: 'rgba(255, 255, 0, 0.5)',
                      }
                      : undefined,
                };
              }),
              count: response.data.tableData.count,
              rows: response.data.tableData.rows,
            },
          };

          this.loading = false;
        },
        error: (error) => {
          console.error('Failed to fetch project schedule chart data:', error);
          this.loading = false;
        },
      });
  }

  fetchProjectScheduleTableData(
    pageNo: number,
    rows: number,
    isDownloadClicked?: boolean
  ) {
    this.constructionProjectSchedulingService
      .getProjectScheduleTableData(pageNo, rows, isDownloadClicked)
      .subscribe({
        next: (response) => {
          if (response && response.data.data) {
            if (isDownloadClicked) {
            } else {
              this.projectScheduleTableData = response.data;
            }
          }
        },
        error: (error) => {
          console.error('Failed to fetch project schedule table data:', error);
        },
      });
  }

  fetchProjectScheduleTableDownloadData() {
    this.constructionProjectSchedulingService
      .getProjectScheduleTableData(this.page, this.rowsPerPage, true)
      .subscribe({
        next: (res) => {
          return res.data;
        },
      });
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.fetchProjectScheduleChartData();
    this.fetchProjectScheduleTableData(this.page, this.rowsPerPage);
  }
}
