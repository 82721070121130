import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { Observable } from 'rxjs';

const {
  AI_ASSIST_CATEGORY,
  AI_ASSIST_SESSION_WITH_CATEGORY,
  AI_ASSIST_CHAT_SESSION_ID,
  AI_ASSIST_CHATS_DATA,
  AI_ASSIST_ALL_CHATS,
} = API_END_POINTS;

type chatSessionIdDataType = {
  message: {
    message: any[];
    type: string;
  };
  sender: string;
  timestamp: string;
};

@Injectable({
  providedIn: 'root',
})
export class AiAssistServiceService {
  constructor(private http: HttpClient) {}

  token =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCJ9.eyJhdWQiOiI5MjNkZGI2ZS03NTJlLTQ1YTQtYmYwOS04ZGMyNTQzYjdmYTUiLCJpc3MiOiJodHRwczovL2xvZ2luLm1pY3Jvc29mdG9ubGluZS5jb20vM2RjZDM1YjUtZjljNS00OGNhLTg2NTMtODIxNTY4YWQzMzk3L3YyLjAiLCJpYXQiOjE3MzIwMzU0NzYsIm5iZiI6MTczMjAzNTQ3NiwiZXhwIjoxNzMyMDQwMTgxLCJhaW8iOiJBWVFBZS84WUFBQUEydStSaUpXZ1J6VVlQWmFsampqSFljeS9BOXlKUzRDZlNPNW5kQVN5UHB3Y2ViNUlwNWY3RVgwWVA3R3llV1A0YWh2VlhvZVFrWUZPVG96d2xJZHp4dGhHbDZVQVEzaXBrd2tINi9QU0tPVTVJS29rbndjQjJUaFhQZm8vYXdJOVFLT01PVnplUDZaMm1KV21HTzl0d1F5UXV6OFd3YnA4Sk1GMFdob1VPSXc9IiwiYXpwIjoiMmQ0NDYyYTgtMzZkNy00YzNlLTg2NDctYjU5M2RmOGVkNzBkIiwiYXpwYWNyIjoiMCIsIm5hbWUiOiJUZWphcyBBdXRrYXIiLCJvaWQiOiIxNGRmNjI2Yi1iMDM2LTQ0MjAtYmUyOS01OTRiNTJmNmZjNGUiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZWphcy5hdXRrYXJAYWxpZ25lZGF1dG9tYXRpb24uY29tIiwicmgiOiIxLkFUMEF0VFhOUGNYNXlraUdVNElWYUswemwyN2JQWkl1ZGFSRnZ3bU53bFE3ZjZVOUFGUTlBQS4iLCJzY3AiOiJhY2Nlc3NfYXNfdXNlciIsInN1YiI6IldmT2g3SDR4OTlNaEFNWFFUODR1VWNfbk1kbW44RXM2YS04UDJ0TEFqN3MiLCJ0aWQiOiIzZGNkMzViNS1mOWM1LTQ4Y2EtODY1My04MjE1NjhhZDMzOTciLCJ1dGkiOiJnSmFhWHFkb29rZVZyQ1RwQXJFZEFBIiwidmVyIjoiMi4wIn0.ZYUBoN61cRpcRhDZ7h697ufc4IS6YwccxOa9TqfnNBF4tJvOCUXjG3lRG6uopk0WXddna7MP60ffbVOBoBZpECpK_UPQ3MMUB4r9PBwuXW-Kcy6MVCQTSa8eeh-wyVpkNGL9mYJ_5mbv9fc5QcZ1aQy6ON5mEoIunWH_fewbutAacHzCW4FXTJyLC_3c0oT3_Plhzc19tUKtomY4leMr3m0OrtoES_FiKzxDLa466k_WSvRAlMWSIJpw-K_IfQJfKgU6py7-JtJHN3R_MjnGdnjwsjktl48KBVHIJftPpe024PVd69TQGA7bn7RvXB_XGUAh_TaBUTWvudAJsvKZSw';

  getCategoryData(): Observable<any> {
    return this.http.get(`${AI_ASSIST_CATEGORY}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
    });
  }
  getSessionWithCategory(category: string): Observable<any> {
    return this.http.post(`${AI_ASSIST_SESSION_WITH_CATEGORY(category)}`, {});
  }
  chatWithSessionId(
    sessionId: string,
    message: string
  ): Observable<chatSessionIdDataType> {
    return this.http.post<chatSessionIdDataType>(
      `${AI_ASSIST_CHAT_SESSION_ID(sessionId)}`,
      { message }
    );
  }
  getChats(sessionId: string): Observable<any> {
    return this.http.get(`${AI_ASSIST_CHATS_DATA(sessionId)}`);
  }
  getAllChats(): Observable<any> {
    return this.http.get(`${AI_ASSIST_ALL_CHATS}`);
  }
}
