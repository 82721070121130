import { Component, ViewChild, ElementRef, Input, viewChild, OnInit } from '@angular/core';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { IconComponent } from "../icon/icon.component";
import Highcharts from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import { Chart } from 'highcharts';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

Exporting(Highcharts);

@Component({
  selector: 'download-chart',
  standalone: true,
  imports: [IconButtonComponent, IconComponent],
  templateUrl: './download-chart.component.html',
  styleUrl: './download-chart.component.css'
})
export class DownloadChartComponent implements OnInit{
  @Input() options: Highcharts.Options[] = [{
    chart: {}
  }]
  @Input() title?: string;
  @Input() id!: string;
  @Input() chartRef!: ElementRef
  
  downloadImage() {
    let title = this.title
    let Inode = document.getElementById(this.id)
    htmlToImage.toPng(Inode!)
  .then(function (dataUrl) {
    download(dataUrl, title+'.png');
  });
  }

  downloadHTMLAsPDF() {
    const Inode = document.getElementById(this.id);
    let title = this.title
    html2canvas(Inode!).then(canvas => {
      const image = htmlToImage.toPng(Inode!)
      const imgData = canvas.toDataURL('image/png');
  
      // A4 landscape dimensions in mm
      const a4Width = 297; // A4 width in mm
      const a4Height = 210; // A4 height in mm
  
      // Calculate dimensions for 80% of the A4 page
      const imgWidth = a4Width * 0.7; // 80% of A4 width
      const imgHeight = a4Height * 0.6; // 80% of A4 height
      const bottomMargin = a4Height * 0.1;
      const topPosition = (a4Height - imgHeight - bottomMargin) / 2;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });
  
      // Calculate the number of pages needed
      const pageHeight = pdf.internal.pageSize.height; // Height of the PDF page
      const heightLeft = imgHeight;
  
      let position = 0;
  
      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', (a4Width - imgWidth) / 2, topPosition, imgWidth, imgHeight);
  
      // Save the PDF
      pdf.save(title +'.pdf');
    });
  }

  ngOnInit() {
  }


  exportChart(type: 'image/png' | 'application/pdf') {    
    if (typeof this.title === 'string') {
      console.log('string title', this.title);
    } else {
      console.log("object title", this.title);
    }
  
    // Find the chart instance
    
    // if (true) {
      // const newOptions: Highcharts.Options = {
      //   ...this.options,
      //   chart: {
      //     ...this.options.chart,
      //     backgroundColor: 'white',
      //     spacing: [50, 50, 50, 50],
      //     width: 1123,
      //     height: Math.round(1123 * 9 / 16),
      //   },
      //   title: {
      //     text: this.title ? (typeof this.title === 'string' ? this.title : 'chart') : '',
      //   },
      // };
  //     chart!.exportChart({
  //       type,
  //       filename: this.title ? (typeof this.title === 'string' ? this.title : 'chart') : 'chart'
  //     }, {
  //       chart: {
  //           type: 'spline',
  //           inverted: true
  //       },
  //       title: {
  //           text: 'Atmosphere Temperature by Altitude',
  //           align: 'left'
  //       },
  //       subtitle: {
  //           text: 'According to the Standard Atmosphere Model',
  //           align: 'left'
  //       },
  //       xAxis: {
  //           reversed: false,
  //           title: {
  //               // enabled: true,
  //               text: 'Altitude'
  //           },
  //           labels: {
  //               format: '{value} km'
  //           },
  //           accessibility: {
  //               rangeDescription: 'Range: 0 to 80 km.'
  //           },
  //           maxPadding: 0.05,
  //           showLastLabel: true
  //       },
  //       yAxis: {
  //           title: {
  //               text: 'Temperature'
  //           },
  //           labels: {
  //               format: '{value}°'
  //           },
  //           accessibility: {
  //               rangeDescription: 'Range: -90°C to 20°C.'
  //           },
  //           lineWidth: 2
  //       },
  //       legend: {
  //           enabled: false
  //       },
  //       tooltip: {
  //           headerFormat: '<b>{series.name}</b><br/>',
  //           pointFormat: '{point.x} km: {point.y}°C'
  //       },
  //       plotOptions: {
  //           spline: {
  //               marker: {
  //                   // enable: false
  //               }
  //           }
  //       },
  //       series: [{
  //         type:'line',
  //           name: 'Temperature',
  //           data: [
  //               [0, 15], [10, -50], [20, -56.5], [30, -46.5], [40, -22.1],
  //               [50, -2.5], [60, -27.7], [70, -55.7], [80, -76.5]
  //           ]
    
  //       }]
  //   });
  //   } else {
  //     console.error('Chart not found!');
    // }
  }
  
 
}
