import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { TablePageEvent } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { DonutChartComponent } from '../../shared/components/charts/donut-chart/donut-chart.component';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { MultiselectDropdownComponent } from '../../shared/components/multiselect-dropdown/multiselect-dropdown.component';
import { PopoverComponent } from '../../shared/components/popover/popover.component';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { SingleSelectComponent } from '../../shared/components/single-select/single-select.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { TabsComponent } from '../../shared/components/tabs/tabs.component';
import {
  DonutChartModel,
  ExecutionDelayHours,
} from '../../shared/interface/ChartTypes';
import { Area, DelayForm } from '../../shared/interface/delay-tracking';
import { ExecutionDelayFilter } from '../../shared/interface/Filters';
import { TabChangeEvent } from '../../shared/interface/Tab';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { delayTrackingServices } from '../../shared/services/delay-tracking/delay-tracking.service';
import { FileServiceService } from '../../shared/services/file-service/file-service.service';
import {
  downloadExportExcel,
  getFileNameFromS3Url,
} from '../../shared/utils/utility-functions';

@Component({
  selector: 'app-delay-tracking',
  standalone: true,
  imports: [
    SingleColumnComponent,
    CommonModule,
    DonutChartComponent,
    HeaderTextComponent,
    LoaderComponent,
    IconButtonComponent,
    PopoverComponent,
    TabsComponent,
    TableComponent,
    IconComponent,
    CustomDialogComponent,
    MultiselectDropdownComponent,
    SingleSelectComponent,
  ],
  templateUrl: './delay-tracking.component.html',
  styleUrl: './delay-tracking.component.css',
})
export class DelayTrackingComponent implements AfterViewInit {
  private delayTrackingService = inject(delayTrackingServices);
  private fileServiceService = inject(FileServiceService);

  private router = inject(Router);
  items = [
    { label: 'Open Items', type: 'Open Items' },
    { label: 'Closed Items', type: 'Closed' },
    { label: 'Pending', type: 'Pending' },
  ];

  @Output() outputRef!: ElementRef;
  delayHoursData: ExecutionDelayHours = { categories: [], series: [] };
  delayStatusData: DonutChartModel = {
    name: 'Execution Delay Status',
    data: [],
  };
  isLoading: boolean = false;
  activeIndex: number = 0;
  rows: number = 10;
  first: number = 0;
  delayTable: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  tableLoading: boolean = false;
  tabChangeLoading: boolean = false;
  delayTableTrigger$ = new BehaviorSubject<void>(undefined);
  delayStatusTrigger$ = new BehaviorSubject<void>(undefined);
  showImage: boolean = false;
  selectedRow: DelayForm | null = null;
  imageLoading: boolean = false;
  imageUrl: string = '';
  isFilter: boolean = false;
  isDonutChartLoading: boolean = false;
  area: Area[] = [];
  selectedArea: string = '';
  areaDropdownValues: { value: string; id?: string | number }[] = [];
  selectedDelayReason: string[] = [];
  delayReasonValues: { value: string; id?: any }[] = [];
  selectedLocation: string[] = [];
  locationValues: { value: string; id?: any }[] = [];
  isBarChartLoading: boolean = false;
  delayFilterTrigger = new BehaviorSubject<void>(undefined);
  @ViewChild('delayTableComponent', { static: true })
  delayTableComponent!: TemplateRef<any>;
  @ViewChild('imageDelayTableTemplate', { static: false })
  imageDelayTableTemplate!: TemplateRef<any>;
  @ViewChild('openFormDelayTableTemplate', { static: false })
  openFormDelayTableTemplate!: TemplateRef<any>;
  @ViewChild(TableComponent) tablecompnt!: TableComponent;
  activetab: string = 'Open Items';

  pageChange = (event: TablePageEvent) => {
    if (event.first !== undefined && event.rows !== undefined) {
      this.first = event.first;
      this.rows = event.rows;
      this.delayTableTrigger$.next();
    }
  };

  async fetchExecutionDelayStatus(filters: ExecutionDelayFilter) {
    this.isDonutChartLoading = true;
    this.delayTrackingService.getExecutionDelayStatus(filters).subscribe({
      next: (response) => {
        this.delayStatusData = response.data;

        this.isDonutChartLoading = false;
      },
      error: (err) => {
        this.isDonutChartLoading = false;
        console.error('error in get Execution Delay Status', err);
      },
    });
  }

  async fetchExecutionDelayHours(filters: ExecutionDelayFilter) {
    this.isBarChartLoading = true;
    this.delayTrackingService.getExecutionDelayHours(filters).subscribe({
      next: (response) => {
        const newDelyaHoursData = {
          categories: response.data.categories,
          series: response.data.series.map((el) => {
            return {
              ...el,
              color: '#ffad36',
            };
          }),
        };
        this.delayHoursData = newDelyaHoursData;

        this.isBarChartLoading = false;
      },
      error: (err) => {
        console.error('error in get Execution Delay Status', err);

        this.isBarChartLoading = false;
      },
    });
  }

  fetchDelayTableData = (isDownloadClicked?: boolean) => {
    this.tabChangeLoading = true;
    this.tableLoading = true;

    this.delayTrackingService
      .getStatusWiseFormData(
        this.items[this.activeIndex].type,
        Math.floor(this.first / this.rows),
        this.rows,
        isDownloadClicked
      )
      .subscribe({
        next: (res) => {
          if (isDownloadClicked) {
          } else {
            if (res.status === 'success') {
              const cols = res.data.columns.map((col: any) => {
                if (col.field === 'imageUrlKey') {
                  return {
                    ...col,
                    bodyTemplate: this.imageDelayTableTemplate,
                  };
                }
                return col;
              });

              this.delayTable = {
                columns: [
                  ...cols,
                  {
                    field: 'redirect',
                    header: 'Open Form',
                    bodyTemplate: this.openFormDelayTableTemplate,
                  },
                ],
                data: res.data.rows,
                count: res.data.count,
              };
            }
          }
          this.tableLoading = false;
          this.tabChangeLoading = false;
        },
        error: (err) => {
          console.error('Failed to fetch table data', err);
          this.tableLoading = false;
          this.tabChangeLoading = false;
        },
      });
  };
  fetchDelayTableDataForDownload = async (): Promise<Record<string, any>[]> => {
    const res = await firstValueFrom(
      this.delayTrackingService.getStatusWiseFormData(
        this.items[this.activeIndex].type,
        Math.floor(this.first / this.rows),
        this.rows,
        true
      )
    );
    return res.data.rows;
  };
  onClickImage = async (rowData: DelayForm) => {
    this.showImage = true;
    this.selectedRow = rowData;
    if (rowData.imageUrlKey) {
      this.imageLoading = true;
      if (!this.imageUrl[`${Number(rowData.id)}`]) {
        this.fileServiceService
          .getPresignedUrls('form', rowData.imageUrlKey)
          .subscribe({
            next: (res) => {
              this.imageUrl = res.data;
            },
          });
      }
      this.imageLoading = false;
    }
  };
  closeImageClick = () => {
    this.showImage = false;
  };

  getFileNameFromS3Url = (url: string) => {
    return getFileNameFromS3Url(url);
  };

  onOpenFormLinkClick = (rowData: DelayForm) => {
    this.router.navigate([`/delay-form/${rowData.id}`]);
  };
  onDelayFormClick() {
    console.log('clicked');
    this.router.navigate(['/delay-form/new']);
  }
  resetMainTable = () => {
    this.rows = 10;
    this.first = 0;
  };
  onTabChange = (d: TabChangeEvent) => {
    this.activeIndex = d.index;
    this.activetab = d.item.label;
    this.tabChangeLoading = true;
    this.resetMainTable();
    this.fetchDelayTableData();
  };
  onAreaSelectChange = (event: any) => {
    this.selectedArea = event.target.value;
    this.locationValues =
      this.area
        .find((area) => {
          return area.areaName === event.target.value;
        })
        ?.areaLocations.map((loc) => {
          return {
            value: loc.locationName,
            id: loc.id,
            name: loc.locationName,
          };
        }) || [];
    this.selectedLocation = [];
    this.selectedDelayReason = [];
    this.delayStatusTrigger$.next();
  };
  async fetchAreas() {
    this.isLoading = true;
    this.delayTrackingService.getAreas().subscribe({
      next: (response) => {
        this.area = response.data;
        this.areaDropdownValues = response.data.map((el) => {
          return {
            value: el.areaName,
            id: el.id,
          };
        });
        this.isLoading = false;
      },
      error: (err) => {
        console.error('error in get Areas', err);
        this.isLoading = false;
      },
    });
  }
  async fetchDelayReason() {
    this.delayTrackingService.getDelayReasons().subscribe({
      next: (response) => {
        this.delayReasonValues = response.data.map((el) => {
          return {
            value: el.reason,
            id: el.id,
          };
        });
      },
      error: (err) => {
        console.error('error in get Areas', err);
      },
    });
  }
  onChangeDelayReason(event: any) {
    this.selectedDelayReason = event.value;
    this.delayStatusTrigger$.next();
  }
  onDelaySubmissionFormClick = () => {
    console.log('clicked');

    this.router.navigate(['/delay-form/new']);
  };
  onChangeLocation(event: any) {
    this.selectedLocation = event.value;
    this.delayStatusTrigger$.next();
  }
  onFilterReset() {
    this.selectedDelayReason = [];
    this.selectedLocation = [];
    this.selectedArea = '';
    this.delayStatusTrigger$.next();
  }
  ngOnInit(): void {
    this.fetchAreas();
    this.fetchDelayReason();
    this.delayTableTrigger$.subscribe({
      next: () => {
        this.fetchDelayTableData();
      },
    });
    this.delayStatusTrigger$.subscribe({
      next: () => {
        this.fetchExecutionDelayHours({
          area: this.selectedArea,
          delayReason: this.selectedDelayReason,
          location: this.selectedLocation,
        });
        this.fetchExecutionDelayStatus({
          area: this.selectedArea,
          delayReason: this.selectedDelayReason,
          location: this.selectedLocation,
        });
        if (
          isEmpty(this.selectedArea) &&
          isEmpty(this.selectedDelayReason) &&
          isEmpty(this.selectedLocation)
        ) {
          this.isFilter = false;
        } else {
          this.isFilter = true;
        }
      },
    });
  }

  ngAfterViewInit(): void {
    this.tablecompnt.onDownloadClick = this.fetchDelayTableDataForDownload;
  }

  onDownloadClickOnTab = () => {
    downloadExportExcel(
      this.tablecompnt.onDownloadClick!,
      this.tablecompnt.cols,
      this.tablecompnt.appliedColumns,
      this.activetab
    );
  };
}
