import { Component, Input, Output, EventEmitter } from '@angular/core';

type TypeBtn = 'button' | 'reset' | 'submit';
@Component({
  selector: 'app-submit-button',
  standalone: true,
  imports: [],
  templateUrl: './submit-button.component.html',
  styleUrl: './submit-button.component.css',
})
export class SubmitButtonComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() type: TypeBtn = 'button';
  @Input() buttonStyles: { [key: string]: string | number } = {};

  @Output() onClick = new EventEmitter<void>();

  handleClick(): void {
    console.log('submitbtnclicked');

    this.onClick.emit();
  }
}
