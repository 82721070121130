import { Component, OnInit, SimpleChanges } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { CommonModule, Location } from '@angular/common';
import { Router } from '@angular/router';
import { ChipModule } from 'primeng/chip';
import { CommodityTrackingService } from '../../shared/services/commodity-tracking/commodity-tracking.service';
import {
  CommodityTracking,
  CommodityTrackingDetail,
  CommodityTrackingResponse,
  ICommodityTrackingForm,
  ICommodityTrackingOverallStatus,
  Milestone,
} from '../../shared/interface/CommodityTracking';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { convertDateToCommonFormat } from '../../shared/utils';
import { trim } from 'lodash-es';
import { PaginatorModule } from 'primeng/paginator';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { saveAsExcelFile } from '../../shared/utils/global-modules';

@Component({
  selector: 'app-commodity-tracking',
  standalone: true,
  imports: [
    ToastModule,
    HeaderTextComponent,
    CommonModule,
    ChipModule,
    DividerModule,
    DropdownModule,
    FormsModule,
    PaginatorModule,
    IconComponent,
    LoaderComponent,
  ],
  templateUrl: './commodity-tracking.component.html',
  styleUrl: './commodity-tracking.component.css',
})
export class CommodityTrackingComponent implements OnInit {
  pageNo: number = 0;
  first: number = 0;
  noOfRecords: number = 10;
  selectedStatus: 'delayed' | 'riskOfDelay' | 'onTime' | undefined = undefined;
  po?: string;
  expandedPo: string | null = null;
  loading: boolean = false;
  commodityData: CommodityTrackingResponse = { rows: [], count: 0 };
  reasonsAndCommentsForDelay: Record<
    string | number,
    { comment: string; reason: { id: any; value: string } }
  > = {};
  InitReasonsAndCommentsForDelay: Record<
    string | number,
    { comment: string; reason: { id: any; value: string } }
  > = {};
  editedReasonsAndCommentsForDelay: Record<
    string | number,
    { comment: string; reason: { id: any; value: string } }
  > = {};
  poDetailsData: CommodityTrackingDetail[] = [];
  overAllStatus: Partial<ICommodityTrackingOverallStatus> = {
    onTime: '',
    delayed: '',
    riskOfDelay: '',
  };
  statusColor = {
    ontime: 'green',
    'risk of delay': 'orange',
    late: 'red',
  };

  fixedColumnData = [
    { key: 'Ideal', value: 'idealCompletionDate' },
    { key: 'Act/Exp Date', value: 'expectedCompletionDate' },
    { key: 'Status', value: 'status' },
    { key: 'Reason For Delay', value: 'reasonForDelay' },
    // { key: 'Comment', value: 'comment' },
  ];

  milestoneData!: CommodityTrackingDetail;
  delayReasonValues: { value: string; id?: any }[] = [];
  convertDateToCommonFormat = (date: string) => {
    return convertDateToCommonFormat(date);
  };

  constructor(
    private router: Router,
    private location: Location,
    private commodityTrackingService: CommodityTrackingService
  ) {}

  onTimeStatusClick = (
    selectedStatus: 'delayed' | 'riskOfDelay' | 'onTime' | undefined
  ) => {
    if (selectedStatus && this.selectedStatus === selectedStatus) {
      this.selectedStatus = undefined;
    } else {
      this.pageNo = 0;
      this.first = 0;
      this.noOfRecords = 10;
      this.selectedStatus = selectedStatus;
      this.getCommodityData();
    }
    // if(selectedStatus) {
    //   this.pageNo = 0;
    //   this.first = 0;
    //   this.noOfRecords = 10;
    //   this.selectedStatus = selectedStatus;
    //   this.getCommodityData()
    // }
  };

  clearPo() {
    this.po = ''; // Clear 'po'
    this.location.replaceState(this.location.path(), ''); // Clear the state
    this.router.navigate([this.location.path()], { replaceUrl: true }); // Replace navigation
    this.getCommodityData();
  }

  getCommodityData = async () => {
    this.loading = true;
    const filters: any = {};
    if (this.po) {
      filters.po = this.po;
    }
    if (this.selectedStatus) {
      filters[this.selectedStatus] = 1;
    }
    this.commodityTrackingService
      .getCommodityData(
        {
          filters,
          noOfRecords: this.noOfRecords,
          pageNo: this.pageNo,
        },
        false
      )
      .subscribe({
        next: (response) => {
          this.commodityData = response.data;
          if (this.po) {
            this.onClickExpandPo(this.po);
          }
          this.loading = false;
        },
        error: (err) => {
          console.error('Failed to fetch Purchase Order Tracking data:', err);
        },
      });
  };

  getPoDetails = (po: string) => {
    this.loading = true;
    this.commodityTrackingService.getPoDetail(po).subscribe({
      next: (response) => {
        const poDetailsResponse = response.data;
        const reasonsAndComments: typeof this.reasonsAndCommentsForDelay = {};
        poDetailsResponse.forEach((el) => {
          if (el.milestoneCode) {
            reasonsAndComments[el.milestoneCode] = {
              comment: el.comment || '',
              reason: { id: el.reasonForDelay, value: el.reasonForDelay || '' },
            };
          }
        });
        this.reasonsAndCommentsForDelay = reasonsAndComments;
        this.InitReasonsAndCommentsForDelay = reasonsAndComments;
        this.poDetailsData = poDetailsResponse;
        this.expandedPo = po;
        this.loading = false;
      },
      error: (err) => {
        console.error('Failed to fetch PO Details data:', err);
      },
    });
  };

  ngOnInit(): void {
    const locationState = this.location.getState() as { po: string };
    if (locationState?.po) {
      this.po = locationState.po;
    }
    this.getCommodityData();
    this.fetchOverallStatus();
    this.fetchDelayReason();
  }

  onClickExpandPo = (po: string) => {
    if (po !== this.expandedPo) {
      this.getPoDetails(po);
    } else {
      this.expandedPo = null;
    }
  };

  // fetchDelayReasons = async () => {
  //   const res = await delayTrackingServices.getDelayReasons();
  //   if (res) {
  //     setDelayReasonDropdown(res.data.data);
  //   }
  // };
  fetchOverallStatus = async () => {
    this.commodityTrackingService.getOverallStatus().subscribe({
      next: (res) => {
        this.overAllStatus = res.data;
      },
      error: (error) => {
        console.error('failed to fetch Over all status data:', error);
      },
    });
  };

  overallStatusStyle() {
    const status = this.poDetailsData[0].overallStatus?.toLowerCase();
    const backgroundColor = status
      ? this.statusColor[status as keyof typeof this.statusColor] || 'inherit'
      : 'inherit';
    const color =
      status && this.statusColor[status as keyof typeof this.statusColor]
        ? 'white'
        : 'inherit';
    return { backgroundColor, color };
  }

  getMilestoneBadge(
    status: string,
    delayStatus: string
  ): { color: string; icon: string } {
    const color = delayStatus?.toLowerCase()
      ? this.statusColor[
          delayStatus.toLowerCase() as keyof typeof this.statusColor
        ] || 'blue'
      : 'blue';
    let icon = '';

    if (status?.toLowerCase() === 'completed') {
      icon = 'pi pi-check';
    } else if (status?.toLowerCase() === 'yet to start') {
      icon = 'pi pi-clock';
    } else if (
      status?.toLowerCase() === 'in progress' ||
      status?.toLowerCase() === 'inprogress'
    ) {
      icon = 'pi pi-spinner';
    }
    return { color, icon };
  }

  getMilestoneValue(item: CommodityTrackingDetail, key: string): any {
    return item[key as keyof Milestone] ?? '-';
  }

  handleReasonChange(options: any, milestoneCode: number): void {
    this.reasonsAndCommentsForDelay = {
      ...this.reasonsAndCommentsForDelay,
      [milestoneCode]: {
        ...this.reasonsAndCommentsForDelay[milestoneCode],
        reason: {
          id: options.id,
          value: options.value,
        },
      },
    };

    this.editedReasonsAndCommentsForDelay = {
      ...this.editedReasonsAndCommentsForDelay,
      [milestoneCode]: {
        ...this.editedReasonsAndCommentsForDelay[milestoneCode],
        reason: {
          id: options.id,
          value: options.value,
        },
      },
    };
  }

  onReset = () => {
    this.reasonsAndCommentsForDelay = this.InitReasonsAndCommentsForDelay;
    this.editedReasonsAndCommentsForDelay = {};
  };

  onClickSubmit = async () => {
    this.loading = true;
    if (this.expandedPo) {
      const data: ICommodityTrackingForm[] = [];
      const selectedCommodityData = this.commodityData.rows.find(
        (el) => el.po == this.expandedPo
      );
      if (selectedCommodityData) {
        for (const msCode in this.editedReasonsAndCommentsForDelay) {
          if (
            this.editedReasonsAndCommentsForDelay[msCode].comment ||
            this.editedReasonsAndCommentsForDelay[msCode].reason
          ) {
            data.push({
              comment: this.editedReasonsAndCommentsForDelay[msCode].comment,
              reasonForDelay:
                this.editedReasonsAndCommentsForDelay[msCode].reason.value,
              milestoneCode: Number(msCode),
              po: selectedCommodityData?.po!,
              projectCode: selectedCommodityData.projectCode,
            });
          }
        }
      }
      this.commodityTrackingService.saveCommentsAndReasons(data).subscribe({
        next: (res) => {
          if (res && res.data) {
            // toast.current?.show({
            //   severity: "info",
            //   summary: "Success",
            //   detail: "Reasons  saved successfully!",
            // });
            this.getPoDetails(this.expandedPo!);
          }
        },
      });
    } else {
      // toast.current?.show({
      //   severity: "error",
      //   summary: "Something went wrong!",
      //   detail: `${(res.data as any)?.message || ''}`,
      // });
      this.loading = false;
    }
  };

  isSubmitDisabled = () => {
    for (const key in this.editedReasonsAndCommentsForDelay) {
      if (
        trim(this.editedReasonsAndCommentsForDelay[key].comment) ||
        this.editedReasonsAndCommentsForDelay[key].reason
      ) {
        return false;
      }
    }
    return true;
  };

  onPageChange = (event: any) => {
    if (event.page !== undefined) {
      this.pageNo = event.page;
      this.noOfRecords = event.rows;
      this.first = event.first;
      this.getCommodityData();
    }
  };
  async fetchDelayReason() {
    this.commodityTrackingService.getDelayReasons().subscribe({
      next: (response) => {
        this.delayReasonValues = response.data.map((el) => {
          return {
            value: el.reason,
            id: el.id,
          };
        });
      },
      error: (err) => {
        console.error('error in get Areas', err);
      },
    });
  }
  exportExcel = (event: any) => {
    if (event) {
      const filters: any = {};
      if (this.po) {
        filters.po = this.po;
      }
      if (this.selectedStatus) {
        filters[this.selectedStatus] = 1;
      }
      this.commodityTrackingService
        .getCommodityData(
          {
            filters,
            noOfRecords: this.noOfRecords,
            pageNo: this.pageNo,
          },
          true
        )
        .subscribe({
          next: (response) => {
            // this.commodityData = response.data;
            const columns = [
              {
                header: 'Purchase Order',
                field: 'po',
              },
              {
                header: 'Material Type',
                field: 'materialType',
              },
              {
                header: 'Supplier',
                field: 'supplier',
              },
              {
                header: 'PO Release Date',
                field: 'poReleaseDate',
              },
              {
                header: 'Expected Delivery Date',
                field: 'expectedDeliveryDate',
              },
              {
                header: 'On Time',
                field: 'onTime',
              },
              {
                header: 'Risk Of Delay',
                field: 'riskOfDelay',
              },
              {
                header: 'Delayed',
                field: 'delayed',
              },
            ];
            import('xlsx').then((xlsx) => {
              const tableData = response.data.rows.map((row) => {
                const obj: Record<string, unknown> = {};
                columns.forEach((col) => {
                  obj[col.header] = row[col.field as keyof CommodityTracking];
                });
                return obj;
              });
              const worksheet = xlsx.utils.json_to_sheet(tableData);
              const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
              };
              const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
              });

              saveAsExcelFile(excelBuffer, 'Purchase Order Tracking');
            });
          },
          error: (err) => {
            console.error('Failed to fetch Purchase Order Tracking data:', err);
          },
        });
    }
  };
}
