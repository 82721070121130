<app-custom-dialog
  [maximizable]="false"
  [alreadyMaximized]="true"
  [visible]="isFormOpen"
  [title]="title"
  (visibleChange)="isFormOpen = $event"
  (closeDialogClick)="onCloseFormClick()"
  [style]="{ width: '100vw', height: '100vh', top: '0', left: '0' }"
>
  <ng-template #submitTitle> </ng-template>
  <app-custom-dialog
    [maximizable]="false"
    titleString="Confirmation"
    [visible]="submitDialog"
    [title]="submitTitle"
    (visibleChange)="submitDialog = $event"
    (closeDialogClick)="onCancelConfirmSubmitPopup()"
    [style]="{ width: '40vw' }"
  >
    <div style="display: block; height: 100%">
      <div style="display: block; justify-content: start">
        <div>Are you sure you want to <strong>submit</strong> the form?</div>
      </div>
      <div
        style="
          display: flex;
          justify-content: end;
          align-content: end;
          gap: 0.7rem;
          margin-top: 2rem;
        "
      >
        <app-cancel-button
          label="No"
          (onClick)="onCancelConfirmSubmitPopup()"
        />
        <app-submit-button
          type="button"
          label="Yes"
          (onClick)="onSubmitForm()"
        />
      </div>
    </div>
  </app-custom-dialog>
  <ng-template #title>
    <div class="flex title" style="display: flex; gap: 2px">
      <div>
        <app-header-text [label]="'Document Control'" style="color: #29c8c1" />
      </div>
    </div>
  </ng-template>

  <form [formGroup]="form" (ngSubmit)="onSubmitFormOpen()">
    <!-- Document Details -->
    <div class="form-card">
      <div class="section-div">
        <div class="section-header-text">Document Details</div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="documentTitle" class="custom-label">
              Document Title<span
                style="color: red; font-weight: bold; font-size: 0.8rem"
              >
                *</span
              >
            </label>
            <p-autoComplete
              class="custom-autocomplete"
              id="documentTitle"
              placeholder="Document Title"
              [suggestions]="filteredDocuments!"
              (completeMethod)="fetchDocumentTitles($event)"
              optionLabel=""
              formControlName="documentTitleField"
              (onSelect)="onTitleChange($event)"
              [style]="{
                height: '1.8rem',
                fontSize: '12px',
                width: '100%',
                borderRadius: '2px',
                border: '1px solid #d1d5db'
              }"
            />
            <div style="height: 1rem;">
            @if(documentTitleField?.invalid && documentTitleField?.touched) {
            <small class="p-error">this field is required.</small>
            }
            </div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="documentNumber" class="custom-label">
              Document Number<span
                style="color: red; font-weight: bold; font-size: 0.8rem"
              >
                *</span
              >
            </label>
            <p-dropdown
              id="documentNumber"
              placeholder="Enter Document Number"
              [options]="documentNumberOptions()"
              optionLabel="value"
              formControlName="documentNumberField"
              class="custom-dropdown"
            />
            <div style="height: 1rem;">
            @if(documentNumberField?.invalid && documentNumberField?.touched) {
            <small class="p-error">this field is required.</small>
            }
            </div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="versionNumber" class="custom-label">
              Version Number<span
                style="color: red; font-weight: bold; font-size: 0.8rem"
              >
                *</span
              >
            </label>
            <p-dropdown
              id="versionNumber"
              placeholder="Enter Version Number"
              [options]="versionNumberOptions()"
              optionLabel="value"
              formControlName="versionNumberField"
              (onChange)="onVersionChange($event)"
              [style]="{
                height: '1.8rem',
                fontSize: '12px',
                width: '100%',
                borderRadius: '2px',
                border: '1px solid #d1d5db'
              }"
            />
            <div style="height: 1rem;">
            @if(versionNumberField?.invalid && versionNumberField?.touched) {
            <small class="p-error">this field is required.</small>
            }
            </div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="departmentDiscipline" class="custom-label">
              Department/Discipline
            </label>
            <input
              id="departmentDiscipline"
              class="custom-textField"
              placeholder="Enter Department/Discipline"
              pInputText
              formControlName="departmentDisciplineField"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="reporter" class="custom-label"> Reporter </label>
            <p-dropdown
              id="reporter"
              placeholder="Select Reporter"
              [options]="userData()"
              optionLabel="value"
              formControlName="reporterField"
              class="custom-dropdown"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="creationDate" class="custom-label">
              Creation Date<span
                style="color: red; font-weight: bold; font-size: 0.8rem"
              >
                *</span
              >
            </label>
           
            <input
              id="creationDate"
              class="custom-dateField"
              placeholder="Select Creation Date"
              type="date"
              formControlName="creationDateField"
            />
            <div style="height: 1rem;">
            @if(creationDateField?.invalid && creationDateField?.touched) {
            <small class="p-error">this field is required.</small>
            }
            </div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="effectiveDate" class="custom-label">
              Effective date
            </label>
            
            <input
              id="effectiveDate"
              placeholder="Select Effective Date"
              type="date"
              [min]="form.get('creationDateField')?.value"
              class="custom-dateField"
              formControlName="effectiveDateField"
            />
            <div style="height: 1rem;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Document History -->
    <div class="form-card">
      <div class="section-div">
        <div class="section-header-text">Document History</div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px; height: fit-content"
          >
            <label for="previousVersion" class="custom-label">
              Previous Version
            </label>
            <p-dropdown
              id="previousVersion"
              placeholder="Enter Previous Version"
              [options]=""
              optionLabel="value"
              formControlName="previousVersionField"
              [style]="{
                height: '1.8rem',
                fontSize: '12px',
                width: '100%',
                borderRadius: '2px',
                border: '1px solid #d1d5db'
              }"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px; height: fit-content"
          >
            <label for="documentLocationFilePath" class="custom-label">
              Document Location/File Path
            </label>
            <input
              id="documentLocationFilePath"
              class="custom-textField"
              placeholder="Enter Document Location/File Path"
              pInputText
              formControlName="documentLocationFilePathField"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="changeSummary" class="custom-label">
              Change Summary
            </label>
            <textarea
              id="changeSummary"
              class="custom-textarea"
              placeholder="Enter Summary of Changes Made in the Current Version"
              rows="5"
              cols="30"
              pInputTextarea
              formControlName="changeSummaryField"
            ></textarea>
            <div style="height: 1rem;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Review and Approval -->
    <div class="form-card">
      <div class="section-div" [title]="'Review and Approval'">
        <div class="section-header-text">Review and Approval</div>
        <div class="row">
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="reviewerName" class="custom-label">
              Reviewer Name
            </label>
            <p-dropdown
              id="reviewerName"
              placeholder="Select Reviewer Name"
              [options]="userData()"
              optionLabel="value"
              formControlName="reviewerNameField"
              [style]="{
                height: '1.8rem',
                fontSize: '12px',
                width: '100%',
                borderRadius: '2px',
                border: '1px solid #d1d5db'
              }"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="reviewDate" class="custom-label"> Review Date </label>
           
            <input
              id="reviewDate"
              placeholder="Select Review Date"
              type="date"
              [min]="form.get('creationDateField')?.value"
              class="custom-dateField"
              formControlName="reviewDateField"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="approvalDate" class="custom-label">
              Approval Date
            </label>
           
            <input
              id="approvalDate"
              placeholder="Select Approval Date"
              type="date"
              [min]="form.get('creationDateField')?.value"
              class="custom-dateField"
              formControlName="approvalDateField"
            />
            <div style="height: 1rem;"></div>
          </div>
          <div
            class="col-12 col-md-6 col-lg-3"
            style="display: grid; margin-top: 10px"
          >
            <label for="currentStatus" class="custom-label">
              Current Status
            </label>
            <p-dropdown
              id="currentStatus"
              placeholder="Enter Current Status"
              [options]="currentStatusData()"
              optionLabel="value"
              formControlName="currentStatusField"
              class="custom-dropdown"
            />
            <div style="height: 1rem;"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- Buttons -->
    <div
      style="
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding-bottom: 2rem;
      "
    >
      <app-cancel-button
        [label]="'Cancel'"
        [buttonStyles]="{}"
        (onClick)="onCloseFormClick()"
      />
      <app-submit-button
        [label]="'Submit'"
        [disabled]="form.invalid"
        [type]="'submit'"
        [buttonStyles]="{}"
      />
    </div>
  </form>
</app-custom-dialog>
