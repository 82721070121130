@if(label){
  <label class="custom-label">
    {{ label }}
  </label>
}

<p-multiSelect
  display="chip"
  [options]="mappedOptions"
  [placeholder]="placeholder"
  [disabled]="disabled!"
  [style]="{ width: '100%', height: '1.8rem'}"
  [showToggleAll]="true"
  selectAllLabel="Select All"
  optionLabel="label"
  optionValue="value"
  [(ngModel)]="value"
  (onChange)="handleChange($event)"
>
</p-multiSelect>
