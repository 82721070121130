import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { isEmpty } from 'lodash-es';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { AiAssistServiceService } from '../../shared/services/home-ai-assist-service/ai-assist-service.service';
import { toTitleCase } from '../../shared/utils/utility-functions';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
type chatSessionIdDataType = {
  message: {
    message: any[];
    type: string;
  };
  sender: string;
  timestamp: string;
};

@Component({
  selector: 'app-ai-assist',
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    TableModule,
    FormsModule,
    IconComponent,
    FormsModule,
    HeaderTextComponent,
  ],
  templateUrl: './ai-assist.component.html',
  styleUrl: './ai-assist.component.css',
})
export class AiAssistComponent
  implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked
{
  private readonly destroy$ = new Subject<void>();
  private readonly aiAssistServiceService = inject(AiAssistServiceService);
  @ViewChild('chatMsg') chatMessage!: any;

  @ViewChild('chatContainer') chatContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('faqs') faqs!: ElementRef<HTMLDivElement>;
  @ViewChild('mainContainer') mainContainer!: ElementRef<HTMLDivElement>;
  @ViewChild('categoriesContainer')
  categoriesContainer!: ElementRef<HTMLDivElement>;

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent): void {
    this.sendMessages();
    event.preventDefault();
  }
  isLoading: boolean = false;
  loading: boolean = true;
  categoryData: any;
  sampleQuestions: string[] = [];
  selectedCategory: string | null = null;
  selectedQue: string | null = null;
  sessionWithCategoryData: any = {};
  chatSessionIdData: chatSessionIdDataType = {
    message: {
      message: [],
      type: '',
    },
    sender: '',
    timestamp: '',
  };
  chatMessages: {
    type: 'sent' | 'recieved' | 'assistant' | 'user';
    message: any;
  }[] = [
    {
      type: 'sent',
      message: '',
    },
  ];
  allChats: string[] = [];

  ngOnInit(): void {
    this.getAIAssistCategoryData();
    this.checkChatMessage();
    window.addEventListener('resize', this.calculateContanerHeight);
  }

  ngAfterViewInit(): void {
    this.calculateContanerHeight();
  }
  getAIAssistCategoryData = () => {
    this.aiAssistServiceService.getCategoryData().subscribe({
      next: (res) => {
        this.categoryData = res;
        this.loading = false;
      },
      error: (err) => {
        console.error('Failed to fetch catergory data', err);
        this.loading = false;
      },
    });
  };
  onCategoryClick = (categoryName: string) => {
    this.isLoading = true;
    this.destroy$.next();
    if (!this.categoryData) return;
    this.selectedCategory =
      this.selectedCategory === categoryName ? null : categoryName;
    const selectedCategoryData = this.categoryData.find(
      (el: any) => el.name === categoryName
    );
    this.sampleQuestions = selectedCategoryData?.sample_questions || [];
    if (!categoryName) return;
    if (!isEmpty(this.sessionWithCategoryData)) {
      const initialChatMessages = this.sessionWithCategoryData.messages.map(
        (el: any) => el.message
      );

      this.chatMessages = initialChatMessages.map((message: any) => ({
        type: 'received',
        message,
      }));
    }

    // Fetch session with the selected category
    this.getSessionWithCategory(categoryName);
    this.isLoading = false;
    this.scrollToBottomMainPage();
    setTimeout(() => {
      this.calculateContanerHeight();
    }, 0);
  };

  calculateContanerHeight = () => {
    const faqH = this.faqs.nativeElement.clientHeight;
    const mainH = this.mainContainer.nativeElement.clientHeight;
    const categoriesContainerH =
      this.categoriesContainer.nativeElement.clientHeight;
    this.chatContainer.nativeElement.style.height = `${
      mainH - faqH - categoriesContainerH
    }px`;
  };

  getSessionWithCategory = (category: string) => {
    this.isLoading = true;
    this.aiAssistServiceService.getSessionWithCategory(category).subscribe({
      next: (res) => {
        this.sessionWithCategoryData = res;
        const initailChatMsg = res.messages.map((el: any) => el.message);

        initailChatMsg.map((el: any) => {
          this.chatMessages = this.chatMessages.map((vl) => {
            return {
              type: 'recieved',
              message: el.message,
            };
          });
        });
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        console.error('Failed to fetch session data with category', err);
      },
    });
  };

  getInitialMessageForInputChat = () => {
    if (!isEmpty(this.sessionWithCategoryData)) {
      const initailChatMsg = this.sessionWithCategoryData.messages.map(
        (el: any) => el.message
      );
      const initialchat = initailChatMsg.map((el: any) => el.message);
      this.chatMessages = this.chatMessages.map((el) => {
        return {
          type: 'recieved',
          message: initialchat[0],
        };
      });
    }
  };

  onSelectQuestion = (que: string) => {
    if (que) {
      this.selectedQue = que;
      if (this.selectedQue) {
        this.chatMessages.push({
          type: 'sent',
          message: this.selectedQue,
        });
        this.chatWithSessionId(
          this.sessionWithCategoryData.session_id,
          this.selectedQue
        );
        setTimeout(() => {
          this.selectedQue = '';
        }, 400);
      }
    }
  };

  onInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    this.selectedQue = inputElement.value; // Manually update value
  }

  sendMessages() {
    if (this.selectedQue && this.sessionWithCategoryData.session_id) {
      this.chatMessages.push({
        type: 'sent',
        message: this.selectedQue,
      });
      this.chatWithSessionId(
        this.sessionWithCategoryData.session_id,
        this.selectedQue
      );
      this.selectedQue = null;
    }
  }

  chatWithSessionId = (sessionId: string, message: string) => {
    this.isLoading = true;
    this.aiAssistServiceService
      .chatWithSessionId(sessionId, message)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.chatSessionIdData = res;
          const hasMessages = this.chatMessages.some(
            (el) => !Array.isArray(el.message) && el.message.trim() !== ''
          );
          if (!hasMessages) {
            this.getAllChats();
          }
          this.chatMessages.push({
            type: 'recieved',
            message: res.message.message,
          });
          this.isLoading = false;
        },
        error: (err) => {
          console.error('Failed to fetch chat data with session id', err);
        },
      });
  };

  getAllChats = () => {
    this.aiAssistServiceService.getAllChats().subscribe({
      next: (res) => {
        this.allChats = res;
      },
      error: (error) => {
        console.error('Failed to fetch all chats', error);
      },
    });
  };

  getKeys = (msg: any) => {
    const column = Object.keys(msg[0]);
    return column.map((el) => {
      return {
        field: el,
        header: el,
      };
    });
  };
  checkArrayObjects = (data: any): boolean => {
    return (
      Array.isArray(data) &&
      data.every((item) => typeof item === 'object' && item !== null)
    );
  };
  checkChatMessage = () => {
    const d = this.chatMessages.some((msg) => msg.message);
    return d;
  };

  ngAfterViewChecked() {
    if (this.chatMessages.length > 0) {
      this.scrollToBottom();
    }
  }

  scrollToBottom(): void {
    if (this.chatMessage?.nativeElement) {
      const container = this.chatMessage.nativeElement;
      container.scrollTo({
        top: container.scrollHeight,
        behavior: 'smooth', // Enable smooth scrolling
      });
    }
  }
  scrollToBottomMainPage = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  onChatHistoryClick = (e: string) => {
    if (e) {
      this.aiAssistServiceService.getChats(e).subscribe({
        next: (res) => {
          this.chatMessages = res.messages.map((el: any) => {
            return {
              message: el.message.message,
              type: el.sender,
            };
          });
        },
      });
    }
  };
  getHighlightedClass = (que: string) => {
    let filteredData = this.chatMessages.filter((el) => el.type === 'sent');
    if (filteredData.length > 0) {
      if (filteredData[filteredData.length - 1].message === que) {
        return '#d6d6d6';
      }
      return '';
    } else {
      return null;
    }
  };

  convertTitleCase = (header: string) => {
    return toTitleCase(header);
  };
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    window.removeEventListener('resize', this.calculateContanerHeight);
  }
}
