import { Component, Input, Output, OnInit } from '@angular/core';
import { CustomDialogComponent } from "../../../shared/components/custom-dialog/custom-dialog.component";
import { HeaderTextComponent } from "../../../shared/components/header-text/header-text.component";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { PrimeNGConfig } from 'primeng/api';
import { CancelButtonComponent } from "../../../shared/components/cancel-button/cancel-button.component";
import { SubmitButtonComponent } from "../../../shared/components/submit-button/submit-button.component";
import { debounce, uniqBy } from 'lodash-es';
import { DocumentControlService } from '../../../shared/services/document-control/document-control.service';
import { UserService } from '../../../shared/services/user/user.service';
import { User } from '../../../shared/interface/user';
import { DocumentControlFormFilter } from '../../../shared/interface/Filters';
import { IDocumentControlForm, ISubmitDocumentControlForm } from '../../../shared/interface/DocumentControlForm';
import { MasterDataServiceService } from '../../../shared/services/master-data-service/master-data-service.service';
import { MasterData } from '../../../shared/interface/MasterData';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CardComponent } from "../../../shared/components/card/card.component";
import { globalModules } from '../../../shared/utils/global-modules';
import dayjs from 'dayjs';


@Component({
  selector: 'app-document-control-form',
  standalone: true,
  imports: [CustomDialogComponent, HeaderTextComponent, ReactiveFormsModule, DropdownModule, AutoCompleteModule, CancelButtonComponent, SubmitButtonComponent, NgbDatepickerModule, ...globalModules],
  templateUrl: './document-control-form.component.html',
  styleUrl: './document-control-form.component.css'
})
export class DocumentControlFormComponent implements OnInit {
  form!: FormGroup;
  @Input() isFormOpen: boolean = false;
  @Input() onCloseForm: (() => void) | undefined;
  submitDialog: boolean = false;
  documents: any[] | undefined;
  filteredDocuments: any[] | undefined;
  documentTitles: string[] = [];
  docNumberVersion: { documentNo: string, version: number | string }[] = [];
  users: User[] = [];
  title: string = '';
  documentControlForm: IDocumentControlForm = {};
  currentStatusOptions: MasterData[] = [];

  constructor(
    private fb: FormBuilder,
    private primengConfig: PrimeNGConfig,
    private documentControlService: DocumentControlService,
    private userService: UserService,
    private masterDataServiceService: MasterDataServiceService
  ) {
    this.primengConfig.ripple = true;
    this.form = this.fb.group({
      documentTitleField: new FormControl(null),
      documentNumberField: ['', [Validators.required]],
      versionNumberField: [null, [Validators.required]],
      departmentDisciplineField: [{ value: undefined, disabled: true}],
      reporterField: [],
      creationDateField: [[Validators.required]],
      effectiveDateField: [],
      previousVersionField: [],
      documentLocationFilePathField: [],
      changeSummaryField: [],
      reviewerNameField: [],
      reviewDateField: [],
      approvalDateField: [],
      currentStatusField: []
    })
  }

  get documentTitleField() {
    return this.form.get('documentTitleField');
  }

  get documentNumberField() {
    return this.form.get('documentNumberField');
  }

  get versionNumberField() {
    return this.form.get('versionNumberField');
  }

  get creationDateField() {
    return this.form.get('creationDateField');
  }

  onCloseFormClick = () => {
    if (this.onCloseForm) {
      this.isFormOpen = false;
      this.form.reset();
      return this.onCloseForm()
    }
  }

//   filterDocument(event: AutoCompleteCompleteEvent) {
//     let filtered: any[] = [];
//     let query = event.query;

//     for (let i = 0; i < (this.documentTitles as any[]).length; i++) {
//         let document = (this.documentTitles as any[])[i];
//         if (document.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
//             filtered.push(document);
//         }
//     }

//     this.filteredDocuments = filtered;
// }

fetchDocumentTitles = (e: AutoCompleteCompleteEvent) => {
  const queryy = e?.query?.trim();  
  if (queryy?.length > 3) {
    this.documentControlService.getDocumentTitles(queryy).subscribe({
      next: (res) => {
        this.documentTitles =  res.data;
        let filtered: any[] = [];
    // let query = e.query;
    if(this.documentTitles.length) {
      for (let i = 0; i < (this.documentTitles as any[]).length; i++) {
          let document = (this.documentTitles as any[])[i];
              filtered.push(document);
      }
      this.filteredDocuments = filtered;
    }
      },
      error: (err) => {
        console.error('Failed to fetch Document Title data:', err);
      }
    })
  } else {
    this.documentTitles = [];
  }
}

onTitleChange = (e: any) => {
  const { value } = e
  // onChange(e)
  if (e.originalEvent?.type === 'click' || e.originalEvent?.key === 'Enter') {
    this.title = value
    this.fetchDocumentNumberVersion(value);

  }
}

// getDocumentTitleData = () => {
//   if(this.filteredDocuments?.length) {
//     return this.filteredDocuments.map((el, indx) => {
//       return { value: el, id: indx }
//     })
//   }
//   return [];
// }

// debouncedFetchTitles = debounce( (query: string) => {
//   // const res = await documentControlService.getDocumentTitles(query);
//   this.documentControlService.getDocumentTitles(query).subscribe({
//     next: (res) => {
//       this.documentTitles =  res.data;
//     },
//     error: (err) => {
//       console.error('Failed to fetch Document Title data:', err);
//     }
//   })
// }, 1000);

fetchDocumentNumberVersion = async (title: string) => {
  this.documentControlService.getDocumentNumberVersion(title).subscribe({
    next: (res) => {
      this.docNumberVersion =  res.data;
      this.documentNumberOptions();
      this.versionNumberOptions();
    },
    error: (err) => {
      console.error('Failed to fetch Document Title data:', err);
    }
  })
}

documentNumberOptions = () => {
  const uniqueDocumentNos = [...new Set(this.docNumberVersion.map((item) => item.documentNo))];
  return uniqueDocumentNos.map((documentNo) => ({
    value: documentNo,
  }));
};

versionNumberOptions = () => {
  return uniqBy(this.docNumberVersion.flatMap((doc) => {
    if (this.form.get('documentNumberField')?.value?.value === doc.documentNo) {
      return [{
        value: doc.version, id: doc.version
      }]
    }
    return []
  }), 'value')
}

fetchDocumentFormDetails = (filters: DocumentControlFormFilter) => {
  this.documentControlService.getDocumentFormDetails(filters).subscribe({
    next: (res) => {
      this.documentControlForm = res.data;
      this.form.patchValue({
        departmentDisciplineField: this.documentControlForm.discipline
      });
    }
  })
}

  onVersionChange = (e: any) => {
  if (e.originalEvent?.type === 'click' || e.originalEvent.key === 'Enter') {
    this.fetchDocumentFormDetails({
      title: this.form.get('documentTitleField')?.value,
      version: this.form.get('versionNumberField')?.value.value,
      documentNo: this.form.get('documentNumberField')?.value.value
    });  
  }
}

fetchUsers = () => {
  this.userService.getUsers().subscribe({
    next: (res) => {
      this.users = res.data;
    },
    error: (error) => {
      console.error('Failed to fetch User data:', error);
    }
  })
};

userData = () => {
    return this.users.map((user) => {
      return { value: user.userName, id: user?.id };
    })
}

fetchCurrentStatus = () => {
  const query = {
    module: 'DOC_CONTROL',
    key: 'current_status'
  }
  this.masterDataServiceService.getMasterData(query).subscribe({
    next: (res) => {
      this.currentStatusOptions = res.data.rows
    },
    error: (err) => {
      console.log('Failed to fetch current status data:', err);
    }
  })
}

currentStatusData = () => {
  return this.currentStatusOptions.map((currStatus) => {
    return { value: currStatus.value }
  })
}

ngOnInit(): void {
  this.fetchUsers();
  this.fetchDocumentTitles('' as unknown as any);
  this.fetchCurrentStatus();
  // this.form.get('documentTitleField')?.valueChanges.subscribe(() => {
  //   this.fetchDocumentNumberVersion(this.title);
  // })
}

onCancelConfirmSubmitPopup = () => {
  this.submitDialog = false;
}

onSubmitFormOpen = () => {
  this.submitDialog = true;
}

onSubmitForm = async () => {
  const userIdToReplace = new Set([
    "authorReporter",
    "reviewerName",
  ]);
  const mappedData: ISubmitDocumentControlForm = {
    documentTitle: this.title,
    documentNumber: this.form.get('documentNumberField')?.value?.value,
    versionNumber: `${this.form.get('versionNumberField')?.value?.value}`,
    departmentDiscipline: this.form.get('departmentDisciplineField')?.value,
    authorReporter: this.form.get('reporterField')?.value?.value,
    creationDate: this.form.get('creationDateField')?.value,
    effectiveDate: this.form.get('effectiveDateField')?.value,
    previousVersions: this.form.get('previousVersionField')?.value,
    changeSummary: this.form.get('changeSummaryField')?.value,
    documentLocationFilePath: this.form.get('documentLocationFilePathField')?.value,
    reviewerName: this.form.get('reviewerNameField')?.value?.value,
    reviewDate: this.form.get('reviewDateField')?.value,
    approvalDate: this.form.get('approvalDateField')?.value,
    currentStatus: this.form.get('currentStatusField')?.value?.value,
  };
  
  const tempSubmitForm: ISubmitDocumentControlForm = { ...mappedData };

  Object.keys(tempSubmitForm).forEach((el) => {
    if (userIdToReplace.has(el) && mappedData[el as keyof ISubmitDocumentControlForm]) {
      tempSubmitForm[el as keyof ISubmitDocumentControlForm] = (this.users.find((user) => {
        return user.userName === tempSubmitForm[el as keyof ISubmitDocumentControlForm];
      })?.id || null) as unknown as never;
    }
    if (el?.toLowerCase().includes('date')) {
      tempSubmitForm[el as keyof ISubmitDocumentControlForm] = tempSubmitForm[el as keyof ISubmitDocumentControlForm] ? dayjs(tempSubmitForm[el as keyof ISubmitDocumentControlForm]).toDate() as any : tempSubmitForm[el as keyof ISubmitDocumentControlForm]
    }
  });


  const formData = new FormData();

  for (const key in tempSubmitForm) {
    const property = key as keyof ISubmitDocumentControlForm;
    if (tempSubmitForm[property]) {
      formData.append(property, tempSubmitForm[property].toString());
    }
  }


  this.documentControlService.submitDocumentControlForm(tempSubmitForm).subscribe({
    next: (res) => {
      if(res) {
        this.submitDialog = false;
        this.onCloseFormClick();
      }
    },
    error: (err) => {
      console.error('Failed to submit document control form:', err);
    }
  })
};



}
