// tabs.interface.ts


// tabs.component.ts
import { Component, Input, Output, EventEmitter, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabChangeEvent, TabItem } from '../../interface/Tab';
import { CardComponent } from "../card/card.component";
import { LoaderComponent } from "../loader/loader.component";
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'app-tabs',
  standalone: true,
  imports: [CommonModule, CardComponent, LoaderComponent, IconComponent],
  templateUrl: './tabs.component.html',
  styleUrl: './tabs.component.css'
})
export class TabsComponent {
  @Input() model: TabItem[] = [];
  @Input() activeIndex = 0;
  @Output() tabChange = new EventEmitter<TabChangeEvent>();
  @Input() templateToLoad!: TemplateRef<any>;
  @Input() loading: boolean = false;;
  @Input() onDownloadClick?: () => void;

  onTabClick(event: Event, index: number, item: TabItem): void {
    event.preventDefault();
    this.tabChange.emit({
      originalEvent: event,
      index: index,
      item: item
    });
  }
}