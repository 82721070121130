<div class="icon-button-wrapper">
    <button
      [ngbTooltip]="title ? title : ''"
      [disabled]="disabled"
      (click)="onClickButton($event)"
      [ngStyle]="{
        'background-color': transparent ? 'transparent' : 'inherit',
        border: 'none',
        outline: 'none',
        'box-shadow': 'none'
      }"
      class="custom-button"
    >
    <app-icon [icon]="icon" [color]="iconColor"></app-icon>
      <span *ngIf="label" [ngStyle]="labelStyle" class="custom-icon-label">{{ label }}</span>
      <span
        *ngIf="superScriptIconVal"
        class="custom-badge"
      ></span>
    </button>
  </div>
  