@if (fieldConfigs.length) {
<form [formGroup]="form" (ngSubmit)="onSubmitForm()">
  @if (isFormEditable) {
  <div class="sections-container" cdkDropList (cdkDropListDropped)="dropSection($event)">
    <ng-template #dragicon>
      <div class="drag-handle section-handle" cdkDragHandle>
        ⋮⋮
      </div>
    </ng-template>
    @for (section of fieldConfigs; track section.sectionName) {
    <div class="mb-2" cdkDrag>

      <app-card [title]="section.sectionName" [endElement]="dragicon">
        <div class="container mb-1">
          <div class="row fields-container" cdkDropList cdkDropListOrientation="mixed"
            [id]="'fields-list-' + section.sectionSortOrder"
            (cdkDropListDropped)="dropField($event, section.sectionSortOrder)">

            @for (field of section.fields; track field.name) {
            <div class="col-lg-3 col-md-12 mb-3 drag-handle field-handle'" cdkDrag>
              <app-dynamic-field [fieldConfig]="field" [form]="form">
              </app-dynamic-field>
            </div>
            }
          </div>
        </div>

        
      </app-card>

    </div>
    }
  </div>
  } @else {

  <div>

    @for (section of fieldConfigs; track section.sectionName) {
    <div class="mb-2">
      <app-card [title]="section.sectionName">

        <div class="container mb-1">
          <div class="row fields-container">

            @for (field of section.fields; track field.name) {
            <div class="col-lg-3 col-md-12 mb-3">
              <app-dynamic-field [fieldConfig]="field" [form]="form">
              </app-dynamic-field>
            </div>
            }
          </div>
        </div>
      </app-card>

    </div>
    }
  </div>
  }
  @if (!avoidSubmitButton) {

  <div class="d-flex w-100">
    <div class="d-flex ml-auto gap-2">
      <button type="submit" class="btn btn-success">{{submitButtonLabel || 'Submit'}}</button>
      <button type="button" class="btn btn-secondary" (click)="onReset()">Reset</button>

    </div>
  </div>
  }
</form>

} @else {
...loading
}