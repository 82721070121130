import { Component, inject } from '@angular/core';
import { TablePageEvent } from 'primeng/table';
import {
  ChartDataType,
  ProjectScheduleTableData,
} from '../../shared/interface/ChartTypes';
import { ScurveDataCardModel } from '../../shared/interface/ScurveDataCardModel';
import { OverallScurveModel } from '../../shared/types/OverallScurveModel';
import { TableData } from '../../shared/types/TableProps';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ScurvedataCardComponent } from '../../shared/components/scurvedata-card/scurvedata-card.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { OverallScurveServices } from '../../shared/services/overall-scurve/overall-scurve.service';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { Router } from '@angular/router';
import { ProcurementScurveServices } from '../../shared/services/procurement-scurve/procurement-scurve.service';

@Component({
  selector: 'app-procurement-scurve',
  standalone: true,
  imports: [
    SingleColumnComponent,
    LoaderComponent,
    ScurvedataCardComponent,
    TableComponent,
    HeaderTextComponent,
    IconButtonComponent,
  ],
  templateUrl: './procurement-scurve.component.html',
  styleUrl: './procurement-scurve.component.css',
})
export class ProcurementScurveComponent {
  private ProcurementScurveService = inject(ProcurementScurveServices);
  private router = inject(Router);
  first: number = 0;
  page: number = 0;
  rowsPerPage: number = 10;
  procurementScurveData: OverallScurveModel = {
    chartData: {} as ChartDataType,
    tableData: {} as TableData,
    metaData: [] as ScurveDataCardModel[],
    monthVal: '',
  } as OverallScurveModel;
  loading: boolean = false;
  paginationOptions:
    | {
        paginationRequired?: boolean;
        onChangePage?: (event: TablePageEvent) => void;
        onRowChange?: () => void;
        rows?: number;
        totalRecords?: number;
        first?: number;
      }
    | undefined = {
    first: this.first,
    rows: this.rowsPerPage,
    onChangePage: this.pageChange,
    paginationRequired: false,
  };

  pageChange(event: TablePageEvent) {
    this.first = event.first;
    this.rowsPerPage = event.rows;
  }
  fetchProcurementScurveData(filters: any) {
    this.loading = true;
    this.ProcurementScurveService.getProcurementSCurve(filters).subscribe({
      next: (response) => {
        this.procurementScurveData = {
          ...response.data,

          tableData: {
            columns: response.data.tableData.columns.map((el) => {
              return {
                ...el,
                style:
                  response.data.monthVal === el.field
                    ? {
                      backgroundColor: 'rgba(255, 255, 0, 0.5)',
                      }
                    : undefined,
              };
            }),
            count: response.data.tableData.count,
            rows: response.data.tableData.rows,
          },
        };
        this.loading = false;
      },
      error: (error) => {
        console.error('Failed to fetch overall s curve data:', error);
        this.loading = false;
      },
    });
  }

  onDisciplineLinkClick() {
    this.router.navigate(['/discipline-scurve']);
  }
  ngOnInit(): void {
    this.fetchProcurementScurveData({});
  }
}
