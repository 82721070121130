import { inject, Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ChartDataType, DocumentControlChart } from '../../interface/ChartTypes';
import { ColumnProps } from '../../interfaces/table-interface';
import { DocumentControlApiFilters, DocumentControlFormFilter } from '../../interface/Filters';
import { ISubmitDocumentControlForm } from '../../interface/DocumentControlForm';

const {
  DOC_CONTROL_FILTERS,
  DOC_DEL_BY_DISC,
  DOC_NOT_ISS_DISC,
  DOC_RELEASE_PLAN,
  DOC_IFC,
  DOCUMENT_FORM_TITLES,
  DOCUMENT_FORM_NUMBER_VERSION,
  DOCUMENT_FORM_DETAILS,
  SAVE_DOCUMENT_CONTROL_FORM,
  DOCUMENT_CONTROL_IFC_PERCENT,
  DOCUMENT_CONTROL_DELIVERY_TABLE,
  DOCUMENT_SCURVE,
} = API_END_POINTS;

@Injectable({
  providedIn: 'root',
})
export class DocumentControlService {
  constructor() {}
  private http = inject(HttpClient);

  getFilters(): Observable<{ data: DocumentControlApiFilters & { cutoff: string } }> {
    return this.http.get<{ data:  DocumentControlApiFilters & { cutoff: string } }>(DOC_CONTROL_FILTERS);
  }

  getScopeByDisc(filters: {
    yearData: [string];
    discipline: string[];
  }): Observable<{ data: DocumentControlChart }> {
    return this.http.post<{ data: DocumentControlChart }>(DOC_DEL_BY_DISC, {
      filters,
    });
  }

  getDocReleasePlan(filters: {
    yearData: [string];
    discipline: string[];
  }): Observable<{ data: DocumentControlChart }> {
    return this.http.post<{ data: DocumentControlChart }>(DOC_RELEASE_PLAN, {
      filters,
    });
  }

  getVarianceByDisc(filters: {
    yearData: [string];
    discipline: string[];
    status: string
  }): Observable<{ data: DocumentControlChart }> {
    return this.http.post<{ data: DocumentControlChart }>(DOC_NOT_ISS_DISC, {
      filters,
    });
  }

  getIfc(filters: {
    yearData: [string];
    discipline: string[];
  }): Observable<{ data: DocumentControlChart }> {
    return this.http.post<{ data: DocumentControlChart }>(DOC_IFC, { filters });
  }

  getIfcPercent(filters: {
    yearData: [string];
    discipline: string[];
  }): Observable<{ data: number }> {
    return this.http.post<{ data: number }>(
      DOCUMENT_CONTROL_IFC_PERCENT,
      { filters }
    );
  }

  getDeliverableStatusTableData(filters: {
    yearData: [string];
    discipline: string[];
  }): Observable<{
    data: {
      data: Record<string, any>[];
      columns: ColumnProps[];
    };
  }> {
    return this.http.post<{
      data: {
        data: Record<string, any>[];
        columns: ColumnProps[];
      };
    }>(DOCUMENT_CONTROL_DELIVERY_TABLE, { filters });
  }

  getEngineeringScurve(): Observable<{ data: ChartDataType }> {
    return this.http.post<{ data: ChartDataType }>(DOCUMENT_SCURVE,{});
  }

  getDocumentTitles(query: string): Observable<{ data: string[] }> {
    return this.http.post<{ data: string[] }>(DOCUMENT_FORM_TITLES, { query })
  }

  getDocumentNumberVersion(title: string): Observable<any> {
    return this.http.post(DOCUMENT_FORM_NUMBER_VERSION, { title })
  }

  getDocumentFormDetails(filters: DocumentControlFormFilter): Observable<any> {
    return this.http.post(DOCUMENT_FORM_DETAILS, { filters })
  }

  submitDocumentControlForm(data: ISubmitDocumentControlForm): Observable<any> {
    return this.http.post(SAVE_DOCUMENT_CONTROL_FORM, data)
  }
}
