import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DonutChartModel, ExecutionDelayHours } from '../../interface/ChartTypes';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { AreasResponse, ContractorResponse, CwpResponse, DelayForm, DelayFormTable, DelayReasonResponse, ExistingDelayForm } from '../../interface/delay-tracking'
import { ExecutionDelayFilter } from '../../interface/Filters';

const {
  DELAY_FORM,
  GET_DELAY_TABLE,
  DELAY_REASONS,
  GET_AREAS,
  CWP,
  CONTRACTORS,
  EXECUTION_DELAY_STATUS,
  EXECUTION_DELAY_HOURS,
  GET_FORM_DATA_BY_ID
} = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})

export class delayTrackingServices {

  constructor() { }
  private http = inject(HttpClient);
  getAreas(): Observable<AreasResponse> {
    return this.http.get<AreasResponse>(GET_AREAS);
  }
  submitDelayForm(data: DelayForm | undefined): Observable<{ data: DelayForm }> {

    return this.http.post<{ data: DelayForm }>(DELAY_FORM, data);
  }

  getStatusWiseFormData(type: string, pageNo?: number, rows?: number, isDownloadClicked?: boolean): Observable<DelayFormTable> {
    // Construct the request payload
    const payload = {
      type,
      pageNo,
      noOfRecords: rows,
      isDownloadClicked
    };

    return this.http.post<DelayFormTable>(GET_DELAY_TABLE, payload);
  }

  getFormById(id: number): Observable<{ data: ExistingDelayForm }> {
    return this.http.get<{ data: ExistingDelayForm }>(GET_FORM_DATA_BY_ID(id));
  }

  updateForm(id: number, data: DelayForm): Observable<{ id: number; status: string; message: string }> {
    const payload = { id, data };
    console.log('payload', payload);
    
    return this.http.put<{ id: number; status: string; message: string }>(DELAY_FORM, payload);
  }

  getCwp(): Observable<CwpResponse> {
    return this.http.get<CwpResponse>(CWP);
  }

  getContractors(): Observable<ContractorResponse> {
    return this.http.get<ContractorResponse>(CONTRACTORS);
  }

  getDelayReasons(): Observable<DelayReasonResponse> {
    return this.http.get<DelayReasonResponse>(DELAY_REASONS);
  }

  getExecutionDelayStatus(filters: ExecutionDelayFilter): Observable<{ data: DonutChartModel }> {
    return this.http.post<{ data: DonutChartModel }>(EXECUTION_DELAY_STATUS, { filters });
  }

  getExecutionDelayHours(filters: ExecutionDelayFilter): Observable<{ data: ExecutionDelayHours }> {
    return this.http.post<{ data: ExecutionDelayHours }>(EXECUTION_DELAY_HOURS, { filters });
  }
}

