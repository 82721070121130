import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Area, Cwp, ProjectHighlightConcern } from '../../../interface/ProjectHighlightConcern';
import { User } from '../../../interface/user';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { globalModules } from '../../../utils/global-modules';
import { CustomDialogComponent } from "../../custom-dialog/custom-dialog.component";
import { PrimeNGConfig } from 'primeng/api';
import { HeaderTextComponent } from "../../header-text/header-text.component";
import { SwitchComponent } from "../../switch/switch.component";
import { InputSwitchChangeEvent } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { UploadButtonComponent } from "../../upload-button/upload-button.component";
import { SubmitButtonComponent } from "../../submit-button/submit-button.component";
import { CancelButtonComponent } from "../../cancel-button/cancel-button.component";
import { HomePageService } from '../../../services/home/home-page.service';
import { UserService } from '../../../services/user/user.service';
import dayjs from 'dayjs';
import { ProjectHighlightConcernService } from '../../../services/project-highlight-concern/project-highlight-concern.service';
type FileUploadHandlerEvent = { files: FileList };


@Component({
  selector: 'app-project-highlight-concerns-form',
  standalone: true,
  imports: [...globalModules, ReactiveFormsModule, CustomDialogComponent, HeaderTextComponent, SwitchComponent, DropdownModule, UploadButtonComponent, SubmitButtonComponent, CancelButtonComponent],
  templateUrl: './project-highlight-concerns-form.component.html',
  styleUrl: './project-highlight-concerns-form.component.css'
})
export class ProjectHighlightConcernsFormComponent implements OnInit {
  form!: FormGroup;
  @Input() isFormOpen: boolean = false;
  @Input() onCloseForm: ((alertType: string) => void) | undefined;
  @Output() submitEvent: EventEmitter<any> = new EventEmitter();

  projectHighltsConcrnForm: ProjectHighlightConcern = {} as ProjectHighlightConcern;
  areas: Area[] = [];
  cwp: Cwp[] = [];
  users: User[] = [];
  uploadPicture: File | null = null;
  fileName: string = '';
  formEnable: "reporter" | "actionowner" = "reporter";
  submitCustomDialog: boolean = false;

  visible: boolean = false;

  isTextFieldDisabled: boolean = true;
  isConcernn: boolean = false;

  today = new Date();
  getWeekOfMonth = (date: Date): number => {
    // Copy the date to avoid modifying the original date
    const currentDate = new Date(date.getTime());

    // Get the first day of the month
    const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    // Calculate the day of the week for the first day of the month (0: Sunday, 1: Monday, ..., 6: Saturday)
    const startDayOfWeek = startOfMonth.getDay();

    // Calculate the offset from the start of the month
    const offset = (currentDate.getDate() + startDayOfWeek - 1) / 7;

    // Calculate the week number
    return Math.floor(offset) + 1;
  };
  monthOfTheDay = this.getWeekOfMonth(this.today);

  constructor(private fb: FormBuilder,
     private primengConfig: PrimeNGConfig,
     private homePageService: HomePageService,
     private userService: UserService,
     private projectHighlightConcernService: ProjectHighlightConcernService) {
    this.primengConfig.ripple = true;

    this.form = this.fb.group({
      projectNameField: [{ value: 'J22N01-Poysilicon', disabled: true }],
      // textField: [Validators.required],
      highlightsConcernsField: ['', [Validators.required]],
      areaField: [null, Validators.required],
      locationField: [null, Validators.required],
      cwpField: [null,],
      actionOwnerField: [null,],
      uploadedFile: [null as File | null],
      isConcern: [this.isConcernn],
    });
  }

  get areaField() {
    return this.form.get('areaField');
  }

  get locationField() {
    return this.form.get('locationField');
  }

  get highlightsConcernsField() {
    return this.form.get('highlightsConcernsField');
  }

  get isConcern() {
    return this.form.get('isConcern');
  }

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    this.fetchArea();
    this.fetchCwp();
    this.fetchUsers();

    this.form.get('areaField')?.valueChanges.subscribe(() => {
      this.form.get('locationField')?.setValue(undefined)
    })
    this.form.get('isConcern')?.valueChanges.subscribe(() => {
      this.form.get('highlightsConcernsField')?.setValue(undefined)
    })

    this.setInitialProjectHighlightValues();

    this.syncFormWithObject();
  }

  setInitialProjectHighlightValues() {
    const projectName = this.form.get('projectNameField')?.value;
  
    // Update projectHighltsConcrnForm initially
    this.projectHighltsConcrnForm = {
      ...this.projectHighltsConcrnForm,
      projectName: projectName,
      month: this.today,
      week: this.monthOfTheDay.toString(),
    };
  }

  syncFormWithObject() {
    // Subscribe to valueChanges to update projectHighltsConcrnForm on form changes
    this.form.valueChanges.subscribe((values) => {
      this.projectHighltsConcrnForm = {
        ...this.projectHighltsConcrnForm,
        ...{
          ...(values.isConcern ? { isConcern: values.isConcern } : null),
          area: values?.areaField?.value,
          location: values?.locationField?.value,
          cwp: values?.cwpField?.value,
          actionOwner: values?.actionOwnerField?.value,
          ...(values.isConcern ? { concerns: values.highlightsConcernsField} : { highlights: values.highlightsConcernsField }),
          uploadPicture: values?.uploadedFile?.name
        }
      };
    });
  }

  fetchArea = () => {
    this.homePageService.getAreas().subscribe({
      next: (response) => {
        this.areas = response.data
      },
      error: (error) => {
        console.error('Failed to fetch Area:', error)
      }
    })
  }

  areaData = () => {
    if(this.areas.length) {
      return this.areas.map((area)=> {
        return { value: area.areaName, id: area.id };
      })
    }
    return [];
  }

  locationData = () => {
    if (this.areas.length) {
      const selectedArea = this.form.get('areaField')?.value;
      if (!selectedArea) {
        console.warn('No area selected');
        return [];
      }
  
  
      // Find the area matching the selected name or ID
      const area = this.areas.find((area) => area.areaName === selectedArea.value || area.id === selectedArea.id);
  
      // Log the matched area to debug
  
      // Map to location data if area exists
      if (area) {
        const locData = area.areaLocations.map((loc) => ({
          value: loc.locationName,
          id: loc.id,
        }));
        return locData;
      }
      // Return empty if no matching area found
      console.warn('No matching area found for:', selectedArea);
    }
  
    return [];
  };
  

  fetchCwp = () => {
    this.homePageService.getCwp().subscribe({
      next: (response) => {
        this.cwp = response.data
      },
      error: (error) => {
        console.error('Failed to fetch Area:', error)
      }
    })
  }

  cwpData = () => {
    if(this.cwp.length) {
      return this.cwp.map((cwp)=> {
        return { value: cwp.cwp, id: cwp.id };
      })
    }
    return [];
  }

  fetchUsers = () => {
    this.userService.getUsers().subscribe({
      next: (response) => {
        this.users = response.data
      },
      error: (err) => {
        console.error('Failed to fetch users:', err)
      }
    })
  }

  userData = () => {
    if(this.areas) {
      return this.users.map((user) => {
        return { value: user.userName, id: user?.id };
      })
    }
    return []
  }

  onSubmitForm() {
    if (this.form.valid) {
      const keyToExclude = 'uploadPicture';
      
      const filteredValues: ProjectHighlightConcern = Object.fromEntries(
        Object.entries(this.projectHighltsConcrnForm).filter(
          ([key, value]) => key !== keyToExclude
        )
      ) as unknown as ProjectHighlightConcern
      const finalData: ProjectHighlightConcern = {
        ...filteredValues,
        month: this.projectHighltsConcrnForm.month
          ? (dayjs(this.projectHighltsConcrnForm.month).format("MM/YYYY") as any)
          : null,
        // ...(this.projectHighltsConcrnForm.isConcern ? { concerns: this.projectHighltsConcrnForm.highlightsConcerns } : { highlights: this.projectHighltsConcrnForm.highlightsConcerns })
      };
      const userIdToReplace = new Set(["actionOwner"]);
      Object.keys(finalData).forEach((el) => {
        if (
          userIdToReplace.has(el) &&
          this.projectHighltsConcrnForm[el as keyof ProjectHighlightConcern]
        ) {
          finalData[el as keyof ProjectHighlightConcern] = (this.users.find((user) => {
            return (
              user.userName === finalData[el as keyof ProjectHighlightConcern]
            );
          })?.id || null) as unknown as never;
        }
      });
      const formData = new FormData();
      const uploadedFile = this.form.get('uploadedFile')?.value as File;
      let objectURL: string | null = null;
      if (uploadedFile) {
        objectURL = URL.createObjectURL(uploadedFile);
        (uploadedFile as any).objectURL = objectURL;
        formData.append("file", uploadedFile);
      }
      for (const key in finalData) {
        const property = key as keyof ProjectHighlightConcern;
        if (finalData[property]) {
          formData.append(property, finalData[property].toString());
        }
      }
      this.projectHighlightConcernService.submitProjectHighlightConcernForm(formData).subscribe({
        next: (res) => {
          if(res && res.length) {
            this.onCloseForm!('success')
            this.form.reset({
              projectNameField: 'J22N01-Poysilicon'
            });
          }
        },
        error: (error) => {
          console.error('Failed to submit the porject highlight/concerns data', error)
        }
      })
    } else {
      this.form.markAllAsTouched();
    }
  }

  onCloseFormClick = () => {
    if(this.onCloseForm) {
      this.isFormOpen = false;
      return this.onCloseForm('cancel')
    }
  }

  onToggleSwitch = (event: any) => {
    const eventt = event as InputSwitchChangeEvent;
    this.isConcernn = eventt.checked
    this.form.patchValue({ isConcern: event.checked });
    
  }

  handleFileUpload(event: FileUploadHandlerEvent) {
    const file = event.files[0];
    this.form.get('uploadedFile')?.setValue(file as File | null);
  }

  handleFileRemove() {
    this.form.get('uploadedFile')?.setValue(null);
  }

  onSubmitBtnClick = () => {
    this.form.reset({
      projectNameField: 'J22N01-Poysilicon'
    });
  }

}