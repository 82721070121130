import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { FormOption } from '../../interface/DynamicForm';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {
  private http = inject(HttpClient);

  getOptionsByMasterData(dataKey: string, parentValue: any): Observable<{
    data: FormOption[];
  }> {
    if (dataKey === 'projects' && parentValue === 'Reliance') {
      return of({
        data: [
          { id: 1, text: 'PolySilicon', value: 'PolySilicon' },
          { id: 2, text: 'RelPolymenr', value: 'RelPolymenr' }
        ]
      });
    }
    if (dataKey === 'projects' && parentValue === 'Shell') {
      return of({
        data: [
          { id: 1, text: 'Pune Petroleum', value: 'Pune Petroleum' },
          { id: 2, text: 'Mum Petroleum', value: 'Mum Petroleum' }
        ]
      });
    }
    if (dataKey === 'forms') {
      return of({
        data: [
          { id: 1, text: 'Delay Form', value: 'Delay Form' },
          { id: 2, text: 'Concern Form', value: 'Concern Form' }
        ]
      });
    }
    return of({
      data: []
    })
    // return this.http.get<{ data: FormOption[] }>(API_END_POINTS.DROP_DOWN_VALS, {
    //   params: {
    //     parentValue
    //   }
    // })
  }
  getValueForRelatedField(masterDataType: string, masterDataValue: any): Observable<{
    data: Record<string, any>;
  }> {
    if (masterDataType === 'documentNo' && masterDataValue === 1) {
      return of({
       data: {documentName: 'Abc'}
      });
    }
    return of({
      data: {}
    })
    // return this.http.get<{ data: FormOption[] }>(API_END_POINTS.DROP_DOWN_VALS, {
    //   params: {
    //     parentValue
    //   }
    // })
  }
  getDropDownValues(masterDataType: string): Observable<{
    data: FormOption[];
  }> {    
    if (masterDataType === 'tenants') {
      return of({
       data: [{
        id: 1,
        text: 'Reliance',
        value: 'Reliance'
      }, {
        id: 2,
        text: 'Shell',
        value: 'Shell'
      }]
      });
    }
    if (masterDataType === 'area') {
      return of({
       data: [{
        id: 1,
        text: 'Area 1',
        value: 'Area 1'
      }, {
        id: 2,
        text: 'Area 2',
        value: 'Area 2'
      }]
      });
    }
    return of({
      data: []
    })
    // return this.http.get<{ data: FormOption[] }>(API_END_POINTS.DROP_DOWN_VALS, {
    //   params: {
    //     parentValue
    //   }
    // })
  }

  getMasterDataKeys():Observable<{
    data: FormOption[];
  }> {
    return of({
      data: [{
       id: 1,
       text: 'area',
       value: 'area'
     }, {
       id: 2,
       text: 'location',
       value: 'location'
     }]
     });
  }
}
