
<div class="h-100">
  <ng-template #table>
    <p-table
      class="custom-table"
      [lazy]="true"
      [loading]="loading"
      [columns]="appliedColumns"
      [value]="filteredData"
      [scrollable]="true"
      [scrollHeight]="'flex'"
      [ngClass]="{
        'datatable-gridlines': showGridLines
      }"
      [paginator]="paginationOptions?.paginationRequired"
      [paginatorStyleClass]="'paginator-style'"
      [rows]="paginationOptions?.rows || 5"
      [first]="paginationOptions?.first"
      [showCurrentPageReport]="true"
      currentPageReportTemplate="{first} to {last} of {totalRecords}"
      [totalRecords]="paginationOptions?.totalRecords!"
      (onPage)="paginationOptions?.onChangePage($event)"
      [rowsPerPageOptions]="[10, 25, 50]"
    >
      <ng-template pTemplate="header" let-columns>
        @for (ind of getMaxDepthArray(columns); track $index) {
        <tr style="border-bottom: 1px solid lightgray; justify-content: center">
          @for (col of columns; track $index) {
          <ng-container
            *ngTemplateOutlet="
              renderHeaderCell;
              context: {
                $implicit: { column: col, targetDepth: ind, currentDepth: 0 }
              }
            "
          >
          </ng-container>

          }
        </tr>
        }

        <ng-template #renderHeaderCell let-contextArguments>
          @if (contextArguments.currentDepth === contextArguments.targetDepth) {
          @if (contextArguments.column?.frozen) {
          <th
            pFrozenColumn
            [ngStyle]="this.getHeaderStyle(contextArguments.column)"
            [colSpan]="getColSpan(contextArguments.column)"
            [rowSpan]="
              contextArguments.column.children
                ? 1
                : getMaxDepth(columns) - contextArguments.currentDepth
            "
          >
            {{ contextArguments.column.header }}
          </th>
          } @else {

          <th
            [ngStyle]="this.getHeaderStyle(contextArguments.column)"
            [attr.pFrozenColumn]="contextArguments.column?.frozen ? '' : null"
            [colSpan]="getColSpan(contextArguments.column)"
            [rowSpan]="
              contextArguments.column.children
                ? 1
                : getMaxDepth(columns) - contextArguments.currentDepth
            "
          >
            {{ contextArguments.column.header }}
          </th>
          } } @else if (contextArguments.column.children) { @for (child of
          contextArguments.column.children; track $index) {
          <ng-container
            *ngTemplateOutlet="
              renderHeaderCell;
              context: {
                $implicit: {
                  column: child,
                  targetDepth: contextArguments.targetDepth,
                  currentDepth: contextArguments.currentDepth + 1
                }
              }
            "
          >
          </ng-container>
          } }
        </ng-template>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowData
        let-continuousRowIndex="rowIndex"
        let-columns="columns"
      >
        @let rowIndex = getRowInd(continuousRowIndex);
        <tr
          [ngClass]="
            conditionalRowClass
              ? conditionalRowClass(getRowData(rowIndex))
              : undefined
          "
        >
          @for (col of flattenColumns(columns); track $index) { @if (col.frozen)
          {
          <td
            pFrozenColumn
            class="table-td"
            [style.minWidth]="'150px'"
            [ngStyle]="col.style"
          >
            @if (col.bodyTemplate) {
            <ng-container
              *ngTemplateOutlet="
                col.bodyTemplate;
                context: { $implicit: getRowData(rowIndex) }
              "
            ></ng-container>

            } @else {
            {{ convertDateVal(data[rowIndex][getColDataWithType([col.field])], col.field) }}
            }
          </td>
          } @else {
          <td
          [ngStyle]="col.style"
          [ngClass]="{
              'text-center':
                data && data[rowIndex]
                  ? isNumberOrDate(
                      data[rowIndex][getColDataWithType([col?.field])], col
                    )
                  : false
            }"
            class="table-td"
            [style.minWidth]="'150px'"
          >
            @if (col.bodyTemplate) {
            <ng-container
              *ngTemplateOutlet="
                col.bodyTemplate;
                context: { $implicit: getRowData(rowIndex) }
              "
            ></ng-container>

            } @else if (data && data[rowIndex]) {

              {{ convertDateVal(data[rowIndex][getColDataWithType([col.field])], col.field) }}
            } @else { -- }
          </td>
          } }
        </tr>
      </ng-template>
      <p-paginator  [appendTo]="'body'"></p-paginator>
    </p-table>
  </ng-template>
  <ng-template #endElement>
    <div>
      <div style="display: flex">
        @if(moreEndElement) {
        <ng-container *ngTemplateOutlet="moreEndElement"></ng-container>
        } @if(!hideDownloadIcon) {
        <div>
          <app-icon-button
            (buttonClick)="exportExcel($event)"
            iconColor="#29c8c1"
            [icon]="'pi pi-download'"
            [title]="'Download'"
          />
        </div>
        } @if(!hideCustomizeColumnIcon) {
        <div>
          <app-icon-button
            iconColor="#29c8c1"
            (buttonClick)="showDialog()"
            [icon]="'pi pi-cog'"
            [title]="'Customize Columns'"
          />
        </div>
        }
      </div>
    </div>

    <div>
      <app-custom-dialog
        [title]="dialogTitle"
        [style]="{ width: '40rem', height: '40rem' }"
        [resetClick]="resetClick"
        [applyClick]="applyClick"
        [visible]="visible"
        (closeDialogClick)="cancelClick()"
        [customizeColumn]="{
          enable: true,

        }"
      >
        <div cdkDropListGroup class="mt-4">
          <div style="display: flex; justify-content: space-between">
            <div class="column-container">
             
              <div
                cdkDropList
                [cdkDropListData]="colsLocal"
                class="column-list"
                [ngClass]="{ 'overflow-y-scroll': colsLocal.length > 8 }"
                (cdkDropListDropped)="drop($event)"
              >
                @for (item of colsLocal; track $index) {
                <div class="column-box-1" cdkDrag>{{ item }}</div>
                }
              </div>
            </div>

            <div class="column-container">

              <div
                cdkDropList
                [cdkDropListData]="defaultColsLocal"
                class="column-list"
                [ngClass]="{ 'overflow-y-scroll': defaultColsLocal.length > 8 }"
                (cdkDropListDropped)="drop($event)"
              >
                @for (item of defaultColsLocal; track item) {
                <div class="column-box-2" cdkDrag>{{ item }}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </app-custom-dialog>
    </div>
    <ng-template #dialogTitle>
      <div class="block">
        <div style="font-weight: 700; color: #29c8c1">
          Customize your Column Sequence
        </div>
        <div>
          Drag and Drop to Right Section to Customize Column names with sequence
          as per the Preference
        </div>
      </div>
    </ng-template>
  </ng-template>
  @if(inTableDialog) {
  <app-custom-dialog
    [style]="{ width: '80vw', 'height': '70vw' }"
    [endElement]="endElement"
    (closeDialogClick)="closeTablePopup()"
    [visible]="isTableDialogVisible"
    [titleString]="title"
  >
    <div style="height: 35vw">
      <ng-container *ngTemplateOutlet="table"></ng-container>
    </div>
  </app-custom-dialog>
  } @else { @if (!avoidCard) {
  <app-card
    [title]="title"
    [helperText]="helperText!"
    [isExpanded]="isExpanded"
    [endElement]="endElement"
    [expandable]="expandable"
    [avoidExpand]="avoidExpand"
    [alreadyMaximized]="true"
  >
    <ng-container *ngTemplateOutlet="table"></ng-container>
  </app-card>
  } @else {
  <ng-container *ngTemplateOutlet="table"></ng-container>

  } }
</div>
