<div class="layout-wrapper">
    <div class="menubar">
        <div class="menubar-start d-flex align-items-center">
            @if(currentPath !== '/3d-viewer') {
            <div class="mx-2 d-flex">
                <app-icon (click)="onShowSideBar()" icon="pi pi-bars" color="red"></app-icon>
            </div>
            }

            <div class="wr-logo flex" [ngClass]="{ 'cursor-pointer': currentPath !== '/3d-viewer' }"
                [ngStyle]="{ marginLeft: currentPath !== '/3d-viewer' ? '0' : '1rem' }" (click)="goHome()">
                <img height="20rem" width="90rem" src="assets/icons/wrlogo.png" alt="loading" />
            </div>
        </div>
        <div class="menubar-end">
            <div class="project-name">Reliance Polysilicon Project</div>
            <div class="welcome-message" [ngStyle]="{ marginRight: currentPath !== '/3d-viewer' ? '0' : '1rem' }">
                Welcome, {{ userName }}
            </div>
            @if (currentPath !== '/3d-viewer') {

            <div class="profile-menu">
                <button class="icon-button" placement="bottom-end" [ngbPopover]="profilePopover" triggers="click"
                    popoverClass="w-8rem" (click)="toggleProfile()">
                    <app-icon color="white" icon="pi pi-angle-down"></app-icon>
                </button>

                <ng-template #profilePopover>
                    <div class="popover-content">
                        <button class="popover-item" (click)="onSettings()">
                            <app-icon color="black" icon="pi pi-cog"></app-icon>
                            Settings
                        </button>
                        <button class="popover-item" (click)="onProfile()">
                            <app-icon color="black" icon="pi pi-user"></app-icon>
                            Profile
                        </button>
                        <button class="popover-item" (click)="onSignOut()">
                            <app-icon color="black" icon="pi pi-sign-out"></app-icon>
                            <span>Sign Out</span>
                        </button>
                    </div>
                </ng-template>
            </div>

            }
        </div>
    </div>

    <div class="flex">
        <app-sidebar (onHide)="onHideSideBar()"></app-sidebar>
    </div>

    <div class="layout-body">
        @if (currentPath !== '/') {
        <div class="back-button"
        [ngStyle]="{'position': currentPath === '/3d-viewer' ? 'relative' : 'static'}"
        (click)="goBackRoute()">
            <i style="color: #29c8c1;" class="pi pi-angle-left" ></i>
            <span class="underline link">Back</span>
        </div>
        }
        <div class="px-3 layout-content">

            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-sidebar [visible]="visible" (onHide)="onHideSideBar()"></app-sidebar>