import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { Observable } from 'rxjs';
import { UsersResponse } from '../../interface/ProjectHighlightConcern';

const { USERS } = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private http = inject(HttpClient);

  getUsers(): Observable<UsersResponse>{
    return this.http.get<UsersResponse>(USERS);
  }
}
