import { Component, inject, OnInit } from '@angular/core';
import { EngScurveService } from '../../shared/services/eng-scurve/eng-scurve.service';
import { ChartDataType } from '../../shared/interface/ChartTypes';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { TablePageEvent } from 'primeng/table';
import { OverallScurveModel } from '../../shared/types/OverallScurveModel';
import { TableData } from '../../shared/types/TableProps';
import { ScurveDataCardModel } from '../../shared/interface/ScurveDataCardModel';
import { ScurvedataCardComponent } from '../../shared/components/scurvedata-card/scurvedata-card.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';

@Component({
  selector: 'app-eng-scurve',
  standalone: true,
  imports: [
    LoaderComponent,
    SingleColumnComponent,
    TableComponent,
    ScurvedataCardComponent,
    HeaderTextComponent,
  ],
  templateUrl: './eng-scurve.component.html',
  styleUrl: './eng-scurve.component.css',
})
export class EngScurveComponent implements OnInit {
  private engScurveService = inject(EngScurveService);
  first: number = 0;
  page: number = 0;
  rowsPerPage: number = 10;
  scurveData: OverallScurveModel = {
    chartData: {} as ChartDataType,
    tableData: {} as TableData,
    metaData: [] as ScurveDataCardModel[],
    monthVal: '',
  };
  loading: boolean = false;
  paginationOptions:
    | {
        paginationRequired?: boolean;
        onChangePage?: (event: TablePageEvent) => void;
        onRowChange?: () => void;
        rows?: number;
        totalRecords?: number;
        first?: number;
      }
    | undefined = {
    first: this.first,
    rows: this.rowsPerPage,
    onChangePage: this.pageChange,
    paginationRequired: false,
  };

  constructor() {}

  ngOnInit(): void {
    this.fetchScurveData();
  }

  defaultColumns: ColumnProps<any>[] = [
    {
      field: 'description',
      header: 'Description',
    },
    {
      field: 'base_plan',
      header: 'Base Plan',
    },
    {
      field: 'cummulative_base_plan',
      header: 'Cummulative Base Plan',
    },
    {
      field: 'cummulative_actual',
      header: 'Cummulative Actual',
    },
  ];

  fetchScurveData(): void {
    this.loading = true;
    this.engScurveService.getEngScurveData().subscribe({
      next: (response) => {
        this.scurveData = {
          ...response.data,

          tableData: {
            columns: response.data.tableData.columns.map((el) => {
              return {
                ...el,
                style:
                  response.data.monthVal === el.field
                    ? {
                        backgroundColor: 'rgba(255, 255, 0, 0.5)',
                      }
                    : undefined,
              };
            }),
            count: response.data.tableData.count,
            rows: response.data.tableData.rows,
          },
        };
        this.loading = false;
      },
      error: (error) => {
        console.error('Failed to fetch project schedule chart data:', error);
        this.loading = false;
      },
    });
  }
  pageChange(event: { first: number; rows: number }): void {
    this.rowsPerPage = event.rows;
    this.first = event.first;
  }
}
