import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { MasterData } from '../../interface/MasterData';

const {
  MASTER_DATA
} = API_END_POINTS

@Injectable({
  providedIn: 'root'
})
export class MasterDataServiceService {

  private http = inject(HttpClient);
  constructor() { }

  getMasterData(query: {module: string, key: string}): Observable<{status: string,
    data: {
      count: number,
      rows: MasterData[]
    }}> { 
    return this.http.post<{
      status: string,
      data: {
        count: number,
        rows: MasterData[]
      }
    }>(MASTER_DATA, query)
  }
}
