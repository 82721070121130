import { inject, Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

const { MODEL_3D_SECRETS } = API_END_POINTS;

@Injectable({
  providedIn: 'root',
})
export class Model3dServiceService {
  private http = inject(HttpClient);

  constructor() {}

  getSecrets(
    projectName: string
  ): Observable<{ data: { access_token: string; urn: string } }> {
    return this.http.post<{ data: { access_token: string; urn: string } }>(
      MODEL_3D_SECRETS,
      {projectName}
    );
  }
}
