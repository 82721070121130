import { 
  Component, EventEmitter, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges 
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Field, Form } from '../../interface/DynamicForm';
import { globalModules } from '../../utils/global-modules';
import { DynamicFieldComponent } from "../dynamic-field/dynamic-field.component";
import { noWhitespaceValidator } from '../../utils/custom-validators';
import { CardComponent } from "../card/card.component";
import { Subject, Subscription } from 'rxjs';
import { CdkDragDrop, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dynamic-form',
  standalone: true,
  imports: [...globalModules, ReactiveFormsModule, DynamicFieldComponent, CardComponent, DragDropModule],
  templateUrl: './dynamic-form.component.html',
  styleUrl: './dynamic-form.component.css',
})
export class DynamicFormComponent implements OnChanges, OnDestroy {
  form!: FormGroup;

  @Input() fieldConfigs: Form = [];
  @Input() submitButtonLabel?: string;
  @Input() avoidSubmitButton?: boolean;
  @Input() isFormEditable: boolean = false;

  @Output() submitEvent: EventEmitter<any> = new EventEmitter();
  @Output() formConfigsChange: EventEmitter<Form> = new EventEmitter();

  formValueChanges$ = new Subject<any>(); // Observable to emit form changes
  private subscription!: Subscription;

  private fb: FormBuilder = inject(FormBuilder);

  dropSection(event: CdkDragDrop<any[]>) {
    if (event.previousIndex === event.currentIndex) return;
  
    // Store the current values
    const currentConfigs = [...this.fieldConfigs];
    
    // Perform the move
    moveItemInArray(currentConfigs, event.previousIndex, event.currentIndex);
    
    // Update sort orders
    currentConfigs.forEach((section, index) => {
      section.sectionSortOrder = index;
    });
  
    // Update the fieldConfigs and emit change
    this.fieldConfigs = currentConfigs;
    this.registerForm(); // Re-register the form with new structure
    this.formConfigsChange.emit(this.fieldConfigs);
  }
  
  dropField(event: CdkDragDrop<any[]>, sectionIndex: number) {
    if (event.previousIndex === event.currentIndex) return;
  
    // Store the current fields
    const section = this.fieldConfigs[sectionIndex];
    const fields = [...section.fields];
    
    // Perform the move
    moveItemInArray(fields, event.previousIndex, event.currentIndex);
    
    // Update sort orders
    fields.forEach((field, index) => {
      field.fieldSortOrder = index;
    });
    
    // Update the section fields
    section.fields = fields;
    this.fieldConfigs = [...this.fieldConfigs]; // Create new reference
    this.registerForm(); // Re-register the form with new structure
    this.formConfigsChange.emit(this.fieldConfigs);
  }
  
  // Modify registerForm to handle options array
  registerForm = () => {
    this.form = this.fb.group({});
    this.fieldConfigs.forEach((section) => {
      section.fields.forEach((field) => {
        const validators = this.getValidators(field);
        
        // If field has options, create a FormArray for them
        if (field.type === 'dropdown_static' || field.type === 'radio' || field.type === 'checkbox') {
          const optionsArray = this.fb.array((field.options || []).map(option => 
            this.fb.group({
              text: [option.text],
              value: [option.value]
            })
          ));
          this.form.addControl(`${field.name}_options`, optionsArray);
        }
        
        this.form.addControl(field.name, this.fb.control(null, validators));
      });
    });
  
    // Subscribe to form value changes after the form is set up
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.form.valueChanges.subscribe((value) => {
      this.formValueChanges$.next(value);
    });
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['fieldConfigs']) {
      this.registerForm(); // Reinitialize form if fieldConfigs change
    }
  }

  getValidators =(field: Field) => {
    const validators = [];
    if (field.isMandatory) {
      validators.push(Validators.required);
      const spaceUnallowedFields: Field['type'][] = ['number', 'text'];
      if (spaceUnallowedFields.includes(field.type)) {
        validators.push(noWhitespaceValidator());
      }
    }
    if (field.regex) {
      const regexValidator = Validators.pattern(new RegExp(field.regex));
      validators.push(regexValidator);
    }
    return validators;
  }

  onSubmitForm = () => {
    if (this.form.valid) {
      this.submitEvent.emit(this.form.value); // Emit form value on submit
    } else {
      this.form.markAllAsTouched();
      console.log('Form has errors.');
    }
  }

  onReset() {
    this.form.reset(); // Reset the form
  }

  isValid = () => {
    return this.form.valid
  }

  ngOnDestroy(): void {
    // Clean up the subscription to avoid memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
