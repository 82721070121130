<div>
    <input
      type="text"
      class="form-control"
      [placeholder]="placeholder"
      [ngbTypeahead]="search"
      [resultFormatter]="formatter"
      [inputFormatter]="formatter"
      [(ngModel)]="value"
      (ngModelChange)="onInputChange($event)"
      (selectItem)="resultSelected.emit($event.item)"
    />
  
     @if (showManualInput) {

         <input
           type="text"
           class="form-control mt-2"
           placeholder="Enter manually..."
           [(ngModel)]="manualInput"
           (ngModelChange)="onManualInputChange($event)"
         />
     }
  </div>
  