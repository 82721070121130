import { Component, Input } from '@angular/core';
import { globalModules } from '../../utils/global-modules';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [...globalModules],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.css'
})
export class LoaderComponent {
  @Input() size: number = 10;
  @Input() backdrop: boolean = false;

  dotStyle: any;
  dots: number[] = [1, 2, 3, 4];

  ngOnInit() {
    this.dotStyle = {
      width: `${this.size}px`,
      height: `${this.size}px`,
      margin: `0 ${this.size / 2}px`
    };
  }

}
