import {
  AfterViewInit,
  Component,
  inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TablePageEvent } from 'primeng/table';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AreaImageComponent } from '../../shared/components/area-image/area-image.component';
import { GanttChartComponent } from '../../shared/components/charts/gantt-chart/gantt-chart.component';
import { CustomDialogComponent } from '../../shared/components/custom-dialog/custom-dialog.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { TableComponent } from '../../shared/components/table/table.component';
import { TabsComponent } from '../../shared/components/tabs/tabs.component';
import { GanttChartType } from '../../shared/interface/ChartTypes';
import { TabChangeEvent } from '../../shared/interface/Tab';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { AreaStatusService } from '../../shared/services/area-status/area-status.service';
import { FileServiceService } from '../../shared/services/file-service/file-service.service';
import { ProjectSchedulingService } from '../../shared/services/project-scheduling/project-scheduling.service';
import { globalModules } from '../../shared/utils/global-modules';
import {
  downloadExportExcel,
  getFileNameFromS3Url,
} from '../../shared/utils/utility-functions';

//this is gantt chart page
type MenuTabs = 'dataQuality' | 'equipment tag data' | '';

@Component({
  selector: 'app-project-scheduling',
  standalone: true,
  imports: [
    ...globalModules,
    LoaderComponent,
    CustomDialogComponent,
    HeaderTextComponent,
    AreaImageComponent,
    GanttChartComponent,
    TabsComponent,
    TableComponent,
  ],
  templateUrl: './project-scheduling.component.html',
  styleUrl: './project-scheduling.component.css',
})
export class ProjectSchedulingComponent implements OnInit, AfterViewInit {
  @ViewChild(TableComponent) tablecompnt!: TableComponent;
  // injects
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private areaStatusService = inject(AreaStatusService);
  private projectSchedulingService = inject(ProjectSchedulingService);
  private fileService = inject(FileServiceService);

  // variables

  items = [
    { label: 'Procurement', type: 'materialPlanning' },
    { label: 'Document Control', type: 'docControl' },
    { label: 'VDR', type: 'vdr' },
  ];
  selectedMenuIconSelector: Record<string, string> = {
    dataQuality: 'pi pi-database',
    equipmentTagData: 'pi pi-pencil',
  };
  menuItems = [
    {
      icon: 'pi pi-pencil',
      label: 'Equipment Tag Data',
      command: () => {
        this.onClickTabMenu('equipment tag data');
      },
    },
  ];
  defaultCols = [
    {
      field: 'equipmentTags',
      header: 'Equipment Tags',
    },
    {
      field: 'description',
      header: 'Description',
    },
    {
      field: 'discipline',
      header: 'Discipline',
    },
    {
      field: 'vendorName',
      header: 'Vendor Name',
    },
    {
      field: 'area',
      header: 'Area',
    },
  ];

  selectedTabMenu: string = '';
  isDialogVisible: boolean = false;
  isLoading: boolean = true;
  tableLoading: boolean = false;
  equipmntTableLoading: boolean = false;
  tabChangeLoading: boolean = false;
  selectedLocation: string = '';
  selectedTask: string = '';
  alertsTrigger$ = new BehaviorSubject<void>(undefined);
  equipmentDataTrigger$ = new BehaviorSubject<void>(undefined);
  ganttChartDataTrigger$ = new BehaviorSubject<string>('');
  selectedArea: string = '';
  chartData: GanttChartType['series'] = [];
  disableDrillDown: boolean = false;
  imagesDialog: boolean = false;
  videoDialog: boolean = false;
  videoUrl: string = '';
  imageUrls: { key: string; name: string }[] = [];
  activeIndex: number = 0;
  //pagination
  rows: number = 10;
  equipmntRows: number = 50;
  first: number = 0;
  equipmntFirst: number = 0;
  alerts: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  equipmentTagData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  currentLevel: number = 0;
  activetab: string = 'Procurement';
  @ViewChild('alertRedirect') alertRedirect!: TemplateRef<any>;

  // methods

  ngOnInit(): void {
    this.getVideo();
    this.activatedRoute.queryParamMap.subscribe({
      next: (params) => {
        const ar = params.get('area') || 'Overall';
        if (ar === 'Overall' || !ar) {
          this.currentLevel = 0;
        } else {
          this.currentLevel = 1;
        }
        this.selectedArea = ar;
        this.equipmentDataTrigger$.next();
        this.ganttChartDataTrigger$.next(ar);
      },
    });

    this.alertsTrigger$.subscribe({
      next: () => {
        this.fetchAlerts();
      },
    });
    this.equipmentDataTrigger$.subscribe({
      next: () => {
        this.fetchEquipmentTagData();
      },
    });
    this.ganttChartDataTrigger$.subscribe({
      next: (val) => {
        this.fetchAreaLevelGanttChart(
          val && val !== 'Overall' ? val : 'Overall'
        );
        this.get4DImages();
      },
    });
  }

  onAreaClick = (area: string) => {
    this.router.navigate([], {
      queryParams: { area: area || 'Overall' },
      queryParamsHandling: 'merge',
    });
    this.disableDrillDown = false;
  };

  downloadVideo = () => {};
  onBarClick = ({ value, level }: { value: string; level: number }) => {
    if (level === 0) {
      this.fetchAreaLevelGanttChart(value);
      this.router.navigate([], {
        queryParams: { area: value || 'Overall' },
        queryParamsHandling: 'merge',
      });
      this.currentLevel = level + 1;
    } else if (level === 1) {
      this.fetchTaskLevelGanttData(value, '');
      this.currentLevel = level + 1;
      this.get4DImages();
    } else if (level === 2) {
      this.fetchTaskLevelGanttData(this.selectedLocation, value);
      this.currentLevel = level + 1;
      this.get4DImages();
    } else {
      // add other levels
    }
  };

  fetchAreaLevelGanttChart = async (area: string) => {
    this.tabChangeLoading = true;
    this.isLoading = true;

    this.areaStatusService.fetchGanttChartData(area).subscribe({
      next: (val) => {
        this.chartData = val.data.series;
      },
      complete: () => {
        if (area !== this.selectedArea) {
          this.selectedArea = area;
          this.ganttChartDataTrigger$.next(area);
        }
        this.selectedLocation = '';
        this.selectedTask = '';
        this.disableDrillDown = false;
        this.alertsTrigger$.next();
      },
      error: () => {
        this.disableDrillDown = false;
        this.isLoading = false;
      },
    });
  };

  fetchTaskLevelGanttData = async (location: string, task?: string) => {
    this.isLoading = true;
    if (location !== this.selectedLocation) {
      this.selectedLocation = location;
    }
    if (task) {
      this.selectedTask = task;
    } else {
      this.selectedTask = '';
    }

    this.areaStatusService
      .fetchTaskLevelChartData(
        location,
        this.selectedArea && this.selectedArea !== 'Overall'
          ? this.selectedArea
          : 'Overall',
        task
      )
      .subscribe({
        next: (val) => {
          this.chartData = val.data.series;
        },
        complete: () => {
          if (task) {
            this.disableDrillDown = true;
          } else {
            this.disableDrillDown = false;
          }
          this.isLoading = false;
        },
        error: () => {
          this.isLoading = false;
        },
      });
  };
  onChangePage = (event: TablePageEvent) => {
    if (event.first !== undefined && event.rows !== undefined) {
      this.first = event.first;
      this.rows = event.rows;
      this.alertsTrigger$.next();
    }
  };

  onEquipmntChangePage = (event: TablePageEvent) => {
    if (event.first !== undefined && event.rows !== undefined) {
      this.equipmntFirst = event.first;
      this.equipmntRows = event.rows;
      this.equipmentDataTrigger$.next();
    }
  };

  onClickTabMenu = (type: MenuTabs) => {
    this.selectedTabMenu = type;
    this.isDialogVisible = true;
  };

  onClosePopup = () => {
    if (!this.isDialogVisible) {
      return;
    }
    this.isDialogVisible = false;
  };

  resetMainTable = () => {
    this.rows = 10;
    this.first = 0;
  };
  onTabChange = (d: TabChangeEvent) => {
    this.activeIndex = d.index;
    this.activetab = d.item.label;
    this.tabChangeLoading = true;
    this.resetMainTable();
    this.alertsTrigger$.next();
  };

  fetchEquipmentTagData = async (isDownloadClicked?: boolean) => {
    this.equipmntTableLoading = true;
    const filters: {
      area?: string;
    } = {};
    if (!!this.selectedArea && this.selectedArea !== 'Overall') {
      filters.area = this.selectedArea;
    }

    this.projectSchedulingService
      .getEquipmentTagData({
        filters,
        pageNo: Math.floor(this.equipmntFirst / this.equipmntRows),
        noOfRecords: this.equipmntRows,
        isDownloadClicked,
      })
      .subscribe({
        next: (res) => {
          console.log('equipmentData', res);
          if (isDownloadClicked) {
          } else {
            this.equipmentTagData = {
              columns: res.data.columns,
              data: res.data.data,
              count: res.data.count,
            };
          }
        },
        complete: () => {
          this.equipmntTableLoading = false;
        },
        error: (error) => {
          console.error('Failed to fetch hse details data', error);
          this.equipmntTableLoading = false;
        },
      });
  };

  fetchEquipmentTagDataForDownload = async (): Promise<
    Record<string, any>[]
  > => {
    try {
      const filters: {
        area?: string;
      } = {};
      if (!!this.selectedArea && this.selectedArea !== 'Overall') {
        filters.area = this.selectedArea;
      }
      const res = await firstValueFrom(
        this.projectSchedulingService.getEquipmentTagData({
          filters,
          pageNo: Math.floor(this.equipmntFirst / this.equipmntRows),
          noOfRecords: this.equipmentTagData.count,
          isDownloadClicked: true,
        })
      );
      return res.data.data;
    } catch (error) {
      console.error('Failed to fetch equipment tag data', error);
      return [];
    }
  };

  fetchAlerts = async (isDownloadClicked?: boolean) => {
    this.tableLoading = true;
    const filters: {
      area?: string[];
      cwp?: string[];
    } = {};
    if (!!this.selectedArea && this.selectedArea !== 'Overall') {
      filters.area = [this.selectedArea, 'Not Available'];
    }
    if (this.selectedLocation) {
      filters.cwp = [this.selectedLocation, 'Not Available'];
    }
    this.projectSchedulingService
      .getAlerts({
        noOfRecords: this.rows,
        pageNo: Math.floor(this.first / this.rows),
        filters,
        isDownloadClicked,
        type: this.items[this.activeIndex].type,
      })
      .subscribe({
        next: (res) => {
          if (isDownloadClicked) {
          } else {
            let cols = res.data.columns;
            cols = cols.concat({
              field: 'redirect',
              header: '',
              bodyTemplate: this.alertRedirect,
            });
            this.alerts = {
              columns: cols,
              data: res.data.data.map((el) => {
                return {
                  ...el,
                  redirect: '',
                };
              }),
              count: res.data.count,
            };
          }
        },
        complete: () => {
          this.tableLoading = false;
          this.tabChangeLoading = false;
          this.isLoading = false;
        },
        error: () => {
          this.tableLoading = false;
          this.tabChangeLoading = false;
        },
      });
  };

  getAlertsForDownload = async (): Promise<Record<string, any>[]> => {
    try {
      const filters: {
        area?: string[];
        cwp?: string[];
      } = {};
      if (!!this.selectedArea && this.selectedArea !== 'Overall') {
        filters.area = [this.selectedArea, 'Not Available'];
      }
      if (this.selectedLocation) {
        filters.cwp = [this.selectedLocation, 'Not Available'];
      }
      const res = await firstValueFrom(
        this.projectSchedulingService.getAlerts({
          noOfRecords: this.rows,
          pageNo: Math.floor(this.first / this.rows),
          filters,
          isDownloadClicked: true,
          type: this.items[this.activeIndex].type,
        })
      );
      return res.data.data;
    } catch (error) {
      console.error('Failed to fetch alerts data', error);
      return [];
    }
  };

  getChartTitle(): string {
    let title = 'Baseline Schedule - ';

    if (this.selectedArea !== '') {
      title += this.selectedLocation ? this.selectedArea : this.selectedArea; // selectedLocation just adds the underline class but doesn't affect the text
    } else {
      title += 'Overall';
    }

    if (this.disableDrillDown && this.selectedLocation) {
      title += ` / ${this.selectedLocation}`;
    }

    return title;
  }

  controlImageDialog = async (area: string) => {
    this.imagesDialog = !this.imagesDialog;
  };

  get4DImages = () => {
    this.fileService
      .get4DImages(this.selectedArea, !!this.selectedLocation)
      .subscribe({
        next: (val) => {
          this.imageUrls = val.data;
        },
        complete: () => {
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false;
        },
      });
  };

  getVideo = () => {
    this.fileService
      .getPresignedUrls(
        'video',
        'Reliance/Polysilicon/Videos/construction_site.mp4'
      )
      .subscribe({
        next: (res) => {
          this.videoUrl = res.data;
        },
      });
  };

  controlVideoDialog = () => {
    this.isLoading = true;
    this.videoDialog = true;
    this.isLoading = false;
  };

  onAlertLinkClick = (type: string, data: any) => {
    if (type === 'docControl') {
      this.router.navigate(['/engineering-status'], {
        state: {
          discipline: data?.costType?.includes('-')
            ? data?.costType?.split(' - ')[1]
            : data?.costType,
        },
      });
    } else if (type === 'vdr') {
      this.router.navigate(['/vdr-status'], {
        state: {
          documentNo: data?.documentNo,
        },
      });
    } else {
      this.router.navigate(['purchase-order-tracking'], {
        state: {
          po: data?.po,
        },
      });
    }
  };

  onCloseDialog = (dialogType: string) => {
    if (dialogType === 'images') {
      this.imagesDialog = false;
    } else if (dialogType === 'main') {
      this.isDialogVisible = false;
    } else if (dialogType === 'video') {
      this.videoDialog = false;
    }
  };

  getFileName = (file: string) => {
    return getFileNameFromS3Url(file);
  };

  ngAfterViewInit(): void {
    this.tablecompnt.onDownloadClick = this.getAlertsForDownload;
  }

  onDownloadClickOnTab = () => {
    downloadExportExcel(
      this.tablecompnt.onDownloadClick!,
      this.tablecompnt.cols,
      this.tablecompnt.appliedColumns,
      this.activetab
    );
  };
}
