<div class="icon-container">
  @if (icon) {
  <div
    [ngbTooltip]="tooltip"
    [ngClass]="getClass()"
    [ngStyle]="hoverClass ? {} : { color, fontSize: iconSize }"
    class="cursor-pointer iholder h-100 d-flex"
  ></div>
  } @else {
  <span></span>
  }
</div>
