<div class="status-indicator-container">
    
    <div class="status-indicator-content">
      
      <div
        class="status-icon-box "
        [ngStyle]="getStatusStyle()"
      >
        <app-icon [icon]="IconType"></app-icon>
      </div>
  
      <!-- Title Section -->
       <div class="d-flex justify-content-center" style="width: 100%;align-items: center;">
        <div class="status-title flex">
          <div class="text-black">{{ title }}</div>
        </div>
        <div [ngStyle]="getStatusStyle()" class="status-circle"></div>
       </div>
      
      <!-- Optional External Link Icon -->
       <div class="end-element">
        <ng-container *ngIf="endElement">
            <ng-container *ngTemplateOutlet="endElement"></ng-container>
          </ng-container>
       </div>
      
    </div>
  </div>
  