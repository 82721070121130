import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitter } from 'stream';

@Component({
  selector: 'app-icon',
  standalone: true,
  imports: [CommonModule, NgbTooltip],
  templateUrl: './icon.component.html',
  styleUrl: './icon.component.css',
})
export class IconComponent {
  @ViewChild('el') el!: ElementRef;
  @Input() icon: string = '';
  @Input() color: string = 'inherit';
  @Input() fillColor: string = '';
  @Input() hoverClass: string = '';
  @Input() tooltip: string = '';
  @Input() iconSize?: string

  getClass = () => {
    const obj: Record<string, boolean> = {
      [this.icon]: true,
    };
    if (this.hoverClass) {
      obj[this.hoverClass] = true;
    }
    return obj;
  };
}
