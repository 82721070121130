import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { IconComponent } from '../components/icon/icon.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

export const globalModules = [
  CommonModule,
  RouterModule,
  IconComponent,
  NgbModule,
]


export const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
  import("file-saver").then((module) => {
    if (module && module.default) {
      const EXCEL_TYPE =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const EXCEL_EXTENSION = ".xlsx";
      const data = new Blob([buffer], {
        type: EXCEL_TYPE,
      });

      module.default.saveAs(
        data,
        fileName + new Date().getTime() + EXCEL_EXTENSION
      );
    }
  });
};