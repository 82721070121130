import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnDestroy,
  ElementRef,
  OnChanges,
  SimpleChanges,
  DoCheck,
  AfterViewChecked,
  AfterViewInit,
  Output,
  TemplateRef,
  ChangeDetectorRef,
} from '@angular/core';
import Highcharts, { Options } from 'highcharts';
import Exporting from 'highcharts/modules/exporting';
import NoData from 'highcharts/modules/no-data-to-display';
import HighchartsPatternFill from 'highcharts/modules/pattern-fill';
import { ChartDataType } from '../../interface/ChartTypes';
import { CardComponent } from '../card/card.component';
import { CommonModule } from '@angular/common';
import { formatThousandNumberToK } from '../../utils';
import { DownloadChartComponent } from '../download-chart/download-chart.component';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';


// Initialize Highcharts modules
Exporting(Highcharts);
NoData(Highcharts);
HighchartsPatternFill(Highcharts);

@Component({
  selector: 'single-column',
  templateUrl: './single-column.component.html',
  standalone: true,
  imports: [CardComponent, CommonModule],
})
export class SingleColumnComponent implements OnDestroy, OnChanges, AfterViewInit {
  @Input() id!: string;
  @Input() node?: HTMLElement;
  @Input() title?: string;
  @Input() cardTitle: string = 'chart';
  @Input() data!: ChartDataType;
  @Input() chartType: 'column' | 'bar' = 'column';
  @Input() legendDisabled = false;
  @Input() yAxisDisabled?: boolean;
  @Input() dataLabelsDisabled?: boolean;
  @Input() stacked?: boolean;
  @Input() drillDown = false;
  @Input() borderWidth?: number;
  @Input() borderColor?: string;
  @Input() symbolRadius?: number;
  @Input() ymax?: number;
  @Input() primaryYAxisMax?: number;
  @Input() secondaryYAxisMax?: number;
  @Input() tooltipOverRide?: Highcharts.TooltipOptions;
  @Input() handleDrillDown?: (
    category: string | number,
    series: string
  ) => void;
  @Input() yAxisType?: Highcharts.AxisTypeValue;
  @Input() avoidExpand: boolean = false;
  @Input() downloadChart: boolean = true;
  @Input() closeChart: boolean = false;
  @Input() dataLabelsSuffix?: string;
  @Input() helperText?: TemplateRef<any>;
  @Input() secondaryYAxisEnabled: boolean = false;
  @Input() primaryYAxisSuffix?: string;
  @Input() secondaryYAxisSuffix?: string;
  @Input() secondaryAxisTickPosition?: number[];
  @Input() primaryAxisTickPosition?: number[];
  @Input() yAxisName?: string;
  @Input() chartColor?: Highcharts.Options['colors'];
  @Input() categoryClass?: string;
  @Input() tooltipSuffix?: string;
  // @ViewChild('chartElement') chartElement!: ElementRef;
  @Input() endElement?: TemplateRef<any>;
  @Input() isExpanded: boolean = false; 
  @Input() titleTemplate?: TemplateRef<any>
  @Input() loading?: boolean = false; 
  @ViewChild('chartElement') chartElement!: ElementRef;
  // @ViewChild('chartContainerExpanded') chartContainerExpanded!: ElementRef;
  // @ViewChild(CardComponent) card!: CardComponent;

  chartOptions: Highcharts.Options = {};

  constructor(private cd: ChangeDetectorRef) {}
  ngOnInit() {
  }
  ngAfterViewInit() {
    this.setChartOptions();
    this.resizeChart()
  }

  downloadImage() {
    let Inode = document.getElementById(this.id)
    htmlToImage.toPng(Inode!)
  .then(function (dataUrl) {
    download(dataUrl, 'my-node.png');
  });
  }
  

 

  downloadHTMLAsPDF() {
    const Inode = document.getElementById(this.id);
  
    html2canvas(Inode!).then(canvas => {
      const image = htmlToImage.toPng(Inode!)
      const imgData = canvas.toDataURL('image/png');
  
      // A4 landscape dimensions in mm
      const a4Width = 297; // A4 width in mm
      const a4Height = 210; // A4 height in mm
  
      // Calculate dimensions for 80% of the A4 page
      const imgWidth = a4Width * 0.7; // 80% of A4 width
      const imgHeight = a4Height * 0.6; // 80% of A4 height
      const bottomMargin = a4Height * 0.1;
      const topPosition = (a4Height - imgHeight - bottomMargin) / 2;
      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4'
      });
  
      // Calculate the number of pages needed
      const pageHeight = pdf.internal.pageSize.height; // Height of the PDF page
      const heightLeft = imgHeight;
  
      let position = 0;
  
      // Add the image to the PDF
      pdf.addImage(imgData, 'PNG', (a4Width - imgWidth) / 2, topPosition, imgWidth, imgHeight);
  
      // Save the PDF
      pdf.save('download.pdf');
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.setChartOptions();
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeChart);
  }

  customTheme: Highcharts.Options = {
    lang: {
      thousandsSep: ',',
    },
    colors: [
      '#29c8c1',
      '#335e6a',
      '#a1c38c',
      '#4cabdb',
      '#89CBD2',
      '#ff9100',
      '#85BDDA',
      '#FBCDAA',
      '#9FA9A3',
    ],
    title: {
      style: {
        color: '#29C8C1',
        fontSize: '1rem',
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: !this.dataLabelsDisabled,
          style: {
            fontSize: '0.8rem',
            fontWeight: 'normal',
            textOutline: 'none',
          },
          formatter: function () {
            return this.y !== 0 ? this.y?.toLocaleString() : null;
          },
        },
      },
    },
    legend: {
      symbolRadius: 0,
    },
  };

  setChartOptions() {
    const thisCont = this;
   
    if (thisCont.id) {
      this.cd.detectChanges()
      const chartContainer = document.getElementById(thisCont.id); 
      if (chartContainer) {
        Highcharts.chart(thisCont.id, {
          ...thisCont.customTheme,
          ...(thisCont.chartColor ? { colors: thisCont.chartColor } : {}),
          chart: {
            type: thisCont.chartType,
            marginBottom: (
              (this.yAxisDisabled && this.chartType === 'bar' && !this.legendDisabled) ? undefined :
              (this.yAxisDisabled && this.chartType === 'bar') ? 0 :
              undefined
            ),
            backgroundColor: 'white', 
            events: {
              load: () => {
                window.addEventListener('resize', thisCont.resizeChart);
              },
            },
          },
          title: {
            text: '',
          },
          tooltip: {
            shared: true,
            style: {
              fontWeight: 'bold',
              zIndex: 9999,
            },
            formatter: function () {
              // Apply the formatThousandNumberToK function to point.y
              const tooltipText = this.points?.map(point => {
                const formattedY = point.y?.toLocaleString();
      
                // Check if the series color is a pattern object or a solid color
                let dotStyle;
                if (point.series.color && typeof point.series.color === 'object' && (point.series.color as any).pattern) {
                  // Use the pattern as an SVG element
                  const pattern = (point.series.color as any).pattern;
                  dotStyle = `
                    <svg width="8" height="8" style="display: inline-block; margin-right: 4px;">
                      <defs>
                        <pattern id="pattern-${point.series.index}" patternUnits="userSpaceOnUse" width="${pattern.width}" height="${pattern.height}">
                          <path d="${pattern.path.d}" stroke="${pattern.path.stroke || pattern.color}" stroke-width="${pattern.path.strokeWidth}" />
                        </pattern>
                      </defs>
                      <rect width="8" height="8" fill="url(#pattern-${point.series.index})" />
                    </svg>`;
                } else {
                  // Use solid color
                  const seriesColor = point.series.color || '#000000'; // Fallback color
                  dotStyle = `
                    <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background-color: ${seriesColor}; margin-right: 4px;"></span>`;
                }
      
                return `
                  <span style="display: flex; align-items: center; white-space: nowrap;">
                    ${dotStyle}
                    ${point.series.name}: ${formattedY} ${thisCont.tooltipSuffix || ''}
                  </span>`;
              }).join(''); // Join points without extra space between them
      
              // Display the category and the formatted tooltip text
              return `<span>${this.point.category}</span><br>` + tooltipText;
            },
            useHTML: true,
            ...thisCont.tooltipOverRide,
          },
          xAxis: {
            labels: {
              // useHTML: true,
              autoRotation: undefined,
              style: {
                textAlign: this.chartType === 'bar' ? 'end' : 'center',
                // zIndex: 1,
              },
              formatter: function () {
                return `<span style='max-width:150px; text-overflow: wrap;' class='${
                  thisCont.categoryClass || 'secondary-text'
                } z-0'>${this.value}</span>`;
              },
              step: 1,
            },
            categories: this.data.categories,
          },
          yAxis: this.secondaryYAxisEnabled
            ? [
                {
                  min: this.yAxisType === 'logarithmic' ? 1 : undefined,
                  max: this.primaryYAxisMax || this.ymax,
                  type: this.yAxisType,
                  title: {
                    text: this.yAxisName || '',
                    style: {
                      fontWeight: 'normal',
                      fontFamily: 'sans-serif',
                    },
                  },
                  ...(this.primaryAxisTickPosition
                    ? { tickPositions: this.primaryAxisTickPosition }
                    : {}),
                  labels: {
                    enabled: this.yAxisDisabled ? false : true,
                    format: `{value}${
                      this.primaryYAxisSuffix ? this.primaryYAxisSuffix : ''
                    }`,
                  },
                  reversedStacks: false,
                  lineWidth: 0,
                  gridLineWidth: 0,
                },
                {
                  opposite: true,
                  max: this.secondaryYAxisMax || this.ymax,
                  title: { text: '' },
                  ...(this.secondaryAxisTickPosition
                    ? { tickPositions: this.secondaryAxisTickPosition }
                    : {}),
                  labels: {
                    format: `{value}${
                      this.secondaryYAxisSuffix ? this.secondaryYAxisSuffix : ''
                    }`,
                  },
                },
              ]
            : {
                min: this.yAxisType === 'logarithmic' ? 1 : undefined,
                // max: ymax,
                type: this.yAxisType,

                title: {
                  text: '',
                },
                labels: {
                  enabled: this.yAxisDisabled ? false : true,
                },
                reversedStacks: false,
                lineWidth: 0,
                gridLineWidth: 0,
              },
          legend: {
            className: 'primary-text',
            enabled: !this.legendDisabled,
            symbolRadius: this.symbolRadius ?? 10,
            y: 22
          },

          plotOptions: {
            series: {
              stacking: this.stacked ? 'normal' : undefined,
              dataLabels: {
                enabled: !this.dataLabelsDisabled,
                formatter: function () {
                  return this.y !== 0
                    ? `${formatThousandNumberToK(this.y)}${
                        thisCont.dataLabelsSuffix
                          ? thisCont.dataLabelsSuffix
                          : ''
                      }`
                    : null;
                },
              },
              ...(this.drillDown && this.handleDrillDown
                ? {
                    cursor: 'pointer',
                    events: {
                      click: (e) => {
                        this.handleDrillDown!(
                          e.point.category,
                          e.point.series.name
                        );
                      },
                    },
                  }
                : {}),
            },

            column: {
              borderRadius: 0,
              borderWidth: this.borderWidth ?? 0,
              borderColor: this.borderColor,
            },
            bar: {
              borderRadius: 0,
              borderWidth: this.borderWidth ?? 0,
              borderColor: this.borderColor,
              groupPadding: 0.1,
              pointPadding: 0,
            },
          },
          series: this.data.series as any,
          exporting: { enabled: false },
          credits: { enabled: false },
        });
      }
    }
  }

  resizeChart = () => {
    const chart = this.chartElement.nativeElement?.chart;
    const doc = document.getElementById(this.id);
    if (doc) {
      // chart.setSize(doc.clientWidth, doc.clientHeight, false);
    }
    // const container = doc?.nativeElement;
  };

  toggleExpand() {
    this.isExpanded = !this.isExpanded;
    // this.resizeChart();
  }
}
