<ng-template #delayTableComponent>
  <app-table
    [onOpenFormLinkClick]="onOpenFormLinkClick"
    [onClickImage]="onClickImage"
    [avoidCard]="true"
    [loading]="tableLoading"
    [cols]="delayTable.columns"
    [defaultColumns]="delayTable.columns"
    [data]="delayTable.data"
    [expandable]="false"
    [hideCustomizeColumnIcon]="false"
    [hideDownloadIcon]="false"
    [paginationOptions]="{
      first: first,
      onChangePage: pageChange,
      paginationRequired: true,
      totalRecords: delayTable.count,
      rows: rows
    }"
    [onDownloadClick]="fetchDelayTableDataForDownload"
  >
  </app-table>
</ng-template>
<ng-template #imageDelayTableTemplate let-rowData>
  <div class="flex gap-1">
    <div title="Image" (click)="onClickImage(rowData)">
      @if (rowData?.imageUrlKey) {
      <app-icon color="gray" [icon]="'pi pi-image'"></app-icon>
      } @else {
      <span style="margin-left: 10px">--</span>
      }
    </div>
  </div>
</ng-template>
<ng-template #openFormDelayTableTemplate let-rowData>
  <div class="flex gap-1">
    <div title="Open" (click)="onOpenFormLinkClick(rowData)">
      <app-icon color="#29c8c1" [icon]="'pi pi-external-link'"></app-icon>
    </div>
  </div>
</ng-template>
<app-custom-dialog
  [visible]="showImage && !!selectedRow"
  (closeDialogClick)="closeImageClick()"
>
  <div className="h-full">
    @if(!selectedRow?.imageUrlKey?.length) {
    <div class="no-image-text">No Image Found</div>
    } @else if (imageLoading) {
    <app-loader [size]="10" />
    } @else {
    <img
      [alt]="
        selectedRow?.imageUrlKey ? getFileNameFromS3Url(imageUrl) : 'No image'
      "
      class="image-container"
      [src]="selectedRow?.imageUrlKey ? imageUrl : ''"
    />
    }
  </div>
</app-custom-dialog>
<div class="h-100 container-fluid">
  @if (isLoading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <div class="header-container">
    <app-header-text
      label="Delay Tracking Status (Sample Data)"
    ></app-header-text>
    <div class="icon-container">
      <div class="icon-subcontainer">
        <app-icon-button
          [label]="'Delay Submission Form'"
          [icon]="'pi pi-file-plus'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          (buttonClick)="onDelayFormClick()"
        ></app-icon-button>
      </div>
      <app-popover [isFilter]="isFilter" [label]="'Execution Delay Filter'">
        <div class="popover-main-container">
          <div class="popover-subcontainer">
            <app-single-select
              [label]="'Area'"
              [options]="areaDropdownValues"
              [placeholder]="'-- Select Area --'"
              (onChange)="onAreaSelectChange($event)"
              [value]="selectedArea"
            ></app-single-select>
            <app-multiselect-dropdown
              [label]="'Location'"
              [options]="locationValues"
              [placeholder]="'-- Select Location --'"
              (onChange)="onChangeLocation($event)"
              [value]="selectedLocation"
              [disabled]="selectedArea.length === 0"
            >
            </app-multiselect-dropdown>
            <app-multiselect-dropdown
              [label]="'Delay Reason'"
              [options]="delayReasonValues"
              [placeholder]="'-- Select Reason --'"
              (onChange)="onChangeDelayReason($event)"
              [value]="selectedDelayReason"
              [disabled]="selectedLocation.length === 0"
            >
            </app-multiselect-dropdown>
          </div>
          <div class="mt-4">
            <span
              class="link underline"
              style="font-size: medium"
              (click)="onFilterReset()"
              >Reset</span
            >
          </div>
        </div>
      </app-popover>
    </div>
  </div>
  <div class="row gap-2 gap-lg-0 mb-2 h-100">
    <div class="col-12 col-md-6 mb-3 h-50" style="padding-right: 0.5rem;">
      <donut-chart
        [id]="'executionDelayStatus'"
        [cardTitle]="'Execution Delay Status'"
        [chartData]="delayStatusData"
        [loading]="isDonutChartLoading"
      ></donut-chart>
    </div>

    <div class="col-12 col-md-6 mb-3 h-50" style="padding-left: 0.5rem;">
      <single-column
        [id]="'executionDelayHours'"
        [cardTitle]="'Execution Delay Hours'"
        [data]="delayHoursData"
        chartType="bar"
        [yAxisDisabled]="true"
        [legendDisabled]="true"
        [loading]="isBarChartLoading"
      ></single-column>
    </div>
    <div class="col-12 col-md-12 pb-3 h-100">
      <app-tabs
        class="h-100"
        [templateToLoad]="delayTableComponent"
        [activeIndex]="activeIndex"
        (tabChange)="onTabChange($event)"
        [model]="items"
        [onDownloadClick]="onDownloadClickOnTab"
      >
      </app-tabs>
    </div>
  </div>
</div>
