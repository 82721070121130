<div class="popover-container">
  <!-- Icon trigger -->
  <app-icon
    icon="pi pi-filter-fill"
    style="color: #808080"
    (click)="togglePopover()"
    tooltip="Filter"
  ></app-icon>
  <span
    *ngIf="isFilter"
    class="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle"
  >
  </span>
  <!-- Popover content -->
  <div
    class="popover fade bs-popover-bottom popover-box popover-content-box"
    [class.show]="isOpen"
    [ngStyle]="{ height: height || 'auto' }"
  >
    <div class="arrow"></div>
    <div class="popover-subcontent-box">
      <h3 class="label-header">
        {{ label }}
      </h3>
      <app-icon
        class="icon-style"
        icon="pi pi-times"
        (click)="togglePopover()"
        tooltip="Close"
      ></app-icon>
    </div>

    <div class="popover-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
