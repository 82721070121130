<div class="d-flex flex-column align-items-start p-3" style="flex-basis: 50rem;">
    <div *ngFor="let step of data; let index = index" class="step-container d-flex align-items-center">
      <div>
        <div
          class="step-icon d-flex justify-content-center align-items-center"
          [ngStyle]="getStatusIcon(step.status).style"
          style="margin-right: 1rem; padding: 0.5rem; border-radius: 50%;"
        >
          <app-icon [icon]="getStatusIcon(step.status).icon" style="font-size: 1.2rem;"></app-icon>
        </div>
        <div
          class="d-flex flex-column"
          [ngStyle]="{
            'width': '2px',
            'height': '40px',
            'background-color': index < data.length - 1 ? '#ccc' : 'white',
            'margin-left': '1.2rem'
          }"
        ></div>
      </div>
  
      <div class="step-content align-self-start">
        <div class="primary-text font-weight-bold">{{ step.actionName }}</div>
        <div class="secondary-text">
          <ng-container *ngIf="step.action && step.actionBy">
            <div><strong>{{ step.action }}:</strong> {{ step.actionBy }}</div>
            ({{ convertDateToCommonFormat(step.actionOn!) }})
          </ng-container>
  
          <ng-container *ngIf="step.assignedTo">
            <div><strong>Assigned To:</strong> {{ step.assignedTo }}</div>
            <div class="secondary-text">&nbsp;</div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>