<!-- Templates Starts Here -->
<ng-template #alertsTable>
  <app-table
    [loading]="tableLoading"
    [avoidCard]="true"
    [cols]="alerts.columns"
    [defaultColumns]="alerts.columns"
    [data]="alerts.data"
    [paginationOptions]="{
      first: first,
      onChangePage: onChangePage,
      paginationRequired: true,
      totalRecords: alerts.count,
      rows: rows
    }"
    [onDownloadClick]="getAlertsForDownload"
  >
  </app-table>
</ng-template>
<ng-template #download>
  <app-icon color="#29c8c1" icon="pi pi-download"></app-icon>
</ng-template>
<ng-template #breadCrumb>
  @if (currentLevel === 0) {
  <div class="card-header-text">Baseline Schedule - Overall Construction</div>
  } @else if (currentLevel === 1) {
  <div class="card-header-text">
    Baseline Schedule {{ " - " }}
    <span>{{ selectedArea }}</span>
  </div>
  } @else if (currentLevel === 2) {
  <div class="card-header-text">
    Baseline Schedule {{ " - " }}
    <span
      (click)="onBarClick({ value: selectedArea, level: 0 })"
      class="link underline"
      >{{ selectedArea }}</span
    >
    {{ " /" }}
    <span>{{ selectedLocation }}</span>
  </div>
  } @else if (currentLevel === 3) {
  <div class="card-header-text">
    Baseline Schedule {{ " - " }}
    <span
      (click)="onBarClick({ value: selectedArea, level: 0 })"
      class="link underline"
      >{{ selectedArea }}</span
    >
    {{ " /" }}
    <span
      (click)="onBarClick({ value: selectedLocation, level: 1 })"
      class="link underline"
    >
      {{ selectedLocation }}</span
    >
    {{ " /" }}
    <span>{{ selectedTask }}</span>
  </div>

  }
</ng-template>
<ng-template #popupTitle>
  @if (selectedTabMenu) {
  <div class="flex title">
    <div class="me-1">
      <app-icon [icon]="selectedMenuIconSelector[selectedTabMenu]"></app-icon>
    </div>
    <div>{{ selectedTabMenu | titlecase }}</div>
  </div>
  }
</ng-template>
<ng-template #alertRedirect let-rowData>
  <app-icon
    icon="pi pi-external-link"
    (click)="onAlertLinkClick(items[activeIndex].type, rowData)"
    class="link"
  ></app-icon>
</ng-template>
<!-- Templates Ends Here -->

<app-custom-dialog
  [style]="{ width: '50vw'}"
  titleString="4D Drawings"
  (closeDialogClick)="onCloseDialog('images')"
  [visible]="imagesDialog"
  [maximizable]="true"
  
>
  <ngb-carousel [interval]="0" [showNavigationArrows]="false">
    @for (image of imageUrls; track $index) {
    <ng-template ngbSlide>
      <div class="h-100">
        <img
          priority
          placeholder
          loading="eager"
          height="100%"
          width="100%"
          [src]="image.key"
          alt="4D Images"
        />
      </div>
      <div class="carousel-caption">
        <h6>{{ getFileName(image.key) }}</h6>
      </div>
    </ng-template>
    }
  </ngb-carousel>
</app-custom-dialog>
<app-custom-dialog
  [downloadIcon]="true"
  (downloadClick)="downloadVideo()"
  titleString="Sample Video"
  [style]="{ width: '50vw', 'max-height': '90%' }"
  (closeDialogClick)="onCloseDialog('video')"
  [visible]="videoDialog"
  
>
  <div class="video-container h-100">
    <video controls width="740" height="360" autoPlay preload="metadata">
      <source [src]="videoUrl" type="video/mp4" />
      <track label="English" kind="captions" srclang="en" src="" default />
      Your browser does not support the video tag.
    </video>
  </div>
</app-custom-dialog>

<app-custom-dialog
  [title]="popupTitle"
  [alreadyMaximized]="true"
  (closeDialogClick)="onCloseDialog('main')"
  [visible]="isDialogVisible"
  class="h-100"
>
  <div class="w-100 h-100">
    <app-table
      [avoidCard]="true"
      [title]="'Equipment Tag Data'"
      [inTableDialog]="true"
      [loading]="equipmntTableLoading"
      [cols]="equipmentTagData.columns"
      [defaultColumns]="defaultCols"
      [data]="equipmentTagData.data"
      (onClosePopup)="onClosePopup()"
      [isTableDialogVisible]="isDialogVisible"
      [paginationOptions]="{
        first: equipmntFirst,
        onChangePage: onEquipmntChangePage,
        paginationRequired: true,
        totalRecords: equipmentTagData.count,
        rows: equipmntRows
      }"
      [onDownloadClick]="fetchEquipmentTagDataForDownload"
    >
    </app-table>
  </div>
</app-custom-dialog>

<div class="subcontainer">
  <app-header-text label="Project Scheduling" [endElement]="endElement">
    <ng-template #endElement>
      <div>
        @for (item of menuItems; track $index) {
        <div
          class="home-menu-item primary-text ms-auto"
          [ngClass]="{ disabled: item.label === 'Data Quality' }"
        >
          <div
            (click)="item.command()"
            class="d-flex p-2 align-items-center cursor-pointer"
          >
            <app-icon [icon]="item.icon"></app-icon>
            <div>{{ item.label }}</div>
          </div>
        </div>
        }
      </div>
    </ng-template>
  </app-header-text>
</div>

<div class="h-100 container-fluid w-100 pe-0">
  @if (isLoading) {
  <app-loader [backdrop]="true"></app-loader>
  }

  <!-- Parent Component Template -->
  <div class="row col-12 h-75">
    <div class="col-12 col-sm-12 col-md-3 pe-0" style="height: 106%">
      <app-area-image
        [isGanttChart]="true"
        class="area-status-bg-img"
        (areaClick)="onAreaClick($event)"
        [selectedArea]="selectedArea"
        (on4dClick)="controlImageDialog(selectedArea)"
        (onVideoClick)="controlVideoDialog()"
      >
      </app-area-image>
    </div>
    <div class="col-12 col-sm-12 col-md-9 h-100 pe-0">
      <app-gantt-chart
        class="h-100"
        [seriesData]="chartData"
        [dataLabelsDisabled]="true"
        [cardProps]="{
          titleTemplate: breadCrumb
        }"
        (onBarClick)="onBarClick($event)"
        [currentLevel]="currentLevel"
        [disableDrillDown]="disableDrillDown"
      >
      </app-gantt-chart>
    </div>
    <div style="height: 90%; padding-bottom: 1rem" class="col-12 pe-0">
      <app-tabs
        class="h-100 pe-0"
        [templateToLoad]="alertsTable"
        [activeIndex]="activeIndex"
        (tabChange)="onTabChange($event)"
        [model]="items"
        [onDownloadClick]="onDownloadClickOnTab"
      >
      </app-tabs>
    </div>
  </div>
</div>
