// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  msalConfig: {
    auth: {
      clientId: '2d4462a8-36d7-4c3e-8647-b593df8ed70d',
      authority:
        'https://login.microsoftonline.com/3dcd35b5-f9c5-48ca-8653-821568ad3397',
    },
  },
  apiConfig: {
    scopes: ['api://923ddb6e-752e-45a4-bf09-8dc2543b7fa5/access_as_user'],
    uri: 'https://dev.worley-api.alignedautomation.com/api/v1',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
