import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { ChartDataType } from '../../interface/ChartTypes';
import { ColumnProps } from '../../interfaces/table-interface';
import { OverallScurveModel } from '../../types/OverallScurveModel';


const {
  PROJECT_SCHEDULE_CHARTDATA,
  PROJECT_SCHEDULE_TABLEDATA
} = API_END_POINTS

@Injectable({
  providedIn: 'root'
})
export class ConstructionProjectSchedulingService {

  constructor(private http: HttpClient) { }

  getProjectScheduleChartData(): Observable<{data: OverallScurveModel }> {
    return this.http.post<{data: OverallScurveModel }>(PROJECT_SCHEDULE_CHARTDATA, null )
  }

  getProjectScheduleTableData(pageNo?: number, noOfRecords?: number, isDownloadClicked?: boolean):
   Observable<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }> {
    return this.http.post<{ data: { columns: ColumnProps[], data: Record<string, any>[], count: number } }>(PROJECT_SCHEDULE_TABLEDATA, {
      pageNo, noOfRecords, isDownloadClicked
  })
  }
  
}
