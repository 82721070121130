<div
  class="card-container h-100"
  [ngClass]="{ fullscreen: isFullscreen }"
  [ngStyle]="style"
>
  @if (titleTemplate || title) {

  <div class="card-header" [ngStyle]="headerStyle">
    @if (titleTemplate) {
    <ng-container
      class="h-100"
      *ngTemplateOutlet="titleTemplate"
    ></ng-container>
    } @else {
    <div class="card-header-text">{{ title }}</div>

    }
    <div class="d-flex align-items-center">
      <!-- Helper Text -->
      @if(helperText){
      <ng-container *ngTemplateOutlet="helperText"></ng-container>
      }

      <!-- End Element (Optional content) -->
      <ng-container *ngIf="endElement">
        <ng-container *ngTemplateOutlet="endElement"></ng-container>
      </ng-container>
      @if(downloadChart && id){
      <ng-container>
        <download-chart [id]="id" [title]="title"></download-chart>
      </ng-container>
      }

      <!-- Download Button -->
      @if(downloadIcon?.enable){
      <app-icon
        [icon]="'pi pi-download'"
        [color]="'#29c8c1'"
        [ngbTooltip]="'Download'"
        (click)="downloadIcon?.onClick()"
      ></app-icon>
      }

      <!-- Customize Column Button -->
      <button
        *ngIf="customizeColumn?.enable"
        class="btn btn-link p-0"
        (click)="customizeColumn?.onClick()"
      >
      <app-icon color="#29c8c1" icon="pi pi-cog"></app-icon>
      </button>
      @if (expandable) { @if(!avoidExpand){

      <app-icon
        (click)="toggleFullscreen()"
        color="#29c8c1"
        [tooltip]="isFullscreen ? 'Close' : 'Expand'"
        [icon]="
          isFullscreen
            ? 'pi pi-times'
            : 'pi pi-arrow-up-right-and-arrow-down-left-from-center'
        "
      >
      </app-icon>
      } }
    </div>
  </div>
  }

  <!-- Card Body -->
  <div
    class="card-body"
    style="height: 90%"
    [ngClass]="{ 'fullscreen-body': isFullscreen }"
  >
    @if(loading){
    <div
      class="d-flex justify-content-center align-items-center"
      style="height: 100%"
    >
      <app-loader></app-loader>
    </div>
    } @else {
    <ng-content></ng-content>
    }
  </div>
</div>
