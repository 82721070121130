import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef, ViewChild,ChangeDetectorRef } from '@angular/core';
import Highcharts from 'highcharts';
import { DonutChartModel } from '../../../interface/ChartTypes';
import { formatThousandNumberToK } from '../../../utils';
import { CardComponent } from '../../card/card.component';
import { globalModules } from '../../../utils/global-modules';
@Component({
  selector: 'donut-chart',
  standalone: true,
  imports: [CardComponent, ...globalModules],
  templateUrl: './donut-chart.component.html',
})
export class DonutChartComponent implements OnChanges, OnDestroy, AfterViewInit {


  @Input() id!: string;
  @Input() cardTitle?: string;
  @Input() downloadChart: boolean = true;
  @Input() chartData!: DonutChartModel;
  @Input() title?: string;
  @Input() colors?: string[];
  @Input() drilldown: boolean = false;
  @Input() loading: boolean = false;
  @Input() handleDrillDown?: (seriesName: string) => void;
  @Input() endElement?: TemplateRef<any>;
  @ViewChild('donutChartElement') donutChartElement!: ElementRef;
  constructor(private cd: ChangeDetectorRef) {}
  ngOnInit() { }
  ngOnChanges(changes: SimpleChanges): void {
    this.setChartOptions();
  }

  ngAfterViewInit(): void {
    this.setChartOptions();
    this.resizeChart()
  }

  resizeChart = () => {
    const chart = this.donutChartElement.nativeElement;
    const container = document.getElementById(this.id);
    if (chart && container) {
      // chart.setSize(container.clientWidth, container.clientHeight * 2, false);

    }
  };
  chartColors = [
    "#29c8c1",
    "#335e6a",
    "#a1c38c",
    "#4cabdb",
    "#89CBD2",
    "#ff9100",
    "#85BDDA",
    "#FBCDAA",
    "#9FA9A3",
  ]

  setChartOptions() {
    if (this.id) {
      this.cd.detectChanges()
      const chartContainer = document.getElementById(this.id);

      if (chartContainer) {
        Highcharts.chart(this.id, {
          chart: {
            type: 'pie',
            marginBottom: 0,
            marginTop: 0,
            backgroundColor: 'white',
            events: {
              load: () => window.addEventListener('resize', this.resizeChart)
            },
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          title: {
            text: this.title,
            align: 'left',
            y: -1,
            style: { fontSize: '0.8rem', x: 0 },
          },
          tooltip: {
            pointFormat:
              '<span>{series.name} ({point.y:,.0f})</span>: <b>{point.percentage:.0f}%</b>',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            borderWidth: 1,
            formatter: function () {
              const formattedY = formatThousandNumberToK(this.point.y);
              return `<span>${this.point.name}<br><span>${this.series.name
                } (${formattedY})</span>: <b>${this.percentage.toFixed(
                  1
                )}%</b></span>`;
            },
            shadow: false,
            style: { color: '#000', left: 0, fontWeight: 'bold' },
            // align: "left",
            // x: 0,
          },
          legend: { enabled: false, },
          plotOptions: {
            pie: {
              borderWidth: 0, // Removes border width
              borderRadius: 0,
              colors: this.colors ? this.colors : this.chartColors,
              innerSize: '70%',

            },
            series: {
              dataLabels: [{
                enabled: true,
                formatter: function () {
                  return `${this.point.name} (${formatThousandNumberToK(
                    this.y
                  )}): ${Number(this.point.percentage?.toPrecision(2))}%`;
                },
                style: { fontWeight: 'normal', fontSize: '0.8rem' },
              }],
              allowPointSelect: true,
              cursor: 'pointer',
              events:
                this.drilldown && this.handleDrillDown
                  ? {
                    click: (event: any) =>
                      this.handleDrillDown!(event.point.name),
                  }
                  : {},

              showInLegend: true,
            },
          },
          series: [
            {
              type: 'pie',
              name: this.chartData.name,
              // colorByPoint: true,
              data: this.chartData.data as any,
            },
          ],
          credits: { enabled: false },
          exporting: {
            enabled: false,
          },
        });
      }
    }
  }
  ngOnDestroy(): void {
    window.removeEventListener('resize', this.resizeChart)
  }
}
