<ng-template #closeicon>
  <app-icon-button (buttonClick)="closeDialog()" [icon]="'pi pi-times'" [iconColor]="'gray'" [title]="'Close'" />
</ng-template>
<ng-template #endButtons>
  <div style="display: flex;">
   @if (endElement) {
     <ng-container *ngTemplateOutlet="endElement"></ng-container>
   }
    <ng-container *ngTemplateOutlet="closeicon"></ng-container>

  </div>
</ng-template>

<div class="flex justify-content-center ">
  <p-dialog [draggable]="false"  (onShow)="onShow($event)"  [closable]="false" [modal]="true" [(visible)]="visible" [maximizable]="maximizable" [style]="style" [dismissableMask]="true"
    (onHide)="closeDialog()">
    <ng-template pTemplate="header" class="d-flex w-100">
      @if (titleString) {
      <div class="card-header-text">
        {{ titleString }}
      </div>
      }
      @if(title) {
      <ng-container *ngTemplateOutlet="title"></ng-container>
      }
      <div class="ml-auto d-flex">

        <ng-container *ngTemplateOutlet="endButtons"></ng-container>

    
      </div>
      
    </ng-template>
    @if(customizeColumn) {
    <ng-template pTemplate="footer">
      <div style="display: flex; justify-content: space-between;">
        <div>
          <button style="border: #29c8c1 1px solid; background-color: white; padding: 8px; border-radius: 5px;"
            (click)="resetClick && resetClick()">Reset Columns</button>
        </div>
        <div style="display: flex; gap: 1rem;">
          <button style="border: #29c8c1 1px solid; background-color: white; padding: 8px; border-radius: 5px;"
            (click)="closeDialog()">Close</button>
          <button
            style="background-color: #29c8c1; color: black; padding: 8px; padding-right: 12px; padding-left: 12px; border: #29c8c1 1px solid; border-radius: 5px;"
            (click)="applyClick && applyClick()">Apply</button>
        </div>
      </div>
    </ng-template>
    }
    <div class="h-100 " >

      <ng-content class="h-75 "></ng-content>
    </div>
  </p-dialog>
</div>