import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { Dialog, DialogModule } from 'primeng/dialog';
import { IconButtonComponent } from "../icon-button/icon-button.component";
import { IconComponent } from "../icon/icon.component";


@Component({
  selector: 'app-custom-dialog',
  standalone: true,
  imports: [DialogModule, ButtonModule, IconButtonComponent, IconComponent, CommonModule],
  templateUrl: './custom-dialog.component.html',
  styleUrl: './custom-dialog.component.css'
})
export class CustomDialogComponent implements OnChanges {
  @Input() title: TemplateRef<string> | null = null;
  @Input() titleString?: string;
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Input() endElement?: TemplateRef<any>;
  @Input() maximizable?: boolean = false;
  @Input() alreadyMaximized?: boolean = false;
  @Input() resizable?: boolean;
  @Input() style?: { [key: string]: string | number }
  @Input() resetClick?: () => void;
  @Input() applyClick?: () => void;
  @Input() downloadIcon?: boolean;
  @Input() customizeColumn?: {
    enable: boolean;
    showDialog?: () => void;
  }
  @Output() closeDialogClick: EventEmitter<unknown> = new EventEmitter();
  @Output() downloadClick: EventEmitter<unknown> = new EventEmitter();
  
  @ViewChild(Dialog) dialogRef!: Dialog;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['downloadIcon']) {
    }
  }

  closeDialog() {
    if(this.closeDialogClick) {
      this.closeDialogClick.emit('')
    }
    this.visibleChange.emit(false);
  }

  isTitleString = () => {
    return typeof this.title === 'string';
  }

  onDownloadClick = () => {
    this.downloadClick.emit()
  }

  onShow = (event: any) => {    
    if (this.alreadyMaximized) {
      this.dialogRef.maximize();
    }
  }
}
