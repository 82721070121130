const API_END_POINTS = {
  PROJECT_SCHEDULE_STATS: '/app/projects-chedule/stats',
  MATERIAL_PLAN_STATS: '/app/material-planning/stats',
  LAST_REFRESHED_DATE: '/app/last-refreshed-date',
  CONSTR_MGMT_STATS: '/app/construction-management/stats',
  HSE_STATS: '/app/hse/stats',
  HSE_DETAILS: '/app/hse/details',
  IVDIMAGES: '/app/files/4dimages',
  HSE_INDICATORS: '/app/health-safety/indicators',
  HSE_TOP_RIBBON_DATA: '/app/health-safety/topribbondata',
  VDR_STATS: '/app/vdr/stats',
  AREA_STATUS: '/app/project-scheduling/location-sch',
  AREA_TASK_STATUS: '/app/project-scheduling/task-sch',
  PROJECT_SCHEDULE_EQUIPMENT_TAG: '/app/project-scheduling/equipment-tag',
  AREA_ALERTS: '/app/areaalerts',
  DOCUMENT_CONTROL_STATS: '/app/document-control/stats',
  DOCUMENT_SCURVE: '/app/document-control/scurve',
  PROJECT_SCHEDULE_CHARTDATA: '/app/project-scheduling/curve-chart',
  PROJECT_SCHEDULE_MONTH_RANGE: '/app/project-scheduling/date-range',
  PROJECT_SCHEDULE_ALERTS: '/app/project-scheduling/alerts',
  PROJECT_SCHEDULE_TABLEDATA: '/app/project-scheduling/details',
  PROCUREMENT_S_CURVE_LINE_CHARTDATA: '/app/expediting-report/overallscurve',
  DISCIPLINE_S_CURVE_CHARTDATA: '/app/expediting-report/disciplinescurve',
  EXPEDITING_MATERIAL_DELIVERED_STATUS_PIE_CHARTDATA:
    '/app/materialdeliveredstatus/piechartdata',
  EXPEDITING_UPCOMING_MATERIAL_DELIVERY_STATUS_PIE_CHARTDATA:
    '/app/upcomingdeliverystatus/piechartdata',
  EXPEDITING_REPORT_TABLEDATA: '/app/expeditingreport/tabledata',
  DATA_QUALITY: '/app/home-page/data-quality',
  HIGHLIGHTS_CONCERNS: '/app/project-highlights',
  DASHBOARD_LINK: (pageName: string) => `/app/DashboardPageLink${pageName}`,
  HIGHLIGHTS_CONCERNS_ALL: '/app/project-highlights/all',
  STACKED_COL_DATA: '/app/stacked-col-data',
  DELIVERY_STATUS: '/app/delivery-status',
  DRILLDOWN_DATA: '/app/drill-down-data',
  MATERIAL_DELAY_REASON: '/app/material-delay-reason',
  MATERIAL_DELIVERY_STATUS: '/app/material-planning/delivery-status',
  MATERIAL_DELAY_DRILLDOWN: '/app/material-delay-drilldown',
  MATERIAL_RELEASE_STATUS: '/app/material-planning/release-status',
  MATERIAL_ACTUAL_STATUS: '/app/material-planning/actual-status',
  INIT_USER: '/app/users/init',
  HOME_CARD_DATA: '/app/home-page',
  DELAY_FORM: '/app/delay-form',
  GET_DELAY_TABLE: '/app/delay-form/status-formdata',
  GET_FORM_DATA_BY_ID: (id: number) => `/app/delay-form/${id}`,
  DELAY_REASONS: '/app/delay-reasons',
  GET_AREAS: '/app/areas',
  DOC_CONTROL_FILTERS: '/app/document-control/filters',
  DOCUMENT_FORM_TITLES: '/app/document-control/document-titles',
  DOCUMENT_FORM_NUMBER_VERSION: '/app/document-control/document-number',
  DOCUMENT_FORM_DETAILS: '/app/document-control/document-details',
  DOCUMENT_CONTROL_IFC_PERCENT: '/app/document-control/ifc-percent',
  DOCUMENT_CONTROL_DELIVERY_TABLE: '/app/document-control/deliverable-status',
  SAVE_DOCUMENT_CONTROL_FORM: '/app/document-control/save-form',
  DOC_NOT_ISS_DISC: '/app/document-control/not-issued-by-discipline',
  DOC_RELEASE_PLAN: '/app/document-control/release-plan',
  DOC_DEL_BY_DISC: '/app/document-control/deliverables-by-discipline',
  DOC_IFC: '/app/document-control/issued-after-ifc',
  USERS: '/app/users',
  CWP: '/app/cwp',
  CONTRACTORS: '/app/contractors',
  PO_LEVEL_DETAILS: (po: string) => `/app/commodity-tracking/${po}`,
  COMMODITY_TRACKING_SAVE: '/app/commodity-tracking/',
  COMMODITY_DATA: '/app/commodity-tracking/all',
  FILES: '/app/files',
  EXECUTION_DELAY_STATUS: '/app/delay-form/execution-delay-status',
  EXECUTION_DELAY_HOURS: '/app/delay-form/execution-delay-hours',
  IMPACT_ASSESSMENT: '/app/impact-areas',
  VDR_RECEIPT_STATUS: '/app/vdr/status',
  VDR_CRITICAL_DOCUMENTS: '/app/vdr/criticaldocuments',
  MATERIAL_PLANNING_FILTERS: '/app/material-planning/filters',
  MATERIAL_PLANNING_DELAY_REASON:
    '/app/material-planning/material-delay-reason',
  VDR_CRITICAL_DOCUMENT_STATUS: '/app/vdr/critical-document-status',
  VDR_REVIEW_STATUS: '/app/vdr/received-reviewed',
  VDR_FILTERS: '/app/vdr/filters',
  EXPEDITING_S_CURVE_FILTERS: '/app/expediting-report/filters',
  COMMODITY_OVERALL_STATUS: '/app/commodity-tracking/overall-status',
  MASTER_DATA: '/app/master-data',
  REFRESH_DATA: '/app/master-data/refresh-data',
  CONSTRUCTION_MANAGEMENT: '/app/construction-management',
  CONSTRUCTION_MANAGEMENT_MONTH_RANGE:
    '/app/construction-management/date-range',
  CONSTRUCTION_MANAGEMENT_CRAFTS: '/app/construction-management/crafts',
  FILES_REPORTS: '/app/files/reports',
  MODEL_3D_SECRETS: '/app/model3d-secrets',
  DROP_DOWN_VALS: '/app/common/dropdownvalues',
  OVERALL_SCURVE: '/app/common/overall-scurve',
  AI_ASSIST_CATEGORY: '/ai/lookups/categories',
  AI_ASSIST_SESSION_WITH_CATEGORY: (category: string) =>
    `/ai/chats?category=${category}`,
  AI_ASSIST_CHAT_SESSION_ID: (sessionId: string) => `/ai/chats/${sessionId}`,
  AI_ASSIST_CHATS_DATA: (sessionId: string) => `/ai/chats/${sessionId}`,
  AI_ASSIST_ALL_CHATS: '/ai/chats',
};

export default API_END_POINTS;
