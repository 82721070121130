<div class="container-fluid h-100">
  @if(loading){
  <app-loader [backdrop]="true" />
  }
  <div class="d-flex align-items-center justify-content-between">
    <app-header-text [label]="'Construction Status'"></app-header-text>
    <div class="d-flex align-items-center gap-3">
      <app-icon-button
        [label]="'Dashboard'"
        [icon]="'pi pi-clipboard'"
        [iconColor]="'#6f6f6f'"
        [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        (click)="onLinksTextClick()"
      ></app-icon-button>
      <app-icon-button
        [label]="'Construction S Curve'"
        (click)="onLinkClick('/construction-project-scheduling')"
        [icon]="'pi pi-chart-line'"
        [iconColor]="'#6f6f6f'"
        [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
      ></app-icon-button>
    </div>
  </div>
  <div class="row" style="height: 45%">
    @if(processedData.length > 0) { @for (item of processedData; track $index;)
    {
    <div class="custom-chart col-12 col-md-6 mb-3 h-100">
      <single-column
        [loading]="
          item.title === 'Manpower Deployment (Nos)'
            ? isManpowerChartLoading
            : item.title === 'Civil Works (CuM)' ||
              item.title === 'Structural (MT)'
            ? isCivilStructuralLoading
            : loading
        "
        [id]="item.title"
        [cardTitle]="item.title"
        [chartType]="'column'"
        [data]="item.data"
        [endElement]="elementAtEnd"
      >
      </single-column>
    </div>
    <ng-template #elementAtEnd>
      @if(locationState !== 'Overall'){ @if(item.title === 'Civil Works (CuM)'
      || item.title === 'Structural (MT)') {
      <app-icon
        [color]="'#29c8c1'"
        [icon]="'pi pi-sync'"
        (click)="onResetArea()"
        [tooltip]="'Show all Area Data'"
      ></app-icon>
      } } @if(item.title === 'Manpower Deployment (Nos)') {
      <div>
        <div style="width: 12rem">
          <app-multiselect-dropdown
            [options]="craftVals"
            [value]="selectedCraftValues"
            [placeholder]="'Craft'"
            (onChange)="onCraftChange($event)"
          ></app-multiselect-dropdown>
        </div>
      </div>

      }
    </ng-template>
    } }
  </div>
</div>

<ng-template #openLinkTemplate let-rowData>
  <div>
    <app-icon
      color="#29c8c1"
      (click)="onOpenFormLinkClick(rowData)"
      [icon]="'pi pi-external-link'"
    ></app-icon>
  </div>
</ng-template>

@if(isLinksTableVisible){
<app-table
  [title]="'Dashboard'"
  [inTableDialog]="true"
  [isTableDialogVisible]="isLinksTableVisible"
  (onClosePopup)="onClosePopup()"
  [cols]="linksData.columns"
  [defaultColumns]="linksData.columns"
  [data]="linksData.data"
  [hideDownloadIcon]="true"
  [hideCustomizeColumnIcon]="true"
></app-table>
}
