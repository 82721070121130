import {
  Component,
  ElementRef,
  inject,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MaterialsPlanningService } from '../../shared/services/materials-planning/materials-planning.service';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import {
  ChartDataType,
  ChartWithTable,
} from '../../shared/interface/ChartTypes';
import { CommonModule } from '@angular/common';
import { forkJoin } from 'rxjs';
import { DashboardLinks } from '../../shared/interface/DashboardLinks';
import { ColumnProps } from '../../shared/interfaces/table-interface';
import { Router } from '@angular/router';
import { DashboardLinksService } from '../../shared/services/pbi-links-servics/dashboard-links.service';
import { IconButtonComponent } from '../../shared/components/icon-button/icon-button.component';
import { finalize } from 'rxjs/operators';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { IconComponent } from '../../shared/components/icon/icon.component';
import { TableComponent } from '../../shared/components/table/table.component';

@Component({
  selector: 'app-materials-planning',
  standalone: true,
  imports: [
    SingleColumnComponent,
    CommonModule,
    HeaderTextComponent,
    IconComponent,
    LoaderComponent,
    IconButtonComponent,
    LoaderComponent,
    TableComponent
  ],
  templateUrl: './materials-planning.component.html',
  styleUrls: ['./materials-planning.component.css'],
})
export class MaterialsPlanningComponent implements OnInit {
  private materialsPlanningService = inject(MaterialsPlanningService);
  @Output() outputRef!: ElementRef;
  actualStatusData: ChartDataType = { categories: [], series: [] };
  planStatusData: ChartDataType = { categories: [], series: [] };
  loading: boolean = false;
  private dashboardLinksService = inject(DashboardLinksService);
  private router = inject(Router);
  isLinksTableVisible: boolean = false;
  linksData: {
    columns: ColumnProps[];
    data: Record<string, any>[];
    count: number;
  } = { columns: [], data: [], count: 0 };
  @ViewChild('openLinkTemplate', { static: false })
  openLinkTemplate!: TemplateRef<any>;


  fetchMaterialActualStatus(filters: any) {
    return this.materialsPlanningService.getMaterialActualStatus(filters);
  }

  fetchPlanStatus(filters: any) {
    return this.materialsPlanningService.getMaterialPlanStatus(filters);
  }


  onLinksTextClick() {
    this.isLinksTableVisible = true;
  }

  onClosePopup = () => {
    if (!this.isLinksTableVisible) {
      return;
    }
    this.isLinksTableVisible = false;
  };

  fetchDashboardLinks = async () => {
    const pageUrl = this.router.url;
    this.dashboardLinksService.getDashboardLinksData(pageUrl).subscribe({
      next: (response) => {
        this.linksData = {
          columns: [
            {
              field: 'iprWprDashboardPageName' as never,
              header: 'Dashboard',
            },
            {
              field: 'download' as never,
              header: 'Open Dashboard',
              bodyTemplate: this.openLinkTemplate,
style: {
          textAlign: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
            },
          ],
          data: response.data.map((el) => {
            return {
              ...el,
              download: '',
            };
          }),
          count: response.data.length,
        };
      },
      error: (err) => {
        console.error('Error fetching dashboard links:', err);
      },
    });
  };

  onOpenFormLinkClick = (rowData: DashboardLinks) => {
    window.open(rowData.iprWprDashboardLink, '_blank');
  };

  ngOnInit(): void {
    this.loading = true;
    this.fetchDashboardLinks();
    forkJoin([
      this.fetchMaterialActualStatus({}),
      this.fetchPlanStatus({}),
    ])
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe({
        next: ([materialActualStatusResponse, planStatusResponse]) => {
          this.actualStatusData = materialActualStatusResponse.data.chartData;
          this.planStatusData = planStatusResponse.data.chartData;
        },
        error: (err) => {
          console.error('Error in fetching material status', err);
          this.loading = false;
        },
      });
  }
}
