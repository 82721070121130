<div class="container-fluid h-100">
  @if(loading){
  <app-loader [backdrop]="true" />
  }
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 6%;
    "
  >
    <app-header-text [label]="'Materials Planning'"></app-header-text>
    <app-icon-button
      [label]="'Dashboard'"
      [icon]="'pi pi-clipboard'"
      [iconColor]="'#6f6f6f'"
      [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
      (click)="onLinksTextClick()"
    ></app-icon-button>
  </div>
  <div class="row gap-2 gap-lg-0" style="height: 90%">
    <!-- Plan Status Card -->
    <div class="col-12 col-md-12 col-lg-6 mb-4 h-100">
      <single-column
        #chartRef
        [id]="'structuralSteelCurve'"
        [cardTitle]="'Structural Steel Curve'"
        [borderWidth]="1"
        [borderColor]="'#a3a3a3'"
        [data]="planStatusData"
        [loading]="loading"
      >
      </single-column>
    </div>

    <!-- Actual Status Card -->
    <div class="col-12 col-md-12 col-lg-6 mb-4 h-100">
      <single-column
        #chartRef
        [id]="'concreteCurve'"
        [cardTitle]="'Concrete Curve'"
        [data]="actualStatusData"
        [loading]="loading"
      ></single-column>
    </div>
  </div>
</div>


<ng-template #openLinkTemplate let-rowData>
  <div>
    <app-icon
      color="#29c8c1"
      (click)="onOpenFormLinkClick(rowData)"
      [icon]="'pi pi-external-link'"
    ></app-icon>
  </div>
</ng-template>

@if(isLinksTableVisible){
<app-table
  [title]="'Dashboard'"
  [inTableDialog]="true"
  [isTableDialogVisible]="isLinksTableVisible"
  (onClosePopup)="onClosePopup()"
  [cols]="linksData.columns"
  [defaultColumns]="linksData.columns"
  [data]="linksData.data"
  [hideDownloadIcon]="true"
  [hideCustomizeColumnIcon]="true"
></app-table>
}
