@if (backdrop) {
<div class="backdrop">
    <div class="loader">
        @for (dot of dots; track $index) {
        <div class="dot" [ngStyle]="dotStyle"></div>
        }
    </div>
</div>
} @else {
<div class="loader">
    @for (dot of dots; track $index) {
    <div class="dot" [ngStyle]="dotStyle"></div>
    }
</div>
}