import { Component ,Input,Output,EventEmitter} from '@angular/core';
import { IconComponent } from '../icon/icon.component';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-status-indicator',
  standalone: true,
  imports: [IconComponent,CommonModule],
  templateUrl: './status-indicator.component.html',
  styleUrl: './status-indicator.component.css'
})
export class StatusIndicatorComponent {
   // Input properties equivalent to React props
   @Input() title: string = '';
   @Input() status: string = '';
   @Input() endElement?: any = '';
   @Input() IconType: string ='';
   
   // Optional click event
   @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

   statusStyles: { [key: string]: any } = {
    ontime: { borderColor: '#008445', color: 'white' },
    risk: { borderColor: '#008445', color: 'white' },
    critical: { borderColor: '#008445', color: 'white' }
  };
 
   // Status styles as an object
   getStatusStyle(): any {
    const baseStyle = this.statusStyles[this.status] || {};
    return {
      'background-color': baseStyle.borderColor,  // Safely access borderColor
      ...baseStyle  // Merge base style with other styles
    };
  }

  // Handle the click event and emit to parent component
  handleClick() {
    this.onClick.emit();
  }

}
