<div class="h-100 container-fluid">
  @if(loading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <div class="header-container">
    <app-header-text class="pb-1" label="Procurement S Curve"></app-header-text>
    <div class="icon-container">
      <div class="icon-list">
        <app-icon-button
          [label]="'Discipline S Curve'"
          (click)="onDisciplineLinkClick()"
          [icon]="'pi pi-chart-line'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        ></app-icon-button>
      </div>
    </div>
  </div>
  <div class="datacard">
    <app-scurvedata-card [data]="procurementScurveData.metaData">
    </app-scurvedata-card>
  </div>

  <div class="mb-3 singlecolumn-container">
    <single-column
      [id]="'Procurement S Curve'"
      [cardTitle]="'Procurement S Curve'"
      [tooltipSuffix]="'%'"
      [secondaryAxisTickPosition]="[0, 20, 40, 60, 80, 100]"
      [secondaryYAxisEnabled]="true"
      [primaryYAxisSuffix]="'%'"
      [secondaryYAxisSuffix]="'%'"
      [dataLabelsSuffix]="'%'"
      [data]="procurementScurveData.chartData"
      [loading]="loading"
    >
    </single-column>
  </div>
  <div class="pb-3">
    @if (procurementScurveData.tableData.count>0) {
    <app-table
      [title]="'Details'"
      [data]="procurementScurveData.tableData.rows"
      [cols]="procurementScurveData.tableData.columns"
      [defaultColumns]="procurementScurveData.tableData.columns"
      [paginationOptions]="paginationOptions"
      [loading]="loading"
      [expandable]="false"
      [hideCustomizeColumnIcon]="false"
      [hideDownloadIcon]="false"
      [textAlign]="'center'"
    ></app-table>
    }
  </div>
</div>
