@if (id) {
<app-card
  [title]="cardTitle!"
  class="h-100"
  [downloadChart]="downloadChart"
  [helperText]="helperText!"
  [isExpanded]="isExpanded"
  [id]="id"
  [avoidExpand]="avoidExpand"
  [endElement]="endElement"
  [titleTemplate]="titleTemplate"
  [loading]="loading"
>
  <div [id]="id" class="h-100" #chartElement></div>
</app-card>
}
