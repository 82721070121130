import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  standalone: true,
  imports: [],
  templateUrl: './cancel-button.component.html',
  styleUrl: './cancel-button.component.css'
})
export class CancelButtonComponent {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() buttonStyles: { [key: string]: string | number } = {};

  @Output() onClick = new EventEmitter<Event>();

  handleClick(event: Event): void {
    this.onClick.emit(event);
  }
}
