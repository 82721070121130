import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import API_END_POINTS from '../../../core/constants/apiEndPoints';
import { Observable } from 'rxjs';
const { FILES, IVDIMAGES } = API_END_POINTS;

@Injectable({
  providedIn: 'root'
})
export class FileServiceService {

  private http = inject(HttpClient);

  get4DImages = (area: string, drilldown: boolean): Observable<{ data: { key: string; name: string }[] }> => {
    return this.http.post<{ data: { key: string; name: string }[] }>(IVDIMAGES, {
      area, drilldown
    });
  };

  getPresignedUrls = (type: string, key: string) => {
    return this.http.get<{ data: string }>(`${FILES}/?key=${key}&type=${type}`);
  };
}
