<div class="container-fluid h-100">
  @if (isLoading) {
    <app-loader [backdrop]="true"></app-loader>
    }
 
  <div class="pb-1">
    <app-header-text 
    [label]="'HSE'"
    [endElement]="endElement">
    <ng-template #endElement>
      <div class="d-flex align-item-centre gap-4">
        @for (item of laggingIndicatorData.data.topRibbonData; track $index) {
        <div style="color: grey; font-size: 1rem; font-weight: bold">
          <span>
            {{ item.metric + ": " }}
          </span>
          <span [ngbTooltip]="item.actualValue.toLocaleString()">
            {{ item.value }}
          </span>
        </div>
        }
      </div>
    </ng-template>
  </app-header-text>
  </div>  
  
    
  

  <div class="row" style="height: 45%">
    <!-- Lagging Indicators -->
    <div class="col-12 h-100 col-md-12 mb-3">
      <single-column
        [id]="'LaggingIndicators'"
        [loading]="isLoadingForLagging"
        [chartType]="'column'"
        [data]="laggingIndicatorData.data.chartData!"
        [cardTitle]="'Lagging Indicators'"
        [legendDisabled]="true"
        [endElement]="laggingIndicatorEndEle"
      ></single-column>

    </div>

    <!-- Leading Indicators -->
    <div class="col-12 h-100 col-md-12">
      <single-column
        [id]="'leading Indicators'"
        [loading]="isLoadingForLeading"
        [data]="leadingIndicatorData.data.chartData"
        [cardTitle]="'Leading Indicators'"
        [legendDisabled]="true"
        [endElement]="leadingIndicatorEndEle"
      ></single-column>
      
    </div>
  </div>
</div>
      <ng-template #laggingIndicatorEndEle>
        <div style="margin-right: 1rem; gap: 0.4rem; margin-bottom: 2px; display: flex; width: 100%; align-items: center;">
          <label>Week End Date -</label>
          <div style="width: 9rem;">
            <app-single-select
            [placeholder]="'Select Date'"
            [value]="laggingFilters.endDate!"
            [options]="getEndDates(laggingIndicatorData.data.endDates)"
            (onChange)="onChangeDate('lagging',$event)"
            />
          </div>
        </div>
        <app-radio
          [style]="'display: flex'"
          [values]="laggingRadioButtons"
          [value]="selectedLagging"
          (onChange)="onLaggingRadioChange($event)"
        ></app-radio>
      </ng-template>
<ng-template #leadingIndicatorEndEle>
   <div style="margin-right: 1rem; gap: 0.4rem; margin-bottom: 2px; display: flex; width: 100%; align-items: center;">
          <label>Week End Date -</label>
          <div style="width: 9rem;">
            <app-single-select
            placeholder="Select Date"
            [value]="leadingFilters.endDate!"
            [options]="getEndDates(leadingIndicatorData.data.endDates)"
            (onChange)="onChangeDate('leading', $event)"
            />
          </div>
        </div>
  <app-radio
    [style]="'display: flex'"
    [values]="leadingRadioButtons"
    [value]="selectedLeading"
    (onChange)="onLeadingRadioChange($event)"
  ></app-radio>
</ng-template>