// gantt-chart.component.ts
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import HighchartsGantt from 'highcharts/modules/gantt';
import NoData from 'highcharts/modules/no-data-to-display';
// import customTheme from './custom-theme';
import { Subscription } from 'rxjs';
import { GanttChartType } from '../../../interface/ChartTypes';
import { globalModules } from '../../../utils/global-modules';
import { CardComponent } from '../../card/card.component';
import ChartTheme from '../chart-theme';

Exporting(Highcharts);
HighchartsGantt(Highcharts);
Highcharts.setOptions(ChartTheme);
NoData(Highcharts);

@Component({
  selector: 'app-gantt-chart',
  templateUrl: './gantt-chart.component.html',
  styleUrl: './gantt-chart.component.css',
  standalone: true,
  imports: [...globalModules, CardComponent],
})
export class GanttChartComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @Input() seriesData!: GanttChartType['series'];
  @Input() legendDisabled?: boolean;
  @Input() yAxisDisabled?: boolean;
  @Input() dataLabelsDisabled?: boolean;
  @Input() borderWidth?: number;
  @Input() borderColor?: string;
  @Input() symbolRadius?: number;
  @Input() ymax?: number;
  @Input() cardProps?: any;
  @Input() tooltipOverride?: Highcharts.TooltipOptions;
  @Input() yAxisType?: Highcharts.AxisTypeValue;
  @Input() disableDrillDown?: boolean;
  @Input() currentLevel: number = 0;

  @Output() onBarClick = new EventEmitter<{ value: string, level: number }>();


  private chart: Highcharts.Chart | null = null;
  private resizeSubscription?: Subscription;

  constructor() { }



  ngOnInit() {
    this.initializeChart('ngOnInit');
  }

  ngAfterViewInit() {
    this.initializeChart('ngAfterViewInit');
  }

  ngOnDestroy() {
    this.resizeSubscription?.unsubscribe();
    if (this.chart) {
      this.chart.destroy();
    }
   
  }


  private setupChartOptions(): Highcharts.Options {
    const component = this;
    return {
      chart: {
        ignoreHiddenSeries: false,
        backgroundColor: 'none',
        height: null,
        events: {
          load: function () {
            const chart = this;
            const containerHeight = chart.container.clientHeight;
            const maxSeriesLength = chart.series[0]?.data.length;

            const rowsVisible = Math.floor(containerHeight / 75);

            chart.yAxis[0].update({
              max:
                maxSeriesLength > rowsVisible
                  ? rowsVisible
                  : maxSeriesLength - 1,
              scrollbar: {
                enabled: maxSeriesLength > rowsVisible,
                showFull: false,
              },
            });
          },
        },
        spacingTop: 10,
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
      },
      tooltip: {
        useHTML: true,
        headerFormat: '<p style="font-size: 12px">{series.name}</p><br>',
        style: {
          fontWeight: 'bold',
        },
        formatter: function () {
          let tooltipContent = `
          <span style="font-size: 12px">${this.series.name} </span><br><br>
          <span style="font-size: 11px">${this.point.name}</span><br>
          `;

          if ((this.point as any).activity_name) {
            tooltipContent += `(${(this.point as any).activity_name})<br>`;
          }

          tooltipContent += `<br>
          Start: ${Highcharts.dateFormat(
            '%A, %b %e, %Y',
            (this.point as any).start
          )}<br>
                  End: ${Highcharts.dateFormat(
                    '%A, %b %e, %Y',
                    (this.point as any).end
                  )}`;
          return tooltipContent;
        },
      },
      xAxis: [
        {
          tickInterval: 1000 * 60 * 60 * 24 * 30,
          labels: {
            format: '{value:%b}',
            style: {
              fontSize: '10px',
            },
          },
        },
        {
          tickInterval: 1000 * 60 * 60 * 24 * 365,
          labels: {
            format: '{value:%Y}',
            style: {
              fontSize: '12px',
            },
          },
        },
      ],
      plotOptions: {
        gantt: {
          pointPadding: 0.3,
          groupPadding: 0.1,
        },
        series: {
          cursor: this.disableDrillDown ? 'default' : 'pointer',
          point: {
            events: {
              click: (event) => {
                if (!this.disableDrillDown) {
                  const barName = event.point.name;
                  component.onBarClick.emit({
                    value: barName,
                    level: this.currentLevel,
                  });
                }
              },
            },
          },
          dataLabels: {
            enabled: !this.dataLabelsDisabled,
          },
        },
      },
      yAxis: {
        labels: {
          style: {
            textOverflow: 'wrap',
            overflow: 'hidden',
            width: 150,
            fontSize: '10px',
          },
        },
      },
      series: this.seriesData.map((el) => ({
        ...el,
        type: 'gantt',
      })) as unknown as Highcharts.SeriesXrangeOptions[],
      exporting: {
        enabled: false,
      },
    };
  }

  // Track changes for chart options dependencies
  ngOnChanges(changes: SimpleChanges) {
    this.initializeChart('ngOnChanges');
  }

  expandChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    document.body.style.overflow = 'hidden';
    setTimeout(() => {
      this.initializeChart('expandChart');
    });
  }


  private initializeChart(from: string) {
    console.log('from', from)
    const container = document.getElementById('chart-container');
    if (container) {
      const chartOptions = this.setupChartOptions();
      Highcharts.ganttChart(container.id, chartOptions);
    }
  }

}