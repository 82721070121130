<div class="container-fluid h-100">
  @if (isLoading) {
  <app-loader [backdrop]="true"></app-loader>
  }
  <div
    class="subcontainer"
  >
    <app-header-text [label]="'Procurement Status'"></app-header-text>
    <div style="display: flex; justify-content: end; align-items: center">
      <div style="display: flex; gap: 1rem">
        <app-icon-button
          [label]="'Dashboard'"
          [icon]="'pi pi-clipboard'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          (click)="onLinksTextClick()"
        ></app-icon-button>
        <app-icon-button
          [label]="'Purchase Order Tracking'"
          [icon]="'pi pi-clipboard'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
          (click)="onLinkClick('/purchase-order-tracking')"
        ></app-icon-button>

        <app-icon-button
          [label]="'Procurement S Curve'"
          (click)="onLinkClick('/procurement-scurve')"
          [icon]="'pi pi-chart-line'"
          [iconColor]="'#6f6f6f'"
          [labelStyle]="{ color: '#29c8c1', 'text-decoration': 'underline' }"
        ></app-icon-button>

        <app-popover [isFilter]="isFilter" [label]="'Order Status Filter'">
          <div
            style="display: flex; flex-direction: column; padding: 1rem 2rem"
          >
            <div class="d-flex flex-column gap-3">
              <app-multiselect-dropdown
                [label]="'Discipline'"
                [options]="disciplines"
                [placeholder]="'-- Select Discipline --'"
                (onChange)="onChangeDiscipline($event)"
                [value]="selectedDiscipline"
              >
              </app-multiselect-dropdown>
              <app-single-select
                [label]="'Area'"
                [options]="areaDropdown"
                [placeholder]="'-- Select Area --'"
                (onChange)="orderStatusAreaSelect($event)"
                [value]="selectedOrderArea"
              ></app-single-select>
            </div>
            <div style="margin-top: 2rem">
              <span
                class="link underline"
                style="font-size: medium"
                [ngStyle]="{
                  color: this.isFilter ? '' : 'grey',
                  cursor: this.isFilter ? 'pointer' : 'default'
                }"
                (click)="onFilterReset()"
                >Reset</span
              >
            </div>
          </div>
        </app-popover>
      </div>
    </div>
  </div>
  <div class="row gap-2 gap-lg-0" style="height: 90%">
    <!-- Over All status -->
    <div class="col-12 col-md-12 col-lg-6 mb-4 mt-1 h-100">
      <donut-chart
        [id]="'overAllStatus'"
        [endElement]="overAllStatusEndEle"
        [cardTitle]="'Overall Status'"
        [chartData]="overAllStatusData"
        [loading]="isDonutChartLoading"
      ></donut-chart>
    </div>

    <!-- Order Status Card -->
    <div class="col-12 col-md-12 col-lg-6 mb-4 mt-1 h-100" #chartRef>
      <single-column
        [id]="'orderStatus'"
        [cardTitle]="'Order Status'"
        [data]="orderStatusData"
        [endElement]="orderStatusEndEle"
        [loading]="isBarChartLoading"
      ></single-column>
    </div>
  </div>
</div>

<ng-template #overAllStatusEndEle>
  @if(selectedOverallArea){
  <div (click)="onOverAllAreaSelectReset()">
    <app-icon [tooltip]="'Reset'" [icon]="'pi pi-sync'" color="#29c8c1"></app-icon>
  </div>
  }
  <app-single-select
    [options]="areaDropdown"
    [placeholder]="'-- Select Area --'"
    (onChange)="overAllAreaSelect($event)"
    [value]="selectedOverallArea"
    [style]="'width: 12rem'"
  ></app-single-select>
</ng-template>

<ng-template #orderStatusEndEle>
  <app-radio
    [style]="'display: flex'"
    [values]="orderStatusRadioBtn"
    [value]="selectedRadio"
    (onChange)="orderStatusRadioSelect($event)"
  ></app-radio>
</ng-template>

<ng-template #openLinkTemplate let-rowData>
  <div>
  <app-icon
    color="#29c8c1"
    (click)="onOpenFormLinkClick(rowData)"
    [icon]="'pi pi-external-link'"
  ></app-icon>
</div>
</ng-template>

@if(isLinksTableVisible){
<app-table
  [title]="'Dashboard'"
  [inTableDialog]="true"
  [isTableDialogVisible]="isLinksTableVisible"
  (onClosePopup)="onClosePopup()"
  [cols]="linksData.columns"
  [defaultColumns]="linksData.columns"
  [data]="linksData.data"
  [hideDownloadIcon]="true"
  [hideCustomizeColumnIcon]="true"
></app-table>
}