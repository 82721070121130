<ng-template #downloadActionTemplate let-rowData>
  <app-icon color="#29c8c1" (click)="onClickDownload(rowData)" icon="pi pi-download"></app-icon>
</ng-template>
<div class="container-fluid home-page-wrapper">
  <!-- Upper Section with full-height alignment -->
  @if(loading) {
  <app-loader [backdrop]="true" />
  }
  <div class="row upper-section col-12">
    <div class="small-show" style="color: #29c8c1; font-weight: bold">
      <div class="ml-auto">
        Last Refresh Date<br />
        <span class="d-flex" style="cursor: pointer">({{ lastRefreshedDate }})
          <app-icon [icon]="'pi pi-info-circle'"></app-icon>
        </span>
      </div>
    </div>
    <div class="col-lg-3 col-12" style="padding-right: 0.3rem; padding-top: 0.5rem">
      <app-area-image [selectedArea]="selectedArea" [isImageDisabled]="isImageDisabled" [imageClass]="imageClass"
        [actionButtonsRequired]="true" (areaClick)="handleAreaClick($event)" (on3dClick)="handle3DClick()"
        (on4dClick)="handle4DClick()"></app-area-image>
    </div>

    <div class="right-section col-lg-9 col-12 col-sm-12 d-flex flex-column h-100">
      <div class="menu-container col-12">
        <div class="d-flex row w-100 justify-content-around">
          <div class="menu-button d-flex justify-content-between" *ngFor="let item of menuItems"
            (click)="item.command()">
            <div class="d-flex align-items-center">
              <app-icon [icon]="item.icon"></app-icon>
              <div>{{ item.label }}</div>
            </div>
          </div>
        </div>
        <div class="refresh-date small-hidden w-25" (click)="onClickTabMenu('lastRefreshedDate')">
          <div class="flex-column align-items-center text-center" style="cursor: pointer">
            <div>Last Refresh Date</div>
            <div class="d-flex">({{ lastRefreshedDate }})</div>
          </div>
          <div style="vertical-align: super; font-size: smaller">
            <app-icon [icon]="'pi pi-info-circle'"></app-icon>
          </div>
        </div>
      </div>
      <app-table [title]="getPopupTitle().title" [loading]="dataQualityTableLoading" [avoidCard]="true"
        [inTableDialog]="true" [isTableDialogVisible]="
          isDialogVisible && selectedTabMenu === 'dataQuality'
        " (onClosePopup)="onClosePopup()" [cols]="dataQuality.columns" [defaultColumns]="defaultColsDataQuality"
        [data]="dataQuality.data" [paginationOptions]="{
          onChangePage: onChangeDataQualityPage,
          rows: dataQualityRowsPerPage,
          totalRecords: dataQuality.count,
          paginationRequired: true,
          first: dataQualityFirst
        }" [onDownloadClick]="getDataQualityTableDataForDownload"></app-table>
      <app-table [title]="getPopupTitle().title" [inTableDialog]="true" [isTableDialogVisible]="
          isDialogVisible && selectedTabMenu === 'lastRefreshedDate'
        " (onClosePopup)="onClosePopup()" [cols]="lastRefreshedDatasets.columns"
        [defaultColumns]="lastRefreshedDatasets.columns" [data]="lastRefreshedDatasets.data"></app-table>
      <app-table [title]="getPopupTitle().title" [inTableDialog]="true" [isTableDialogVisible]="
          isDialogVisible && selectedTabMenu === 'reports'
        " (onClosePopup)="onClosePopup()" [cols]="reportsColumns" [defaultColumns]="reportsColumns"
        [data]="reportsData" [onDownloadClick]="downloadReports"></app-table>

      <app-custom-dialog [visible]="showImage && !!selectedRow" (closeDialogClick)="closeImageClick()"
        [alreadyMaximized]="true" [maximizable]="true">
        <div className="h-full">
          @if(!selectedRow?.imageUrlKey?.length) {
          <div style="display: flex; justify-content: center">
            No Image Found
          </div>
          } @else if (imageLoading) {
          <app-loader [size]="10" />
          } @else {
          <img [alt]="
              selectedRow?.imageUrlKey
                ? getFileNameFromS3Url(imageUrl)
                : 'No image'
            " style="height: 100%; width: 100%" [src]="selectedRow?.imageUrlKey ? imageUrl : ''" />
          }
        </div>
      </app-custom-dialog>

      <!-- Status Indicator Section -->
      <div class="row status-indicator-wrapper mb-3">
        <div class="status-block col-lg-4 col-md-12 col-12">
          <app-status-indicator [title]="this.selectedArea || 'Overall'" status="risk" IconType="pi pi-info-circle"
            style="width: fit-content !important" [endElement]="endElement">
            <ng-template #endElement>
              <app-icon (click)="redirectToSchedule()" color="#29c8c1" [icon]="'pi pi-external-link'"
                [tooltip]="'More Details'"></app-icon>
            </ng-template>
          </app-status-indicator>
        </div>

        <div style="padding-left: 0.25rem !important" class="status-block col-lg-4 col-md-12 col-12">
          <app-status-indicator title="Schedule" status="ontime" IconType="pi pi-calendar">
          </app-status-indicator>
        </div>

        <div style="padding-left: 0.2rem !important" class="status-block col-lg-4 col-md-12 col-12">
          <app-status-indicator title="HSE" status="ontime" IconType="pi pi-shield">
          </app-status-indicator>
        </div>
      </div>

      <!-- Information Cards Section -->
      <div class="row information-container">
        <div class="card-content col-lg-6 col-md-12 col-12 d-flex" *ngFor="let card of cardData; let ind = index">
          <app-card [expandable]="false" [titleTemplate]="titleIcon" [endElement]="endElement" class="w-100 h-100"
            [loading]="cardLoading" [headerStyle]="{ marginBottom: '-0.5rem' }">
            <ng-template #endElement>
              <app-icon routerLink="/{{ card.redirectUrl }}" [state]="{ area: selectedArea }" color="#29c8c1"
                [icon]="'pi pi-external-link'" [tooltip]="'More Details'"></app-icon>
            </ng-template>
            <ng-template #titleIcon>
              <div class="d-flex align-items-center gap-1">
                <div class="card-header-text">{{ card.title }}</div>
                <div>
                  <app-icon [tooltip]="
                      'Area mapping missing for some lines,refer to Data Quality Tab'
                    " [color]="'#29c8c1'" [icon]="'pi pi-info-circle'"></app-icon>
                </div>
              </div>
            </ng-template>
            <div class="d-flex justify-content-evenly align-items-center h-100" style="padding: 0 0.5rem 0.5rem 0.5rem">
              <ng-container *ngFor="let item of card.data; let ind = index" class="text-center">
                <div class="d-flex flex-column text-center">
                  <div class="d-flex justify-content-center align-items-center" [ngbTooltip]="item.hoverMessage">
                    {{ item.label || "--" }}
                  </div>
                  <div [ngbTooltip]="item.actualValue" [ngStyle]="{ color: item.color }" class="primary-text fw-bold">
                    {{ item.value || "--" }}
                  </div>
                </div>
                <div class="vertical-divider" *ngIf="ind < card.data.length - 1"></div>
              </ng-container>
            </div>
          </app-card>
        </div>
      </div>
    </div>
  </div>
  <!-- End of Upper Section -->

  <!-- Lower Section (Project Highlight Table) -->
  <div class="row lower-section col-12">
    <ng-template #actionTemplateProjectHighLight let-rowData>
      <div title="Image" (click)="onClickImage(rowData)">
        @if (rowData?.imageUrlKey) {
        <app-icon class="justify-self-center" color="gray" icon="pi pi-image"></app-icon>
        } @else {
        <span>--</span>
        }
      </div>
    </ng-template>
    <div style="height: 30rem; font-weight: bold; margin-bottom: 1rem">
      <app-table [loading]="isConcernTableLoading" [conditionalRowClass]="getRowClass"
        [title]="'Project Highlights/Concerns'" [data]="projectHighlights.data" [cols]="projectHighlights.columns"
        [defaultColumns]="defaultColumns" [onClickImage]="onClickImage" [moreEndElement]="enddElement"
        [paginationOptions]="{
          paginationRequired: true,
          onChangePage: pageChange,
          rows: rowsPerPage,
          first: first,
          totalRecords: projectHighlights.count
        }" [onDownloadClick]="getProjectHighlightConcernDataForDownload"></app-table>
    </div>

    @if(isProjectConcernFormOpen) {

    <app-project-highlight-concerns-form [isFormOpen]="isProjectConcernFormOpen" [onCloseForm]="onCloseProjectForm">
    </app-project-highlight-concerns-form>
    }

    <ng-template #enddElement>
      <div class="underline link small-hidden secondary-text font-bold me-3 d-flex align-items-center"
        (click)="openFormClick()">
        Project Highlights/Concerns Form
      </div>
      <app-switch class="d-flex secondary-text me-3 link" [name]="'isConcern'" [disabled]="false" [checked]="isConcern"
        [checkedLabel]="'Concerns Only'" (onToggleSwitch)="onToggleSwitch($event)">
      </app-switch>

      <app-popover height="20rem" [isFilter]="true" [label]="'Filter'">
        <div
          class="h-100"
          style="display: flex; flex-direction: column; padding: 1rem 2rem"
        >
          <div class="d-flex flex-column gap-3 h-75">
            <div>
            <label
             class="custom-label"
              >From
            </label>
            <input
              ngbDatepicker
              [placeholder]="'yyyy-mm-dd'"
              [(ngModel)]="monthFrom"
              (change)="fromDateChange($event)"
              [value]="monthFrom"
              [max]="today"
              class="form-control custom-input"
              type="date"
            />
          </div>
          <div>
            <label
             class="custom-label"
              >To
            </label>
            <input
              ngbDatepicker
              [placeholder]="'yyyy-mm-dd'"
              [(ngModel)]="monthTo"
              [value] = "monthTo"
              [min]="monthFrom"
              [max]="today"
              class="form-control custom-input"
              type="date"
            />
          </div>
          </div>
          <div class="d-flex align-self-end h-25 w-100">
            <div
              (click)="onResetConcernFilter()"
              class="link underline"
              style="font-size: medium"
            >
              Reset
            </div>
            <div class="ms-auto">
              <app-submit-button
                (onClick)="onApplyFilter($event)"
                label="Apply"
                [disabled]="(isEmpty(monthTo!) || isEmpty(monthFrom!))? true : false"
              ></app-submit-button>
            </div>
          </div>
        </div>
      </app-popover>
    </ng-template>
  </div>
  <!-- End of Lower Section -->
</div>