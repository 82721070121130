<ng-template #submitTitle></ng-template>

<div class="delay-form-page-conatiner">
  @if (loading) {
  <app-loader [backdrop]="true"></app-loader>
  }@else {
  <div style="display: flex; align-items: center; margin-bottom: 1rem">
    <div>
      <app-header-text
        class="form-title"
        [label]="'Delay Form'"
        style="color: #000000"
      />
      <p
        style="
          font-style: italic;
          margin: 0;
          font-size: 0.85rem;
          color: #363535;
        "
      >
        Are you facing a delay due to external factors in completing your
        activity on time? Please record your delay reason and details in the
        form below.
      </p>
    </div>
    @if(metaData.overallStatus) {
    <div
      class="ml-auto cursor-pointer"
      (click)="onShowStepperClick()"
      [ngStyle]="{
        '--text-color': metaData.overallStatus.textColor,
        '--bg-color': metaData.overallStatus.color
      }"
    >
      <p-chip
        styleClass="custom-chip"
        [label]="metaData.overallStatus.status"
      ></p-chip>
    </div>
    }
  </div>

  <form [formGroup]="form" (ngSubmit)="handleFormSubmit($event)">
    @if(visibleSections.includes('reporter')) {
    <div class="form-container">
      <label
        class="form-titile"
        for="reporter"
        style="
          font-size: 1.1rem;
          font-weight: 600;
          color: #29c8c1;
          padding: 1rem;
        "
      >
        Reporter
      </label>
      <br />
      <div class="hz-line"></div>

      <div class="container-fluid row-container">
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="area" style="font-weight: 600"
              >Area<span style="color: red">*</span>
            </label>
            <p-dropdown
              id="area"
              class="custom-dropdown"
              [options]="areaData()"
              optionLabel="value"
              optionValue="value"
              formControlName="area"
              placeholder="Select Area"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(area?.invalid && area?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="location" style="font-weight: 600"
              >Location<span style="color: red">*</span></label
            >
            <p-dropdown
              id="location"
              class="custom-dropdown"
              [options]="locationData()"
              optionLabel="value"
              optionValue="value"
              formControlName="location"
              placeholder="Select Location"
              [disabled]="!form.get('areaField')"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(location?.invalid && location?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="cwp" style="font-weight: 600"
              >CWP<span style="color: red">*</span></label
            >
            <p-dropdown
              id="cwp"
              class="custom-dropdown"
              [options]="cwpData()"
              optionLabel="value"
              optionValue="value"
              formControlName="cwp"
              placeholder="Select CWP"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(cwp?.invalid && cwp?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="activityName"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Activity Name<span style="color: red">*</span></label
            >
            <input
              id="activityName"
              class="custom-input"
              optionLabel="value"
              optionValue="value"
              formControlName="activityName"
              placeholder="Enter Activity Name"
              
              pInputText
            />
            <div style="height: 1rem">
              @if(activityName?.invalid && activityName?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="supervisor" style="font-weight: 600"
              >Supervisor<span style="color: red">*</span></label
            >
            <p-dropdown
              id="supervisor"
              class="custom-dropdown"
              [options]="userData()"
              optionLabel="value"
              optionValue="value"
              formControlName="supervisor"
              placeholder="Select Supervisor"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(supervisor?.invalid && supervisor?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="contractor" style="font-weight: 600"
              >Contractor<span style="color: red">*</span></label
            >
            <p-dropdown
              id="contractor"
              class="custom-dropdown"
              [options]="contractorData()"
              optionLabel="value"
              optionValue="value"
              formControlName="contractor"
              placeholder="Select Contractor"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(contractor?.invalid && contractor?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="delay reason"
              style="font-weight: 600"
              >Delay Reason<span style="color: red">*</span></label
            >
            <p-dropdown
              id="delayReason"
              class="custom-dropdown"
              [options]="DelayReasonData()"
              optionLabel="value"
              optionValue="value"
              formControlName="delayReason"
              placeholder="Select Reason"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(delayReason?.invalid && delayReason?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="DelayDateTime"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Delay Start Date/Time<span style="color: red">*</span>
            </label>
            <input
              id="DelayDateTime"
              class="custom-input"
              formControlName="delayStartDateTime"
              placeholder="Select start Date"
              
              pInputText
              type="datetime-local"
            />
            <div style="height: 1rem">
              @if(delayStartDateTime?.invalid && delayStartDateTime?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="actionOwner" style="font-weight: 600"
              >Action Owner<span style="color: red">*</span></label
            >
            <p-dropdown
              id="actionOwner"
              class="custom-dropdown"
              [options]="userData()"
              optionLabel="value"
              optionValue="value"
              formControlName="actionOwner"
              placeholder="Select Action Owner"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(actionOwner?.invalid && actionOwner?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="approver" style="font-weight: 600"
              >Approver<span style="color: red">*</span></label
            >
            <p-dropdown
              id="approver"
              class="custom-dropdown"
              [options]="userData()"
              optionLabel="value"
              optionValue="value"
              formControlName="approver"
              [disabled]="reporterDisabled()"
              (onChange)="onChangeDropdown($event)"
              placeholder="Select Approver"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(approver?.invalid && approver?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="priority" style="font-weight: 600"
              >Priority<span style="color: red">*</span></label
            >
            <p-dropdown
              id="priority"
              class="custom-dropdown"
              [options]="priorityData()"
              optionLabel="value"
              optionValue="value"
              formControlName="priority"
              placeholder="Select Priority"
              [disabled]="reporterDisabled()"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(priority?.invalid && priority?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div
            class="col-12 col-md-6"
            style="
              display: grid;
              margin-top: 10px;
              height: fit-content;
              width: fit-content;
            "
          >
            @if(imageUrlKey) {
            <div
              [ngStyle]="{
                marginTop: '8px'
              }"
            >
              <button
                class="custom-button"
                type="button"
                (click)="onClickViewImageBtn()"
                size="large"
              >
                <div class="button-content">
                  <app-icon [color]="'white'" [icon]="'pi pi-eye'" /> View Image
                </div>
              </button>
            </div>
            } @if(isEmptyMetaData()){
            <label
              class="label-text"
              for="upload"
              style="font-weight: 600; height: fit-content"
              >Upload Picture<span style="color: red">*</span></label
            >
            <app-upload-button
              [id]="'upload'"
              [disabled]="reporterDisabled()"
              [disableRemove]="reporterDisabled()"
              (onUpload)="handleFileUpload($event)"
              (onRemoveClick)="handleFileRemove()"
            />
            <div style="height: 1rem"></div>
            }
          </div>
        </div>
      </div>
    </div>
    } @if(visibleSections.includes('approver')) {
    <div class="form-container mt-3">
      <label
        class="form-titile"
        for="reporter"
        style="
          font-size: 1.1rem;
          font-weight: 600;
          color: #29c8c1;
          padding: 1rem;
        "
      >
        Approver
      </label>
      <br />
      <div class="hz-line"></div>

      <div class="container-fluid row-container">
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="impactAssessment"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Impact Assessment<span style="color: red">*</span></label
            >
            <input
              id="impactAssessment"
              class="custom-input"
              optionLabel="value"
              formControlName="impactAssessment"
              placeholder="Enter Impact Assessment"
              [readOnly]="!editableSections.includes('approver')"
              [disabled]="!editableSections.includes('approver')"
              
              pInputText
            />
            <div style="height: 1rem">
              @if(impactAssessment?.invalid && impactAssessment?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label class="label-text" for="severity" style="font-weight: 600"
              >Severity<span style="color: red">*</span>
            </label>
            <p-dropdown
              id="severity"
              class="custom-dropdown"
              [options]="priorityData()"
              optionLabel="value"
              optionValue="value"
              formControlName="severity"
              placeholder="Select Severity"
              [disabled]="!editableSections.includes('approver')"
              [style]="{ height: '28px', fontSize: '12px', width: '100%' }"
            />
            <div style="height: 1rem">
              @if(severity?.invalid && severity?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    } @if(visibleSections.includes('actionOwner')) {
    <div class="form-container mt-3">
      <label
        class="form-titile"
        for="reporter"
        style="
          font-size: 1.1rem;
          font-weight: 600;
          color: #29c8c1;
          padding: 1rem;
        "
      >
        Action Owner
      </label>
      <br />
      <div class="hz-line"></div>

      <div class="container-fluid row-container">
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="causeRcaDelay"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >RCA Delay<span style="color: red">*</span></label
            >
            <input
              id="causeRcaDelay"
              class="custom-input"
              optionLabel="value"
              formControlName="causeRcaDelay"
              placeholder="Enter Cause"
              [readOnly]="!editableSections.includes('actionOwner')"
              [disabled]="!editableSections.includes('actionOwner')"
              
              pInputText
            />
            <div style="height: 1rem">
              @if(causeRcaDelay?.invalid && causeRcaDelay?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="expectedResolutionDateTime"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Expected Resolution Date/Time<span style="color: red">*</span>
            </label>
            <input
              id="expectedResolutionDateTime"
              class="custom-input"
              formControlName="expectedResolutionDateTime"
              placeholder="Select Date/Time"
              [min]="isMinDate(form.getRawValue().delayStartDateTime)"
              [readOnly]="!editableSections.includes('actionOwner')"
              [disabled]="!editableSections.includes('actionOwner')"
              
              pInputText
              type="datetime-local"
            />
            <div style="height: 1rem">
              @if(expectedResolutionDateTime?.invalid &&
              expectedResolutionDateTime?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          @if(ifNotPendingAnalysis()) {
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="actionTaken"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Action Taken<span style="color: red">*</span></label
            >
            <input
              id="actionTaken"
              class="custom-input"
              optionLabel="value"
              formControlName="actionTaken"
              placeholder="Enter Activity Name"
              [readOnly]="!editableSections.includes('actionOwner')"
              [disabled]="!editableSections.includes('actionOwner')"
              
              pInputText
            />
            <div style="height: 1rem">
              @if(actionTaken?.invalid && actionTaken?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="actualResolutionDateTime"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Actual Resolution Date/Time<span style="color: red">*</span>
            </label>
            <input
              id="actualResolutionDateTime"
              class="custom-input"
              formControlName="actualResolutionDateTime"
              placeholder="Select Date/Time"
              [readOnly]="!editableSections.includes('actionOwner')"
              [disabled]="!editableSections.includes('actionOwner')"
              
              pInputText
              type="datetime-local"
              [min]="isMinDate(form.getRawValue().delayStartDateTime)"
              [max]="isMaxDate()"
            />
            <div style="height: 1rem">
              @if(actualResolutionDateTime?.invalid &&
              actualResolutionDateTime?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
          }
        </div>
      </div>
    </div>
    } @if(visibleSections.includes('closure')) {
    <div class="form-container mt-3">
      <label
        class="form-titile"
        for="reporter"
        style="
          font-size: 1.1rem;
          font-weight: 600;
          color: #29c8c1;
          padding: 1rem;
        "
      >
        Closure
      </label>
      <br />
      <div class="hz-line"></div>

      <div class="container-fluid row-container">
        <div class="row">
          <div class="col-12 col-md-3" style="display: grid; margin-top: 10px">
            <label
              class="label-text"
              for="delayStopDateTime"
              style="font-weight: bold; padding-bottom: 0.3rem"
              >Delay Stop Date/Time<span style="color: red">*</span>
            </label>
            <input
              id="delayStopDateTime"
              class="custom-input"
              formControlName="delayStopDateTime"
              placeholder="Select Stop Date"
              [readOnly]="!editableSections.includes('closure')"
              [disabled]="!editableSections.includes('closure')"
              
              pInputText
              type="datetime-local"
              [min]="isMinDate(form.getRawValue().actualResolutionDateTime)"
              [max]="isMaxDate()"
            />
            <div style="height: 1rem">
              @if(delayStopDateTime?.invalid && delayStopDateTime?.touched) {
              <small class="p-error">This field is required.</small>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    } @if(editableSectionsCheck) {
    <div class="end-buttons">
      <div>
        <app-cancel-button
          [label]="'Cancel'"
          [buttonStyles]="{}"
          (onClick)="onCancel()"
        />
      </div>
      <div>
        <app-submit-button
          [label]="'Submit'"
          [disabled]="form.invalid"
          [type]="'submit'"
          [buttonStyles]="{}"
        />
      </div>
    </div>
    }
    <app-custom-dialog
      [maximizable]="false"
      [visible]="submitDialog"
      [title]="submitTitle"
      (visibleChange)="submitDialog = $event"
      (closeDialogClick)="onCancelConfirmSubmitPopup()"
      [style]="{ width: '40vw', height: '20vh', top: '0', left: '0' }"
    >
      <div style="display: block; height: 100%">
        <div style="display: block; justify-content: start">
          <div>
            <app-header-text
              [label]="'Are you sure you want to submit the form?'"
            />
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: end;
            align-content: end;
            gap: 0.7rem;
            margin-top: 2rem;
          "
        >
          <app-cancel-button
            label="No"
            (onClick)="onCancelConfirmSubmitPopup()"
          />
          <app-submit-button
            type="button"
            label="Yes"
            (onClick)="onSubmitForm()"
          />
        </div>
      </div>
    </app-custom-dialog>
  </form>
  @if(!isEmptyMetaDataApprovalLogs()) {
  <div>
    <app-custom-dialog
      [maximizable]="false"
      [visible]="showStepper"
      [title]="steppertitle"
      (visibleChange)="showStepper = $event"
      (closeDialogClick)="onCloseStepper()"
      [style]="{ width: '40vw', height: '80vh', top: '0', left: '0' }"
    >
      <app-stepper [data]="metaData.approvalLogs" />
    </app-custom-dialog>
  </div>
  }
  <div>
    <app-custom-dialog
      [maximizable]="false"
      [visible]="uploadedFileDialog"
      [title]="uploadedTitle"
      (visibleChange)="uploadedFileDialog = $event"
      (closeDialogClick)="onCloseUploadedFilePopup()"
    >
      <img style="height: 100%; width: 100%" [src]="imageUrlKey" alt="loading" />
    </app-custom-dialog>
  </div>
  }
</div>
<ng-template #steppertitle>
  <div style="color: #29c8c1; font-weight: 700; font-size: 1rem">
    Delay Form Status
  </div>
</ng-template>
<ng-template #uploadedTitle>
  <div style="color: #29c8c1; font-weight: 700; font-size: 1rem">
    Uploaded File
  </div>
</ng-template>
