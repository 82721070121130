import { Component } from '@angular/core';
import { ChartDataType } from '../../shared/interface/ChartTypes';
import { HseService } from '../../shared/services/hse/hse.service';
import { response } from 'express';
import { SingleColumnComponent } from '../../shared/components/single-column/single-column.component';
import { CardComponent } from '../../shared/components/card/card.component';
import { CommonModule } from '@angular/common';
import {
  HseFilter,
  IHealthSafetyFilter,
  RadioBtn,
} from '../../shared/interface/Filters';
import { DonutChartComponent } from '../../shared/components/charts/donut-chart/donut-chart.component';
import { HeaderTextComponent } from '../../shared/components/header-text/header-text.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { RadioComponent } from '../../shared/components/buttons/radio/radio.component';
import { ChangeDetectorRef } from '@angular/core';
import { SingleSelectComponent } from "../../shared/components/single-select/single-select.component";
import { IndicatorDataType } from '../../shared/types/Hse';


@Component({
  selector: 'app-hse',
  standalone: true,
  imports: [
    SingleColumnComponent,
    CommonModule,
    HeaderTextComponent,
    NgbTooltip,
    LoaderComponent,
    RadioComponent,
    SingleSelectComponent
],
  templateUrl: './hse.component.html',
  styleUrl: './hse.component.css',
})
export class HseComponent {
  button: any;
  constructor(private hseService: HseService, private cdr: ChangeDetectorRef) {}
  isLoading = false;
  isLoadingForLagging = false;
  isLoadingForLeading = false;
  laggingIndicatorData: IndicatorDataType = {
    data: {
      chartData: {
        categories: [],
        series: []
      },
      endDates: [],
      topRibbonData: []
    }
  };
  leadingIndicatorData: IndicatorDataType = {
    data: {
      chartData: {
        categories: [],
        series: []
      },
      endDates: [],
      topRibbonData: []
    }
  };

  laggingFilters: IHealthSafetyFilter = {
    metric: 'Lagging Indicators',
    filterColumn: 'ptd',
    endDate: ''
  };
  leadingFilters: IHealthSafetyFilter = {
    metric: 'Leading Indicators',
    filterColumn: 'ptd',
    endDate: ''

  };
  hseTopRibbonData: {
    metric: string;
    value: number;
    actualValue: number;
    title: string;
  }[] = [];
  commonFilters: HseFilter = {
    yearData: [null, null],
  };

  laggingRadioButtons: RadioBtn[] = [
    { key: 'wtd', name: 'WTD', disabled: false },
    { key: 'ptd', name: 'PTD', disabled: false },
    { key: 'ytd', name: 'YTD', disabled: false },
  ];

  leadingRadioButtons: RadioBtn[] = [
    { key: 'wtd', name: 'WTD', disabled: false },
    { key: 'ptd', name: 'PTD', disabled: false }
  ];

  selectedLagging: RadioBtn = { key: 'ptd', name: 'PTD', disabled: false };
  selectedLeading: RadioBtn = { key: 'ptd', name: 'PTD', disabled: false };

  fetchLaggingIndicatorsData(): void {
    this.isLoadingForLagging = true;
    this.hseService.getIndicatorsData(this.laggingFilters).subscribe({
      next: (response) => {
        this.laggingIndicatorData = response,
        this.isLoadingForLagging = false;
        this.isLoading = false;
      },
      error: () => {
        this.isLoadingForLagging = false;
        console.error('Failed to fetch lagging indicators data');
      },
    });
  }

  getEndDates = (datesInStringArr: string[]) => {
    return datesInStringArr.map(el => {
      return {
        value: el
      }
    })
  }
  fetchLeadingIndicatorsData(): void {
    this.isLoadingForLeading = true;
    this.hseService.getIndicatorsData(this.leadingFilters).subscribe({
      next: (response) => {
        this.leadingIndicatorData = response,
        this.isLoadingForLeading = false;
        this.isLoading = false;
      },
      error: () => {
        this.isLoadingForLeading = false;
        console.error('Failed to fetch leading indicators data');
      },
    });
  }


  laggingRadioValue() {
    return this.laggingRadioButtons.find(
      (el) => el.key === this.laggingFilters.filterColumn
    );
  }
  onLaggingRadioChange(e: any): void {
    if(e.key !== this.laggingFilters.filterColumn) {
      this.fetchLaggingIndicatorsData();
    }
    this.laggingFilters.filterColumn = e.key;
    this.selectedLagging = e
    
  }

  onLeadingRadioChange(e: any): void {
    if(e.key !== this.leadingFilters.filterColumn) {
      this.fetchLeadingIndicatorsData();
    }
    this.leadingFilters.filterColumn = e.key;
    this.selectedLeading = e;
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.fetchLaggingIndicatorsData();
    this.fetchLeadingIndicatorsData();
  }

  onChangeDate = (type: 'leading' | 'lagging',event: any) => {
    if(type === 'lagging') {
      this.fetchLaggingIndicatorsData();
          this.laggingFilters.endDate = event.target.value;

    } else if (type === 'leading') {
      this.fetchLeadingIndicatorsData();
                this.leadingFilters.endDate = event.target.value;


    }
  }
}
