import { Component, Input,TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-header-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './header-text.component.html',
  styleUrl: './header-text.component.css'
})
export class HeaderTextComponent {
  @Input() label!: string;
  @Input() endElement?: any;
}
