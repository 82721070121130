import dayjs from "dayjs"
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);
const tzMapper: Record<string, string> = {
  'Asia/Calcutta': 'IST'
}
export const formatThousandNumberToK = (num?: number | null) => {
  if (num === undefined || num === null) {
    return num;
  }
  
  // Handle millions
  if (num >= 1000000) {
    return `${Number((num / 1000000).toPrecision(4))}M`; // Converts number to millions (e.g., 3,336,454 becomes "3.336M")
  }
  
  // Handle thousands
  if (num >= 1000) {
    return `${Number((num / 1000).toPrecision(2))}k`; // Converts number to thousands (e.g., 1500 becomes "1.5k")
  }

  // Return number as-is if less than 1000
  return num;
};

export const convertDateToCommonFormat = (date?: string) => {
  if (!date) {
      return ''
  }
  const localTz = dayjs.tz.guess();    
  const formattedDate = dayjs(date).tz(localTz).format(`MMM DD, YYYY hh:mm A ${tzMapper[localTz] || 'z'}`);
  return formattedDate;
}