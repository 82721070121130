import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConstructionManagmentFilter } from '../../interface/Filters';
import { ConstructionManagmentChart } from '../../interface/ConstructionManagement';
import API_END_POINTS from '../../../core/constants/apiEndPoints';

const {
  CONSTRUCTION_MANAGEMENT,
  CONSTRUCTION_MANAGEMENT_CRAFTS
} = API_END_POINTS

@Injectable({
  providedIn: 'root'
})
export class ConstructionManagementService {
  
  private http = inject(HttpClient);
  constructor() { }

  getConstructionManagementData(filters: ConstructionManagmentFilter): Observable<{ data: ConstructionManagmentChart[] }> {
    return this.http.post<{ data: ConstructionManagmentChart[] }>(CONSTRUCTION_MANAGEMENT, { filters });
  }

  getCraftData(): Observable<{ data: { value: string, id: string | number }[] }> {
    return this.http.get<{ data: { value: string, id: string | number }[] }>(CONSTRUCTION_MANAGEMENT_CRAFTS);
  }
}
