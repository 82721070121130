import {
  CdkDragDrop,
  DragDropModule,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { isNull, isUndefined } from 'lodash-es';
import { TableModule, TablePageEvent } from 'primeng/table';
import { ColumnProps } from '../../interfaces/table-interface';
import { saveAsExcelFile } from '../../utils/global-modules';
import { CardComponent } from '../card/card.component';
import { CustomDialogComponent } from '../custom-dialog/custom-dialog.component';
import { IconButtonComponent } from '../icon-button/icon-button.component';
import { PaginatorModule } from 'primeng/paginator';
import {
  commonDateTimeFormat,
  convertDateToYYMMDD,
} from '../../utils/utility-functions';

@Component({
  selector: 'app-table',
  standalone: true,
  imports: [
    TableModule,
    CommonModule,
    DragDropModule,
    CardComponent,
    CustomDialogComponent,
    IconButtonComponent,
    PaginatorModule,
  ],
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
})
export class TableComponent<T = any> implements OnInit, OnChanges {
  @Input() data!: T[];
  @Input() loading: boolean = false;
  @Input() cols!: ColumnProps<T>[];
  @Input() cardTitle?: string;
  @Input() defaultColumns!: ColumnProps<T>[];
  @Input() inTableDialog: boolean = false;
  @Input() isTableDialogVisible: boolean = false;
  @Input() onClickImage?: (rowData: any) => void;
  @Input() moreEndElement?: TemplateRef<any>;
  @Input() paginationOptions?: {
    paginationRequired?: boolean;
    onChangePage?: (event: TablePageEvent) => void;
    onRowChange?: () => void;
    rows?: number;
    totalRecords?: number;
    first?: number;
  };
  @Input() title: string = '';
  @Input() titleTemplate?: TemplateRef<any>;
  @Input() expandable?: boolean = true;
  @Input() avoidExpand: boolean = false;
  @Input() scrollHeight?: string = '';
  @Input() hideCustomizeColumnIcon?: boolean = false;
  @Input() hideDownloadIcon?: boolean = false;
  @Input() isExpanded: boolean = false;
  @Input() helperText?: TemplateRef<any>;
  @Input() avoidCard?: boolean;
  @Input() onOpenFormLinkClick?: (rowData: any) => void;
  @Input() showGridLines?: boolean;
  @Input() textAlign?: string;
  @Input() conditionalRowClass?: (item: any) => { [key: string]: boolean };
  @Input() onDownloadClick?: () => Promise<Record<string, any>[]>;

  @Output() onClosePopup: EventEmitter<any> = new EventEmitter();

  defaultColsLocal: string[] = [];
  colsLocal: string[] = [];
  visible: boolean = false;
  filteredCols: ColumnProps<T>[] = [];

  unAppliedColumns: ColumnProps<T>[] = [];
  appliedColumns: ColumnProps<T>[] = [];

  lastAppliedCols: string[] = [];
  lastAppliedDefaultCols: string[] = [];

  filteredData!: T[];

  // pageChange(event: TablePageEvent) {
  //     this.first = event.first;
  //     this.rows = event.rows;
  // }

  @ViewChild(CardComponent, { static: true }) cardComponent!: CardComponent;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cols'] && this.cols) {
      this.filteredCols = this.cols.filter((el) => {
        return !this.defaultColumns.map((vl) => vl.header).includes(el.header);
      });
      this.colsLocal = this.filteredCols.map((el) => el.header) || [];
      this.defaultColsLocal = this.defaultColumns.map((el) => el.header) || [];

      this.lastAppliedCols = [...this.colsLocal];
      this.lastAppliedDefaultCols = [...this.defaultColsLocal];
    }
    this.appliedColumns = this.defaultColsLocal?.map((header) => {
      const defaultColumn = this.cols.find((col) => col.header === header);
      const defaultColumnField = this.cols.find(
        (col) => col.header === header
      )?.field;
      return defaultColumn
        ? { ...defaultColumn }
        : header === 'Highlights/Concerns'
        ? { header, field: 'highlights/Concerns' as keyof T }
        : {
            header,
            field: defaultColumnField ? defaultColumnField : ('' as keyof T),
          }; // Adjust as necessary
    });
    this.unAppliedColumns = this.colsLocal.map((header) => {
      const defaultColumn = this.cols.find((col) => col.header === header);
      const defaultColumnField = this.cols.find(
        (col) => col.header === header
      )?.field;
      return defaultColumn
        ? { ...defaultColumn }
        : header === 'Highlights/Concerns'
        ? { header, field: 'highlights/Concerns' as keyof T }
        : {
            header,
            field: defaultColumnField ? defaultColumnField : ('' as keyof T),
          }; // Adjust as necessary
    });
    this.updateFilteredData();
  }

  getRowData = (ind: number) => {
    return this.data[ind];
  };

  ngOnInit(): void {}

  private updateFilteredData() {
    this.filteredData = this.data.map((row) => {
      const filteredRow: Record<string, any> = {};
      this.appliedColumns.forEach((col) => {
        filteredRow[col.field as string] =
          row[col.field] !== undefined ? row[col.field] : null;
      });
      return filteredRow as T;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  applyClick = () => {
    this.unAppliedColumns = this.colsLocal.map((header) => {
      const defaultColumn = this.cols.find((col) => col.header === header);
      const defaultColumnField = this.cols.find(
        (col) => col.header === header
      )?.field;
      return defaultColumn
        ? { ...defaultColumn }
        : header === 'Highlights/Concerns'
        ? { header, field: 'highlights/Concerns' as keyof T }
        : {
            header,
            field: defaultColumnField ? defaultColumnField : ('' as keyof T),
          }; // Adjust as necessary
    });
    this.appliedColumns = this.defaultColsLocal.map((header) => {
      const defaultColumn = this.cols.find((col) => col.header === header);
      const defaultColumnField = this.cols.find(
        (col) => col.header === header
      )?.field;

      return defaultColumn
        ? { ...defaultColumn }
        : header === 'Highlights/Concerns'
        ? { header, field: 'highlights/Concerns' as keyof T }
        : {
            header,
            field: defaultColumnField ? defaultColumnField : ('' as keyof T),
          }; // Adjust as necessary
    });

    this.lastAppliedCols = [...this.colsLocal];
    this.lastAppliedDefaultCols = [...this.defaultColsLocal];
    this.updateFilteredData();
    this.visible = false;
  };

  resetClick = () => {
    this.colsLocal = this.cols
      .filter((el) => {
        return !this.defaultColumns.map((vl) => vl.header).includes(el.header);
      })
      .map((hedr) => hedr.header);

    this.defaultColsLocal = this.defaultColumns.map((el) => el.header);
  };

  cancelClick = () => {
    this.colsLocal = [...this.lastAppliedCols];
    this.defaultColsLocal = [...this.lastAppliedDefaultCols];

    this.visible = false;
  };

  showDialog = () => {
    this.visible = true;
  };

  exportExcel = (event: any) => {
    if (event) {
      import('xlsx').then((xlsx) => {
        if (this.onDownloadClick) {
          this.onDownloadClick().then((res) => {
            if (this.cols.some((el) => !!el.children?.length)) {
              // Handle grouped columns
              const parentHeaders: string[] = [];
              const subHeaders: string[] = [];
              const sheetData = [];

              // Traverse columns and create parent and subheaders
              this.cols.forEach((col) => {
                if (col.children && col.children.length) {
                  // Push the parent header with empty subheader placeholders
                  parentHeaders.push(col.header);
                  col.children.forEach((subCol) => {
                    parentHeaders.push(''); // Empty parent placeholder for each child
                    subHeaders.push(subCol.header);
                  });
                } else {
                  parentHeaders.push(col.header);
                  subHeaders.push(''); // No sub-header for single column
                }
              });

              // Add headers to sheetData
              sheetData.push(parentHeaders);
              sheetData.push(subHeaders);

              // Map data rows based on grouped columns
              res.forEach((row) => {
                const rowData: any[] = [];
                this.cols.forEach((col) => {
                  if (col.children && col.children.length) {
                    col.children.forEach((subCol) => {
                      rowData.push(row[subCol.field as any]);
                    });
                  } else {
                    rowData.push(row[col.field as any]);
                  }
                });
                sheetData.push(rowData);
              });

              // Convert to worksheet
              const worksheet = xlsx.utils.aoa_to_sheet(sheetData);
              const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
              };
              const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
              });

              // Save the file
              saveAsExcelFile(
                excelBuffer,
                typeof this.title === 'string' ? this.title : 'data'
              );
            } else {
              // Flat columns (original logic)
              const tableData = res.map((row) => {
                const obj: Record<string, any> = {};
                this.appliedColumns.forEach((col) => {
                  if (typeof col.field === 'string') {
                    if (col.field?.toLowerCase() !== 'action') {
                      obj[col.header] = row[col.field];
                    }
                  }
                });
                return obj;
              });
              const worksheet = xlsx.utils.json_to_sheet(tableData);
              const workbook = {
                Sheets: { data: worksheet },
                SheetNames: ['data'],
              };
              const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
              });

              saveAsExcelFile(
                excelBuffer,
                typeof this.title === 'string' ? this.title : 'data'
              );
            }
          });
        } else {
          if (this.cols.some((el) => !!el.children?.length)) {
            // Handle grouped columns
            const parentHeaders: string[] = [];
            const subHeaders: string[] = [];
            const sheetData = [];

            // Traverse columns and create parent and subheaders
            this.cols.forEach((col) => {
              if (col.children && col.children.length) {
                // Push the parent header with empty subheader placeholders
                parentHeaders.push(col.header);
                col.children.forEach((subCol) => {
                  parentHeaders.push(''); // Empty parent placeholder for each child
                  subHeaders.push(subCol.header);
                });
              } else {
                parentHeaders.push(col.header);
                subHeaders.push(''); // No sub-header for single column
              }
            });

            // Add headers to sheetData
            sheetData.push(parentHeaders);
            sheetData.push(subHeaders);

            // Map data rows based on grouped columns
            this.filteredData.forEach((row) => {
              const rowData: any[] = [];
              this.cols.forEach((col) => {
                if (col.children && col.children.length) {
                  col.children.forEach((subCol) => {
                    rowData.push(row[subCol.field]);
                  });
                } else {
                  rowData.push(row[col.field]);
                }
              });
              sheetData.push(rowData);
            });

            // Convert to worksheet
            const worksheet = xlsx.utils.aoa_to_sheet(sheetData);
            const workbook = {
              Sheets: { data: worksheet },
              SheetNames: ['data'],
            };
            const excelBuffer = xlsx.write(workbook, {
              bookType: 'xlsx',
              type: 'array',
            });

            // Save the file
            saveAsExcelFile(
              excelBuffer,
              typeof this.cardComponent.title === 'string'
                ? this.cardComponent.title
                : 'data'
            );
          } else {
            // Flat columns (original logic)
            const tableData = this.filteredData.map((row) => {
              const obj: Record<string, any> = {};
              this.appliedColumns.forEach((col) => {
                if (col.field !== 'action') {
                  obj[col.header] = row[col.field];
                }
              });
              return obj;
            });
            const worksheet = xlsx.utils.json_to_sheet(tableData);
            const workbook = {
              Sheets: { data: worksheet },
              SheetNames: ['data'],
            };
            const excelBuffer = xlsx.write(workbook, {
              bookType: 'xlsx',
              type: 'array',
            });

            saveAsExcelFile(
              excelBuffer,
              typeof this.cardComponent?.title === 'string'
                ? this.cardComponent.title
                : 'data'
            );
          }
        }
      });
    }
  };

  flattenColumns = (columns: ColumnProps[]) => {
    const result: ColumnProps[] = [];

    function recurse(items: ColumnProps[]) {
      items.forEach((item) => {
        if (item.children) {
          recurse(item.children);
        } else {
          result.push({
            field: item.field,
            header: item.header,
            frozen: item.frozen,
            bodyTemplate: item.bodyTemplate,
            style: item.style,
          });
        }
      });
    }

    recurse(columns);
    return result;
  };

  getMaxDepth = (columns: ColumnProps[]): number => {
    let maxDepth = 1;
    columns.forEach((col) => {
      if (col.children) {
        const depth = this.getMaxDepth(col.children) + 1;
        if (depth > maxDepth) maxDepth = depth;
      }
    });
    return maxDepth;
  };

  getColSpan = (column: ColumnProps): number => {
    if (!column.children) return 1;
    return column.children.reduce(
      (sum, child) => sum + this.getColSpan(child),
      0
    );
  };

  getMaxDepthArray = (columns: ColumnProps[]) => {
    const maxDepth = this.getMaxDepth(columns);
    const arr: number[] = [];
    for (let i = 0; i < maxDepth; i++) {
      arr.push(i);
    }
    return arr;
  };

  getColDataWithType = (d: any) => {
    return d as keyof T;
  };

  isNumberOrDate = (value: any, column: any) => {
    const field = (column.field as string)?.toLowerCase();
    const header = (column.header as string)?.toLowerCase();
    const comb = `${field} ${header}`;
    if (
      comb.includes('date') ||
      comb.includes('time') ||
      comb.includes('month') ||
      comb.includes('week') ||
      field === 'po'
    ) {
      return true;
    }
    if (typeof value === 'string') {
      return value.endsWith('%');
    }

    if (!isUndefined(value) && !isNull(value)) {
      if (typeof value === 'number') {
        return true;
      }
      if (!isNaN(Number(value))) {
        return true;
      }
    }
    return false;
  };

  getRowInd = (continuousRowIndex: number) => {
    if (this.paginationOptions?.paginationRequired) {
      return continuousRowIndex - Number(this.paginationOptions?.first);
    }
    return continuousRowIndex;
  };
  getHeaderStyle = (column: ColumnProps) => {
    const obj: any = {};

    if (column.children?.length) {
      obj.textAlign = 'center';
      obj.justifyContent = 'center';
    } else {
      if (column.frozen) {
        obj.textAlign = 'left';
        obj.justifyContent = 'left';
      } else {
        obj.textAlign = this.textAlign || 'left';
        obj.justifyContent = this.textAlign || 'left';
      }
    }
    const field = (column.field as string)?.toLowerCase();
    const header = (column.header as string)?.toLowerCase();
    const comb = `${field} ${header}`;
    if (
      comb.includes('date') ||
      comb.includes('time') ||
      comb.includes('month') ||
      comb.includes('week') ||
      field === 'po'
    ) {
      obj.textAlign = 'center';
      obj.justifyContent = 'center';
    }
    if (column.style) {
      return {
        ...obj,
        ...column.style,
      };
    }
    return obj;
  };

  closeTablePopup = () => {
    this.onClosePopup.emit(true);
  };

  convertDateVal = (value: any, col: any) => {
    if (col?.toLowerCase().includes('time')) {
      return commonDateTimeFormat(value);
    }
    if (col?.toLowerCase().includes('date')) {
      return convertDateToYYMMDD(value);
    }
    return value;
  };
}
