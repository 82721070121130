import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { SubmitButtonComponent } from '../submit-button/submit-button.component';
import { CancelButtonComponent } from '../cancel-button/cancel-button.component';
import { CommonModule } from '@angular/common';
import { IconComponent } from '../icon/icon.component';
type FileUploadHandlerEvent = { files: FileList };


@Component({
  selector: 'app-upload-button',
  standalone: true,
  imports: [SubmitButtonComponent, CancelButtonComponent, CommonModule, IconComponent],
  templateUrl: './upload-button.component.html',
  styleUrl: './upload-button.component.css'
})
export class UploadButtonComponent {
  @Input() disabled: boolean = false;
  @Input() disableRemove: boolean = false;

  @Output() onUpload = new EventEmitter<FileUploadHandlerEvent>();
  @Output() onRemoveClick = new EventEmitter<void>();

  @ViewChild('fileInput') fileInput!: ElementRef;

  fileName: string = '';
  dialogVisible: boolean = false;

  onFileSelected(event: Event): void {
    const target = event.target as HTMLInputElement;
    const files = target.files;
    if (files && files.length) {
      this.onUpload.emit({ files });
      this.fileName = files[0].name; // Set the file name for display
    }
  }

  openConfirmDialog(): void {
    this.dialogVisible = true;
  }

  closeConfirmDialog(): void {
    this.dialogVisible = false;
  }

  confirmFileRemoveClick(): void {
    this.onRemoveClick.emit();
    this.fileName = ''; // Clear the file name display
    this.fileInput.nativeElement.value = ''; // Clear the file input value
    this.closeConfirmDialog();
  }
}
