@if (id) {
<app-card
  class="h-100"
  [id]="id"
  [title]="cardTitle!"
  [downloadChart]="downloadChart"
  [endElement]="endElement"
  [loading]="loading"
>
  <div [id]="id" class="h-100" #donutChartElement></div>
</app-card>
}
